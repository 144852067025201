import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  Image, Platform,
} from 'react-native';
import { Link, useRoute } from '@react-navigation/native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import logo from '../../assets/886-logo-gold.png';
import AccountIcon from "../svgs/AccountIcon";
import { useHover } from 'react-native-web-hooks';
import { useNavigation, useLinkTo } from '@react-navigation/native';
import Ionicons from '@expo/vector-icons/Ionicons';
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import MenuIcon from "../svgs/MenuIcon";
import HoverTouchableOpacityLink from "../components/HoverTouchableOpacityLink";
import { useEffect, useState } from "react";
import appState from "../../app.state";
import DropdownMenu from "../components/DropdownMenu";
import DismissKeyboard from "../components/DismissKeyboard";

export default function DashboardLayout({ children }) {
  const window = useWindowDimensions();
  const route = useRoute();
  const navigation = useNavigation();
  const [u, setUser] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setUser(await appState.authService.whoami());
      } catch (e) {
        console.error(e);
      }
    })();
  });

  const ww = window.window;

  return Platform.OS === 'web' ? (
    <SafeAreaView style={{ flex: 1, backgroundColor: Constants.colors.backgroundPrimary }}>
      <View style={r.get('headerContainer', ww)}>
        <View style={r.get('headerLeftWrap', ww)}>
          <Image
            source={logo}
            style={r.get('logo', window.width)}
          />
        </View>
        {window.width > Constants.breakpoints[1] ? (
          <>
            <View style={r.get('headerCenterWrap', ww)}>
              <Link to={{ screen: 'Ideas' }}>
                <HoverTouchableOpacity style={[
                  r.get('menuItem', ww),
                  route.name === 'Ideas' && r.get('menuItemActive', ww)
                ]}>
                  <Text style={r.get('menuText', ww)}>Ideas</Text>
                </HoverTouchableOpacity>
              </Link>

              <Link to={{ screen: 'Charts', params: { symbol: 'BTC-USD' } }}>
                <HoverTouchableOpacity style={[
                  r.get('menuItem', ww),
                  route.name === 'Charts' && r.get('menuItemActive', ww)
                ]}>
                  <Text style={r.get('menuText', ww)}>Strategies</Text>
                </HoverTouchableOpacity>
              </Link>

              <Link to={{ screen: 'Learn' }}>
                <HoverTouchableOpacity style={[
                  r.get('menuItem', ww),
                  route.name === 'Learn' && r.get('menuItemActive', ww)
                ]}>
                  <Text style={r.get('menuText', ww)}>Learn</Text>
                </HoverTouchableOpacity>
              </Link>

              <Link to={{ screen: 'Live' }}>
                <HoverTouchableOpacity style={[
                  r.get('menuItem', ww),
                  route.name === 'Live' && r.get('menuItemActive', ww)
                ]}>
                  <Text style={r.get('menuText', ww)}>Live</Text>
                </HoverTouchableOpacity>
              </Link>
            </View>
            <View style={r.get('headerRightWrap', ww)}>
              {(() => {
                const menuitems = [
                  {
                    name: "Support",
                    to: { screen: 'Support' },
                  },
                  u && u.user_access.includes('manage users') && {
                    name: "Users",
                    to: { screen: 'Users', params: { page: 1 } },
                  },
                  u && u.user_access.includes('manage users') && {
                    name: "Payments",
                    to: { screen: 'Payments', params: { page: 1 } },
                  },
                  u && u.user_access.includes('manage users') && {
                    name: "Affiliates",
                    to: { screen: 'Affiliates', params: { page: 1 } },
                  },
                  u && u.affiliate && {
                    name: "Your Affiliate Dashboard",
                    to: { screen: 'AffiliateDashboard' },
                  },
                  u && u.hasWallet && {
                    name: "Your Wallet",
                    to: { screen: 'WalletDashboard' },
                  },
                  u && u.user_access.includes('manage options') && {
                    name: "Partners",
                    to: { screen: 'PartnerSettings' },
                  },
                  u && u.user_access.includes('manage options') && {
                    name: "App Wallet",
                    to: { screen: 'AppWallet' },
                  },
                ].filter(v => v)

                return menuitems && (
                  <DropdownMenu
                    renderButton={() => (
                      <View
                        style={[
                          { flexDirection: 'row', alignItems: 'center' },
                          r.get('menuItem', ww),
                        ]}
                      >
                        <Ionicons name="ios-cog-outline" color="#fff" size={22}
                                  style={{ marginRight: 10 }} />
                        {/*<Text style={r.get('menuText', ww)}>More</Text>*/}
                      </View>
                    )}
                    menuItems={menuitems}
                  />
                );
              })()}

              <Link to={{ screen: 'Account' }}>
                <HoverTouchableOpacity
                  style={[
                    { flexDirection: 'row', alignItems: 'center' },
                    r.get('menuItem', ww),
                    route.name === 'Account' && r.get('menuItemActive', ww)
                  ]}
                >
                  <AccountIcon style={{ width: 20, marginRight: 10 }} />
                  <Text style={r.get('menuText', ww)}>Account</Text>
                </HoverTouchableOpacity>
              </Link>
            </View>
          </>
        ) : (
          <View style={r.get('headerRightWrap', ww)}>
            <HoverTouchableOpacity
              style={[
                { flexDirection: 'row', alignItems: 'center' },
                r.get('menuItem', ww),
                { marginRight: -10, marginBottom: 0 },
              ]}
              onPress={() => navigation.toggleDrawer()}
            >
              <MenuIcon style={{ width: 20, marginRight: 10 }} />
              <Text style={r.get('menuText', ww)}>Menu</Text>
            </HoverTouchableOpacity>
          </View>
        )}
      </View>
      <View style={{ flex: 1, zIndex: 0, backgroundColor: Constants.colors.backgroundPrimary }}>
        {children}
      </View>
    </SafeAreaView>
  ) : <>{children}</>;
}

const baseStyles = StyleSheet.create({
  headerContainer: {
    backgroundColor: '#000',
    flexDirection: 'row',
    padding: 20,
    zIndex: 1,
    borderBottomWidth: 1,
    borderColor: '#32353d',
  },
  headerLeftWrap: {
    justifyContent: 'center',
    width: 200,
  },
  headerCenterWrap: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  headerRightWrap: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: 200,
    flexDirection: 'row',
  },
  logo: {
    width: 200,
    height: 30,
    resizeMode: 'contain',
  },
  menuItem: {
    marginHorizontal: 10,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderWidth: 1,
    borderRadius: 5,
  },
  menuItemActive: {
    borderColor: '#fff',
  },
  menuText: {
    fontSize: 14,
    letterSpacing: 1,
    fontWeight: '600',
    color: '#fff',
    textTransform: 'uppercase',
    fontFamily: Constants.fontFamily,
  },
});

const stylesSM = StyleSheet.create({
  headerLeftWrap: {
    flex: 1,
  },
  menuItem: {
    marginBottom: 20,
  },
    sidebar: {
        height: 0,
        width: 0,
    },
});

const stylesMD = StyleSheet.create({
  headerLeftWrap: {
    flex: 1,
  },
    sidebar: {
        height: 0,
        width: 0,
    },
});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
