import * as React from 'react';
import { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  TextInput,
  Platform,
  ActivityIndicator
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import PreAuthLayout from "../layouts/PreAuthLayout";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import appState from '../../app.state';
import { Link } from "@react-navigation/native";
import DismissKeyboard from "../components/DismissKeyboard";

export default function ForgotPassScreen() {
  const window = useWindowDimensions();
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState(false);
  const [e, setError] = useState(null);

  const fieldError = (f) => (e && e.errors && e.errors[f]) || [];

  const formSubmit = async function () {
    if (submitting) return;
    try {
      setSubmitting(true);
      const { message } = await appState.appApi.forgotPass({ email });
      setMessage(message);
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      setSubmitting(false);
    }
  };

  console.log('fielderrors', fieldError('email'), fieldError('password'));

  return (
    <PreAuthLayout>
      <DismissKeyboard>
        <View style={responsive.get('container', window.width)}>
          <View style={responsive.get('form', window.width)}>
            <Text style={responsive.get('heading', window.width)}>Password Reset</Text>

            {message && (
              <Text style={responsive.get('successMsg', window.width)}>{message}</Text>
            )}

            <Errors errors={fieldError('email')} />

            <TextInput
              style={responsive.get('input', window.width)}
              onChangeText={setEmail}
              value={email}
              keyboardType={'email-address'}
              placeholder={'Email'}
              placeholderTextColor={'#aaa'}
            />

            <HoverTouchableOpacity onPress={formSubmit} style={responsive.get('btn', window.width)}>
              {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
              <Text style={responsive.get('btnText', window.width)}>Send</Text>
            </HoverTouchableOpacity>
          </View>
          <Link to={'/login'}>
            <HoverTouchableOpacity style={responsive.get('link', window.width)}>
              <Text style={responsive.get('linkText', window.width)}>Login</Text>
            </HoverTouchableOpacity>
          </Link>
        </View>
      </DismissKeyboard>
    </PreAuthLayout>
  );
}

function Errors({ errors }) {
  if (!errors || errors.length === 0) return;

  return (
    <View style={responsive.get('errors', window.width)}>
      {errors.map((e) => (
        <Text key={e} style={responsive.get('error', window.width)}>{e}</Text>
      ))}
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundPrimary,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  form: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: Constants.colors.backgroundSecondary,
    padding: 30,
    borderRadius: 5,
    marginBottom: 20,
  },
  heading: {
    fontSize: 21,
    marginBottom: 20,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  btn: {
    backgroundColor: Constants.colors.accent,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnText: {
    color: Constants.colors.backgroundSecondary,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  linkText: {
    color: Constants.colors.textColor,
    fontSize: 14,
    fontFamily: Constants.fontFamily,
  },
  errors: {
    marginBottom: 10,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  successMsg: {
    color: '#000',
    backgroundColor: '#d0ffe3',
    borderWidth: 1,
    borderColor: '#33a963',
    padding: 15,
    borderRadius: 5,
    marginBottom: 20,
  },
});

const stylesSM = StyleSheet.create({});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const responsive = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
