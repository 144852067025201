import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  TextInput,
  ActivityIndicator,
  ScrollView,
  Image,
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import Swiper from 'react-native-swiper/src';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import { useCallback, useEffect, useState } from "react";
import SplashScreen from "./Splash";
import appState from '../../app.state';
import fmtAttachment from "../utilities/fmtAttachment";
import { fmtDT } from "../utilities/fmtDate";
import * as ImagePicker from "expo-image-picker";
import DraggableFlatList, { ScaleDecorator } from "react-native-draggable-flatlist";
import { useFocusEffect } from "@react-navigation/native";
import { LiteCreditCardInput } from "react-native-credit-card-input-plus";
import CardIcons from "react-native-credit-card-input-plus/src/Icons";

export default function ManageCardsScreen({ navigation, route }) {
  const window = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [e, setError] = useState(null);
  const [user, setUser] = useState({});
  const [cards, setCards] = useState([]);

  const [addCardSubmitting, setAddCardSubmitting] = useState(false);
  const [card, setCard] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [addCardError, setAddCardError] = useState(null);

  const [cardDefaultSubmitting, setCardDefaultSubmitting] = useState({});
  const [isDeleteCardOpen, setIsDeleteCardOpen] = useState({});

  const load = () => {
    (async () => {
      try {
        setLoading(true);
        const u = appState.authService.user;
        setUser(u);
        setCards(await appState.appApi.getCards({ user_id: u.id }));
      } catch (e) {
        setError(e);
        console.log('error');
      } finally {
        setLoading(false);
      }
    })();
  };

  const cardUpdated = (card) => {
    setCard(card);
    console.log({ card });
  };

  const deleteCardOpen = (card) => {
    setIsDeleteCardOpen({ [card.id]: true });
    setTimeout(() => {
      setIsDeleteCardOpen({});
    }, 5000);
  };

  const deleteCard = async (card) => {
    try {
      await appState.appApi.deleteCard(card.id);
      setCards(await appState.appApi.getCards({ user_id: user.id }));
    } catch (e) {
      setError(e);
    }
  };

  const makeCardDefault = async (c) => {
    if (Object.values(cardDefaultSubmitting).reduce((acc, v) => acc || v, false)) return;
    try {
      setCardDefaultSubmitting({ [c.id]: true });
      setError(null);

      await appState.appApi.setDefaultCard(c.id);
      setCards(await appState.appApi.getCards({ user_id: user.id }));
    } catch (e) {
      setError(e);
    } finally {
      setCardDefaultSubmitting({});
    }
  };

  const addCard = () => {
    (async () => {
      try {
        setAddCardSubmitting(true);
        const data = {
          user_id: user.id,
          card_number: card.values.number.replaceAll(' ', ''),
          card_type: card.values.type.toUpperCase().replace('-', ''),
          card_exp_month: card.values.expiry.split('/')[0],
          card_exp_year: card.values.expiry.split('/')[1],
          card_cvv: card.values.cvc,
          card_first_name: firstName,
          card_last_name: lastName,
          address: address,
          phone: phone,
          email: email,
        };
        await appState.appApi.saveCard(data);

        load();
        setCard({});
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setAddress('');
        setAddCardError(null);
      } catch (e) {
        setAddCardError(e);
      } finally {
        setAddCardSubmitting(false);
      }
    })();
  };

  useFocusEffect(useCallback(() => {
    load();
  }, [navigation, route]));

  const fieldError = (f) => (e && e.errors && e.errors[f]) || [];

  if (loading) return <SplashScreen />;

  console.log({ user, cards });

  navigation.setOptions({
    title: 'Manage Cards',
  });

  return (
    <TouchableWithoutFeedback onPress={() => Platform.OS === 'web' && navigation.goBack()}>
      <ScrollView style={{ height: window.height }}>
        <View style={[r.get('container', window.width)]}>
          <TouchableWithoutFeedback>
            <View style={[r.get('containerInner', window.width), { minHeight: window.height - 20 }]}
                  onStartShouldSetResponder={() => true}>
              {Platform.OS === 'web' && (
                <View style={r.get('head', window.width)}>
                  <Text style={r.get('title', window.width)}>Manage Cards</Text>

                  <HoverTouchableOpacity onPress={() => navigation.goBack()}
                                         style={[r.get('close', window.width)]}>
                    <Ionicons name="ios-close" size={22} style={r.get('closeIcon', window.width)}
                              accessibilityLabel={"Close"} />
                  </HoverTouchableOpacity>
                </View>
              )}

              <View style={r.get('body', window.width)}>
                <View style={[r.get('manageCardsWrap', window.width)]}>
                  {!!e && (
                    <Text style={r.get('errorMsg', window.width)}>{e.message}</Text>
                  )}

                  {cards.length === 0 ? (
                    <View>
                      <Text style={r.get('infoMsg', window.width)}>You have no cards. Add one below.</Text>
                    </View>
                  ) : (
                    <View style={r.get('cardsWrap', window.width)}>
                      {cards.map((c) => (
                        <View style={r.get('card', window.width)}>
                          <View style={r.get('cardInner', window.width)}>
                            <View style={r.get('cardInfoWrap', window.width)}>
                              <Image
                                style={r.get('cardicon', window.width)}
                                source={c.type === 'VISA' ? CardIcons['visa'] : CardIcons['master-card']}
                              />

                              <View style={r.get('cardInfoInner', window.width)}>
                                <Text style={r.get('cardInfo', window.width)}>{c.type} {c.last_digits}</Text>
                                <Text style={r.get('cardName', window.width)}>{c.name}</Text>
                              </View>

                              <HoverTouchableOpacity onPress={() => makeCardDefault(c)}>
                                {cardDefaultSubmitting[c.id] ? (
                                  <ActivityIndicator
                                    size={'small'}
                                    animating={true}
                                    color={Constants.colors.textColor}
                                  />
                                ) : (c.default ? (
                                  <Ionicons name="star" size={22} color={Constants.colors.textColor} />
                                ) : (
                                  <Ionicons name="star-outline" size={22} color={Constants.colors.textColor} />
                                ))}
                              </HoverTouchableOpacity>
                            </View>
                          </View>

                          <View style={r.get('deleteCardWrap', window.width)}>
                            {!isDeleteCardOpen[c.id] ? (
                              <HoverTouchableOpacity onPress={() => deleteCardOpen(c)}>
                                  <Text style={r.get('deleteCardText', window.width)}>Delete</Text>
                              </HoverTouchableOpacity>
                            ) : (
                              <HoverTouchableOpacity onPress={() => deleteCard(c)}>
                                <Text style={r.get('deleteCardText', window.width)}>Yes, I am sure.</Text>
                              </HoverTouchableOpacity>
                            )}
                          </View>
                        </View>
                      ))}
                    </View>
                  )}


                  <View style={[r.get('addCardForm', window.width)]}>
                    <Text style={r.get('heading2', window.width)}>Add Card</Text>

                    <View style={r.get('formGroup', window.width)}>
                      <Text style={r.get('label', window.width)}>Card</Text>
                      <View
                        style={[r.get('input', window.width), r.get('cardInput', window.width)]}>
                        <LiteCreditCardInput
                          onChange={cardUpdated}
                          inputStyle={{ color: Constants.colors.textColor, outlineStyle: 'none' }}
                        />
                      </View>
                      <View style={r.get('cardicons', window.width)}>
                        <Image style={r.get('cardicon', window.width)} source={CardIcons['visa']} />
                        <Image style={r.get('cardicon', window.width)} source={CardIcons['master-card']} />
                      </View>
                    </View>

                    <View style={r.get('inputRow', window.width)}>
                      <View style={[r.get('formGroup', window.width), { flex: 1, paddingRight: 15 }]}>
                        <Text style={r.get('label', window.width)}>Card First Name</Text>
                        <TextInput
                          style={r.get('input', window.width)}
                          onChangeText={setFirstName}
                          value={firstName}
                        />
                      </View>

                      <View style={[r.get('formGroup', window.width), { flex: 1 }]}>
                        <Text style={r.get('label', window.width)}>Card Last Name</Text>
                        <TextInput
                          style={r.get('input', window.width)}
                          onChangeText={setLastName}
                          value={lastName}
                        />
                      </View>
                    </View>

                    <View style={r.get('formGroup', window.width)}>
                      <Text style={r.get('label', window.width)}>Email</Text>
                      <TextInput
                        style={r.get('input', window.width)}
                        onChangeText={setEmail}
                        value={email}
                      />
                    </View>

                    <View style={r.get('formGroup', window.width)}>
                      <Text style={r.get('label', window.width)}>Phone</Text>
                      <TextInput
                        style={r.get('input', window.width)}
                        onChangeText={setPhone}
                        value={phone}
                      />
                    </View>

                    <View style={r.get('formGroup', window.width)}>
                      <Text style={r.get('label', window.width)}>Address</Text>
                      <TextInput
                        style={r.get('input', window.width)}
                        onChangeText={setAddress}
                        value={address}
                      />
                    </View>

                    {addCardError && (
                      <Errors errors={addCardError && addCardError.message ? [addCardError.message] : []} />
                    )}

                    <HoverTouchableOpacity onPress={() => addCard()}
                                           style={[r.get('btn', window.width), { marginTop: 20 }]}>
                      {addCardSubmitting && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
                      <Text style={r.get('btnText', window.width)}>Add Card</Text>
                    </HoverTouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </ScrollView>
    </TouchableWithoutFeedback>
  );
}


function ImagesList({ images, setImages }) {
  const removeImage = (i) => {
    const d = [...images];
    d.splice(i, 1);
    setImages(d);
  };

  const renderItem = ({ item, drag, isActive, getIndex }) => {
    return (
      <ScaleDecorator>
        <TouchableOpacity
          activeOpacity={0.9}
          onPressIn={drag}
          disabled={isActive}
          style={[r.get('imageWrap', window.width)]}
        >
          <Image source={item.source} style={[r.get('image', window.width)]}
                 resizeMode={'contain'} />

          <HoverTouchableOpacity
            containerStyle={r.get('imageIconCloseWrap', window.width)}
            style={r.get('imageIconClose', window.width)}
            onPress={() => removeImage(getIndex())}
          >
            <Ionicons name="ios-close-outline" size={22} color={'#fff'}
                      accessibilityLabel="Delete Image" />
          </HoverTouchableOpacity>
        </TouchableOpacity>
      </ScaleDecorator>
    );
  };

  return (
    <DraggableFlatList
      data={images}
      // horizontal={true}
      onDragEnd={({ data }) => setImages(data)}
      keyExtractor={(item) => item.key}
      renderItem={renderItem}
    />
  );
}

function Errors({ errors }) {
  if (!errors || errors.length === 0) return;

  return (
    <View style={r.get('errors', window.width)}>
      {errors.map((e) => (
        <Text key={e} style={r.get('error', window.width)}>{e}</Text>
      ))}
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: Platform.OS === 'web' ? {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
    paddingTop: 20,
  } : {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  containerInner: {
    minHeight: '50%',
    maxWidth: 800,
    width: '100%',
    backgroundColor: Constants.colors.backgroundSecondary,
    ...Platform.select({
      web: {
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
      },
    }),
  },
  closeIcon: {
    color: Constants.colors.textColor,
  },
  head: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 40,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  title: {
    flex: 1,
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    color: Constants.colors.textColor,
  },
  body: {
    flex: 1,
  },
  manageCardsWrap: {
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  heading2: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 10,
    color: Constants.colors.textColor,
  },
  smBtn: {
    flexDirection: 'row',
    // backgroundColor: '#000',
    paddingHorizontal: 15,
    paddingVertical: 5,
    alignItems: 'center',
    alignSelf: 'flex-start',
    borderRadius: 8,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: Constants.colors.textColor,
  },
  smBtnText: {
    color: Constants.colors.textColor,
    fontWeight: '500',
  },
  btn: {
    backgroundColor: Constants.colors.accent,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    minWidth: 200,
  },
  btnText: {
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.backgroundSecondary,
  },
  errors: {
    marginBottom: 0,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  label: {
    fontSize: 15,
    marginBottom: 5,
    color: Constants.colors.textColor,
    fontWeight: '600',
  },
  formGroup: {
    marginBottom: 15,
  },
  desc: {
    fontSize: 13,
    fontStyle: 'italic',
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  imagesWrap: {
    marginVertical: 10,
  },
  image: {
    width: '100%',
    aspectRatio: 2/1,
    borderRadius: 8,
    marginRight: 15,
    marginBottom: 15,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    backgroundColor: '#000',
  },
  imageIconCloseWrap: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  imageIconClose: {
    backgroundColor: '#b11a1a',
    borderWidth: 1,
    borderColor: '#fff',
    width: 25,
    height: 25,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  msgInput: {
    minHeight: 250,
    padding: 15,
    paddingTop: 15,
    paddingBottom: 15,
  },
  successMsg: {
    color: '#000',
    backgroundColor: '#d0ffe3',
    borderWidth: 1,
    borderColor: '#33a963',
    padding: 15,
    borderRadius: 5,
    marginBottom: 20,
  },
  errorMsg: {
    color: '#000',
    backgroundColor: '#ffd0d0',
    borderWidth: 1,
    borderColor: '#a93333',
    padding: 15,
    borderRadius: 5,
    marginBottom: 20,
  },
  infoMsg: {
    color: '#000',
    backgroundColor: '#d0efff',
    borderWidth: 1,
    borderColor: '#335ea9',
    padding: 15,
    borderRadius: 5,
    marginBottom: 20,
  },
  containerWrap: {
    backgroundColor: Constants.colors.backgroundPrimary,
  },
  inputRow: {
    flexDirection: 'row',
  },
  cardicons: {
    flexDirection: 'row',
    marginTop: -10,
  },
  cardicon: {
    width: 32,
    height: 27,
    resizeMode: "contain",
  },
  addCardForm: {
    marginTop: 20,
    paddingTop: 20,
    borderTopWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  cardsWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  card: {
    width: '33.3%',
  },
  cardInner: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 20,
    borderRadius: 6,
    marginBottom: 20,
    marginRight: 20,
  },
  cardInfoWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardInfo: {
    color: Constants.colors.textColor,
    fontWeight: '600',
  },
  cardInfoInner: {
    paddingLeft: 10,
    flex: 1,
  },
  cardName: {
    color: Constants.colors.textColor,
  },
  deleteCardWrap: {
    marginTop: -15,
    marginBottom: 20,
  },
  deleteCardText: {
    color: '#f31414',
    textDecorationLine: 'underline',
  },
});

const stylesSM = StyleSheet.create({
  addMessageWrap: {
    paddingHorizontal: 15,
    paddingVertical: 15,
  },
  container: {
    ...(Platform.OS === 'web' ? {
      paddingHorizontal: 8,
    } : {})
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
