import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator, ScrollView, TextInput, TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import appState from "../../app.state";
import { useCallback, useEffect, useState } from "react";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import PaginatedList from "../components/PaginatedList";
import { fmtD, fmtDT } from "../utilities/fmtDate";
import { Link, useFocusEffect } from "@react-navigation/native";
import DropDownPicker from "react-native-dropdown-picker";

export default function AffiliatesScreen({ navigation }) {
  const window = useWindowDimensions();
  const ww = window.width;

  const [focusIndex, setFocusIndex] = useState(0);
  const [search, setSearch] = useState('');

  const [status, setStatus] = useState('');
  const [statusOpen, setStatusOpen] = useState(false);
  const [statusOptions, setStatusOptions] = useState([
    { label: 'Any', value: '' },
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
    { label: 'Pending', value: 'pending' },
    { label: 'Expired', value: 'expired' },
  ]);

  const [role, setRole] = useState('');
  const [roleOpen, setRoleOpen] = useState(false);
  const [roleOptions, setRoleOptions] = useState([
    { label: 'Any', value: '' },
    { label: 'Subscriber', value: 'subscriber' },
    { label: 'Trader', value: 'trader' },
    { label: 'Customer Support', value: 'customer-support' },
    { label: 'Super Admin', value: 'super-admin' },
  ]);

  const dropdownsSetOpen = [setStatusOpen, setRoleOpen];

  const [inviteSending, setInviteSending] = useState(false);
  const [inviteError, setInviteError] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');

  const closeOtherDropdowns = (f) => {
    dropdownsSetOpen.map((f2) => {
      if (f !== f2) f2(false);
    });
  };

  const getData = async (p) => {
    return appState.appApi.getAffiliates({
      page: p,
      limit: 8,
      search,
    });
  };

  const sendInvite = async () => {
    try {
      setInviteSending(true);
      await appState.appApi.sendInvite({
        email: inviteEmail,
        global: true,
      })

      //reload
      const max = 9999999999999999;
      setFocusIndex(Math.floor(Math.random() * max));
    } catch (e) {
      console.error(e);
      setInviteError(true);
      setTimeout(() => setInviteError(false), 1000);
    } finally {
      setInviteSending(false);
    }
  };

  useFocusEffect(useCallback(() => {
    console.log('focus users', focusIndex);
    const max = 9999999999999999;
    setFocusIndex(Math.floor(Math.random() * max));
  }, [navigation]));

  navigation.setOptions({
    headerRight: () => (
      <Link to={{ screen: 'CreateUser' }}>
        <HoverTouchableOpacity
          style={[r.get('headerRightBtnInner', window.width)]}
        >
          <Ionicons name="ios-add-outline" size={28} color={Constants.colors.textColor} />
        </HoverTouchableOpacity>
      </Link>
    ),
  });

  return (
    <DashboardLayout>
      <ScrollView>
        <TouchableWithoutFeedback onPressIn={() => closeOtherDropdowns()}>
          <View style={r.get('container', ww)}>
            <View style={r.get('body', ww)}>
              {Platform.OS === 'web' ? (
                <>
                  <View style={r.get('headingWrap', ww)}>
                    <Text style={[r.get('heading', ww), { minWidth: 300 }]}>Affiliates</Text>

                    <View style={r.get('inviteWrap', ww)}>
                      <Text style={r.get('label', ww)}>Invite Affiliate</Text>
                      <View style={r.get('inviteInner', ww)}>
                        <View style={r.get('inviteInputCol', ww)}>
                          <TextInput
                            style={r.get('input', ww)}
                            onChangeText={setInviteEmail}
                            value={inviteEmail}
                            placeholder={'Email...'}
                            placeholderTextColor={'#aaa'}
                            onSubmitEditing={() => sendInvite()}
                          />
                        </View>

                        <HoverTouchableOpacity onPress={() => sendInvite()}
                                               style={[r.get('btnSm', ww), r.get('inviteSendBtn', ww)]}>
                          {inviteSending ? (
                            <ActivityIndicator color={Constants.colors.accent} />
                          ) : inviteError ? (
                            <Ionicons name="ios-warning-outline" size={18}
                                      color={Constants.colors.accent} />
                          ) : (
                            <Ionicons name="ios-paper-plane-outline" size={18}
                                      color={Constants.colors.accent} />
                          )}
                        </HoverTouchableOpacity>
                      </View>
                    </View>
                  </View>
                  <View style={r.get('div', ww)} />
                </>
              ) : (
                <>
                  <View style={r.get('inviteWrap', ww)}>
                    <Text style={r.get('label', ww)}>Invite Affiliate</Text>
                    <View style={r.get('inviteInner', ww)}>
                      <View style={r.get('inviteInputCol', ww)}>
                        <TextInput
                          style={r.get('input', ww)}
                          onChangeText={setInviteEmail}
                          value={inviteEmail}
                          placeholder={'Email...'}
                          placeholderTextColor={'#aaa'}
                          onSubmitEditing={() => sendInvite()}
                        />
                      </View>

                      <HoverTouchableOpacity onPress={() => sendInvite()}
                                             style={[r.get('btnSm', ww), r.get('inviteSendBtn', ww)]}>
                        {inviteSending ? (
                          <ActivityIndicator color={Constants.colors.accent} />
                        ) : inviteError ? (
                          <Ionicons name="ios-warning-outline" size={18}
                                    color={Constants.colors.accent} />
                        ) : (
                          <Ionicons name="ios-paper-plane-outline" size={18}
                                    color={Constants.colors.accent} />
                        )}
                      </HoverTouchableOpacity>
                    </View>
                  </View>
                  <View style={r.get('div', ww)} />
                </>
              )}


              <View style={r.get('filters', ww)}>
                <View style={r.get('filter', ww)}>
                  <Text style={r.get('label', ww)}>Search</Text>

                  <TextInput
                    style={r.get('input', ww)}
                    onChangeText={setSearch}
                    value={search}
                    placeholder={'Search by user\'s email'}
                    placeholderTextColor={'#aaa'}
                  />
                </View>

              </View>

              <PaginatedList
                extraData={JSON.stringify([search, status, role, focusIndex])}
                getData={getData}
                renderItem={({ item, index }) => (
                  <HoverTouchableOpacity
                    containerStyle={[r.get('rowWrap', ww)]}
                    style={[r.get('row', ww), Platform.OS !== 'web' && { width: ww - 40 }]}
                    key={index}
                    onPress={() => navigation.navigate('AffiliateDashboard', { id: item.id })}
                  >
                    <Link to={{ screen: 'AffiliateDashboard', params: { id: item.id } }}
                          style={[r.get('rowLink', ww)]}>
                      <View style={[r.get('rowInner', ww)]}>
                        <View style={r.get('rowCol', ww)}>
                          <Text
                            style={r.get('rowTitle', ww)}>{item.user_info && item.user_info.email}</Text>
                          <Text
                            style={r.get('rowValue', ww)}>Level {item.level} • {item.active_subs} Active
                            Subscribers</Text>
                        </View>
                        <View style={[r.get('rowCol', ww), r.get('rowColLast', ww)]}>
                          <Text style={r.get('rowValue', ww)}>{fmtDT(item.created_at)}</Text>
                        </View>
                      </View>
                    </Link>
                  </HoverTouchableOpacity>
                )}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
    </DashboardLayout>
  );
}

function makeTitle(slug) {
  var words = slug.split('-');

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(' ');
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  body: {
    padding: 40,
    backgroundColor: Constants.colors.backgroundSecondary,
    maxWidth: 900,
    width: '100%',
    margin: 20,
    borderRadius: 8,

    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 7,

    elevation: 3,

    overflow: 'hidden',

    minHeight: 500,
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  row: {
    paddingHorizontal: 25,
    paddingVertical: 15,
    backgroundColor: Constants.colors.backgroundThird,
    borderRadius: 8,

    borderColor: Constants.colors.borderColor,
    borderWidth: 1,

    overflow: 'hidden',
    marginVertical: 5,

    cursor: 'pointer',
  },
  rowTitle: {
    fontSize: 15,
    fontWeight: '600',
    color: Constants.colors.textColor,
  },
  circleBtn: {
    backgroundColor: '#000',
    cursor: 'pointer',
    borderRadius: 50,
    height: 32,
    width: 32,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    marginLeft: -10,
  },
  filters: {
    flexDirection: 'row',
    zIndex: 1,
  },
  filter: {
    marginRight: 10,
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  dropdown: {
    width: 230,
    borderRadius: 5,
    borderColor: Constants.colors.borderColor,
    backgroundColor: Constants.colors.backgroundSecondary,
    paddingHorizontal: 15,
    paddingVertical: 10,
    position: 'relative',
    marginBottom: 10,
  },
  headerRightBtnInner: {
    padding: 5,
    marginTop: 5,
  },
  rowValue: {
    color: Constants.colors.textColor,
  },
  inviteWrap: {
    flex: 1,
  },
  inviteInner: {
    flexDirection: 'row',
  },
  inviteInputCol: {
    flex: 1,
  },
  inviteSendBtn: {
    height: 50,
    marginLeft: -7,
    backgroundColor: Constants.colors.backgroundSecondary,
  },
  btnSm: {
    borderColor: Constants.colors.accent,
    borderWidth: 1,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  div: {
    marginVertical: 30,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
});

const stylesSM = StyleSheet.create({
  filters: {
    flexDirection: 'column',
  },
  filter: {
    width: '100%',
  },
  dropdown: {
    width: '100%',
  },
  body: {
    padding: 20,
    margin: 0,
  },

  // rowWrap: {
  //   width: '100%',
  // },
  // row: {
  //   width: '100%',
  // },
  // rowCol: {
  //   width: '100%',
  // },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({
  // row: {
  //   flexDirection: 'row',
  // },
  rowInner: {
    width: '100%',
    flexDirection: 'row',
  },
  rowCol: {
    flex: 1,
    justifyContent: 'center',
  },
  rowColLast: {
    alignItems: 'flex-end',
  },
  rowWrap: {
    flex: 1,
    width: '100%',
  },
  headingWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
