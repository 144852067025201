import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator,
  Image, TextInput,
  ScrollView,
  Platform,
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import appState from '../../app.state';
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import Ionicons from '@expo/vector-icons/Ionicons';
import { useCallback, useEffect, useState } from "react";
import SplashScreen from "./Splash";
import { fmtD, fmtDT } from "../utilities/fmtDate";
import fmtAttachment from "../utilities/fmtAttachment";
import { DateTime } from "luxon";
import * as ImagePicker from 'expo-image-picker';
import { Link, useFocusEffect } from "@react-navigation/native";
import PaginatedList from "../components/PaginatedList";

export default function WalletDashboardScreen({ navigation, route }) {
  const window = useWindowDimensions();
  const ww = window.width;
  const [loading, setLoading] = useState(true);
  const [e, setError] = useState(null);
  const [user, setUser] = useState('');
  const [focusIndex, setFocusIndex] = useState(0);
  const [ethAddrSaving, setEthAddrSaving] = useState(false);
  const [ethAddrError, setEthAddrError] = useState(false);
  const [ethAddr, setEthAddr] = useState('');
  const [ethPrice, setEthPrice] = useState('');
  const [wallet, setWallet] = useState(null);
  const [payoutSum, setPayoutSum] = useState(null);

  const fieldError = (f) => (e && e.errors && e.errors[f]) || [];

  useFocusEffect(useCallback(() => {
    const max = 9999999999999999;
    setFocusIndex(Math.floor(Math.random() * max));

    (async () => {
      try {
        setLoading(true);
        const u = appState.authService.user;
        console.log({ u: JSON.parse(JSON.stringify(u)) });
        setUser(u);

        const wallet = await appState.appApi.getWallet();
        setWallet(wallet);
        setEthAddr(wallet.payout_address);

        setPayoutSum(await appState.appApi.getPayoutSum({
          user_id: u.id,
        }));

        // https://api.exchange.coinbase.com/products/ETH-USD/ticker
        const priceRsp = await (await fetch('https://api.exchange.coinbase.com/products/ETH-USD/ticker')).json();
        setEthPrice(priceRsp.price);

        setLoading(false);
      } catch (e) {
        console.error(e);
        setError(e);
      }
    })();
  }, [navigation]));

  const getPayouts = async (p) => {
    return appState.appApi.getPayouts({
      page: p,
      limit: 4,
      user_id: user.id,
    });
  };

  const getWithdrawals = async (p) => {
    return appState.appApi.getWithdrawals({
      page: p,
      limit: 4,
      user_id: user.id,
    });
  };

  const saveEthAddr = async () => {
    try {
      setEthAddrSaving(true);
      await appState.appApi.updateWallet({
        payout_address: ethAddr,
      });

      //reload
      // const max = 9999999999999999;
      // setFocusIndex(Math.floor(Math.random() * max));
    } catch (e) {
      console.error(e);
      setEthAddrError(true);
      setTimeout(() => setEthAddrError(false), 1000);
    } finally {
      setEthAddrSaving(false);
    }
  };

  if (loading) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  return (
    <DashboardLayout>
      <ScrollView>
        <View style={r.get('container', ww)}>
          {Platform.OS === 'web' && (
            <Text style={r.get('title', ww)}>Wallet</Text>
          )}

          <View style={r.get('row', ww)}>
            <View
              style={[r.get('column', ww), r.get('sidebarColumn', ww)]}>
              <Text
                style={[r.get('inputHeading', ww), { marginBottom: 5 }]}>Payout Address (ETH)</Text>

              <View style={r.get('ethAddrWrap', ww)}>
                <View style={r.get('ethAddrInputCol', ww)}>
                  <TextInput
                    style={r.get('input', ww)}
                    onChangeText={setEthAddr}
                    value={ethAddr}
                    placeholder={'enter eth address...'}
                    placeholderTextColor={'#aaa'}
                    onSubmitEditing={() => saveEthAddr()}
                  />
                </View>

                <View style={{ alignItems: 'flex-start' }}>
                  <HoverTouchableOpacity
                    onPress={() => saveEthAddr()}
                    style={[r.get('btnSm', ww), r.get('btnLeft', ww), { marginTop: 0 }]}
                  >
                    <Text style={[r.get('btnTextSm', ww), { marginRight: 10 }]}>Save</Text>
                    {ethAddrSaving ? (
                      <ActivityIndicator color={Constants.colors.accent} />
                    ) : ethAddrError ? (
                      <Ionicons name="ios-warning-outline" size={18}
                                color={Constants.colors.accent} />
                    ) : (
                      <Ionicons name="ios-wallet-outline" size={18}
                                color={Constants.colors.accent} />
                    )}
                  </HoverTouchableOpacity>
                </View>
              </View>

              <View style={[r.get('div', ww), { marginBottom: 5 }]} />

              <Text style={r.get('statTitle', ww)}>Current Balance</Text>
              <Text style={r.get('statValMd', ww)}>{wallet.balance} ETH</Text>
              <Text style={r.get('statDescMd', ww)}>~{ethToUSD(wallet.balance, ethPrice)}</Text>

              <View style={{ alignItems: 'flex-start' }}>
                <HoverTouchableOpacity
                  style={[r.get('btnSm', ww), r.get('btnLeft', ww), { marginTop: 10 }]}>
                  <Text style={r.get('btnTextSm', ww)}>Withdraw</Text>
                </HoverTouchableOpacity>
              </View>

              <View style={[r.get('div', ww), { marginBottom: 5 }]} />

              <Text style={r.get('statTitle', ww)}>Income (30 Days)</Text>
              <Text style={r.get('statValMd', ww)}>{payoutSum.month} ETH</Text>
              <Text style={r.get('statDescMd', ww)}>~{ethToUSD(payoutSum.month, ethPrice)}</Text>

              <Text style={r.get('statTitle', ww)}>Income (All Time)</Text>
              <Text style={r.get('statValMd', ww)}>{payoutSum.total} ETH</Text>
              <Text style={r.get('statDescMd', ww)}>~{ethToUSD(payoutSum.total, ethPrice)}</Text>
            </View>

            <View
              style={[r.get('column', ww), r.get('mainColumn', ww)]}>
              <Text style={[r.get('heading', ww)]}>Income</Text>

              <PaginatedList
                extraData={JSON.stringify([focusIndex])}
                getData={getPayouts}
                renderItem={({ item, index }) => (
                  <View
                    style={[r.get('listRow', ww), { cursor: 'default', flex: 1, width: '100%' }]}
                    key={index}>
                    <View style={[r.get('rowInner', ww)]}>
                      <View style={r.get('rowCol', ww)}>
                        <Text style={r.get('rowTitle', ww)}>{item.amount} {item.currency}</Text>
                      </View>
                      <View style={[r.get('rowCol', ww), r.get('rowColLast', ww)]}>
                        <Text style={r.get('rowValue', ww)}>{fmtDT(item.created_at)}</Text>
                      </View>
                    </View>
                  </View>
                )}
              />

              <View style={r.get('div', ww)} />

              <Text style={[r.get('heading', ww)]}>Withdrawals</Text>

              <PaginatedList
                extraData={JSON.stringify([focusIndex])}
                getData={getWithdrawals}
                renderItem={({ item, index }) => (
                  <View
                    style={[r.get('listRow', ww), { cursor: 'default', flex: 1, width: '100%' }]}
                    key={index}>
                    <View style={[r.get('rowInner', ww)]}>
                      <View style={r.get('rowCol', ww)}>
                        <Text style={r.get('rowTitle', ww)}>{item.amount} {item.currency}</Text>
                        <Text style={r.get('rowValue', ww)}>+ Fee: {item.fee} {item.currency}</Text>
                      </View>
                      <View style={[r.get('rowCol', ww), r.get('rowColLast', ww)]}>
                        <Text style={[r.get('rowValue', ww), { fontSize: 12 }]}>{item.to}</Text>
                        <Text style={r.get('rowValue', ww)}>{fmtDT(item.created_at)}</Text>
                      </View>
                    </View>
                  </View>
                )}
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </DashboardLayout>
  );
}

function ethToUSD(bal, price) {
  return `$${(parseFloat(bal) * parseFloat(price)).toFixed(2)}`;
}

function Errors({ errors }) {
  const window = useWindowDimensions();
  const ww = window.width;
  if (!errors || errors.length === 0) return;

  return (
    <View style={r.get('errors', ww)}>
      {errors.map((e) => (
        <Text key={e} style={r.get('error', ww)}>{e}</Text>
      ))}
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    marginBottom: 40,
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 1000,
  },
  title: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginVertical: 30,
    color: Constants.colors.textColor,
  },
  profileHead: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  heading: {
    fontSize: 18,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  inputHeading: {
    fontSize: 15,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  row: {
    flexDirection: 'row',
    flex: 1,
    maxWidth: 1000,
    width: '100%',
  },
  column: {
    backgroundColor: Constants.colors.backgroundSecondary,
    padding: 30,
    borderRadius: 8,
    marginBottom: 30,

    overflow: 'hidden',

    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,

    elevation: 3,
  },
  mainColumn: {
    flex: 1,
    marginLeft: 10,
  },
  sidebarColumn: {
    width: 300,
  },
  btn: {
    borderColor: Constants.colors.accent,
    borderWidth: 1,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnSm: {
    borderColor: Constants.colors.accent,
    borderWidth: 1,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  btnText: {
    color: Constants.colors.backgroundSecondary,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  btnTextSm: {
    color: Constants.colors.accent,
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  div: {
    marginVertical: 30,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  errors: {
    marginBottom: 10,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  btnLeft: {
    alignSelf: 'flex-start',
  },
  successMsg: {
    color: '#000',
    backgroundColor: '#d0ffe3',
    borderWidth: 1,
    borderColor: '#33a963',
    padding: 15,
    borderRadius: 5,
    marginBottom: 20,
  },
  colTitle: {
    color: Constants.colors.textColor,
    fontSize: 24,
  },
  statTitle: {
    color: Constants.colors.accent,
    fontSize: 12,
    marginTop: 25,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '600',
    marginBottom: 10,
    // opacity: 0.8,
  },
  statValLg: {
    color: Constants.colors.textColor,
    fontSize: 32,
  },
  statValMd: {
    color: Constants.colors.textColor,
    fontSize: 21,
  },
  statDescMd: {
    color: Constants.colors.textColor,
    fontSize: 16,
    fontStyle: 'italic',
  },
  // ethAddrWrap: {
  //   flexDirection: 'row',
  // },
  ethAddrInputCol: {
    flex: 1,
  },
  inviteSendBtn: {
    height: 50,
    // marginLeft: -7,
    backgroundColor: Constants.colors.backgroundSecondary,
  },
  rowValue: {
    color: Constants.colors.textColor,
  },
  listRow: {
    paddingHorizontal: 25,
    paddingVertical: 15,
    backgroundColor: Constants.colors.backgroundThird,
    borderRadius: 8,

    borderColor: Constants.colors.borderColor,
    borderWidth: 1,

    overflow: 'hidden',
    marginVertical: 5,

    cursor: 'pointer',
  },
  rowTitle: {
    fontSize: 15,
    fontWeight: '600',
    color: Constants.colors.textColor,
  },
});

const stylesSM = StyleSheet.create({
  row: {
    flexDirection: 'column',
  },
  column: {
    borderRadius: 0,
  },
  mainColumn: {
    marginLeft: 0,
  },
  sidebarColumn: {
    width: '100%',
  },
  footer: {
    alignItems: 'center',
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({
  rowInner: {
    width: '100%',
    flexDirection: 'row',
  },
  rowCol: {
    flex: 1,
    justifyContent: 'center',
  },
  rowColLast: {
    alignItems: 'flex-end',
  },
  rowWrap: {
    flex: 1,
    width: '100%',
  },

});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
