import { observable } from "mobx";
import {
  APP_API_URL,
  APP_CHARTS_LIBRARY_URL,
  APP_TV_DATAFEED_LIB_URL,
} from '@env';
import AsyncStorageAuthProvider from "./src/adapters/AsyncStorageAuthProvider";
import AppAPI from "./src/adapters/AppAPI";
import AuthService from "./src/services/AuthService";
import PushNotificationService from "./src/services/PushNotificationService";

const state = observable({});
const test12 = '';
const authProvider = new AsyncStorageAuthProvider();
const appApi = new AppAPI({
  endpoint: APP_API_URL,
  authProvider,
});
const authService = new AuthService({ authProvider, appApi });
const pushNotificationService = new PushNotificationService();

export default {
  state,
  authProvider,
  appApi,
  authService,
  pushNotificationService,
  config: {
    APP_API_URL,
    APP_CHARTS_LIBRARY_URL,
    APP_TV_DATAFEED_LIB_URL,
  },
};
