import * as React from 'react';
import { useCallback, useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  TextInput,
  Platform,
  ActivityIndicator,
  Alert,
  ScrollView,
} from 'react-native';
import { A } from '@expo/html-elements';
import QRCode from 'react-native-qrcode-svg';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import PreAuthLayout from "../layouts/PreAuthLayout";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import appState from '../../app.state';
import { Link, useFocusEffect } from "@react-navigation/native";
import DashboardLayout from "../layouts/DashboardLayout";
import SplashScreen from "./Splash";
import { fmtDT } from "../utilities/fmtDate";
import { DateTime } from "luxon";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as Clipboard from 'expo-clipboard';


export default function PayScreen({ navigation, route }) {
  const window = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [p, setPayment] = useState(true);

  const { id } = route.params;

  useFocusEffect(useCallback(() => {
    (async () => {
      setLoading(true);
      setPayment(await appState.appApi.getPayment(id));
      setLoading(false);
    })();
  }, [navigation, id]));

  if (loading) return <SplashScreen />;

  const expires = DateTime.fromISO(p.created_at).plus(1000 * 60 * 60 * 6).toISO();

  let status = p.status === 'paid' ? 'Paid' : 'Pending';
  if (p.status !== 'paid' && DateTime.fromISO(p.created_at).plus(1000 * 60 * 60 * 4).toMillis() < DateTime.now()) {
    status = 'Expired';
  }

  const copyAddress = () => {
    (async () => {
      try {
        console.log('copyAddress');
        await Clipboard.setStringAsync(p.address);
        alert('Address Copied');
      } catch (e) {
        console.error(e);
      }
    })();
  };

  navigation.setOptions({
    title: `Pay (ID: ${id})`,
  });

  const isCardPayment = p && p.type === 'pf_card';

  return (
    <DashboardLayout>
      <View style={responsive.get('containerWrap', window.width)}>
        <ScrollView>
          <View style={responsive.get('container', window.width)}>
            <View style={responsive.get('form', window.width)}>
              <Text
                style={[responsive.get('statusText', window.width), { backgroundColor: status === 'Expired' ? '#b81111' : status === 'Paid' ? '#11b83b' : '#0046a5' }]}>{status}</Text>
              <View style={[responsive.get('formHeader', window.width), isCardPayment && { paddingBottom: 25 }]}>
                <View style={[responsive.get('headingWrap', window.width)]}>
                  {Platform.OS === 'web' && (
                    <Text style={[responsive.get('heading', window.width), isCardPayment && { textAlign: 'center' }]}>Pay (ID: {id})</Text>
                  )}

                  {!isCardPayment && (
                    <View style={[responsive.get('statusWrap', window.width)]}>
                      <A
                        hrefAttrs={{ target: "_blank" }}
                        href={`https://www.etherchain.org/account/${p.address}#txs`}
                      >
                        <HoverTouchableOpacity
                          style={[responsive.get('link', window.width), responsive.get('viewTrx', window.width)]}>
                          <Text style={{ color: Constants.colors.textColor }}>Monitor
                            Transactions</Text>
                          <Ionicons
                            style={[responsive.get('openIcon', window.width), responsive.get('openIconViewTrx', window.width)]}
                            name={'ios-open-outline'} size={18} />
                        </HoverTouchableOpacity>
                      </A>
                    </View>
                  )}
                </View>
              </View>

              <View style={responsive.get('formBody', window.width)}>
                <View style={responsive.get('infoWrap', window.width)}>
                  <Text
                    style={responsive.get('amountText', window.width)}>{p.currency === 'USD' ? parseFloat(p.amount).toFixed(2) : p.amount} {p.currency}</Text>
                  <Text
                    style={responsive.get('descText', window.width)}>{`For ${p.interval} ${p.interval_type}${p.interval > 1 ? 's' : ''} of membership`}</Text>

                  {isCardPayment && (
                    <>
                      <View style={responsive.get('cardUsed', window.width)}>
                        <Text style={responsive.get('cardUsedText', window.width)}>{p.card_type} {p.last_digits}</Text>
                      </View>
                    </>
                  )}

                  {!isCardPayment && (
                    <HoverTouchableOpacity style={[responsive.get('linkBadge', window.width)]}
                                           onPress={() => copyAddress()}>
                      <Ionicons
                        style={[responsive.get('openIcon', window.width), { marginRight: 5 }]}
                        name={'ios-copy-outline'} size={18} color={'#fff'} />
                      <Text style={[responsive.get('linkBadgeText', window.width)]}>Copy
                        Address</Text>
                    </HoverTouchableOpacity>
                  )}
                </View>

                {!isCardPayment && (
                  <View style={responsive.get('qrAndLink', window.width)}>
                    <QRCode size={200} value={etherPaymentLink(p.address, parseFloat(p.amount))} />


                    <A href={etherPaymentLink(p.address, parseFloat(p.amount))}>
                      <HoverTouchableOpacity style={[responsive.get('link', window.width)]}>
                        <Text style={{ color: Constants.colors.textColor, }}>Open in Wallet</Text>
                        <Ionicons
                          style={[responsive.get('openIcon', window.width)]}
                          name={'ios-open-outline'} size={18}
                        />
                      </HoverTouchableOpacity>
                    </A>

                  </View>
                )}

                <View style={responsive.get('dates', window.width)}>
                  <Text
                    style={{ color: Constants.colors.textColor, }}>Created: {fmtDT(p.created_at)}</Text>
                  {!isCardPayment && (
                    <Text
                      style={{ color: Constants.colors.textColor, }}>Expires: {fmtDT(expires)}</Text>
                  )}
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </DashboardLayout>
  );
}

function etherPaymentLink(address, value) {
  return `ethereum:pay-${address}?value=${value.toExponential()}`
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  form: {
    width: '100%',
    maxWidth: 320,
    backgroundColor: Constants.colors.backgroundSecondary,
    borderRadius: 5,
    marginBottom: 20,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  formHeader: {
    padding: 30,
    paddingBottom: 0,
    paddingTop: 25,
    backgroundColor: Constants.colors.backgroundThird,
  },
  formBody: {
    padding: 30,
  },
  heading: {
    fontSize: 21,
    fontFamily: Constants.fontFamily,
    marginBottom: -15,
    fontWeight: '600',
    color: Constants.colors.textColor,
  },
  headingWrap: {
    marginBottom: 20,
  },
  statusWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    opacity: 1,
  },
  statusText: {
    color: '#fff',
    backgroundColor: '#444',
    textAlign: 'center',
    padding: 8,
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  infoWrap: {
    marginBottom: 20,
    textAlign: 'center',
    justifyContent: 'center',
  },
  amountText: {
    fontWeight: '600',
    fontSize: 22,
    color: Constants.colors.textColor,
  },
  descText: {
    fontSize: 14,
    opacity: 0.6,
    fontStyle: 'italic',
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  btn: {
    backgroundColor: Constants.colors.backgroundPrimary,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnText: {
    color: Constants.colors.textColor,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },

  linkText: {
    fontSize: 14,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  errors: {
    marginBottom: 10,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  openIcon: {
    paddingLeft: 5,
    color: Constants.colors.textColor,
  },
  link: {
    flexDirection: 'row',
    marginVertical: 10,
    borderBottomWidth: 1,
    borderColor: Constants.colors.textColor,
  },
  qrAndLink: {
    alignItems: 'center',
  },
  dates: {
    alignItems: 'center',
    marginTop: 10,
    opacity: 0.7,
  },
  viewTrx: {
    margin: 0,
    alignSelf: 'flex-start',
    // marginLeft: 5,
    color: Constants.colors.textColor,
    borderColor: Constants.colors.textColor,
  },
  linkBadge: {
    borderRadius: 20,
    backgroundColor: '#000',
    paddingVertical: 4,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  linkBadgeText: {
    color: Constants.colors.textColor,
  },
  cardUsed: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  cardUsedText: {
    backgroundColor: Constants.colors.backgroundThird,
    color: Constants.colors.textColor,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 10,
    borderRadius: 4,
    fontWeight: '600',
  },
});

const stylesSM = StyleSheet.create({
  container: {
    padding: 0,
    backgroundColor: Constants.colors.backgroundSecondary,
  },
  form: {
    maxWidth: 700,
    marginBottom: 0,
    borderWidth: 0,
    borderRadius: 0,
  },
  formHeader: {
    backgroundColor: Constants.colors.backgroundSecondary,
    padding: 5,
    alignItems: 'center',
  },
  viewTrx: {
    color: Constants.colors.textColor,
    borderColor: Constants.colors.textColor,
  },
  openIconViewTrx: {
    color: Constants.colors.textColor,
  },
  amountText: {
    textAlign: 'center',
  },
  descText: {
    textAlign: 'center',
  },
  headingWrap: {
    marginBottom: 0,
  },
  formBody: {
    padding: 0,
    paddingBottom: 40,
  },
  containerWrap: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundPrimary,
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const responsive = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
