import * as React from "react"
import Svg, { Defs, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

const MenuIcon = (props) => (
  <Svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 229.39 155.4"
    {...props}
  >
    <Defs></Defs>
    <Path
      fill={'#fff'}
      className="cls-1"
      d="M219.22.04c.83.11 1.04.11 1.86.32 2.13.55 4.08 1.75 5.54 3.41.83.95 1.5 2.03 1.97 3.2.46 1.16.73 2.41.79 3.66.17 4.15-2.06 8.14-5.68 10.17-1.1.61-2.3 1.04-3.54 1.24-.83.15-1.04.12-1.88.16H11.1c-.84-.04-1.05-.02-1.87-.16-2.18-.37-4.22-1.4-5.81-2.92a11.21 11.21 0 0 1-2.23-3.02c-.57-1.12-.94-2.34-1.1-3.58C-.36 9.05.9 5.5 3.42 3.09c.68-.65 1.44-1.21 2.27-1.68C6.78.8 7.98.37 9.23.16 10.05.01 10.27.04 11.1 0h207.19c.31.02.63.03.94.04ZM219.22 66.64c.83.11 1.04.11 1.86.32 2.13.55 4.08 1.75 5.54 3.41.83.95 1.5 2.03 1.97 3.2 1.55 3.85.77 8.36-1.97 11.47-.83.95-1.82 1.75-2.91 2.36-1.1.61-2.3 1.04-3.54 1.24-.83.15-1.04.12-1.88.16H11.1c-.84-.04-1.05-.02-1.87-.16-1.24-.21-2.44-.64-3.54-1.24C2.65 85.69.54 82.58.1 79.12c-.12-.94-.12-1.89 0-2.83a11.156 11.156 0 0 1 3.33-6.6c1.59-1.52 3.63-2.55 5.81-2.92.82-.15 1.04-.12 1.87-.16h207.18c.31.02.63.03.94.04ZM219.22 133.24c.83.11 1.04.11 1.86.32 2.13.55 4.08 1.75 5.54 3.41 2.74 3.12 3.52 7.62 1.97 11.47-.46 1.17-1.14 2.25-1.97 3.2-.83.95-1.82 1.75-2.91 2.36-1.1.61-2.3 1.04-3.54 1.24-.83.15-1.04.12-1.88.16H11.1c-.84-.04-1.05-.02-1.87-.16-2.18-.37-4.22-1.4-5.81-2.92-.68-.65-1.27-1.39-1.77-2.2-1.84-2.97-2.15-6.72-.85-9.95.47-1.17 1.14-2.25 1.97-3.2.83-.95 1.82-1.75 2.91-2.36 1.1-.61 2.3-1.04 3.54-1.24.82-.15 1.04-.12 1.87-.16h207.19c.31.02.63.03.94.04Z"
    />
  </Svg>
)

export default MenuIcon
