import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  TextInput,
  ActivityIndicator,
  // ScrollView,
  Image, FlatList, RefreshControl, Animated,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Ionicons from '@expo/vector-icons/Ionicons';
import Swiper from 'react-native-swiper/src';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import { useCallback, useEffect, useRef, useState } from "react";
import SplashScreen from "./Splash";
import appState from '../../app.state';
import fmtAttachment from "../utilities/fmtAttachment";
import { fmtDT } from "../utilities/fmtDate";
import DashboardLayout from "../layouts/DashboardLayout";
import { observer } from "mobx-react-lite";
import { observable, runInAction } from "mobx";
import Lightbox from "react-native-lightbox";
import { useFocusEffect } from "@react-navigation/native";

export default function ChatScreen({ navigation, route }) {
  const window = useWindowDimensions();
  const ww = window.width;
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [e, setError] = useState(true);
  const [u, setUser] = useState(false);
  const [messages, setMessages] = useState(false);
  const [messagesPagination, setMessagesPagination] = useState(false);

  let { group } = route.params;
  let title = 'Chat';
  if (group === 'idea') {
    title = 'Ideas Chat';
  } else if (group.startsWith('chart-')) {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const groupPieces = group.split('-');
    title = `${capitalizeFirstLetter(groupPieces[1])} ${groupPieces[2]}-${groupPieces[3]} Chat`;
  }

  const loadMessages = async () => {
    const chatRes = await appState.appApi.getBroadcastMessages({ group, limit: 10 });
    setMessagesPagination(chatRes);
    setMessages(chatRes.data);
    console.log({ messages: chatRes.data });
  };

  const refreshMessages = () => {
    (async () => {
      try {
        setError(false);
        setRefreshing(true);
        await loadMessages();
      } catch (e) {
        setError(e);
      } finally {
        setRefreshing(false);
      }
    })();
  };

  useFocusEffect(useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        setUser(await appState.authService.whoami());
        await loadMessages();
      } catch (e) {
        setError(e);
        console.log('error');
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation]));

  const editMessage = (id) => {
    navigation.navigate('AddEditMessage', { id, group: 'idea' });
  };

  const deleteMessage = (id) => {
    navigation.navigate('DeleteMessage', { id });
  };

  if (loading) return <SplashScreen />;

  navigation.setOptions({
    headerLeftStyle: { marginLeft: 10 },
  });

  if (u.user_access.includes('manage chats')) {
    navigation.setOptions({
      title,
      headerRight: () => (
        <HoverTouchableOpacity style={[r.get('headerRightBtn', ww)]}
                               onPress={() => navigation.navigate('AddEditMessage', {
                                 id: 'new',
                                 group: 'idea'
                               })}>
          <Ionicons name="ios-add-outline" size={32} color={Constants.colors.textColor} />
        </HoverTouchableOpacity>
      ),
    });
  }

  return (
    <DashboardLayout>
      <View style={r.get('container', ww)}>
        <View style={r.get('chatWrap', window.width)}>
          <FlatList
            contentContainerStyle={r.get('chatListContent', window.width)}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={refreshMessages}
                                            tintColor={Constants.colors.textColor}
                                            titleColor={Constants.colors.textColor} />}
            ListHeaderComponent={Platform.OS === 'web' && (
              <View style={[r.get('chatHeader', window.width), { padding: 25 }]}>
                <View style={[r.get('headerChatBtnWrap', window.width)]}>
                  <HoverTouchableOpacity
                    style={[r.get('headerChatBtn', window.width)]}
                    onPress={() => navigation.goBack()}
                  >
                    <Ionicons name="chevron-back-outline" size={28}
                              color={Constants.colors.textColor} />
                  </HoverTouchableOpacity>
                </View>
                <Text style={[r.get('heading', window.width)]}>{title}</Text>
              </View>
            )}
            numColumns={1}
            data={messages}
            renderItem={({ item, index, separators }) => (
              <>
                <View style={r.get('chatMessage', window.width)}>
                  <View style={r.get('chatMessageHead', window.width)}>
                    {(() => {
                      const a = fmtAttachment(item.author.profile_img);
                      const authorImg = { uri: a && a.sizes && a.sizes.sm_crop };
                      return (
                        <View style={r.get('chatMessageAuthor', window.width)}>
                          <Image style={r.get('chatAuthorImg', window.width)} source={authorImg}
                                 resizeMode={'cover'} />
                          <Text
                            style={r.get('chatAuthorName', window.width)}>{item.author.name}</Text>
                        </View>
                      );
                    })()}

                    <Text
                      style={r.get('chatMessageDate', window.width)}>{fmtDT(item.created_at)}</Text>
                  </View>
                  {item.images.length > 0 && (
                    <ChatImgSlider
                      images={item.images.map((i) => ({ ...fmtAttachment(i), original: i }))} />
                  )}
                  <View style={r.get('chatMessageBody', window.width)}>
                    <Text style={r.get('chatMessageBodyText', window.width)}>
                      {item.content}
                    </Text>
                  </View>
                </View>
                <View style={{
                  marginTop: -20,
                  marginBottom: 25,
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}>
                  <HoverTouchableOpacity style={r.get('linkWrap', window.width)}
                                         onPress={() => editMessage(item.id)}>
                    <Text style={[r.get('linkText', window.width)]}>Edit</Text>
                  </HoverTouchableOpacity>

                  <HoverTouchableOpacity style={r.get('linkWrap', window.width)}
                                         onPress={() => deleteMessage(item.id)}>
                    <Text style={[r.get('linkText', window.width), { color: 'red' }]}>Delete</Text>
                  </HoverTouchableOpacity>
                </View>
              </>
            )}
          />
        </View>
      </View>
    </DashboardLayout>
  );
}

const ChatImgSlider = observer(function ({ images }) {
  const window = useWindowDimensions();
  console.log('width', window.width);
  console.log({ images });
  const [index, setIndex] = useState(0);
  // const { height, width } = images[index];
  // const aspectRatio = width/height;
  const storeObservable = useRef(observable({
    aspectRatios: {},
    aspectRatioLoading: {},
    slideLayouts: {},
  }));
  const store = storeObservable.current;

  async function getImageSize(uri) {
    return new Promise(function (resolve, reject) {
      Image.getSize(uri, ((width, height) => resolve({ width, height })), reject);
    });
  }

  const indexChanged = (i) => {
    setIndex(parseInt(i, 10));
  };

  const setSlideLayout = (index, event) => {
    const { width, height } = event.nativeEvent.layout;
    runInAction(() => {
      store.slideLayouts[index] = { width, height };
    });
  };

  useEffect(() => {
    if ((store.aspectRatioLoading[index]) || (store.aspectRatios[index])) return;
    (async () => {
      try {
        runInAction(() => {
          store.aspectRatioLoading[index] = true;
        });
        const { width, height } = await getImageSize(images[index].sizes.md);
        console.log('image size', { width, height });
        runInAction(() => {
          store.aspectRatios[index] = width / height;
        });
      } catch (e) {
        console.error(e)
      } finally {
        runInAction(() => {
          store.aspectRatioLoading[index] = false;
        });
        // set loading false
      }
    })();
  }, [index]);

  const aspectRatioLoading = store.aspectRatioLoading[index];
  const aspectRatio = aspectRatioLoading ? 1 : store.aspectRatios[index];

  console.log({
    index,
    aspectRatio,
    aspectRatioLoading,
    slideLayouts: JSON.parse(JSON.stringify(store.slideLayouts)),
    aspectRatioLoadingStore: JSON.parse(JSON.stringify(store.aspectRatioLoading)),
    aspectRatiosStore: JSON.parse(JSON.stringify(store.aspectRatios)),
  });

  return (
    <View
      style={[r.get('chatMessageImg', window.width), { aspectRatio: aspectRatio > 1 ? aspectRatio : 1 }]}>
      <Swiper
        showsButtons={true}
        showsPagination={true}
        onIndexChanged={indexChanged}
        paginationStyle={{
          bottom: -20,
        }}
        loop={false}
        prevButton={<Ionicons style={[r.get('chatSliderArrow', window.width), {
          paddingVertical: 40,
          paddingRight: 40
        }]} name={'ios-caret-back-outline'} color={'#fff'} />}
        nextButton={<Ionicons
          style={[r.get('chatSliderArrow', window.width), { paddingVertical: 40, paddingLeft: 40 }]}
          name={'ios-caret-forward-outline'} color={'#fff'} />}
        dotStyle={{
          backgroundColor: '#777',
          opacity: 0.7,
          height: 5,
          width: 5,
          // borderWidth: 1,
          // borderColor: '#000',
        }}
        activeDotStyle={{
          backgroundColor: Constants.colors.textColor,
          height: 7,
          width: 7,
        }}
        containerStyle={r.get('chatMessageSlider', window.width)}
      >
        {images.map((i, index) => {
          const width = store.slideLayouts[index] && store.slideLayouts[index].width;
          const height = store.slideLayouts[index] && store.slideLayouts[index].height;
          // const aspectRatio = width/height;
          console.log({ index, width, height });
          return (
            <View style={r.get('chatMessageSlide', window.width)} key={index}
                  onLayout={(event) => setSlideLayout(index, event)}>
              <Lightbox
                renderContent={() => (
                  <Image
                    style={[{ flex: 1, backgroundColor: '#000' }]}
                    source={{ uri: i.sizes.xl }}
                    resizeMode={'contain'}
                  />
                )}
                // activeProps={{ style: { flex: 1}, resizeMode: "contain" }}
              >
                <Image
                  style={[
                    r.get('chatMessageSlideImg', window.width),
                    { height, width },
                  ]}
                  source={{ uri: i.sizes.md }}
                  resizeMode={'contain'}
                />
              </Lightbox>
              {store.aspectRatioLoading[index] && <ActivityIndicator
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  marginTop: -15,
                  marginLeft: -15,
                  backgroundColor: '#Fff',
                  borderRadius: 25,
                  padding: 5,
                }}
                color="#000"
              />}
            </View>
          );
        })}
      </Swiper>
    </View>
  );
});

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerRightBtn: {
    marginRight: 5,
  },

  chatMessage: {
    // borderWidth: 1,
    backgroundColor: Constants.colors.backgroundSecondary,
    borderColor: Constants.colors.borderColor,
    marginBottom: 25,

    overflow: 'hidden',
  },
  chatMessageHead: {
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  chatListContent: {},
  chatMessageAuthor: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  chatAuthorImg: {
    width: 30,
    height: 30,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginRight: 10,
  },
  chatAuthorName: {
    fontWeight: '600',
    fontFamily: Constants.fontFamily,
    fontSize: 14,
    color: Constants.colors.textColor,
  },
  chatMessageDate: {
    fontFamily: Constants.fontFamily,
    fontWeight: '400',
    fontSize: 11,
    opacity: 0.7,
    color: Constants.colors.textColor,
  },
  chatMessageBody: {
    padding: 15,
    paddingTop: 25,
  },
  chatMessageBodyText: {
    fontFamily: Constants.fontFamily,
    fontWeight: '400',
    fontSize: 13,
    lineHeight: 18,
    color: Constants.colors.textColor,
  },

  chatMessageImg: {
    width: '100%',
    marginBottom: 10,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  chatMessageSlide: {
    flex: 1,

  },
  chatMessageSlideImg: {
    backgroundColor: '#000',
  },
  chatMessageSlider: {
    width: '100%',
  },
  chatSliderArrow: {
    textShadowColor: '#00000078',
    textShadowRadius: 3,
    textShadowOffset: {
      width: 0,            // These can't both be 0
      height: 0,           // i.e. the shadow has to be offset in some way
    },
  },
  chatHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  linkWrap: {
    cursor: 'pointer',
    paddingVertical: 4,
    paddingHorizontal: 10,
  },
  linkText: {
    color: Constants.colors.textColor,
    textDecorationLine: 'underline',
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    color: Constants.colors.textColor,
  },
  headerChatBtn: {
    paddingRight: 20,
  },
});

const stylesSM = StyleSheet.create({});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
