import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Animated,
  FlatList,
  Image,
  Platform,
  RefreshControl, StyleSheet,
  Text,
  useWindowDimensions,
  View
} from "react-native";
import Constants from "../../constants";
import HoverTouchableOpacity from "./HoverTouchableOpacity";
import Ionicons from "@expo/vector-icons/Ionicons";
import fmtAttachment from "../utilities/fmtAttachment";
import { fmtDT } from "../utilities/fmtDate";
import ChatImgSlider from "./ChatImgSlider";
import { useFocusEffect } from "@react-navigation/native";
import appState from "../../app.state";
import Responsive from "../utilities/responsive";

export default observer(function ({ group, user, navigation, route, loadMessages }) {
  const window = useWindowDimensions();

  const [msgRefreshing, setMsgRefreshing] = useState(false);
  const [e, setError] = useState(false);
  const [messages, setMessages] = useState(false);
  const [messagesPagination, setMessagesPagination] = useState(false);

  const [sendMessageOpen, setSendMessageOpen] = useState(false);
  const chatOpenRotateAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const springConfig = {
      friction: 4,
      tension: 100,
    };
    if (sendMessageOpen) {
      Animated.spring(chatOpenRotateAnim, {
        toValue: 1,
        ...springConfig,
        useNativeDriver: true
      }).start();
    } else {
      Animated.spring(chatOpenRotateAnim, {
        toValue: 0,
        ...springConfig,
        useNativeDriver: true
      }).start();
    }
  }, [sendMessageOpen]);

  const chatRotate = chatOpenRotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '45deg']
  });

  const refreshMessages = () => {
    (async () => {
      try {
        setError(false);
        setMsgRefreshing(true);
        const res = await loadMessages();
        setMessagesPagination(res);
        setMessages(res.data);
      } catch (e) {
        setError(e);
      } finally {
        setMsgRefreshing(false);
      }
    })();
  };

  const editMessage = (id) => {
    navigation.navigate('AddEditMessage', { id, group });
  };

  const deleteMessage = (id) => {
    navigation.navigate('DeleteMessage', { id });
  };

  useFocusEffect(useCallback(() => {
    console.log('focused');
    refreshMessages();
  }, [navigation, route]));

  useEffect(() => {
    console.log('mounted');
    refreshMessages();
  }, [navigation, route]);

  return (
    <FlatList
      style={Platform.OS === 'web' && { height: 100 }}
      contentContainerStyle={r.get('chatListContent', window.width)}
      refreshControl={<RefreshControl refreshing={msgRefreshing}
                                      onRefresh={refreshMessages}
                                      tintColor={Constants.colors.textColor}
                                      titleColor={Constants.colors.textColor} />}
      ListHeaderComponent={(
        <>
          <View style={r.get('chatHeader', window.width)}>
            <View style={r.get('chatHeaderInner', window.width)}>
              <Text style={[r.get('heading', window.width), {
                paddingLeft: 0,
                marginBottom: 10,
              }]}>Chat</Text>
              {user.user_access.includes('manage chats') && (
                <HoverTouchableOpacity style={[r.get('circleBtn', window.width)]}
                                       onPress={() => navigation.navigate('AddEditMessage', {
                                         id: 'new',
                                         group,
                                       })}>
                  <Animated.View style={{ transform: [{ rotate: chatRotate }] }}>
                    <Ionicons name="ios-add-outline" size={22} color={"#fff"} />
                  </Animated.View>
                </HoverTouchableOpacity>
              )}
            </View>

            <View>

            </View>
          </View>
        </>
      )}
      numColumns={1}
      data={messages}
      renderItem={({ item, index, separators }) => (
        <>
          <View style={r.get('chatMessage', window.width)}>
            <View style={r.get('chatMessageHead', window.width)}>
              {(() => {
                const a = fmtAttachment(item.author.profile_img);
                const authorImg = a && { uri: a && a.sizes && a.sizes.sm_crop };
                return (
                  <View style={r.get('chatMessageAuthor', window.width)}>
                    {authorImg && (
                      <Image style={r.get('chatAuthorImg', window.width)} source={authorImg}
                             resizeMode={'cover'} />
                    )}
                    <Text
                      style={r.get('chatAuthorName', window.width)}>{item.author.name}</Text>
                  </View>
                );
              })()}

              <Text
                style={r.get('chatMessageDate', window.width)}>{fmtDT(item.created_at)}</Text>
            </View>
            {item.images.length > 0 && (
              <ChatImgSlider
                images={item.images.map((i) => ({ ...fmtAttachment(i), original: i }))} />
            )}
            <View style={r.get('chatMessageBody', window.width)}>
              <Text style={r.get('chatMessageBodyText', window.width)}>
                {item.content}
              </Text>
            </View>
          </View>
          <View style={{
            marginTop: -20,
            marginBottom: 25,
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}>
            <HoverTouchableOpacity style={r.get('linkWrap', window.width)}
                                   onPress={() => editMessage(item.id)}>
              <Text style={[r.get('linkText', window.width)]}>Edit</Text>
            </HoverTouchableOpacity>

            <HoverTouchableOpacity style={r.get('linkWrap', window.width)}
                                   onPress={() => deleteMessage(item.id)}>
              <Text
                style={[r.get('linkText', window.width), { color: 'red' }]}>Delete</Text>
            </HoverTouchableOpacity>
          </View>
        </>
      )}
    />
  );
});

const baseStyles = StyleSheet.create({
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    color: Constants.colors.textColor,
  },
  heading2: {
    fontSize: 18,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    color: Constants.colors.textColor,
  },
  btn: {
    backgroundColor: Constants.colors.backgroundPrimary,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: Constants.colors.accent,
  },
  btnText: {
    color: Constants.colors.accent,
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  chatMessage: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginBottom: 25,
    borderRadius: 7,
    backgroundColor: Constants.colors.backgroundThird,

    overflow: 'hidden',

    // shadowColor: '#000',
    // shadowOffset: { width: 0, height: 1 },
    // shadowOpacity: 0.1,
    // shadowRadius: 2,
    //
    // elevation: 3,
  },
  chatMessageHead: {
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  chatListContent: {
    paddingHorizontal: 30,
    paddingVertical: 30,
  },
  chatMessageAuthor: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  chatAuthorImg: {
    width: 30,
    height: 30,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginRight: 10,
  },
  chatAuthorName: {
    fontWeight: '600',
    fontFamily: Constants.fontFamily,
    fontSize: 14,
    color: Constants.colors.textColor,
  },
  chatMessageDate: {
    fontFamily: Constants.fontFamily,
    fontWeight: '400',
    fontSize: 11,
    opacity: 0.7,
    color: Constants.colors.textColor,
  },
  chatMessageBody: {
    padding: 15,
  },
  chatMessageBodyText: {
    fontFamily: Constants.fontFamily,
    fontWeight: '400',
    fontSize: 13,
    opacity: 0.7,
    color: Constants.colors.textColor,
  },
  chatHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  chatHeaderInner: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  circleBtn: {
    backgroundColor: '#000',
    cursor: 'pointer',
    borderRadius: 50,
    height: 32,
    width: 32,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    marginLeft: -10,
  },
  linkWrap: {
    cursor: 'pointer',
    paddingVertical: 4,
    paddingHorizontal: 10,
  },
  linkText: {
    color: Constants.colors.textColor,
    textDecorationLine: 'underline',
  },
});

const stylesSM = StyleSheet.create({
  btnSm: {
    backgroundColor: Constants.colors.backgroundPrimary,
    padding: 20,
    paddingHorizontal: 25,
    borderRadius: 8,
    marginVertical: 10,
  },
  btnSmText: {
    fontWeight: '500',
    color: Constants.colors.textColor,
    fontSize: 18,
  },
});

const stylesMD = StyleSheet.create({

});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
