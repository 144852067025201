import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator, FlatList
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import appState from '../../app.state';
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useEffect, useState } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import SplashScreen from "./Splash";

export default function MoreScreen({ navigation }) {
  const window = useWindowDimensions();
  const ww = window.width;

  const [loading, setLoading] = useState(true);
  const [u, setUser] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setUser(await appState.authService.whoami());
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })()
  }, []);

  if (loading) return <SplashScreen />;

  const menuItems = [
    {
      title: 'Account',
      onPress: () => navigation.navigate('Account'),
    },

    ...(u.user_access.includes('manage users') && [
      {
        title: 'Users',
        onPress: () => navigation.navigate('Users', { page: 1 }),
      },
      {
        title: 'Payments',
        onPress: () => navigation.navigate('Payments', { page: 1 }),
      },
      {
        title: 'Affiliates',
        onPress: () => navigation.navigate('Affiliates', { page: 1 }),
      },
    ]),

    ...(u && u.affiliate ? [
      {
        title: "Your Affiliate Dashboard",
        onPress: () => {
          props.navigation.navigate('AffiliateDashboard')
        },
      },
    ] : []),
    ...(u && u.hasWallet ? [
      {
        title: "Your Wallet",
        onPress: () => {
          props.navigation.navigate('WalletDashboard')
        },
      },
    ] : []),

    ...(u.user_access.includes('manage options') && [
      {
        title: 'Partners',
        onPress: () => navigation.navigate('PartnerSettings'),
      },
      {
        title: 'App Wallet',
        onPress: () => navigation.navigate('AppWallet'),
      },
    ]),

    {
      title: 'Enable Push Notifications',
      onPress: () => appState.pushNotificationService.registerForPushNotifications(),
    },
    {
      title: 'Logout',
      onPress: () => appState.authService.logout(),
    }
  ];

  return (
    <View style={r.get('container', ww)}>
      <FlatList
        data={menuItems}
        keyExtractor={(item) => item.title}
        renderItem={({ item }) => (
          <HoverTouchableOpacity onPress={item.onPress} style={r.get('menuItem', ww)}>
            <View style={r.get('menuItemContent', ww)}>
              <Text style={r.get('menuItemText', ww)}>{item.title}</Text>
            </View>
            <View style={[r.get('menuItemArrow', ww)]}>
              <Ionicons name="chevron-forward-outline" size={22} color={Constants.colors.textColor} />
            </View>
          </HoverTouchableOpacity>
        )}
      />
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
  menuItem: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    padding: 20,
    // backgroundColor: '#fff',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: Constants.colors.borderColor,
    marginBottom: -1,
  },
  menuItemContent: {
    flex: 1,
  },
  menuItemText: {
    fontSize: 16,
    fontWeight: '500',
    color: Constants.colors.textColor,
  },
});

const stylesSM = StyleSheet.create({

});

const stylesMD = StyleSheet.create({

});

const stylesLG = StyleSheet.create({

});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
