import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator, TouchableOpacity, TextInput, ScrollView,
  Platform,
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import { useCallback, useEffect, useRef, useState } from "react";
import appState from '../../app.state';
import SplashScreen from "./Splash";
import DraggableFlatList, { ScaleDecorator } from "react-native-draggable-flatlist";
import Ionicons from "@expo/vector-icons/Ionicons";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import { useFocusEffect } from "@react-navigation/native";

export default function EditLearnCategoryVideosScreen({ route, navigation }) {
  const window = useWindowDimensions();
  const ww = window.width;

  const { categorySlug } = route.params;
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState(null);
  const [category, setCategory] = useState(null);
  const [videos, setVideos] = useState([]);
  const deletedVideos = useRef([]);
  const [submitting, setSubmitting] = useState(false);

  useFocusEffect(useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const catsRes = await appState.appApi.getLearnCategories();
        setCategories(catsRes);
        const allCats = catsRes.reduce((acc, c) => {
          acc.push(c);
          acc = acc.concat(c.sub_categories);
          return acc;
        }, []);
        const catRes = allCats.find((c) => c.slug === categorySlug);
        setCategory(catRes);

        const videosRes = await appState.appApi.getLearnVideos({
          learn_category_id: catRes.id,
          limit: 99
        });
        setVideos(videosRes.data);

        setLoading(false);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation, route]));

  const save = () => {
    (async () => {
      try {
        if (submitting) return;
        setSubmitting(true);

        await Promise.all(videos.map(async (c, i) => {
          const data = { ...c, learn_category_id: category.id, order: i };
          if (!c.new) {
            await appState.appApi.updateLearnVideo(c.id, data);
          } else {
            await appState.appApi.createLearnVideo(data);
          }
        }));

        await Promise.all(deletedVideos.current.map(async (c) => {
          await appState.appApi.deleteLearnVideo(c.id);
        }));
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    })();
  };

  const deleteVideo = (i) => {
    const video = videos[i];
    deletedVideos.current.push(video);

    const vids = JSON.parse(JSON.stringify(videos));
    vids.splice(i, 1);
    setVideos(vids);
  };

  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  const addVideo = () => {
    const vids = JSON.parse(JSON.stringify(videos));
    setVideos([...vids, { new: true, id: makeid(5), title: '', description: '', embed: '', open: true }]);
  };

  const updateVideoField = (index, field, text) => {
    const vids = JSON.parse(JSON.stringify(videos));
    vids[index][field] = text;
    setVideos(vids);
  };


  if (loading) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  navigation.setOptions({ title: `Edit "${category.name}"` });

  const renderItem = ({ item, drag, isActive, getIndex }) => {
    return (
      <ScaleDecorator>
        <View style={[r.get('video', window.width)]}>
          <TouchableOpacity
            activeOpacity={0.5}
            onPressIn={drag}
            disabled={isActive}
            style={[r.get('moveWrap', window.width)]}
          >
            <Ionicons name="ios-move-outline" size={22} color={Constants.colors.textColor} />
          </TouchableOpacity>
          <View style={[r.get('videoContent', window.width)]}>
            <View style={[r.get('videoHead', window.width)]}>
              <HoverTouchableOpacity
                style={[r.get('videoOpenBtn', window.width)]}
                onPress={() => updateVideoField(getIndex(), 'open', !item.open)}
              >
                <Text style={[r.get('videoHeading', window.width)]}>{item.title}</Text>
                <Ionicons name="caret-down-outline" size={22} color={Constants.colors.textColor} accessibilityLabel="Toggle Video Content" />
              </HoverTouchableOpacity>
            </View>
            {item.open && (
              <EditVideoFields item={item} getIndex={getIndex} updateVideoField={updateVideoField} />
            )}
          </View>

          {item.open && (
            <HoverTouchableOpacity
              containerStyle={[r.get('iconCloseWrap', window.width), r.get('iconCloseWrapRight', window.width)]}
              style={[r.get('iconClose', window.width), r.get('iconCloseDelete', window.width)]}
              onPress={() => deleteVideo(getIndex())}
            >
              <Ionicons name="ios-close-outline" size={22} color={Constants.colors.textColor}
                        accessibilityLabel="Delete Video" />
            </HoverTouchableOpacity>
          )}
        </View>
      </ScaleDecorator>
    );
  };

  return (
    <DashboardLayout>
      <View style={r.get('container', ww)}>
        <View style={r.get('body', ww)}>
          <DraggableFlatList
            data={videos}
            contentContainerStyle={[r.get('contentContainer', ww)]}
            onDragEnd={({ data }) => setVideos(data)}
            keyExtractor={(item) => item.id}
            ListHeaderComponent={() => (
              <>
                {Platform.OS === 'web' && (
                  <View style={{ alignItems: 'center', }}>
                    <View style={{
                      maxWidth: 900,
                      width: '100%',
                      marginTop: 20,
                      marginBottom: 10,
                      alignItems: 'flex-start'
                    }}>
                      <HoverTouchableOpacity
                        onPress={() => navigation.navigate('Learn', { categorySlug: category.slug })}
                        style={r.get('goBackBtn', ww)}>
                        <Ionicons name="ios-chevron-back-outline" size={22} color={Constants.colors.textColor} />
                        <Text style={r.get('goBackText', ww)}>Go Back</Text>
                      </HoverTouchableOpacity>
                    </View>
                  </View>
                )}

                {Platform.OS === 'web' && (
                  <View>
                    <Text style={r.get('heading', ww)}>Edit "{category.name}" Videos</Text>
                  </View>
                )}
              </>
            )}
            renderItem={renderItem}
            ListFooterComponent={() => (
              <View>
                <HoverTouchableOpacity
                  containerStyle={[r.get('iconCloseWrap', window.width)]}
                  style={r.get('iconClose', window.width)}
                  onPress={() => addVideo()}
                >
                  <Ionicons name="ios-add-outline" size={22} color={Constants.colors.textColor}
                            accessibilityLabel="Add Video" />
                </HoverTouchableOpacity>

                <HoverTouchableOpacity
                  onPress={() => save()}
                  containerStyle={[r.get('btnLeft', ww), { marginTop: 20 }]}
                  style={[r.get('btn', ww)]}
                >
                  {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={Constants.colors.textColor} />}
                  <Text style={r.get('btnText', ww)}>Save</Text>
                </HoverTouchableOpacity>
              </View>
            )}
          />
        </View>
      </View>
    </DashboardLayout>
  );
}

function EditVideoFields({ item, getIndex, updateVideoField }) {
  return (
    <View style={r.get('videoFields', window.width)}>
      <View style={r.get('formGroup', window.width)}>
        <Text style={[r.get('label', window.width)]}>Title</Text>

        <TextInput
          style={r.get('input', window.width)}
          onChangeText={(text) => updateVideoField(getIndex(), 'title', text)}
          value={item.title}
        />
      </View>

      <View style={r.get('formGroup', window.width)}>
        <Text style={[r.get('label', window.width)]}>Description</Text>

        <TextInput
          style={r.get('input', window.width)}
          onChangeText={(text) => updateVideoField(getIndex(), 'description', text)}
          value={item.description}
          multiline
          numberOfLines={4}
        />
      </View>

      <View style={[r.get('formGroup', window.width), r.get('formGroupLast', window.width)]}>
        <Text style={[r.get('label', window.width)]}>Embed Code</Text>

        <TextInput
          style={r.get('input', window.width)}
          onChangeText={(text) => updateVideoField(getIndex(), 'embed', text)}
          value={item.embed}
          multiline
          numberOfLines={4}
        />
      </View>
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    backgroundColor: Constants.colors.backgroundPrimary,
  },
  body: {
    maxWidth: 900,
    width: '100%',
    borderRadius: 8,
  },
  contentContainer: {
    paddingVertical: 40,
    paddingHorizontal: 40,
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  btnLeft: {
    alignSelf: 'flex-start',
  },
  btn: {
    // backgroundColor: '#000',
    borderWidth: 1,
    borderColor: Constants.colors.accent,
    padding: 15,
    paddingHorizontal: 30,
    borderRadius: 8,
    marginVertical: 10,
    letterSpacing: 1,
    textTransform: 'uppercase',
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnText: {
    fontWeight: '500',
    color: Constants.colors.accent,
    fontSize: 16,
  },
  video: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginBottom: 10,
    borderRadius: 8,
    backgroundColor: Constants.colors.backgroundSecondary,
    overflow: 'hidden',
  },
  label: {
    fontSize: 16,
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginTop: 5,
    fontFamily: Constants.fontFamily,
    flex: 1,
    color: Constants.colors.textColor,
  },
  moveWrap: {
    justifyContent: 'center',
    padding: 10,
    marginRight: 5,
    position: 'absolute',
    top: 0,
    bottom: 0,
    alignItems: 'center',
    width: 45,
  },
  videoContent: {
    padding: 15,
    flex: 1,
    paddingRight: 65,
    borderLeftWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginLeft: 45,
  },
  iconCloseWrap: {
    alignSelf: 'flex-start',
  },
  iconCloseWrapRight: {
    position: 'absolute',
    top: '50%',
    // transform: [{ translateY: '-50%' }],
    right: 15,
  },
  iconClose: {
    backgroundColor: '#000',
    borderWidth: 1,
    borderColor: '#fff',
    width: 32,
    height: 32,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  iconCloseDelete: {
    backgroundColor: '#b11a1a',
  },
  formGroup: {
    marginBottom: 15,
    flex: 1,
  },
  formGroupLast: {
    marginBottom: 0,
  },
  goBackBtn: {
    flexDirection: 'row',
    // backgroundColor: '#000',
    alignItems: 'center',
    // padding: 15,
    // paddingVertical: 5,
    borderRadius: 8,
    cursor: 'pointer',
  },
  goBackText: {
    color: Constants.colors.textColor,
  },
  videoHead: {
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
    margin: -15,
    padding: 15,
    marginRight: -65,
    paddingRight: 30,
    marginBottom: -16,
  },
  videoFields: {
    marginTop: 31,
  },
  videoHeading: {
    fontSize: 16,
    fontWeight: '500',
    flex: 1,
    color: Constants.colors.textColor,
  },
  videoOpenBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
});

const stylesSM = StyleSheet.create({
  contentContainer: {
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
