import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";

export default function SplashScreen() {
  const window = useWindowDimensions();
  return (
    <View style={responsive.get('container', window.width)}>
      <ActivityIndicator color={Constants.colors.textColor} />
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const stylesSM = StyleSheet.create({

});

const stylesMD = StyleSheet.create({

});

const stylesLG = StyleSheet.create({

});

const responsive = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
