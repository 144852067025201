import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  Platform,
  ScrollView, ActivityIndicator
} from 'react-native';
import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { WebView } from 'react-native-webview';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import SplashScreen from "./Splash";
import appState from '../../app.state';
import { Link, useFocusEffect } from "@react-navigation/native";
import Ionicons from "@expo/vector-icons/Ionicons";
import BottomSheet, { BottomSheetFlatList } from "@gorhom/bottom-sheet";
import Chat from "../components/Chat";

export default function LearnScreen({ route, navigation }) {
  const window = useWindowDimensions();
  const ww = window.width;

  const categorySlug = route.params && route.params.categorySlug;

  const [loading, setLoading] = useState(true);
  const [cats, setCats] = useState([]);
  const [category, setCat] = useState({});
  const [videos, setVideos] = useState([]);
  const [u, setUser] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  const bottomSheetRef = useRef(null);
  const snapPoints = useMemo(() => ["75%"], []);

  const setVideoLoading = (vId, val) => {
    const videosDup = JSON.parse(JSON.stringify(videos));
    const index = videosDup.findIndex((v) => v.id === vId);
    videosDup[index].loading = val;
    setVideos(videosDup);
  };

  useFocusEffect(useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        setUser(await appState.authService.whoami());
        if (!categorySlug) {
          // redirect to first category...
          const cats = await appState.appApi.getLearnCategories();
          if (cats.length === 0) return;

          if (cats[0].sub_categories.length === 0) {
            navigation.navigate('Learn', { categorySlug: cats[0].slug });
          } else {
            navigation.navigate('Learn', { categorySlug: cats[0].sub_categories[0].slug });
          }
        } else {
          const catsRes = await appState.appApi.getLearnCategories();
          setCats(catsRes);
          const allCats = catsRes.reduce((acc, c) => {
            acc.push(c);
            acc = acc.concat(c.sub_categories);
            return acc;
          }, []);
          const catRes = allCats.find((c) => c.slug === categorySlug);
          setCat(catRes);
          const videosRes = await appState.appApi.getLearnVideos({
            limit: 99,
            learn_category_id: catRes.id,
          });
          setVideos(videosRes.data.map(({ embed, ...rest }) => ({
            embed: embed.replace("iframe", "iframe style='position: absolute; top: 0; left: 0; width: 100%; height: 100%;'"),
            loading: true,
            ...rest,
          })));
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation, route]));

  if (loading || !categorySlug) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  navigation.setOptions({
    title: category.name,
    headerLeft: () => (
      <HoverTouchableOpacity
        style={[r.get('headerLeftBtn', window.width)]}
        onPress={() => bottomSheetRef.current.snapToIndex(0)}
      >
        <Ionicons name="list-outline" size={28} color={Constants.colors.textColor} />
      </HoverTouchableOpacity>
    ),
    headerRight: () => u.user_access.includes('manage options') && (
      <Link to={{ screen: 'EditLearnCategoryVideos', params: { categorySlug } }}
            style={r.get('headerRightBtn', ww)}>
        <HoverTouchableOpacity
          style={[r.get('headerRightBtnInner', window.width)]}
        >
          <Ionicons name="create-outline" size={28} color={Constants.colors.textColor} />
        </HoverTouchableOpacity>
      </Link>
    ),
  });

  return (
    <DashboardLayout>
      <View style={r.get('container', ww)}>
        {!(ww < 900 && Platform.OS !== 'web') && (
          <View style={r.get('nav', ww)}>
            <ScrollView style={{ height: (ww < 900 && navOpen) ? 500 : 104 }} contentContainerStyle={r.get('navInner', ww)}>
              {(ww < 900) && (
                <View style={r.get('mobileHeaderContainer', ww)}>
                  <View style={{ alignItems: 'flex-start', flex: 1 }}>
                    <HoverTouchableOpacity style={[r.get('btnSm', ww), { marginHorizontal: 20 }]}
                                           onPress={() => setNavOpen(!navOpen)}>
                      <Text
                        style={r.get('btnSmText', ww)}>{(!navOpen) ? 'Open Nav' : 'Close Nav'}</Text>
                    </HoverTouchableOpacity>
                  </View>

                  <View style={[r.get('headerChatBtnWrap', window.width)]}>
                    <HoverTouchableOpacity
                      style={[r.get('headerChatBtn', window.width)]}
                      onPress={() => navigation.navigate('Chat', { group: `learn` })}
                    >
                      <Ionicons name="chatbubble-outline" size={28}
                                color={Constants.colors.textColor} />
                    </HoverTouchableOpacity>
                  </View>
                </View>
              )}
              {(ww > 900 || navOpen) && (
                <View style={r.get('navItems', ww)}>
                  {cats.map((c) => (
                    <View style={[r.get('navItemWrap', ww)]} key={c.slug}>
                      {c.sub_categories.length === 0 ? (
                        <HoverTouchableOpacity
                          onPress={() => navigation.navigate('Learn', { categorySlug: c.slug })}
                          activeOpacity={categorySlug === c.slug ? 1 : null}
                          hoveredOpacity={categorySlug === c.slug ? 1 : null}
                          style={[
                            r.get('navItem', ww),
                            categorySlug === c.slug && r.get('navItemActive', ww),
                            r.get('parentNavItem', ww),
                            categorySlug === c.slug && r.get('parentNavItemActive', ww),
                          ]}
                        >
                          <Text
                            style={[
                              r.get('navItemText', ww),
                              categorySlug === c.slug && r.get('navItemTextActive', ww),
                              r.get('parentNavItemText', ww),
                              categorySlug === c.slug && r.get('parentNavItemTextActive', ww),
                            ]}
                          >
                            {c.name}
                          </Text>
                        </HoverTouchableOpacity>
                      ) : (
                        <View style={[
                          r.get('navItem', ww),
                          categorySlug === c.slug && r.get('navItemActive', ww),
                        ]}>
                          <Text
                            style={[
                              r.get('navItemText', ww),
                              categorySlug === c.slug && r.get('navItemTextActive', ww)
                            ]}
                          >
                            {c.name}
                          </Text>
                        </View>
                      )}


                      <View style={[r.get('navItemSubNav', ww)]}>
                        {c.sub_categories.map((c => (
                          <HoverTouchableOpacity
                            onPress={() => navigation.navigate('Learn', { categorySlug: c.slug })}
                            key={c.slug}
                            activeOpacity={categorySlug === c.slug ? 1 : null}
                            hoveredOpacity={categorySlug === c.slug ? 1 : null}
                            style={[
                              r.get('navItem', ww),
                              categorySlug === c.slug && r.get('navItemActive', ww)
                            ]}
                          >
                            <Text
                              style={[
                                r.get('navItemText', ww),
                                categorySlug === c.slug && r.get('navItemTextActive', ww)
                              ]}
                            >
                              {c.name}
                            </Text>
                          </HoverTouchableOpacity>
                        )))}
                      </View>

                    </View>
                  ))}

                  {u.user_access.includes('manage options') && (
                    <Link to={{ screen: 'EditLearnCategories' }} style={r.get('leftLink', ww)}>
                      <HoverTouchableOpacity style={r.get('btnLightSm', ww)}>
                        <Text style={r.get('btnLightSmText', ww)}>Edit Categories</Text>
                      </HoverTouchableOpacity>
                    </Link>
                  )}
                </View>
              )}
            </ScrollView>
          </View>
        )}

        <View style={r.get('body', ww)}>
          <ScrollView style={{ height: 100 }}>
            <View style={r.get('content', ww)}>
              <View style={r.get('contentInner', ww)}>
                {Platform.OS === 'web' && (
                  <View style={r.get('headingWrap', ww)}>
                    <Text style={r.get('heading', ww)}>
                      <View
                        style={[r.get('timelineDot', ww), r.get('timelineDotLight', ww), r.get('timelineDotTop', ww)]} />
                      {category.name}
                    </Text>
                    {u.user_access.includes('manage options') && (
                      <Link to={{ screen: 'EditLearnCategoryVideos', params: { categorySlug } }}
                            style={r.get('leftLink', ww)}>
                        <HoverTouchableOpacity style={r.get('btnSm', ww)}>
                          <Text style={r.get('btnSmText', ww)}>Edit Category's Videos</Text>
                        </HoverTouchableOpacity>
                      </Link>
                    )}
                  </View>
                )}

                <View style={r.get('videos', ww)}>
                  {videos.map((v) => (
                    <View style={r.get('video', ww)} key={v.id}>
                      <View style={[r.get('timelineDot', ww)]} />

                      <View style={r.get('videoPlayer', ww)}>
                        {Platform.OS === 'web' ? (
                          <>
                            <div dangerouslySetInnerHTML={{ __html: v.embed }}></div>
                          </>
                        ) : (
                          <>
                            <View style={{
                              flex: 1,
                              backgroundColor: Constants.colors.backgroundPrimary
                            }}>
                              <WebView
                                style={{
                                  resizeMode: 'cover',
                                  flex: 1,
                                  backgroundColor: Constants.colors.backgroundPrimary
                                }}
                                onLoadEnd={() => {
                                  setVideoLoading(v.id, false);
                                }}
                                source={{
                                  html: `
                                    <html>
                                      <head>
                                          <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                      </head>
                                      <body style="background-color: ${Constants.colors.backgroundPrimary}">
                                        ${v.embed}
                                      </body>
                                    </html>
                                  `,
                                }}
                                allowsFullscreenVideo={true}
                              />
                              {v.loading && (
                                <View style={{
                                  ...StyleSheet.absoluteFillObject,
                                  backgroundColor: Constants.colors.backgroundPrimary,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}>
                                  <ActivityIndicator color={Constants.colors.textColor} />
                                </View>
                              )}
                            </View>
                          </>
                        )}
                      </View>
                      <View style={r.get('videoInfo', ww)}>
                        <Text style={r.get('videoHeading', ww)}>{v.title}</Text>
                        <Text style={r.get('videoDesc', ww)}>{v.description}</Text>
                      </View>
                    </View>
                  ))}
                </View>
                <View
                  style={[r.get('timelineDot', ww), r.get('timelineDotLight', ww), r.get('timelineDotBtm', ww)]} />
              </View>
            </View>
          </ScrollView>
        </View>

        {Platform.OS === 'web' && (
          <View testID='chatwrap' style={r.get('sidebar', window.width)}>
            <Chat
              group={`learn`}
              user={u}
              navigation={navigation}
              route={route}
              loadMessages={async () => appState.appApi.getBroadcastMessages({
                group: `learn`,
                limit: 10
              })}
            />
          </View>
        )}
      </View>

      {Platform.OS !== 'web' && (
        <BottomSheet
          ref={bottomSheetRef}
          snapPoints={snapPoints}
          detach={true}
          enablePanDownToClose={true}
          index={-1}
          style={[r.get('sheetContainer', ww)]}
          backgroundStyle={{
            backgroundColor: Constants.colors.backgroundSecondary,
          }}
          handleIndicatorStyle={{
            backgroundColor: Constants.colors.textColor,
          }}
        >
          <BottomSheetFlatList
            data={cats}
            keyExtractor={c => c.slug}
            ListHeaderComponent={() => (
              <View style={[r.get('sheetHead', ww)]}>
                <Text style={[r.get('sheetHeading', ww)]}>Select Category</Text>

                {u.user_access.includes('manage options') && (
                  <Link to={{ screen: 'EditLearnCategories' }}
                        style={r.get('sheetHeadBtnWrap', ww)}>
                    <HoverTouchableOpacity
                      style={[r.get('sheetHeadBtn', window.width)]}
                    >
                      <Ionicons name="create-outline" size={28}
                                color={Constants.colors.textColor} />
                    </HoverTouchableOpacity>
                  </Link>
                )}
              </View>
            )}
            renderItem={({ item }) => item.sub_categories.length > 0 ? (
              <BottomSheetFlatList
                data={item.sub_categories}
                keyExtractor={c => c.slug}
                ListHeaderComponent={() => (
                  <View style={[r.get('sheetCategory', ww), r.get('sheetParentCategory', ww)]}>
                    <Text
                      style={[r.get('sheetCategoryName', ww), r.get('sheetParentCategoryName', ww)]}>{item.name}</Text>
                  </View>
                )}
                ListFooterComponent={() => (<View style={[{ marginBottom: 40 }]} />)}
                renderItem={({ item }) => (
                  <HoverTouchableOpacity
                    onPress={() => navigation.navigate('Learn', { categorySlug: item.slug })}
                    style={[r.get('sheetCategory', ww), r.get('sheetSubCategory', ww)]}>
                    <Text style={[r.get('sheetCategoryName', ww)]}>{item.name}</Text>
                    <View
                      style={[r.get('sheetCategoryBtn', window.width)]}
                    >
                      <Ionicons name="chevron-forward-outline" size={22}
                                color={Constants.colors.textColor} />
                    </View>
                  </HoverTouchableOpacity>
                )}
              />
            ) : (
              <HoverTouchableOpacity
                onPress={() => navigation.navigate('Learn', { categorySlug: item.slug })}
                style={[r.get('sheetCategory', ww)]}>
                <Text style={[r.get('sheetCategoryName', ww)]}>{item.name}</Text>
                <View
                  style={[r.get('sheetCategoryBtn', window.width)]}
                >
                  <Ionicons name="chevron-forward-outline" size={22}
                            color={Constants.colors.textColor} />
                </View>
              </HoverTouchableOpacity>
            )}
          />
        </BottomSheet>
      )}
    </DashboardLayout>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  nav: {
    width: 300,
    backgroundColor: Constants.colors.backgroundSecondary,
    // borderTopColor: Constants.colors.borderColor,
    // borderTopWidth: 1,
    borderRightColor: Constants.colors.borderColor,
    borderRightWidth: 1,
  },
  navInner: {
    paddingVertical: 40,
  },
  body: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundPrimary,
  },
  content: {
    padding: 40,
    maxWidth: 1000,
    width: '100%',
    flex: 1,
  },
  navItem: {
    padding: 20,
    backgroundColor: Constants.colors.backgroundSecondary,
    marginBottom: 10,
    cursor: 'pointer',
  },
  navItemText: {
    color: Constants.colors.textColor,
    fontWeight: '400',
    fontSize: 16,
  },
  navItemActive: {
    padding: 20,
    backgroundColor: Constants.colors.backgroundThird,
    cursor: 'default',
  },
  navItemTextActive: {
    color: Constants.colors.textColor,
    fontWeight: '600',
  },
  contentInner: {
    borderLeftWidth: 1,
    borderLeftColor: Constants.colors.borderColor,
    flex: 1,
    paddingLeft: 40,
  },
  headingWrap: {
    marginTop: -10,
    marginBottom: 40,
  },
  heading: {
    fontSize: 28,
    color: Constants.colors.textColor,
  },
  timelineDot: {
    width: 12,
    height: 12,
    borderRadius: 10,
    position: 'absolute',
    backgroundColor: Constants.colors.borderColor,
    left: -46,
    top: '50%',
    // transform: [{ translateY: '-50%' }],
  },
  timelineDotLight: {
    borderWidth: 1,
    backgroundColor: Constants.colors.backgroundPrimary,
    borderColor: Constants.colors.borderColor,
  },
  timelineDotTop: {
    top: 0,
    // transform: [{ translateY: '0%' }],
  },
  video: {
    flexDirection: 'row',
    marginBottom: 40,
    alignItems: 'center',
  },
  videoPlayer: {
    flex: 1,
    aspectRatio: 16 / 9,
    backgroundColor: '#000',
    borderRadius: 8,
    marginRight: 40,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  videoInfo: {
    flex: 1,
  },
  videoHeading: {
    fontSize: 20,
    fontWeight: '500',
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  videoDesc: {
    fontWeight: '300',
    lineHeight: 20,
    fontSize: 14,
    color: Constants.colors.textColor,
  },
  vidwrap: {
    position: 'relative',
    paddingTop: '56.26%',
  },
  timelineDotBtm: {
    left: -6,
    top: 'auto',
    bottom: -10,
  },
  btnLightSm: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 10,
    paddingHorizontal: 15,
    borderRadius: 8,
  },
  btnLightSmText: {
    fontWeight: '500',
  },
  btnSm: {
    // backgroundColor: Constants.colors.back,
    padding: 10,
    paddingHorizontal: 15,
    borderRadius: 8,
    marginVertical: 10,
    borderColor: Constants.colors.textColor,
    borderWidth: 1,
  },
  btnSmText: {
    fontWeight: '500',
    color: Constants.colors.textColor,
  },
  leftLink: {
    alignSelf: 'flex-start',
  },

  sheetContainer: {
    // add horizontal space
    marginHorizontal: 0,
    // borderWidth: 1,
    // borderColor: '#ddd',
    // borderTopLeftRadius: 12,
    // borderTopRightRadius: 12,
    // backgroundColor: Constants.colors.backgroundSecondary,
  },
  sheetContainerContent: {
    flex: 1,
    alignItems: "center",
    justifyContent: 'center',
  },
  sheetHead: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 30,
    paddingHorizontal: 20,
  },
  sheetHeading: {
    fontSize: 22,
    flex: 1,
    color: Constants.colors.textColor,
  },
  sheetCategory: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    flexDirection: 'row',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginTop: -1,
    alignItems: 'center',
  },
  sheetParentCategory: {
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },
  sheetParentCategoryName: {
    fontSize: 14,
    textTransform: 'uppercase',
    letterSpacing: 1,
    opacity: 0.6,
  },
  sheetSubCategory: {
    paddingLeft: 40,
  },
  sheetCategoryName: {
    fontSize: 18,
    flex: 1,
    color: Constants.colors.textColor,
  },
  navItemSubNav: {
    marginLeft: 20,
    paddingLeft: 20,
    marginBottom: 10,
    borderLeftWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  sidebar: {
    width: 400,
    backgroundColor: Constants.colors.backgroundSecondary,
    borderLeftWidth: 1,
    borderLeftColor: Constants.colors.borderColor,
  },
  headerChatBtnWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: 15,
    backgroundColor: Constants.colors.backgroundSecondary,
    borderColor: Constants.colors.borderColor,
    borderLeftWidth: 1,
  },
});

const stylesSMMD = {
  container: {
    flexDirection: 'column',
    flex: 1,
  },
  contentInner: {
    borderLeftWidth: 0,
    paddingLeft: 0,
  },
  timelineDot: {
    height: 0,
    width: 0,
  },
  timelineDotLight: {
    borderWidth: 0,
  },
  content: {
    padding: 0,
  },
  video: {
    flexDirection: 'column',
  },
  videoInfo: {
    padding: 15,
    width: '100%',
  },
  videoPlayer: {
    width: '100%',
    marginRight: 0,
    marginBottom: 20,
    borderRadius: 0,
    borderColor: Constants.colors.borderColor,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  headerLeftBtn: {
    marginLeft: 10,
  },
  headerRightBtn: {
    marginRight: 10,
  },
  headingWrap: {
    padding: 20,
    marginTop: 0,
    marginBottom: 0,
  },
  nav: {
    width: '100%',
    // flex: 1,
  },
  navInner: {
    paddingVertical: 20,
  },
  sidebar: {
    height: 0,
    width: 0,
  },
  mobileHeaderContainer: {
    flexDirection: 'row',
  },
};

const stylesSM = StyleSheet.create({
  ...stylesSMMD
});

const stylesMD = StyleSheet.create({
  ...stylesSMMD
});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
