import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator, ScrollView, TextInput, TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import appState from "../../app.state";
import { useCallback, useEffect, useState } from "react";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import PaginatedList from "../components/PaginatedList";
import { fmtD, fmtDT } from "../utilities/fmtDate";
import { DateTime } from "luxon";
import { Link, useFocusEffect } from "@react-navigation/native";
import DropDownPicker from "react-native-dropdown-picker";
import DatePicker from "../components/DatePicker";

export default function PaymentsScreen({ navigation }) {
  const window = useWindowDimensions();
  const ww = window.width;

  const [focusIndex, setFocusIndex] = useState(0);
  const [search, setSearch] = useState('');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [status, setStatus] = useState('');
  const [statusOpen, setStatusOpen] = useState(false);
  const [statusOptions, setStatusOptions] = useState([
    { label: 'Any', value: '' },
    { label: 'Pending', value: 'pending' },
    { label: 'Paid', value: 'paid' },
    { label: 'Expired', value: 'expired' },
  ]);

  const dropdownsSetOpen = [setStatusOpen];

  const closeOtherDropdowns = (f) => {
    dropdownsSetOpen.map((f2) => {
      if (f !== f2) f2(false);
    });
  };

  const getData = async (p) => {
    return appState.appApi.getPayments({
      page: p,
      limit: 8,
      search,
      status,
      start_date: DateTime.fromJSDate(startDate).setZone('utc').toISO(),
      end_date: DateTime.fromJSDate(endDate, { zone: 'default' }).plus({ day: 1 }).setZone('utc').toISO(),
    });
  };

  useFocusEffect(useCallback(() => {
    console.log('focus users', focusIndex);
    const max = 9999999999999999;
    setFocusIndex(Math.floor(Math.random() * max));
  }, [navigation]));

  return (
    <DashboardLayout>
      <ScrollView>
        <TouchableWithoutFeedback onPressIn={() => closeOtherDropdowns()}>
          <View style={r.get('container', ww)}>
            <View style={r.get('body', ww)}>
              {Platform.OS === 'web' && (
                <Text style={r.get('heading', ww)}>Payments</Text>
              )}

              <View style={r.get('filters', ww)}>
                <View style={r.get('filter', ww)}>
                  <Text style={r.get('label', ww)}>Search</Text>

                  <TextInput
                    style={r.get('input', ww)}
                    onChangeText={setSearch}
                    value={search}
                    placeholder={'Search by user\'s email'}
                    placeholderTextColor={'#aaa'}
                  />
                </View>

                <View style={[r.get('filter', ww), { zIndex: 1 }]}>
                  <Text style={r.get('label', ww)}>Status</Text>

                  <DropDownPicker
                    style={r.get('dropdown', window.width)}
                    placeholder="Choose"
                    placeholderStyle={{ color: '#aaa' }}
                    open={statusOpen}
                    value={status}
                    items={statusOptions}
                    setOpen={setStatusOpen}
                    setValue={setStatus}
                    setItems={setStatusOptions}
                    onOpen={() => closeOtherDropdowns(setStatusOpen)}
                  />
                </View>

                <View style={r.get('filter', ww)}>
                  <Text style={r.get('label', ww)}>Start Date</Text>

                  <DatePicker
                    date={startDate}
                    setDate={setStartDate}
                  />
                </View>

                <View style={r.get('filter', ww)}>
                  <Text style={r.get('label', ww)}>End Date</Text>

                  <DatePicker
                    date={endDate}
                    setDate={setEndDate}
                  />
                </View>
              </View>

              <PaginatedList
                extraData={JSON.stringify([search, status, startDate, endDate, focusIndex])}
                getData={getData}
                renderResultStats={(res) => {
                  return (
                    <Text style={r.get('total', ww)}>
                      Results: {res.total} Payments, {res.eth_sum || 0} ETH Paid
                    </Text>
                  );
                }}
                renderItem={({ item, index }) => {
                  const p = item;
                  let status = p.status === 'paid' ? 'Paid' : 'Pending';
                  if (p.status !== 'paid' && DateTime.fromISO(p.created_at).plus(1000 * 60 * 60 * 4).toMillis() < DateTime.now()) {
                    status = 'Expired';
                  }

                  return (
                    <View style={[r.get('row', ww), { cursor: 'default', flex: 1, width: '100%' }]} key={index}>
                      <View style={[r.get('rowCol', ww)]}>
                        <View style={r.get('paymentHead', ww)}>
                          <Text style={r.get('paymentAmt', ww)}>{p.amount} {p.currency}</Text>
                          <Text style={r.get('paymentStatus', ww)}>{status}</Text>
                        </View>

                        <Text style={r.get('paymentUser', ww)}>{p.user_email}</Text>
                      </View>
                      <View style={[r.get('rowCol', ww), r.get('rowColLast', ww)]}>
                        <Text
                          style={r.get('paymentDesc', ww)}>{`For ${p.interval} ${p.interval_type}${p.interval > 1 ? 's' : ''} of membership`}</Text>
                        <Text
                          style={r.get('paymentDate', ww)}>Created: {fmtDT(p.created_at)}</Text>
                      </View>
                    </View>
                  );
                }}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    </ScrollView>
</DashboardLayout>
)
  ;
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  body: {
    padding: 40,
    backgroundColor: Constants.colors.backgroundSecondary,
    maxWidth: 900,
    width: '100%',
    margin: 20,
    borderRadius: 8,

    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 7,

    elevation: 3,

    overflow: 'hidden',

    minHeight: 500,
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  row: {
    paddingHorizontal: 25,
    paddingVertical: 15,
    backgroundColor: Constants.colors.backgroundThird,
    borderRadius: 8,

    borderColor: Constants.colors.borderColor,
    borderWidth: 1,

    overflow: 'hidden',
    marginVertical: 5,

    cursor: 'pointer',
  },
  rowTitle: {
    fontSize: 15,
    fontWeight: '600',
    color: Constants.colors.textColor,
  },
  paymentHead: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  paymentAmt: {
    fontWeight: '500',
    fontSize: 16,
    marginRight: 10,
    color: Constants.colors.textColor,
  },
  paymentStatus: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    borderRadius: 5,
    paddingVertical: 2,
    paddingHorizontal: 8,
    color: Constants.colors.textColor,
  },
  paymentDesc: {
    fontStyle: 'italic',
    color: Constants.colors.textColor,
  },
  paymentDate: {
    fontSize: 12,
    color: Constants.colors.textColor,
  },
  filters: {
    flexDirection: 'row',
    zIndex: 1,
  },
  filter: {
    marginRight: 10,
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  dropdown: {
    width: 230,
    borderRadius: 5,
    borderColor: Constants.colors.borderColor,
    backgroundColor: Constants.colors.backgroundSecondary,
    paddingHorizontal: 15,
    paddingVertical: 10,
    position: 'relative',
    marginBottom: 10,
  },
  total: {
    fontWeight: '300',
    marginBottom: 10,
    fontSize: 13,
    opacity: 0.6,
    color: Constants.colors.textColor,
  },
  paymentUser: {
    color: Constants.colors.textColor,
  },
});

const stylesSM = StyleSheet.create({
  filters: {
    flexDirection: 'column',
  },
  filter: {
    width: '100%',
  },
  dropdown: {
    width: '100%',
  },
  body: {
    padding: 20,
    margin: 0,
    borderRadius: 0,
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  rowCol: {
    flex: 1,
    justifyContent: 'center',
  },
  rowColLast: {
    alignItems: 'flex-end',
  },
  rowWrap: {
    flex: 1,
    width: '100%',
  },
});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
