import * as React from 'react';
import { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  TextInput,
  Platform,
  ActivityIndicator, Alert
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import PreAuthLayout from "../layouts/PreAuthLayout";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import appState from '../../app.state';
import { Link } from "@react-navigation/native";
import * as Device from 'expo-device';
import SplashScreen from "./Splash";
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";

export default function RegisterScreen({ navigation, route }) {
  const window = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [e, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('');
  const [paymentType, setPaymentType] = useState('eth_network');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const POs = await appState.appApi.getPaymentOptions();
        setPaymentOptions(POs);
        setSelectedPaymentOption(POs[0]);
      } catch (e) {
        console.error(e);
        Alert.alert('Failed to fetch payment options. Please, try again later.');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const fieldError = (f) => (e && e.errors && e.errors[f]) || [];

  const deviceName = Platform.OS === 'web' ? `Web App Login: ${Device.modelName} ${Device.osName} ${Device.osVersion}` : `Native Login: ${Device.deviceName}`;
  console.log('affiliateID: ' + (route.params && route.params.a && parseInt(route.params.a)));
  const formSubmit = async function() {
    if (submitting) return;
    try {
      setSubmitting(true);
      const { membership, payment } = await appState.appApi.register({
        payment_option_id: po.id,
        payment_method: paymentType,
        email,
        password,
        password_confirmation: passwordConfirmation,
        device_name: deviceName,
        affiliate_id: route.params && route.params.a && parseInt(route.params.a),
      });

      await appState.authService.login({ email, password, device_name: deviceName });

      if (payment) {
        setTimeout(() => navigation.navigate('Pay', { id: payment.id }), 10);
      } else {
        setTimeout(() => navigation.navigate('CardPay', { membership_id: membership.id, payment_option_id: po.id, }), 10);
      }
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      setSubmitting(false);
    }
  };

  console.log('fielderrors', fieldError('email'), fieldError('password'));

  const po = selectedPaymentOption;

  if (loading) return <SplashScreen />;

  return (
    <PreAuthLayout>
      <View style={responsive.get('container', window.width)}>
        <View style={responsive.get('form', window.width)}>
          <Text style={responsive.get('heading', window.width)}>Sign Up for All Access Membership</Text>

          <View>
            <Text style={responsive.get('poHeading1', window.width)}>
              {`$${po.usd} billed every${po.interval > 1 ? po.interval : ''} ${po.interval_type}${po.interval > 1 ? 's' : ''}`}
            </Text>
            <Text style={responsive.get('poHeading2', window.width)}>Paid in Ethereum</Text>
          </View>


          <Errors errors={fieldError('email')} />

          <TextInput
            style={responsive.get('input', window.width)}
            onChangeText={setEmail}
            value={email}
            keyboardType={'email-address'}
            placeholder={'Email'}
            placeholderTextColor={'#aaa'}
          />

          <Errors errors={fieldError('password')} />

          <TextInput
            style={responsive.get('input', window.width)}
            onChangeText={setPassword}
            value={password}
            secureTextEntry={true}
            placeholder={'Set Account Password'}
            placeholderTextColor={'#aaa'}
          />

          <Errors errors={fieldError('password_confirmation')} />

          <TextInput
            style={responsive.get('input', window.width)}
            onChangeText={setPasswordConfirmation}
            value={passwordConfirmation}
            secureTextEntry={true}
            placeholder={'Password Confirmation'}
            placeholderTextColor={'#aaa'}
          />

          <View style={responsive.get('payment_type_wrap', window.width)}>
            <Text style={responsive.get('label', window.width)}>Payment Method:</Text>
            <RadioButtonGroup
              selected={paymentType}
              onSelected={(value) => setPaymentType(value)}
              containerOptionStyle={{ marginBottom: 10 }}
              labelStyle={{ color: Constants.colors.textColor, marginLeft: 5 }}
              radioStyle={{ borderColor: Constants.colors.textColor }}
              radioBackground={Constants.colors.textColor}
            >
              <RadioButtonItem value="eth_network" label="ETH" />
              <RadioButtonItem value="pf_card" label="Credit Card (4.5% + $0.50 fee)" />
            </RadioButtonGroup>
          </View>

          <HoverTouchableOpacity onPress={formSubmit} style={responsive.get('btn', window.width)}>
            {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
            <Text style={responsive.get('btnText', window.width)}>Continue to Payment</Text>
          </HoverTouchableOpacity>
        </View>
        <Link to={'/login'}>
          <HoverTouchableOpacity style={responsive.get('link', window.width)}>
            <Text style={responsive.get('linkText', window.width)}>Already have an account? Login</Text>
          </HoverTouchableOpacity>
        </Link>
      </View>
    </PreAuthLayout>
  );
}

function Errors({ errors }) {
  if (!errors || errors.length === 0) return;

  return (
    <View style={responsive.get('errors', window.width)}>
      {errors.map((e) => (
        <Text key={e} style={responsive.get('error', window.width)}>{e}</Text>
      ))}
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundPrimary,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  form: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: Constants.colors.backgroundSecondary,
    padding: 30,
    borderRadius: 5,
    marginBottom: 20,
  },
  heading: {
    fontSize: 21,
    marginBottom: 20,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  poHeading1: {
    fontSize: 19,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  poHeading2: {
    fontSize: 14,
    marginBottom: 20,
    fontFamily: Constants.fontFamily,
    fontStyle: 'italic',
    color: Constants.colors.textColor,
  },
  payment_type_wrap: {
    marginVertical: 15,
  },
  label: {
    fontSize: 14,
    marginBottom: 10,
    color: Constants.colors.textColor,
    fontWeight: '600',
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  btn: {
    backgroundColor: Constants.colors.accent,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnText: {
    color: Constants.colors.backgroundSecondary,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },

  linkText: {
    color: Constants.colors.textColor,
    fontSize: 14,
    fontFamily: Constants.fontFamily,
  },
  errors: {
    marginBottom: 10,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
});

const stylesSM = StyleSheet.create({

});

const stylesMD = StyleSheet.create({

});

const stylesLG = StyleSheet.create({

});

const responsive = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
