import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  Image, Platform,
} from 'react-native';
import { Link, useRoute } from '@react-navigation/native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import logo from '../../assets/886-logo-gold.png';
import AccountIcon from "../svgs/AccountIcon";
import { useHover } from 'react-native-web-hooks';
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import MenuIcon from "../svgs/MenuIcon";
import { StatusBar } from "expo-status-bar";
import DismissKeyboard from "../components/DismissKeyboard";

export default function PreAuthLayout({ children }) {
  const window = useWindowDimensions();
  const route = useRoute();

  const ww = window.window;

  return (
    <DismissKeyboard>
      <SafeAreaView style={{ flex: 1, backgroundColor: '#000' }}>
        <StatusBar style="light" />
        <View style={r.get('headerContainer', ww)}>
          <View style={r.get('headerLeftWrap', ww)}>
            <Image
              source={logo}
              style={r.get('logo', window.width)}
            />
          </View>
        </View>
        <View style={{ flex: 1, zIndex: 0 }}>
          {children}
        </View>
      </SafeAreaView>
    </DismissKeyboard>
  );
}

const baseStyles = StyleSheet.create({
  headerContainer: {
    backgroundColor: '#000',
    flexDirection: 'row',
    padding: 20,
    zIndex: 1,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  headerLeftWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  headerCenterWrap: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  headerRightWrap: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: 200,
  },
  logo: {
    width: 200,
    height: 30,
    resizeMode: 'contain',
  },
  menuItem: {
    marginHorizontal: 10,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderWidth: 1,
    borderRadius: 5,
  },
  menuItemActive: {
    borderColor: '#fff',
  },
  menuText: {
    fontSize: 14,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#fff',
    textTransform: 'uppercase',
    fontFamily: Constants.fontFamily,
  },
});

const stylesSM = StyleSheet.create({
  headerLeftWrap: {
    flex: 1,
  },
});

const stylesMD = StyleSheet.create({
  headerLeftWrap: {
    flex: 1,
  },
});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
