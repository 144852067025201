import AsyncStorage from '@react-native-async-storage/async-storage';
import { makeAutoObservable } from "mobx";

export default class AsyncStorageAuthProvider {
  token = false;
  constructor() {
    this.key = '886authtoken';
    makeAutoObservable(this);

    (async () => {
      try {
        await this.loadAuthToken();
      } catch (e) {
        console.error(e);
      }
    })();
  }

  async setAuthToken(token) {
    await AsyncStorage.setItem(this.key, token);
    this.token = token;
    console.log('set', { token: this.token });
  }

  async loadAuthToken() {
    this.token = await AsyncStorage.getItem(this.key)
    console.log('load', { token: this.token });
  }
}
