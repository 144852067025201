import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator, ScrollView, TextInput, TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import appState from "../../app.state";
import { useCallback, useEffect, useState } from "react";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import PaginatedList from "../components/PaginatedList";
import { fmtD, fmtDT } from "../utilities/fmtDate";
import { DateTime } from "luxon";
import { Link, useFocusEffect } from "@react-navigation/native";
import DropDownPicker from "react-native-dropdown-picker";
import DatePicker from "../components/DatePicker";
import UserSelect from "../components/UserSelect";
import SplashScreen from "./Splash";

export default function AppWalletScreen({ navigation, route }) {
  const window = useWindowDimensions();
  const ww = window.width;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [report, setReport] = useState({});

  useFocusEffect(useCallback(() => {
    navigation.setOptions({ title: `App Wallet Report` });

    (async () => {
      try {
        setError(null);
        setLoading(true);
        const r = await appState.appApi.getAppWalletReport();
        setReport({ ...r, app_wallet_balance: parseFloat(r.app_wallet_balance).toFixed(8), available_balance: ethSubtract(r.app_wallet_balance, r.user_wallets_balance) });
      } catch (e) {
        setError(e);
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation, route]));

  if (loading) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  return (
    <DashboardLayout>
      <ScrollView>
        <View>
          <View style={r.get('container', ww)}>
            <View style={r.get('body', ww)}>
              {Platform.OS === 'web' && (
                <Text style={r.get('heading', ww)}>App Wallet Report</Text>
              )}

              {error && (
                <Errors errors={error && error.message ? [error.message] : []} />
              )}

              <View style={r.get('formGroupWrap', ww)}>
                <Text style={r.get('label', ww)}>App Wallet ETH Address</Text>
                <Text style={r.get('input', ww)}>{report.app_wallet_address}</Text>
              </View>

              <View style={r.get('formGroupWrap', ww)}>
                <Text style={r.get('label', ww)}>App Wallet Balance</Text>
                <Text style={r.get('input', ww)}>{report.app_wallet_balance} ETH</Text>
                <Text style={r.get('desc', ww)}>~${(report.app_wallet_balance * report.eth_price).toFixed(2)}</Text>
              </View>

              <View style={r.get('formGroupWrap', ww)}>
                <Text style={r.get('label', ww)}>User Wallets Balance</Text>
                <Text style={r.get('input', ww)}>{report.user_wallets_balance} ETH</Text>
                <Text style={r.get('desc', ww)}>~${(report.user_wallets_balance * report.eth_price).toFixed(2)}</Text>
              </View>

              <View style={r.get('formGroupWrap', ww)}>
                <Text style={r.get('label', ww)}>Available Balance</Text>
                <Text style={r.get('input', ww)}>{report.available_balance} ETH</Text>
                <Text style={r.get('desc', ww)}>~${(report.available_balance * report.eth_price).toFixed(2)}</Text>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </DashboardLayout>
  );
}

function ethSubtract(a, b) {
  return (((parseFloat(a) * 100000000) - (parseFloat(b) * 100000000)) / 100000000).toFixed(8)
}

function Errors({ errors }) {
  const window = useWindowDimensions();
  const ww = window.width;
  if (!errors || errors.length === 0) return;

  return (
    <View style={r.get('errors', ww)}>
      {errors.map((e) => (
        <Text key={e} style={r.get('error', ww)}>{e}</Text>
      ))}
    </View>
  );
}


const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  body: {
    padding: 40,
    backgroundColor: Constants.colors.backgroundSecondary,
    maxWidth: 900,
    width: '100%',
    margin: 20,
    borderRadius: 8,

    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 7,

    elevation: 3,

    overflow: 'hidden',

    minHeight: 500,
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  label: {
    fontSize: 15,
    marginBottom: 5,
    color: Constants.colors.textColor,
    fontWeight: '600',
  },
  labelSm: {
    fontSize: 13,
    marginBottom: 5,
    color: Constants.colors.textColor,
    fontWeight: '500',
  },
  formGroupWrap: {
    marginBottom: 30,
  },
  formGroup: {
    marginRight: 15,
  },
  desc: {
    fontSize: 13,
    opacity: 0.6,
    fontStyle: 'italic',
    marginTop: -5,
    color: Constants.colors.textColor,
  },
  btn: {
    backgroundColor: Constants.colors.accent,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    // minWidth: 400,
    // maxWidth: '100%',
  },
  btnText: {
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.backgroundSecondary,
  },
  btnSm: {
    backgroundColor: Constants.colors.accent,
    borderWidth: 1,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnTextSm: {
    color: Constants.colors.backgroundPrimary,
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  btnLeft: {
    alignSelf: 'flex-start',
  },
  partners: {
    marginBottom: 20,
  },
  partner: {
    padding: 12,
    borderColor: Constants.colors.borderColor,
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 20,
    paddingRight: 40,
  },
  iconCloseWrap: {
    alignSelf: 'flex-start',
  },
  iconCloseWrapRight: {
    position: 'absolute',
    top: '50%',
    marginTop: -15,
    // transform: [{ translateY: '-50%' }],
    right: 15,
  },
  errors: {
    marginBottom: 0,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  fields: {
    flexDirection: 'row',
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    paddingVertical: 8.5,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
});

const stylesSM = StyleSheet.create({
  body: {
    padding: 20,
    margin: 0,
    borderRadius: 0,
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
