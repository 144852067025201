import { makeAutoObservable, runInAction } from "mobx";

export default class AuthService {
  loggedIn = false;
  userId = false;
  user = false;
  loading = true;

  constructor({ authProvider, appApi }) {
    this.authProvider = authProvider;
    this.appApi = appApi;

    makeAutoObservable(this);

    (async () => {
      try {
        await this.init();
      } catch (e) {
        console.error(e);
      }
    })();
  }

  async login({ email, password, device_name }) {
    const token = await this.appApi.createToken({ email, password, device_name });
    await this.authProvider.setAuthToken(token);

    // this also inits class properties.
    return this.whoami();
  }

  async logout() {
    await this.authProvider.setAuthToken(null);
    runInAction(() => {
      this.loggedIn = false;
      this.userId = false;
      this.user = false;
    });
  }

  async isLoggedIn() {
    const user = await this.whoami();
    return user && user.id > 0;
  }

  async whoami() {
    const user = this.user || await this.appApi.whoami();
    runInAction(() => {
      this.userId = user && user.id;
      this.loggedIn = user && user.id > 0;
      this.user = user;
    });
    return user;
  }

  async init() {
    runInAction(() => {
      this.loading = true;
    });
    await this.authProvider.loadAuthToken();
    await this.whoami();
    runInAction(() => {
      this.loading = false;
    });
  }
}
