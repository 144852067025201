import * as React from "react"
import Svg, { Path } from "react-native-svg"

const AccountIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 414.39 414.23"
    {...props}
  >
    <Path
      d="M357.56 349.74A207.192 207.192 0 0 0 207.19 0c-54.32 0-106.45 21.33-145.2 59.39A207.217 207.217 0 0 0 .03 203.51a207.157 207.157 0 0 0 56.8 146.23 207.245 207.245 0 0 0 94.88 56.97 207.282 207.282 0 0 0 110.67 0c36.21-10.03 69-29.72 94.88-56.97 0 0 .3.15.3 0ZM207.2 30.08c43.82-.09 86.11 16.03 118.77 45.24a177.626 177.626 0 0 1 58.12 113.02c4.77 43.55-6.73 87.34-32.29 122.92a162.782 162.782 0 0 0-88.79-78.14c19.21-15.46 31.14-38.21 32.92-62.8s-6.76-48.82-23.55-66.88c-16.8-18.05-40.35-28.31-65.01-28.31s-48.21 10.26-65.01 28.31a88.785 88.785 0 0 0 9.36 129.68 162.802 162.802 0 0 0-88.8 78.14 177.58 177.58 0 0 1-32.3-122.82A177.599 177.599 0 0 1 207.21 30.07ZM148 163.28c0-15.7 6.23-30.76 17.34-41.86 11.1-11.11 26.16-17.34 41.86-17.34s30.76 6.23 41.86 17.34c11.1 11.1 17.34 26.16 17.34 41.86a59.206 59.206 0 0 1-59.2 59.2 59.206 59.206 0 0 1-59.2-59.2ZM83.92 335.25a133.238 133.238 0 0 1 72.12-72.55 133.17 133.17 0 0 1 102.3 0 133.223 133.223 0 0 1 72.13 72.55 177.593 177.593 0 0 1-246.56 0Z"
      style={{
        fill: "#fff",
      }}
    />
  </Svg>
)

export default AccountIcon
