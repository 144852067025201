import * as React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useHover } from 'react-native-web-hooks';
import { Platform, TouchableOpacity as RNTouchableOpacity, View } from "react-native";

export default function HoverTouchableOpacity({ containerStyle, style, children, activeOpacity, hoveredOpacity, ...props }) {
  const ref = React.useRef(null);
  const hovered = useHover(ref);

  if (Platform.OS === 'android') {
    return (
      <View style={[
        containerStyle,
        hovered && ({ opacity: hoveredOpacity || 0.7 }),
      ]}>
        <RNTouchableOpacity
          {...props}
          activeOpacity={activeOpacity || 0.35}
          style={style}
          ref={ref}
        >
          {children}
        </RNTouchableOpacity>
      </View>
    );
  }

  return (
    <TouchableOpacity
      {...props}
      activeOpacity={activeOpacity || 0.35}
      containerStyle={[containerStyle, hovered && ({ opacity: hoveredOpacity || 0.7 })]}
      style={style}
      ref={ref}
    >
      {children}
    </TouchableOpacity>
  );
  }
