import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  Platform,
  TouchableWithoutFeedback,
  ActivityIndicator,
  ScrollView,
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import { useState } from "react";
import SplashScreen from "./Splash";
import appState from '../../app.state';

export default function DeleteIdeaScreen({ navigation, route }) {
  const window = useWindowDimensions();
  const [submitting, setSubmitting] = useState(false);

  let { id } = route.params;

  const deleteIdea = () => {
    if (submitting) return;

    (async () => {
      try {
        setSubmitting(true);

        await appState.appApi.deleteIdea(id);

        navigation.goBack();
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    })();
  };

  navigation.setOptions({ title: `Delete Idea (ID: ${id})` });

  return (
    <TouchableWithoutFeedback onPress={() => Platform.OS === 'web' && navigation.goBack()}>
      <ScrollView style={{ height: window.height }}>
        <View style={[r.get('container', window.width)]}>
          <TouchableWithoutFeedback>
            <View style={[r.get('containerInner', window.width), { minHeight: window.height - 20 }]}
                  onStartShouldSetResponder={() => true}>
              {Platform.OS === 'web' && (
                <View style={r.get('head', window.width)}>
                  <Text style={r.get('title', window.width)}>{`Delete Idea (ID: ${id})`}</Text>

                  <HoverTouchableOpacity onPress={() => navigation.goBack()}
                                         style={[r.get('close', window.width)]}>
                    <Ionicons name="ios-close" size={22} style={r.get('closeIcon', window.width)}
                              accessibilityLabel={"Close"} />
                  </HoverTouchableOpacity>
                </View>
              )}

              <View style={r.get('body', window.width)}>
                <Text style={r.get('confirmMsg', window.width)}>Are you sure you want to delete this idea?</Text>

                <View style={{ flexDirection: 'row' }}>
                  <HoverTouchableOpacity onPress={() => deleteIdea()} style={[r.get('btn', window.width)]}>
                    {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
                    <Text style={r.get('btnText', window.width)}>Yes, Delete</Text>
                  </HoverTouchableOpacity>
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </ScrollView>
    </TouchableWithoutFeedback>
  );
}

const baseStyles = StyleSheet.create({
  container: Platform.OS === 'web' ? {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
    paddingTop: 20,
  } : {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  containerInner: {
    minHeight: '50%',
    maxWidth: 800,
    width: '100%',
    backgroundColor: Constants.colors.backgroundSecondary,
    ...Platform.select({
      web: {
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
      },
    }),
  },
  closeIcon: {
    color: Constants.colors.textColor,
  },
  head: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 40,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  title: {
    flex: 1,
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    color: Constants.colors.textColor,
  },
  body: {
    flex: 1,
    padding: 40,
  },
  confirmMsg: {
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  addMessageWrap: {
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  heading2: {
    fontSize: 18,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 10,
    color: Constants.colors.textColor,
  },
  smBtn: {
    flexDirection: 'row',
    // backgroundColor: '#000',
    paddingHorizontal: 15,
    paddingVertical: 5,
    alignItems: 'center',
    alignSelf: 'flex-start',
    borderRadius: 8,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: Constants.colors.textColor,
  },
  smBtnText: {
    color: Constants.colors.textColor,
    fontWeight: '500',
  },
  btn: {
    backgroundColor: Constants.colors.accent,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    minWidth: 200,
  },
  btnText: {
    color: Constants.colors.backgroundSecondary,
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  errors: {
    marginBottom: 0,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
});

const stylesSM = StyleSheet.create({
  body: {
    padding: 20,
    paddingVertical: 40,
  },
  container: {
    ...(Platform.OS === 'web' ? {
      paddingHorizontal: 8,
    } : {})
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
