import * as React from 'react';
import { useState } from 'react';
import {
  ActivityIndicator, Button,
  Platform, Pressable,
  StyleSheet,
  Text,
  TextInput,
  useWindowDimensions,
  View
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import PreAuthLayout from "../layouts/PreAuthLayout";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import appState from '../../app.state';
import { Link } from "@react-navigation/native";
import * as Device from 'expo-device';
import DismissKeyboard from "../components/DismissKeyboard";
import { runInAction } from "mobx";

export default function LoginScreen({ route }) {
  const window = useWindowDimensions();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [e, setError] = useState(null);

  console.log({ route });
  const fieldError = (f) => (e && e.errors && e.errors[f]) || [];

  const deviceName = Platform.OS === 'web' ? `Web App Login: ${Device.modelName} ${Device.osName} ${Device.osVersion}` : `Native Login: ${Device.deviceName}`;

  const formSubmit = async function () {
    console.log('formSubmit');
    if (submitting) return;
    try {
      setSubmitting(true);
      await appState.authService.login({ email, password, device_name: deviceName });
      setTimeout(() => {
        runInAction(() => {
          appState.state.redirect = route && route.params && route.params.redirect;
        });
      }, 0);

    } catch (e) {
      console.log(e.message);
      setError(e);
    } finally {
      setSubmitting(false);
    }
  };

  console.log('fielderrors', fieldError('email'), fieldError('password'));

  return (
    <PreAuthLayout>
      <DismissKeyboard>
        <View style={responsive.get('container', window.width)}>
          <View style={responsive.get('form', window.width)}>
            <Text style={responsive.get('heading', window.width)}>Login</Text>

            <Errors errors={fieldError('email')} />

            <TextInput
              style={responsive.get('input', window.width)}
              autoCapitalize="false"
              onChangeText={setEmail}
              value={email}
              keyboardType={'email-address'}
              placeholder={'Email'}
              placeholderTextColor={'#aaa'}
            />

            <Errors errors={fieldError('password')} />

            <TextInput
              style={responsive.get('input', window.width)}
              autoCapitalize="false"
              onChangeText={setPassword}
              value={password}
              secureTextEntry={true}
              placeholder={'Password'}
              placeholderTextColor={'#aaa'}
            />

            <HoverTouchableOpacity onPress={formSubmit} style={responsive.get('btn', window.width)}>
              {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={Constants.colors.textColor} />}
              <Text style={responsive.get('btnText', window.width)}>Login</Text>
            </HoverTouchableOpacity>
          </View>
          <Link to={'/forgot-password'}>
            <HoverTouchableOpacity style={responsive.get('link', window.width)}>
              <Text style={responsive.get('linkText', window.width)}>Forgot Password?</Text>
            </HoverTouchableOpacity>
          </Link>
        </View>
      </DismissKeyboard>
    </PreAuthLayout>
  );
}

function Errors({ errors }) {
  if (!errors || errors.length === 0) return;

  return (
    <View style={responsive.get('errors', window.width)}>
      {errors.map((e) => (
        <Text key={e} style={responsive.get('error', window.width)}>{e}</Text>
      ))}
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundPrimary,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  form: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: Constants.colors.backgroundSecondary,
    padding: 30,
    borderRadius: 5,
    marginBottom: 20,
  },
  heading: {
    fontSize: 21,
    marginBottom: 20,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  btn: {
    backgroundColor: Constants.colors.accent,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnText: {
    color: Constants.colors.backgroundSecondary,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },

  linkText: {
    fontSize: 14,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  errors: {
    marginBottom: 10,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
});

const stylesSM = StyleSheet.create({});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const responsive = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
