import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator, ScrollView, TextInput, TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import appState from "../../app.state";
import { useCallback, useEffect, useState } from "react";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import PaginatedList from "../components/PaginatedList";
import { fmtD, fmtDT } from "../utilities/fmtDate";
import { DateTime } from "luxon";
import { Link, useFocusEffect } from "@react-navigation/native";
import DropDownPicker from "react-native-dropdown-picker";
import DatePicker from "../components/DatePicker";
import UserSelect from "../components/UserSelect";
import SplashScreen from "./Splash";

export default function PartnerSettingsScreen({ navigation }) {
  const window = useWindowDimensions();
  const ww = window.width;

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [focusIndex, setFocusIndex] = useState(0);
  const [owner, setOwner] = useState(null);
  const [dev, setDev] = useState(null);
  const [devPercent, setDevPercent] = useState(null);
  const [e, setError] = useState(null);
  const [partners, setPartners] = useState([]);

  useFocusEffect(useCallback(() => {
    console.log('focus users', focusIndex);
    const max = 9999999999999999;
    setFocusIndex(Math.floor(Math.random() * max));

    navigation.setOptions({ title: `Partner Settings` });

    (async () => {
      try {
        setError(null);
        setLoading(true);
        const { dataObject } = await appState.appApi.getOptions({
          option_keys: ['owner_user', 'dev_user', 'dev_user_percentage'],
        });
        console.log({ dataObject });
        setDevPercent(dataObject.dev_user_percentage);
        const usersRes = await appState.appApi.getUsers({
          limit: 99,
          ids: [dataObject.owner_user, dataObject.dev_user].filter((v) => v)
        });
        const userObj = usersRes.data.reduce((acc, u) => {
          acc[u.id] = u;
          return acc;
        }, {});
        setOwner(userObj[dataObject.owner_user]);
        setDev(userObj[dataObject.dev_user]);

        const partners = await appState.appApi.getPartners();
        console.log({ partners });
        setPartners(partners);
      } catch (e) {
        setError(e);
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation]));

  const save = () => {
    (async () => {
      try {
        setError(null);
        setSubmitting(true);
        await appState.appApi.updateOptions({
          data: [
            { key: 'owner_user', value: owner && owner.id },
            { key: 'dev_user', value: dev && dev.id },
            { key: 'dev_user_percentage', value: devPercent && devPercent },
          ],
        });

        await appState.appApi.updatePartners({
          partners: partners.map((p, i) => ({
            user_id: p.user && p.user.id,
            percentage: p.percentage,
            order: i
          }))
        });
      } catch (e) {
        setError(e);
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    })();
  };

  const addPartner = () => {
    const pDup = [...partners];
    pDup.push({ user: null, percentage: '' });
    setPartners(pDup);
  };

  const updatePartner = (index, field, val) => {
    const ps = JSON.parse(JSON.stringify(partners));
    ps[index][field] = val;
    setPartners(ps);
  };

  const deletePartner = (i) => {
    const ps = JSON.parse(JSON.stringify(partners));
    ps.splice(i, 1);
    setPartners(ps);
  };

  if (loading) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  return (
    <DashboardLayout>
      <ScrollView>
        <View>
          <View style={r.get('container', ww)}>
            <View style={r.get('body', ww)}>
              {Platform.OS === 'web' && (
                <Text style={r.get('heading', ww)}>Partners</Text>
              )}

              <View style={r.get('formGroupWrap', ww)}>
                <Text style={r.get('label', ww)}>Owner</Text>

                <View style={r.get('formGroup', ww)}>
                  <Text style={r.get('labelSm', ww)}>User</Text>
                  <UserSelect user={owner} userSelected={(u) => setOwner(u)} />
                </View>
              </View>

              <View style={r.get('formGroupWrap', ww)}>
                <Text style={r.get('label', ww)}>Developer</Text>
                <View style={r.get('fields', ww)}>
                  <View style={r.get('formGroup', ww)}>
                    <Text style={r.get('labelSm', ww)}>User</Text>
                    <UserSelect user={dev} userSelected={(u) => setDev(u)} />
                  </View>
                  <View style={r.get('formGroup', ww)}>
                    <Text style={r.get('labelSm', ww)}>Percentage</Text>
                    <TextInput
                      style={r.get('input', ww)}
                      onChangeText={setDevPercent}
                      value={devPercent}
                      placeholder={'0.00'}
                      placeholderTextColor={'#aaa'}
                    />
                  </View>
                </View>
              </View>

              <View style={r.get('formGroupWrap', ww)}>
                <Text style={r.get('label', ww)}>Partner Users</Text>

                <View style={r.get('partners', ww)}>
                  {partners.map((p, i) => (
                    <View style={r.get('partner', ww)} key={(p && p.id) || `i${i}`}>
                      <View style={r.get('fields', ww)}>
                        <View style={r.get('formGroup', ww)}>
                          <Text style={r.get('labelSm', ww)}>User</Text>
                          <UserSelect user={p.user} userSelected={(u) => updatePartner(i, 'user', u)} />
                        </View>
                        <View style={r.get('formGroup', ww)}>
                          <Text style={r.get('labelSm', ww)}>Percentage</Text>
                          <TextInput
                            style={r.get('input', ww)}
                            onChangeText={(v) => updatePartner(i, 'percentage', v)}
                            value={p.percentage}
                            placeholder={'0.00'}
                            placeholderTextColor={'#aaa'}
                          />
                        </View>
                      </View>

                      <HoverTouchableOpacity
                        containerStyle={[r.get('iconCloseWrap', window.width), r.get('iconCloseWrapRight', window.width)]}
                        style={[r.get('iconClose', window.width), r.get('iconCloseDelete', window.width)]}
                        onPress={() => deletePartner(i)}
                      >
                        <Ionicons name="ios-close-outline" size={22}
                                  color={Constants.colors.textColor}
                                  accessibilityLabel="Delete Category" />
                      </HoverTouchableOpacity>
                    </View>
                  ))}
                </View>

                <View style={r.get('btnLeft', ww)}>
                  <HoverTouchableOpacity onPress={() => addPartner()} style={[r.get('btnSm', ww)]}>
                    <Text style={r.get('btnTextSm', ww)}>Add Partner</Text>
                  </HoverTouchableOpacity>
                </View>
              </View>

              {e && !!e.message && (
                <View style={[r.get('errors', window.width), { marginBottom: 20 }]}>
                  <Text key={e} style={r.get('error', window.width)}>{e.message}</Text>
                </View>
              )}

              {/*<View style={{ alignItems: 'flex-start' }}>*/}
              <HoverTouchableOpacity style={r.get('btn', window.width)} onPress={() => save()}>
                {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
                <Text style={r.get('btnText', window.width)}>Save</Text>
              </HoverTouchableOpacity>
              {/*</View>*/}
            </View>
          </View>
        </View>
      </ScrollView>
    </DashboardLayout>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  body: {
    padding: 40,
    backgroundColor: Constants.colors.backgroundSecondary,
    maxWidth: 900,
    width: '100%',
    margin: 20,
    borderRadius: 8,

    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 7,

    elevation: 3,

    overflow: 'hidden',

    minHeight: 500,
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  label: {
    fontSize: 15,
    marginBottom: 5,
    color: Constants.colors.textColor,
    fontWeight: '600',
  },
  labelSm: {
    fontSize: 13,
    marginBottom: 5,
    color: Constants.colors.textColor,
    fontWeight: '500',
  },
  formGroupWrap: {
    marginBottom: 30,
  },
  formGroup: {
    marginRight: 15,
  },
  btn: {
    backgroundColor: Constants.colors.accent,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    // minWidth: 400,
    // maxWidth: '100%',
  },
  btnText: {
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.backgroundSecondary,
  },
  btnSm: {
    backgroundColor: Constants.colors.accent,
    borderWidth: 1,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnTextSm: {
    color: Constants.colors.backgroundPrimary,
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  btnLeft: {
    alignSelf: 'flex-start',
  },
  partners: {
    marginBottom: 20,
  },
  partner: {
    padding: 12,
    borderColor: Constants.colors.borderColor,
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 20,
    paddingRight: 40,
  },
  iconCloseWrap: {
    alignSelf: 'flex-start',
  },
  iconCloseWrapRight: {
    position: 'absolute',
    top: '50%',
    marginTop: -15,
    // transform: [{ translateY: '-50%' }],
    right: 15,
  },
  errors: {
    marginBottom: 0,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  fields: {
    flexDirection: 'row',
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    paddingVertical: 8.5,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
});

const stylesSM = StyleSheet.create({
  body: {
    padding: 20,
    margin: 0,
    borderRadius: 0,
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
