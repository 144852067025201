import * as React from 'react';
import { useCallback, useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  TextInput,
  Platform,
  ActivityIndicator,
  Alert,
  ScrollView, Image,
} from 'react-native';
import { A } from '@expo/html-elements';
import QRCode from 'react-native-qrcode-svg';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import PreAuthLayout from "../layouts/PreAuthLayout";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import appState from '../../app.state';
import { Link, useFocusEffect } from "@react-navigation/native";
import DashboardLayout from "../layouts/DashboardLayout";
import SplashScreen from "./Splash";
import { fmtDT } from "../utilities/fmtDate";
import { DateTime } from "luxon";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as Clipboard from 'expo-clipboard';
import { LiteCreditCardInput } from "react-native-credit-card-input-plus";
import CardIcons from "react-native-credit-card-input-plus/src/Icons";
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";


export default function CardPayScreen({ navigation, route }) {
  const window = useWindowDimensions();

  if (Platform.OS !== 'web') return <DashboardLayout><Text>Use browser to make card payment.</Text></DashboardLayout>;

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [m, setMembership] = useState(true);
  const [po, setPaymentOption] = useState({});
  const [card, setCard] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [error, setError] = useState(null);
  const [useExistingCard, setUseExistingCard] = useState('new');
  const [existingCards, setExistingCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  console.log(route);
  const { membership_id, payment_option_id } = route.params;

  useFocusEffect(useCallback(() => {
    (async () => {
      setLoading(true);
      const u = await appState.authService.whoami();
      setEmail(u.email);
      setMembership(parseInt(membership_id));
      const POs = await appState.appApi.getPaymentOptions();
      const paymentOption = POs.find(o => o.id === parseInt(payment_option_id));
      setPaymentOption(paymentOption);
      const cardsRes = await appState.appApi.getCards({ user_id: u.id });
      setExistingCards(cardsRes);
      setUseExistingCard(cardsRes.length > 0 ? 'existing' : 'new');
      const defaultCard = cardsRes.find((c) => c.default);
      if (defaultCard) {
        setSelectedCard(defaultCard);
      }
      setLoading(false);
    })();
  }, [navigation, route]));

  const cardUpdated = (card) => {
    setCard(card);
    console.log({ card });
  };

  const pay = () => {
    (async () => {
      try {
        setSubmitting(true);
        let payData;
        if (useExistingCard === 'existing') {
          payData = {
            payment_option_id: po.id,
            membership_id: m,
            card_id: selectedCard.id,
          };
        } else {
          payData = {
            payment_option_id: po.id,
            membership_id: m,
            card_number: card.values.number.replaceAll(' ', ''),
            card_type: card.values.type.toUpperCase().replace('-', ''),
            card_exp_month: card.values.expiry.split('/')[0],
            card_exp_year: card.values.expiry.split('/')[1],
            card_cvv: card.values.cvc,
            card_first_name: firstName,
            card_last_name: lastName,
            address: address,
            phone: phone,
            email: email,
            save_card: 1,
          };
        }
        const res = await appState.appApi.payWithCard(payData);
        navigation.navigate('Account');
      } catch (e) {
        setError(e);
      } finally {
        setSubmitting(false);
      }
    })();
  };

  if (loading) return <SplashScreen />;

  navigation.setOptions({
    title: `Pay with Card`,
  });


  return (
    <DashboardLayout>
      <View style={responsive.get('containerWrap', window.width)} testID="containerWrap">
        <ScrollView testID="scrollview">
          <View style={responsive.get('container', window.width)} testID="container">
            <View style={responsive.get('form', window.width)}>
              <View style={responsive.get('formHeader', window.width)}>
                <View style={[responsive.get('headingWrap', window.width)]}>
                  {Platform.OS === 'web' && (
                    <Text style={[responsive.get('heading', window.width)]}>Pay with Card</Text>
                  )}
                </View>
              </View>

              <View style={responsive.get('formBody', window.width)}>
                <View style={responsive.get('infoWrap', window.width)}>
                  <Text
                    style={responsive.get('amountText', window.width)}>${withCardFee(po.usd)}</Text>
                  <Text
                    style={responsive.get('descText', window.width)}>{getPODesc(po)}. Includes a 4.5% + $0.50 processing fee.</Text>
                </View>

                {existingCards.length > 0 && (
                  <View style={responsive.get('formGroup', window.width)}>
                    <RadioButtonGroup
                      selected={useExistingCard}
                      onSelected={(value) => setUseExistingCard(value)}
                      containerOptionStyle={{ marginBottom: 10 }}
                      labelStyle={{ color: Constants.colors.textColor, marginLeft: 5 }}
                      radioStyle={{ borderColor: Constants.colors.textColor }}
                      radioBackground={Constants.colors.textColor}
                    >
                      <RadioButtonItem value="existing" label="Use Existing" />
                      <RadioButtonItem value="new" label="Add New" />
                    </RadioButtonGroup>
                  </View>
                )}

                {useExistingCard === 'existing' ? (
                  <>
                    {existingCards.length === 0 ? (
                      <View>
                        <Text style={responsive.get('infoMsg', window.width)}>You have no cards. Add one below.</Text>
                      </View>
                    ) : (
                      <View style={responsive.get('cardsWrap', window.width)}>
                        {existingCards.map((c) => (
                          <HoverTouchableOpacity onPress={() => setSelectedCard(c)} containerStyle={responsive.get('card', window.width)}>
                            <View style={responsive.get('cardInner', window.width)}>
                              <View style={responsive.get('cardInfoWrap', window.width)}>
                                <Image
                                  style={responsive.get('cardicon', window.width)}
                                  source={c.type === 'VISA' ? CardIcons['visa'] : CardIcons['master-card']}
                                />

                                <View style={responsive.get('cardInfoInner', window.width)}>
                                  <Text style={responsive.get('cardInfo', window.width)}>{c.type} {c.last_digits}</Text>
                                  <Text style={responsive.get('cardName', window.width)}>{c.name}</Text>
                                </View>

                                <View>
                                  {selectedCard === c ? (
                                    <Ionicons name="checkmark-circle-outline" size={22} color={Constants.colors.textColor} />
                                  ) : (
                                    <Ionicons name="ellipse-outline" size={22} color={Constants.colors.textColor} />
                                  )}
                                </View>
                              </View>
                            </View>
                          </HoverTouchableOpacity>
                        ))}
                      </View>
                    )}
                  </>
                ) : (
                  <>
                    <View style={responsive.get('formGroup')}>
                      <Text style={responsive.get('label', window.width)}>Card</Text>
                      <View
                        style={[responsive.get('input', window.width), responsive.get('cardInput', window.width)]}>
                        <LiteCreditCardInput
                          onChange={cardUpdated}
                          inputStyle={{ color: Constants.colors.textColor, outlineStyle: 'none' }}
                        />
                      </View>
                      <View style={responsive.get('cardicons', window.width)}>
                        <Image style={responsive.get('cardicon', window.width)} source={CardIcons['visa']} />
                        <Image style={responsive.get('cardicon', window.width)} source={CardIcons['master-card']} />
                      </View>
                    </View>

                    <View style={responsive.get('inputRow', window.width)}>
                      <View style={[responsive.get('formGroup', window.width), { flex: 1, paddingRight: 15 }]}>
                        <Text style={responsive.get('label', window.width)}>Card First Name</Text>
                        <TextInput
                          style={responsive.get('input', window.width)}
                          onChangeText={setFirstName}
                          value={firstName}
                        />
                      </View>

                      <View style={[responsive.get('formGroup', window.width), { flex: 1 }]}>
                        <Text style={responsive.get('label', window.width)}>Card Last Name</Text>
                        <TextInput
                          style={responsive.get('input', window.width)}
                          onChangeText={setLastName}
                          value={lastName}
                        />
                      </View>
                    </View>

                    <View style={responsive.get('formGroup', window.width)}>
                      <Text style={responsive.get('label', window.width)}>Email</Text>
                      <TextInput
                        style={responsive.get('input', window.width)}
                        onChangeText={setEmail}
                        value={email}
                      />
                    </View>

                    <View style={responsive.get('formGroup', window.width)}>
                      <Text style={responsive.get('label', window.width)}>Phone</Text>
                      <TextInput
                        style={responsive.get('input', window.width)}
                        onChangeText={setPhone}
                        value={phone}
                      />
                    </View>

                    <View style={responsive.get('formGroup', window.width)}>
                      <Text style={responsive.get('label', window.width)}>Address</Text>
                      <TextInput
                        style={responsive.get('input', window.width)}
                        onChangeText={setAddress}
                        value={address}
                      />
                    </View>
                  </>
                )}

                {error && (
                  <Errors errors={error && error.message ? [error.message] : []} />
                )}

                <HoverTouchableOpacity onPress={() => pay()}
                                       style={[responsive.get('btn', window.width), { marginTop: 20 }]}>
                  {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
                  <Text style={responsive.get('btnText', window.width)}>Pay</Text>
                </HoverTouchableOpacity>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </DashboardLayout>
  );
}

function Errors({ errors }) {
  const window = useWindowDimensions();
  const ww = window.width;
  if (!errors || errors.length === 0) return;

  return (
    <View style={responsive.get('errors', ww)}>
      {errors.map((e) => (
        <Text key={e} style={responsive.get('error', ww)}>{e}</Text>
      ))}
    </View>
  );
}

const getPODesc = (po) => {
  return `For ${po.interval} ${po.interval_type}${po.interval > 1 ? 's' : ''} of membership`;
};

const withCardFee = (amt) => {
  return (((parseInt(parseFloat(amt) * 100) * 1.045) + 50) / 100).toFixed(2);
};

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  form: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: Constants.colors.backgroundSecondary,
    borderRadius: 5,
    marginBottom: 20,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  formHeader: {
    padding: 30,
    paddingBottom: 15,
    paddingTop: 15,
    backgroundColor: Constants.colors.backgroundThird,
  },
  formBody: {
    padding: 30,
  },
  heading: {
    fontSize: 21,
    fontFamily: Constants.fontFamily,
    marginBottom: -15,
    fontWeight: '600',
    color: Constants.colors.textColor,
  },
  headingWrap: {
    marginBottom: 20,
  },
  statusWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    opacity: 1,
  },
  statusText: {
    color: '#fff',
    backgroundColor: '#444',
    textAlign: 'center',
    padding: 8,
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  infoWrap: {
    marginBottom: 20,
    // textAlign: 'center',
    // justifyContent: 'center',
  },
  amountText: {
    fontWeight: '600',
    fontSize: 22,
    color: Constants.colors.textColor,
  },
  descText: {
    fontSize: 14,
    opacity: 0.6,
    fontStyle: 'italic',
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  cardInput: {
    paddingVertical: 5,
    paddingHorizontal: 5,
  },
  btn: {
    backgroundColor: Constants.colors.accent,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnText: {
    color: Constants.colors.textColor,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },

  linkText: {
    fontSize: 14,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  errors: {
    marginBottom: 10,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  openIcon: {
    paddingLeft: 5,
    color: Constants.colors.textColor,
  },
  link: {
    flexDirection: 'row',
    marginVertical: 10,
    borderBottomWidth: 1,
    borderColor: Constants.colors.textColor,
  },
  qrAndLink: {
    alignItems: 'center',
  },
  dates: {
    alignItems: 'center',
    marginTop: 10,
    opacity: 0.7,
  },
  viewTrx: {
    margin: 0,
    alignSelf: 'flex-start',
    // marginLeft: 5,
    color: Constants.colors.textColor,
    borderColor: Constants.colors.textColor,
  },
  linkBadge: {
    borderRadius: 20,
    backgroundColor: '#000',
    paddingVertical: 4,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  linkBadgeText: {
    color: Constants.colors.textColor,
  },
  label: {
    fontSize: 15,
    marginBottom: 5,
    color: Constants.colors.textColor,
    fontWeight: '600',
  },
  formGroup: {
    marginBottom: 15,
  },
  containerWrap: {
    backgroundColor: Constants.colors.backgroundPrimary,
  },
  inputRow: {
    flexDirection: 'row',
  },
  cardicons: {
    flexDirection: 'row',
    marginTop: -10,
  },
  cardicon: {
    width: 32,
    height: 27,
    resizeMode: "contain",
  },
  cardsWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  card: {
    width: '100%',
  },
  cardInner: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 20,
    borderRadius: 6,
    marginBottom: 20,
    marginRight: 20,
  },
  cardInfoWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardInfo: {
    color: Constants.colors.textColor,
    fontWeight: '600',
  },
  cardInfoInner: {
    paddingLeft: 10,
    flex: 1,
  },
  cardName: {
    color: Constants.colors.textColor,
  },
});

const stylesSM = StyleSheet.create({
  container: {
    padding: 0,
    backgroundColor: Constants.colors.backgroundSecondary,
  },
  form: {
    maxWidth: 700,
    marginBottom: 0,
    borderWidth: 0,
    borderRadius: 0,
  },
  formHeader: {
    backgroundColor: Constants.colors.backgroundSecondary,
    padding: 5,
    alignItems: 'center',
  },
  viewTrx: {
    color: Constants.colors.textColor,
    borderColor: Constants.colors.textColor,
  },
  openIconViewTrx: {
    color: Constants.colors.textColor,
  },
  amountText: {
    textAlign: 'center',
  },
  descText: {
    textAlign: 'center',
  },
  headingWrap: {
    marginBottom: 0,
  },
  formBody: {
    padding: 0,
    paddingBottom: 40,
  },
  containerWrap: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundPrimary,
  },
});

const stylesMD = StyleSheet.create({
  formBody: {
    padding: 10,
  },
});

const stylesLG = StyleSheet.create({});

const responsive = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
