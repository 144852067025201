import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator, ScrollView, Image, TextInput
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import appState from "../../app.state";
import { useCallback, useEffect, useState } from "react";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import PaginatedList from "../components/PaginatedList";
import { fmtD, fmtDT } from "../utilities/fmtDate";
import { useFocusEffect } from "@react-navigation/native";
import SplashScreen from "./Splash";
import * as ImagePicker from "expo-image-picker";
import fmtAttachment from "../utilities/fmtAttachment";
import DropDownPicker from "react-native-dropdown-picker";
import { DateTime } from "luxon";

export default function UserEditScreen({ route, navigation }) {
  const window = useWindowDimensions();
  const ww = window.width;
  const { id } = route.params;
  console.log('route params', { id });

  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [e, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [user, setUser] = useState('');
  const [passwordMsg, setPasswordMsg] = useState('');
  const [newImage, setNewImage] = useState(false);
  const [displayNameMsg, setDisplayNameMsg] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [expiration, setExpiration] = useState('');
  const [submittingProfile, setSubmittingProfile] = useState(false);

  const [status, setStatus] = useState('');
  const [statusOpen, setStatusOpen] = useState(false);
  const [statusOptions, setStatusOptions] = useState([
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
    { label: 'Pending', value: 'pending' },
    { label: 'Expired', value: 'expired' },
  ]);

  const [role, setRole] = useState('');
  const [roleOpen, setRoleOpen] = useState(false);
  const [roleOptions, setRoleOptions] = useState([
    { label: 'Subscriber', value: 'subscriber' },
    { label: 'Trader', value: 'trader' },
    { label: 'Customer Support', value: 'customer-support' },
    { label: 'Super Admin', value: 'super-admin' },
  ]);

  const dropdownsSetOpen = [setStatusOpen, setRoleOpen];

  const fieldError = (f) => (e && e.errors && e.errors[f]) || [];

  const closeOtherDropdowns = (f) => {
    dropdownsSetOpen.map((f2) => {
      if (f !== f2) f2(false);
    });
  };

  const loadUser = async (id) => {
    console.log('loadUser', { id });
    const u = await appState.appApi.getUser(id);
    setError(null);
    setUser(u)
    setDisplayName(u.display_name || '');
    setEmail(u.email || '');
    setStatus((u.membership && u.membership.status) || '');
    setExpiration((u.membership && fmtDT(u.membership.expiration)) || '');
    setRole(u.user_roles[0]);
  };

  useFocusEffect(useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        await loadUser(id);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [navigation, id]));

  if (loading) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  const changePass = () => {
    if (submitting) return;
    (async () => {
      try {
        setSubmitting(true);
        await appState.appApi.updateUser(id, {
          password,
          password_confirmation: passwordConfirmation,
        });
        setPasswordMsg('Password Changed');
      } catch (e) {
        console.error(e);
        setError(e);
      } finally {
        setSubmitting(false);
      }
    })();
  };

  const selectProfileImg = () => {
    (async () => {
      try {
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsEditing: true,
          aspect: [1, 1],
          quality: 1,
        });

        console.log(result);

        if (result.cancelled) return;

        setNewImage(result.uri);
        //setNewImageSaving(true);

        // const aId = await appState.appApi.uploadAttachment(result.uri);
        // await appState.appApi.updateUser(user.id, { profile_img_id: aId });
        //
        // setUser(await appState.appApi.whoami());

      } catch (e) {
        console.error(e);
        //alert('Failed to upload profile image');
      } finally {
        //setNewImageSaving(false);
        // setNewImage(false);
      }
    })();
  };

  const saveProfile = () => {
    if (submittingProfile) return;
    (async () => {
      try {
        setSubmittingProfile(true);
        setError(null);

        const updateData = {
          display_name: displayName,
          email,
          role,
          membership_expiration: DateTime.fromFormat(expiration, "MMM d, y, h:mm a").setZone('utc').toISO({ includeOffset: false }),
          membership_status: status,
        };

        if (newImage) {
          updateData.profile_img_id = await appState.appApi.uploadAttachment(newImage);
        }
        await appState.appApi.updateUser(id, updateData);
        await loadUser(id);

        setDisplayNameMsg('Profile saved.');
      } catch (e) {
        setError(e);
        console.error(e);
      } finally {
        setSubmittingProfile(false);
      }
    })();
  };

  const deleteUser = () => {
    if (deleting) return;

    (async () => {
      try {
        setDeleting(true);

        await appState.appApi.deleteUser(id);

        navigation.goBack();
      } catch (e) {
        console.error(e);
      } finally {
        setDeleting(false);
      }
    })();
  };

  const uProfImg = fmtAttachment(user.profile_img);

  return (
    <DashboardLayout>
      <ScrollView>
        <View style={r.get('container', ww)}>
          <View style={r.get('body', ww)}>
            <Text style={r.get('heading', ww)}>Edit User (ID: {id})</Text>

            {!!displayNameMsg && (
              <Text style={r.get('successMsg', ww)}>{displayNameMsg}</Text>
            )}

            <Errors errors={fieldError('profile_img_id')} />

            <View style={r.get('profileHead', ww)}>
              <HoverTouchableOpacity onPress={() => selectProfileImg()}  style={r.get('profileImageWrap', ww)}>
                <Image source={{ uri: newImage ? newImage : uProfImg && uProfImg.sizes.sm_crop }} style={r.get('profileImage', ww)} />

                <View style={r.get('profileEditBtn', ww)}>
                  <Ionicons name="ios-create-outline" size={18} color={'#fff'} />
                </View>
              </HoverTouchableOpacity>
            </View>

            <View style={r.get('formGroup', ww)}>
              <Text style={r.get('label', ww)}>Email</Text>

              <Errors errors={fieldError('email')} />

              <TextInput
                style={r.get('input', ww)}
                onChangeText={setEmail}
                value={email}
                placeholder={'Email'}
                placeholderTextColor={'#aaa'}
              />
            </View>

            <View style={[r.get('formGroup', ww), { zIndex: 1 }]}>
              <Text style={r.get('label', ww)}>Status</Text>

              <Errors errors={fieldError('membership_status')} />

              <DropDownPicker
                style={r.get('dropdown', window.width)}
                placeholder="Choose"
                placeholderStyle={{ color: Constants.colors.textColor, opacity: 0.4 }}
                labelStyle={{ color: Constants.colors.textColor }}
                open={statusOpen}
                value={status}
                items={statusOptions}
                setOpen={setStatusOpen}
                setValue={setStatus}
                setItems={setStatusOptions}
                onOpen={() => closeOtherDropdowns(setStatusOpen)}
              />
            </View>


            <View style={r.get('formGroup', ww)}>
              <Text style={r.get('label', ww)}>Expiration</Text>
              <Text style={r.get('inputDesc', ww)}>Must be formatted like this: {DateTime.now().toLocaleString(DateTime.DATETIME_MED)}</Text>

              <Errors errors={fieldError('membership_expiration')} />

              <TextInput
                style={r.get('input', ww)}
                onChangeText={setExpiration}
                value={expiration}
                placeholder={'Expiration'}
                placeholderTextColor={'#aaa'}
              />
            </View>

            <View style={r.get('formGroup', ww)}>
              <Text style={r.get('label', ww)}>Display Name</Text>

              <Errors errors={fieldError('display_name')} />

              <TextInput
                style={r.get('input', ww)}
                onChangeText={setDisplayName}
                value={displayName}
                placeholder={'Display Name'}
                placeholderTextColor={'#aaa'}
              />
            </View>

            <View style={[r.get('formGroup', ww), { zIndex: 1 }]}>
              <Text style={r.get('label', ww)}>Role</Text>

              <Errors errors={fieldError('role')} />

              <DropDownPicker
                style={r.get('dropdown', window.width)}
                placeholder="Choose"
                placeholderStyle={{ color: Constants.colors.textColor, opacity: 0.4 }}
                labelStyle={{ color: Constants.colors.textColor }}
                open={roleOpen}
                value={role}
                items={roleOptions}
                setOpen={setRoleOpen}
                setValue={setRole}
                setItems={setRoleOptions}
                onOpen={() => closeOtherDropdowns(setRoleOpen)}
              />
            </View>

            <Errors errors={e && e.message ? [e.message] : []} />

            <HoverTouchableOpacity onPress={() => saveProfile()} style={[r.get('btn', ww), r.get('btnLeft', ww)]}>
              {submittingProfile && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
              <Text style={r.get('btnTextSm', ww)}>Save Profile</Text>
            </HoverTouchableOpacity>

            <View style={r.get('div', ww)} />

            <Text style={r.get('heading', ww)}>Change Password</Text>

            {!!passwordMsg && (
              <Text style={r.get('successMsg', ww)}>{passwordMsg}</Text>
            )}

            <Errors errors={fieldError('password')} />

            <TextInput
              style={r.get('input', ww)}
              onChangeText={setPassword}
              value={password}
              secureTextEntry={true}
              placeholder={'New Password'}
              placeholderTextColor={'#aaa'}
            />

            <Errors errors={fieldError('password_confirmation')} />

            <TextInput
              style={r.get('input', ww)}
              onChangeText={setPasswordConfirmation}
              value={passwordConfirmation}
              secureTextEntry={true}
              placeholder={'Password Confirmation'}
              placeholderTextColor={'#aaa'}
            />

            <HoverTouchableOpacity onPress={() => changePass()} style={[r.get('btn', ww), r.get('btnLeft', ww)]}>
              {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
              <Text style={r.get('btnTextSm', ww)}>Change Password</Text>
            </HoverTouchableOpacity>
          </View>


          <HoverTouchableOpacity onPress={() => deleteUser()} style={[r.get('btn', ww), r.get('deleteBtn', ww)]}>
            {deleting && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
            <Text style={[r.get('btnText', window.width), r.get('deleteBtnText', ww)]}>Delete user</Text>
          </HoverTouchableOpacity>
        </View>
      </ScrollView>
    </DashboardLayout>
  );
}

function Errors({ errors }) {
  const window = useWindowDimensions();
  const ww = window.width;
  if (!errors || errors.length === 0) return;

  return (
    <View style={r.get('errors', ww)}>
      {errors.map((e) => (
        <Text key={e} style={r.get('error', ww)}>{e}</Text>
      ))}
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  body: {
    padding: 40,
    backgroundColor: Constants.colors.backgroundSecondary,
    maxWidth: 900,
    width: '100%',
    margin: 20,
    borderRadius: 8,

    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 7,

    elevation: 3,

    overflow: 'hidden',

    minHeight: 500,
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  row: {
    paddingHorizontal: 25,
    paddingVertical: 15,
    backgroundColor: '#fff',
    borderRadius: 8,

    borderColor: '#ddd',
    borderWidth: 1,

    overflow: 'hidden',
    marginVertical: 5,

    cursor: 'pointer',

    flexDirection: 'row',
  },
  rowTitle: {
    fontSize: 15,
    fontWeight: '600',
  },
  rowCol: {
    flex: 1,
    justifyContent: 'center',
  },
  rowColLast: {
    alignItems: 'flex-end',
  },
  profileHead: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  profileEditBtn: {
    position: 'absolute',
    top: -10,
    right: -10,
    backgroundColor: '#000',
    height: 32,
    width: 32,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  profileImageWrap: {
    height: 80,
    width: 80,
    marginRight: 20,
  },
  profileImage: {
    height: 80,
    width: 80,
    backgroundColor: Constants.colors.backgroundSecondary,
    borderRadius: 50,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  email: {
    fontSize: 20,
  },
  div: {
    marginVertical: 30,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  errors: {
    marginBottom: 10,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  formGroup: {
    marginBottom: 10,
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  btn: {
    // backgroundColor: '#000',
    borderColor: Constants.colors.accent,
    borderWidth: 1,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  btnSm: {
    // backgroundColor: '#000',
    borderColor: Constants.colors.accent,
    borderWidth: 1,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnText: {
    color: Constants.colors.accent,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  btnTextSm: {
    color: Constants.colors.accent,
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  btnLeft: {
    alignSelf: 'flex-start',
  },
  successMsg: {
    color: '#000',
    backgroundColor: '#d0ffe3',
    borderWidth: 1,
    borderColor: '#33a963',
    padding: 15,
    borderRadius: 5,
    marginBottom: 20,
  },
  dropdown: {
    width: 230,
    borderRadius: 5,
    borderColor: Constants.colors.borderColor,
    backgroundColor: Constants.colors.backgroundSecondary,
    paddingHorizontal: 15,
    paddingVertical: 10,
    position: 'relative',
    marginBottom: 10,
    outlineStyle: 'none',
  },
  inputDesc: {
    fontSize: 11,
    fontStyle: 'italic',
    marginBottom: 5,
    color: Constants.colors.textColor,
  },
  deleteBtn: {
    backgroundColor: '#d41010',
    borderRadius: 25,
    paddingVertical: 5,
    marginBottom: 30,
    borderWidth: 0,
  },
  deleteBtnText: {
    fontSize: 12,
    color: '#fff',
  },
});

const stylesSM = StyleSheet.create({});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
