import { useEffect, useState } from "react";
import { ActivityIndicator, StyleSheet, Text, useWindowDimensions, View } from "react-native";
import HoverTouchableOpacity from "./HoverTouchableOpacity";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as React from "react";
import Constants from "../../constants";
import Responsive from "../utilities/responsive";

const disabledOpacity = 0.2;

export default function PaginatedList({ extraData, getData, renderItem, renderResultStats }) {
  const window = useWindowDimensions();
  const ww = window.width;

  const [loading, setLoading] = useState(true);
  const [res, setRes] = useState(null);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(null);

  const loadPage = (p) => {
    (async () => {
      try {
        setLoading(true);
        const res = await getData(p);
        setPage(p);
        setTotalPages(res.last_page);
        setTotal(res.total);
        setItems(res.data);
        setRes(res);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  };

  let renderStats = renderResultStats
  if (typeof renderResultStats !== 'function') {
    renderStats = (res) => {
      return <Text style={r.get('total', ww)}>Total Results: {res && res.total}</Text>;
    };
  }

  useEffect(() => {
    console.log('load page', { extraData });
    setLoading(true);
    const watchId = setTimeout(() => {
      loadPage(1)
    }, 300);

    return () => clearTimeout(watchId);
  }, [extraData]);

  // useEffect(() => loadPage(1), []);

  if (loading) return <ActivityIndicator color={Constants.colors.textColor} />;

  return (
    <View>
      {renderStats(res)}

      <Pagination total={totalPages} currentPage={page} onSelect={(p) => loadPage(p)} />

      <View style={r.get('items', ww)}>
        {items.map((item, index) => renderItem({ item, index }))}
      </View>

      <Pagination total={totalPages} currentPage={page} onSelect={(p) => loadPage(p)} />
    </View>
  );
}

function Pagination({ total, currentPage, onSelect }) {
  const window = useWindowDimensions();
  const ww = window.width;

  return (
    <View style={r.get('pageItems', ww)}>
      <HoverTouchableOpacity activeOpacity={currentPage === 1 ? disabledOpacity : null} hoveredOpacity={currentPage === 1 ? 1 : null} style={[r.get('pageItem', ww), currentPage === 1 && r.get('disabledPageItem', ww)]} onPress={() => (currentPage > 1) && onSelect(currentPage - 1)}>
        <Ionicons size={22} name="ios-chevron-back-outline" color={Constants.colors.textColor} />
      </HoverTouchableOpacity>

      {Array.apply(null, { length: total }).map((e, i) => (
        <HoverTouchableOpacity style={[r.get('pageItem', ww), i+1 === currentPage && r.get('pageItemActive', ww)]} onPress={() => onSelect(i+1)}>
          <Text style={[r.get('pageItemText', ww), i+1 === currentPage && r.get('pageItemTextActive', ww)]}>{i+1}</Text>
        </HoverTouchableOpacity>
      ))}

      <HoverTouchableOpacity activeOpacity={currentPage === total ? disabledOpacity : null} hoveredOpacity={currentPage === total ? 1 : null} style={[r.get('pageItem', ww), currentPage === total && r.get('disabledPageItem', ww)]} onPress={() => (currentPage < total) && onSelect(currentPage + 1)}>
        <Ionicons size={22} name="ios-chevron-forward-outline" color={Constants.colors.textColor} />
      </HoverTouchableOpacity>
    </View>
  );
}

const baseStyles = StyleSheet.create({
  total: {
    fontWeight: '300',
    marginBottom: 10,
    fontSize: 13,
    opacity: 0.6,
    color: Constants.colors.textColor,
  },
  pageItems: {
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'center',
  },
  disabledPageItem: {
    opacity: disabledOpacity,
  },
  pageItem: {
    borderWidth: 2,
    borderColor: Constants.colors.textColor,
    height: 32,
    width: 32,
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    cursor: 'pointer',
  },
  pageItemText: {
    fontWeight: '500',
    color: Constants.colors.textColor,
  },
  pageItemActive: {
    backgroundColor: Constants.colors.textColor,
  },
  pageItemTextActive: {
    color: Constants.colors.backgroundPrimary,
  },
});

const stylesSM = StyleSheet.create({

});

const stylesMD = StyleSheet.create({

});

const stylesLG = StyleSheet.create({

});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
