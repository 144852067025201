import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ScrollView, Image, Platform, ActivityIndicator
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import SplashScreen from "./Splash";
import { useCallback, useState } from "react";
import { Link, useFocusEffect } from "@react-navigation/native";
import appState from "../../app.state";
import fmtAttachment from "../utilities/fmtAttachment";
import ScaledImage from "../components/ScaledImage";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import { WebView } from "react-native-webview";

export default function LiveChannelScreen({ navigation, route }) {
  const window = useWindowDimensions();

  const [loading, setLoading] = useState(true);
  const [eventLoading, setEventLoading] = useState(true);
  const [chatLoading, setChatLoading] = useState(true);
  const [c, setChannel] = useState({});

  useFocusEffect(useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const chRes = await appState.appApi.getLiveChannels({ limit: 99 });
        console.log(chRes, route, route.params && parseInt(route.params.id));
        setChannel(chRes.data.find((c) => c.id === (route.params && parseInt(route.params.id))));
        setLoading(false);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation, route]));

  if (loading) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  return (
    <DashboardLayout>
      <View style={r.get('container', window.width)}>
        <View style={r.get('channelWrap', window.width)}>
          {Platform.OS === 'web' ? (
            <>
              {eventLoading && (
                <View style={{
                  ...StyleSheet.absoluteFillObject,
                  backgroundColor: Constants.colors.backgroundPrimary,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <ActivityIndicator color={Constants.colors.textColor} />
                </View>
              )}
              <div style={{ height: '100%', width: '100%' }} dangerouslySetInnerHTML={{ __html: c.event_embed_code }}></div>
            </>
          ) : (
            <>
              <View style={{
                flex: 1,
                backgroundColor: Constants.colors.backgroundPrimary
              }}>
                <WebView
                  style={{
                    resizeMode: 'cover',
                    flex: 1,
                    backgroundColor: Constants.colors.backgroundPrimary
                  }}
                  onLoadEnd={() => {
                    setEventLoading(false);
                  }}
                  source={{
                    html: `
                        <html>
                          <head>
                              <meta name="viewport" content="width=device-width, initial-scale=1.0">
                          </head>
                          <body style="background-color: ${Constants.colors.backgroundPrimary}">
                            ${c.event_embed_code}
                          </body>
                        </html>
                      `,
                  }}
                  allowsFullscreenVideo={true}
                />
                {eventLoading && (
                  <View style={{
                    ...StyleSheet.absoluteFillObject,
                    backgroundColor: Constants.colors.backgroundPrimary,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    <ActivityIndicator color={Constants.colors.textColor} />
                  </View>
                )}
              </View>
            </>
          )}
        </View>
        <View style={r.get('chatWrap', window.width)}>
          {Platform.OS === 'web' ? (
            <>
              {chatLoading && (
                <View style={{
                  ...StyleSheet.absoluteFillObject,
                  backgroundColor: Constants.colors.backgroundPrimary,
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: -1,
                }}>
                  <ActivityIndicator color={Constants.colors.textColor} />
                </View>
              )}
              <div style={{ height: '100%', width: '100%' }} dangerouslySetInnerHTML={{ __html: c.chat_embed_code }}></div>
            </>
          ) : (
            <>
              <View style={{
                flex: 1,
                backgroundColor: Constants.colors.backgroundPrimary
              }}>
                <WebView
                  style={{
                    resizeMode: 'cover',
                    flex: 1,
                    backgroundColor: Constants.colors.backgroundPrimary
                  }}
                  onLoadEnd={() => {
                    setChatLoading(false);
                  }}
                  source={{
                    html: `
                        <html>
                          <head>
                              <meta name="viewport" content="width=device-width, initial-scale=1.0">
                          </head>
                          <body style="background-color: ${Constants.colors.backgroundPrimary}">
                            ${c.chat_embed_code}
                          </body>
                        </html>
                      `,
                  }}
                  allowsFullscreenVideo={true}
                />
                {chatLoading && (
                  <View style={{
                    ...StyleSheet.absoluteFillObject,
                    backgroundColor: Constants.colors.backgroundPrimary,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    <ActivityIndicator color={Constants.colors.textColor} />
                  </View>
                )}
              </View>
            </>
          )}
        </View>
      </View>
    </DashboardLayout>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundSecondary,
    flexDirection: 'row',
  },
  channelWrap: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundPrimary,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  chatWrap: {
    width: 400,
    height: '100%',
    borderLeftWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  scheduleText: {
    fontSize: 16,
    lineHeight: 24,
    color: Constants.colors.textColor,
  },
  channels: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -15,
  },
  channelOuter: {
    padding: 15,
    width: '33.3%',
  },
  channel: {
    borderWidth: 1,
    borderRadius: 8,
    overflow: 'hidden',
    borderColor: Constants.colors.borderColor,
    backgroundColor: Constants.colors.backgroundSecondary,
    width: '100%',
  },
  channelImgWrap: {
    width: '100%',
    marginBottom: 0,
  },
  channelImg: {
    flex: 1,
  },
  channelInfo: {
    padding: 20,
  },
  channelTitle: {
    fontSize: 20,
    lineHeight: 24,
    color: Constants.colors.textColor,
    marginBottom: 5,
  },
  channelDesc: {
    fontSize: 15,
    lineHeight: 20,
    color: Constants.colors.textColor,
  },
  headingWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  leftLink: {
    alignSelf: 'flex-start',
  },
  btnLightSm: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 10,
    paddingHorizontal: 15,
    borderRadius: 8,
  },
  btnLightSmText: {
    fontWeight: '500',
  },
  btnSm: {
    // backgroundColor: Constants.colors.back,
    padding: 10,
    paddingHorizontal: 15,
    borderRadius: 8,
    marginVertical: 10,
    borderColor: Constants.colors.textColor,
    borderWidth: 1,
  },
  btnSmText: {
    fontWeight: '500',
    color: Constants.colors.textColor,
  },
});

const stylesSM = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  channelsWrap: {
    width: '100%',
  },
  scheduleWrap: {
    width: '100%',
  },
});

const stylesMD = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  channelsWrap: {
    width: '100%',
  },
  scheduleWrap: {
    width: '100%',
  },
});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
