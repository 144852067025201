import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator, TouchableOpacity, TextInput, ScrollView,
  Platform, Image,
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import { useCallback, useEffect, useRef, useState } from "react";
import appState from '../../app.state';
import SplashScreen from "./Splash";
import DraggableFlatList, { ScaleDecorator } from "react-native-draggable-flatlist";
import Ionicons from "@expo/vector-icons/Ionicons";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import { useFocusEffect } from "@react-navigation/native";
import * as ImagePicker from "expo-image-picker";
import fmtAttachment from "../utilities/fmtAttachment";

export default function EditLiveScreen({ route, navigation }) {
  const window = useWindowDimensions();
  const ww = window.width;

  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState('');
  const [channels, setChannels] = useState([]);
  const deletedChannels = useRef([]);
  const [submitting, setSubmitting] = useState(false);

  useFocusEffect(useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const chRes = await appState.appApi.getLiveChannels({ limit: 99 });
        setChannels(chRes.data);
        setSchedule(await appState.appApi.getLiveSchedule());
        setLoading(false);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation, route]));

  const save = () => {
    (async () => {
      try {
        if (submitting) return;
        setSubmitting(true);

        await Promise.all(channels.map(async (c, i) => {
          const data = { ...c, order: i };

          if (c.newImage) {
            data.thumbnail_id = await appState.appApi.uploadAttachment(c.newImage);
          }

          if (!c.new) {
            await appState.appApi.updateLiveChannel(c.id, data);
          } else {
            await appState.appApi.createLiveChannel(data);
          }
        }));

        await Promise.all(deletedChannels.current.map(async (c) => {
          await appState.appApi.deleteLiveChannel(c.id);
        }));

        await appState.appApi.updateOptions({
          data: [
            { key: 'live_schedule', value: schedule },
          ],
        });
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    })();
  };

  const deleteChannel = (i) => {
    const channel = channels[i];
    deletedChannels.current.push(channel);

    const vids = JSON.parse(JSON.stringify(channels));
    vids.splice(i, 1);
    setChannels(vids);
  };

  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  const addChannel = () => {
    const vids = JSON.parse(JSON.stringify(channels));
    setChannels([...vids, { new: true, id: makeid(5), title: '', description: '', event_embed_code: '', chat_embed_code: '', newImage: '', thumbnail: {}, thumbnail_id: null, open: true }]);
  };

  const updateChannelField = (index, field, text) => {
    const items = JSON.parse(JSON.stringify(channels));
    items[index][field] = text;
    setChannels(items);
  };

  const selectChannelImg = (index) => {
    (async () => {
      try {
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsEditing: true,
          aspect: [1, 1],
          quality: 1,
        });

        console.log(result);

        if (result.cancelled) return;

        updateChannelField(index, 'newImage', result.uri);

      } catch (e) {
        console.error(e);
      }
    })();
  };


  if (loading) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  navigation.setOptions({ title: `Edit Live` });

  const renderItem = ({ item, drag, isActive, getIndex }) => {
    return (
      <ScaleDecorator>
        <View style={[r.get('channel', window.width)]}>
          <TouchableOpacity
            activeOpacity={0.5}
            onPressIn={drag}
            disabled={isActive}
            style={[r.get('moveWrap', window.width)]}
          >
            <Ionicons name="ios-move-outline" size={22} color={Constants.colors.textColor} />
          </TouchableOpacity>
          <View style={[r.get('channelContent', window.width)]}>
            <View style={[r.get('channelHead', window.width)]}>
              <HoverTouchableOpacity
                style={[r.get('channelOpenBtn', window.width)]}
                onPress={() => updateChannelField(getIndex(), 'open', !item.open)}
              >
                <Text style={[r.get('channelHeading', window.width)]}>{item.title}</Text>
                <Ionicons name="caret-down-outline" size={22} color={Constants.colors.textColor} accessibilityLabel="Toggle Channel Content" />
              </HoverTouchableOpacity>
            </View>
            {item.open && (
              <EditChannelFields item={item} getIndex={getIndex} updateChannelField={updateChannelField} selectChannelImg={selectChannelImg} />
            )}
          </View>

          {item.open && (
            <HoverTouchableOpacity
              containerStyle={[r.get('iconCloseWrap', window.width), r.get('iconCloseWrapRight', window.width)]}
              style={[r.get('iconClose', window.width), r.get('iconCloseDelete', window.width)]}
              onPress={() => deleteChannel(getIndex())}
            >
              <Ionicons name="ios-close-outline" size={22} color={Constants.colors.textColor}
                        accessibilityLabel="Delete Channel" />
            </HoverTouchableOpacity>
          )}
        </View>
      </ScaleDecorator>
    );
  };

  console.log({ channels });

  return (
    <DashboardLayout>
      <View style={r.get('container', ww)}>
        <View style={r.get('body', ww)}>
          <View style={r.get('contentContainer', ww)}>
            {Platform.OS === 'web' && (
              <View style={{ alignItems: 'center', }}>
                <View style={{
                  maxWidth: 900,
                  width: '100%',
                  marginTop: 20,
                  marginBottom: 10,
                  alignItems: 'flex-start'
                }}>
                  <HoverTouchableOpacity
                    onPress={() => navigation.navigate('Live')}
                    style={r.get('goBackBtn', ww)}>
                    <Ionicons name="ios-chevron-back-outline" size={22} color={Constants.colors.textColor} />
                    <Text style={r.get('goBackText', ww)}>Go Back</Text>
                  </HoverTouchableOpacity>
                </View>
              </View>
            )}

            <View>
              <Text style={r.get('heading', ww)}>Edit Schedule</Text>
            </View>

            <View style={[r.get('formGroup', window.width), r.get('formGroupLast', window.width)]}>
              {/*<Text style={[r.get('label', window.width)]}>Schedule</Text>*/}

              <TextInput
                style={r.get('input', window.width)}
                onChangeText={setSchedule}
                value={schedule}
                multiline
                numberOfLines={8}
              />
            </View>
          </View>

          <DraggableFlatList
            data={channels}
            contentContainerStyle={[r.get('contentContainer', ww)]}
            onDragEnd={({ data }) => setChannels(data)}
            keyExtractor={(item) => item.id}
            ListHeaderComponent={() => (
              <>
                <View style={{ marginTop: 20 }}>
                  <Text style={r.get('heading', ww)}>Edit Channels</Text>
                </View>
              </>
            )}
            renderItem={renderItem}
            ListFooterComponent={() => (
              <View>
                <HoverTouchableOpacity
                  containerStyle={[r.get('iconCloseWrap', window.width)]}
                  style={r.get('iconClose', window.width)}
                  onPress={() => addChannel()}
                >
                  <Ionicons name="ios-add-outline" size={22} color={Constants.colors.textColor}
                            accessibilityLabel="Add Channel" />
                </HoverTouchableOpacity>

                <HoverTouchableOpacity
                  onPress={() => save()}
                  containerStyle={[r.get('btnLeft', ww), { marginTop: 20 }]}
                  style={[r.get('btn', ww)]}
                >
                  {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={Constants.colors.textColor} />}
                  <Text style={r.get('btnText', ww)}>Save</Text>
                </HoverTouchableOpacity>
              </View>
            )}
          />
        </View>
      </View>
    </DashboardLayout>
  );
}

function EditChannelFields({ item, getIndex, updateChannelField, selectChannelImg }) {
  const img = fmtAttachment(item.thumbnail);

  return (
    <View style={r.get('channelFields', window.width)}>
      <View style={r.get('formGroup', window.width)}>
        <Text style={[r.get('label', window.width)]}>Title</Text>

        <TextInput
          style={r.get('input', window.width)}
          onChangeText={(text) => updateChannelField(getIndex(), 'title', text)}
          value={item.title}
        />
      </View>

      <View style={r.get('formGroup', window.width)}>
        <Text style={[r.get('label', window.width)]}>Description</Text>

        <TextInput
          style={r.get('input', window.width)}
          onChangeText={(text) => updateChannelField(getIndex(), 'description', text)}
          value={item.description}
          multiline
          numberOfLines={4}
        />
      </View>

      <View style={[r.get('formGroup', window.width)]}>
        <Text style={[r.get('label', window.width)]}>Event Embed Code</Text>

        <TextInput
          style={r.get('input', window.width)}
          onChangeText={(text) => updateChannelField(getIndex(), 'event_embed_code', text)}
          value={item.event_embed_code}
          multiline
          numberOfLines={4}
        />
      </View>

      <View style={[r.get('formGroup', window.width)]}>
        <Text style={[r.get('label', window.width)]}>Chat Embed Code</Text>

        <TextInput
          style={r.get('input', window.width)}
          onChangeText={(text) => updateChannelField(getIndex(), 'chat_embed_code', text)}
          value={item.chat_embed_code}
          multiline
          numberOfLines={4}
        />
      </View>

      <View style={[r.get('formGroup', window.width), r.get('formGroupLast', window.width)]}>
        <Text style={[r.get('label', window.width), { marginBottom: 20 }]}>Featured Image</Text>

        <HoverTouchableOpacity onPress={() => selectChannelImg(getIndex())}  style={r.get('channelImgWrap', window.width)}>
          <Image source={{ uri: item.newImage ? item.newImage : img && img.sizes.md }} style={r.get('channelImg', window.width)} />

          <View style={r.get('channelImgEditBtn', window.width)}>
            <Ionicons name="ios-create-outline" size={18} color={'#fff'} />
          </View>
        </HoverTouchableOpacity>
      </View>
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    backgroundColor: Constants.colors.backgroundPrimary,
  },
  body: {
    maxWidth: 900,
    width: '100%',
    borderRadius: 8,
  },
  contentContainer: {
    paddingVertical: 40,
    paddingHorizontal: 40,
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  btnLeft: {
    alignSelf: 'flex-start',
  },
  btn: {
    // backgroundColor: '#000',
    borderWidth: 1,
    borderColor: Constants.colors.accent,
    padding: 15,
    paddingHorizontal: 30,
    borderRadius: 8,
    marginVertical: 10,
    letterSpacing: 1,
    textTransform: 'uppercase',
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnText: {
    fontWeight: '500',
    color: Constants.colors.accent,
    fontSize: 16,
  },
  channel: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginBottom: 10,
    borderRadius: 8,
    backgroundColor: Constants.colors.backgroundSecondary,
    overflow: 'hidden',
  },
  label: {
    fontSize: 16,
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginTop: 5,
    fontFamily: Constants.fontFamily,
    flex: 1,
    color: Constants.colors.textColor,
  },
  moveWrap: {
    justifyContent: 'center',
    padding: 10,
    marginRight: 5,
    position: 'absolute',
    top: 0,
    bottom: 0,
    alignItems: 'center',
    width: 45,
  },
  channelContent: {
    padding: 15,
    flex: 1,
    paddingRight: 65,
    borderLeftWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginLeft: 45,
  },
  iconCloseWrap: {
    alignSelf: 'flex-start',
  },
  iconCloseWrapRight: {
    position: 'absolute',
    top: '50%',
    // transform: [{ translateY: '-50%' }],
    right: 15,
  },
  iconClose: {
    backgroundColor: '#000',
    borderWidth: 1,
    borderColor: '#fff',
    width: 32,
    height: 32,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  iconCloseDelete: {
    backgroundColor: '#b11a1a',
  },
  formGroup: {
    marginBottom: 15,
    flex: 1,
  },
  formGroupLast: {
    marginBottom: 0,
  },
  goBackBtn: {
    flexDirection: 'row',
    // backgroundColor: '#000',
    alignItems: 'center',
    // padding: 15,
    // paddingVertical: 5,
    borderRadius: 8,
    cursor: 'pointer',
  },
  goBackText: {
    color: Constants.colors.textColor,
  },
  channelHead: {
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
    margin: -15,
    padding: 15,
    marginRight: -65,
    paddingRight: 30,
    marginBottom: -16,
  },
  channelFields: {
    marginTop: 31,
  },
  channelHeading: {
    fontSize: 16,
    fontWeight: '500',
    flex: 1,
    color: Constants.colors.textColor,
  },
  channelOpenBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  channelImgEditBtn: {
    position: 'absolute',
    top: -10,
    right: -10,
    backgroundColor: '#000',
    height: 32,
    width: 32,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  channelImgWrap: {
    maxWidth: 300,
    aspectRatio: 16/9,
    marginRight: 20,
  },
  channelImg: {
    maxWidth: 300,
    aspectRatio: 16/9,
    backgroundColor: Constants.colors.backgroundSecondary,
    borderRadius: 5,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
});

const stylesSM = StyleSheet.create({
  contentContainer: {
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
