import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  TextInput,
  ActivityIndicator,
  ScrollView,
  Image,
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import Swiper from 'react-native-swiper/src';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import { useCallback, useEffect, useState } from "react";
import SplashScreen from "./Splash";
import appState from '../../app.state';
import fmtAttachment from "../utilities/fmtAttachment";
import { fmtDT } from "../utilities/fmtDate";
import * as ImagePicker from "expo-image-picker";
import DraggableFlatList, { ScaleDecorator } from "react-native-draggable-flatlist";
import { useFocusEffect } from "@react-navigation/native";

export default function AddEditMessageScreen({ navigation, route }) {
  const window = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [e, setError] = useState(true);
  const [msg, setMsg] = useState('');
  const [images, setImages] = useState([]);

  let { id, group } = route.params;
  const isNew = id === 'new';
  if (isNew) id = null;

  useFocusEffect(useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        if (isNew) return;

        const m = await appState.appApi.getBroadcastMessage(id);
        setMsg(m.content);
        setImages(m.images.map((i) => {
          const a = fmtAttachment(i);
          return {
            id: i.id,
            key: i.id,
            ...a,
            isNew: false,
            source: { uri: a && a.sizes && a.sizes.lg },
          };
        }));
      } catch (e) {
        setError(e);
        console.log('error');
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation, id]));

  const fieldError = (f) => (e && e.errors && e.errors[f]) || [];

  const addImages = () => {
    (async () => {
      try {
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          aspect: [16, 9],
          quality: 1,
          allowsMultipleSelection: true,
        });

        console.log(result);

        if (result.cancelled) return;

        const newImages = result.selected.map((r) => ({
          result: r,
          isNew: true,
          key: (Math.random() + 1).toString(36).substring(7),
          source: { uri: r.uri },
        }));

        setImages([...images, ...newImages]);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const save = () => {
    if (submitting) return;

    (async () => {
      try {
        setSubmitting(true);
        setError(false);

        // upload attachments
        const imageIds = await Promise.all(images.map(async (i) => {
          const dup = { ...i };
          if (dup.isNew) {
            dup.id = await appState.appApi.uploadAttachment(i.result.uri);
          }

          return dup.id;
        }));

        const data = {
          group,
          content: msg,
          images: imageIds,
        };

        if (isNew) {
          await appState.appApi.createBroadcastMessage(data);
        } else {
          await appState.appApi.updateBroadcastMessage(id, data);
        }

        navigation.goBack();
      } catch (e) {
        console.error(e);
        setError(e);
      } finally {
        setSubmitting(false);
      }
    })();
  };


  if (loading) return <SplashScreen />;

  navigation.setOptions({
    title: isNew ? 'Add Message' : `Edit Message (ID: ${id})`,
  });

  return (
    <TouchableWithoutFeedback onPress={() => Platform.OS === 'web' && navigation.goBack()}>
      <ScrollView style={{ height: window.height }}>
        <View style={[r.get('container', window.width)]}>
          <TouchableWithoutFeedback>
            <View style={[r.get('containerInner', window.width), { minHeight: window.height - 20 }]}
                  onStartShouldSetResponder={() => true}>
              {Platform.OS === 'web' && (
                <View style={r.get('head', window.width)}>
                  <Text style={r.get('title', window.width)}>{isNew ? 'Add Message' : `Edit Message (ID: ${id})`}</Text>

                  <HoverTouchableOpacity onPress={() => navigation.goBack()}
                                         style={[r.get('close', window.width)]}>
                    <Ionicons name="ios-close" size={22} style={r.get('closeIcon', window.width)}
                              accessibilityLabel={"Close"} />
                  </HoverTouchableOpacity>
                </View>
              )}

              <View style={r.get('body', window.width)}>
                <View style={[r.get('addMessageWrap', window.width)]}>
                  <View>
                    <View style={[r.get('formGroup', window.width)]}>
                      <Text style={[r.get('label', window.width)]}>Message</Text>
                      <Errors errors={fieldError('description')} />
                      <TextInput
                        style={[r.get('input', window.width), r.get('msgInput', window.width)]}
                        onChangeText={setMsg}
                        value={msg}
                        placeholder={''}
                        placeholderTextColor={'#aaa'}
                        multiline={true}
                        numberOfLines={8}
                      />
                    </View>
                  </View>

                  <View>
                    <View style={[r.get('formGroup', window.width)]}>
                      <Text style={[r.get('label', window.width)]}>Images</Text>
                      <Errors errors={fieldError('images')} />

                      <View style={[r.get('imagesWrap', window.width)]}>
                        <ImagesList images={images} setImages={setImages} />
                      </View>

                      <HoverTouchableOpacity style={r.get('smBtn', window.width)}
                                             onPress={() => addImages()}>
                        <Ionicons style={r.get('smBtnIcon', window.width)} name="ios-add-outline"
                                  size={22} color={'#fff'} />
                        <Text style={r.get('smBtnText', window.width)}>Add Images</Text>
                      </HoverTouchableOpacity>
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row' }}>
                    <HoverTouchableOpacity onPress={() => save()} style={[r.get('btn', window.width)]}>
                      {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
                      <Text style={r.get('btnText', window.width)}>{isNew ? 'Send' : 'Save'}</Text>
                    </HoverTouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </ScrollView>
    </TouchableWithoutFeedback>
  );
}


function ImagesList({ images, setImages }) {
  const removeImage = (i) => {
    const d = [...images];
    d.splice(i, 1);
    setImages(d);
  };

  const renderItem = ({ item, drag, isActive, getIndex }) => {
    return (
      <ScaleDecorator>
        <TouchableOpacity
          activeOpacity={0.9}
          onPressIn={drag}
          disabled={isActive}
          style={[r.get('imageWrap', window.width)]}
        >
          <Image source={item.source} style={[r.get('image', window.width)]}
                 resizeMode={'contain'} />

          <HoverTouchableOpacity
            containerStyle={r.get('imageIconCloseWrap', window.width)}
            style={r.get('imageIconClose', window.width)}
            onPress={() => removeImage(getIndex())}
          >
            <Ionicons name="ios-close-outline" size={22} color={'#fff'}
                      accessibilityLabel="Delete Image" />
          </HoverTouchableOpacity>
        </TouchableOpacity>
      </ScaleDecorator>
    );
  };

  return (
    <DraggableFlatList
      data={images}
      // horizontal={true}
      onDragEnd={({ data }) => setImages(data)}
      keyExtractor={(item) => item.key}
      renderItem={renderItem}
    />
  );
}

function Errors({ errors }) {
  if (!errors || errors.length === 0) return;

  return (
    <View style={r.get('errors', window.width)}>
      {errors.map((e) => (
        <Text key={e} style={r.get('error', window.width)}>{e}</Text>
      ))}
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: Platform.OS === 'web' ? {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
    paddingTop: 20,
  } : {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  containerInner: {
    minHeight: '50%',
    maxWidth: 800,
    width: '100%',
    backgroundColor: Constants.colors.backgroundSecondary,
    ...Platform.select({
      web: {
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
      },
    }),
  },
  closeIcon: {
    color: Constants.colors.textColor,
  },
  head: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 40,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  title: {
    flex: 1,
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    color: Constants.colors.textColor,
  },
  body: {
    flex: 1,
  },
  addMessageWrap: {
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  heading2: {
    fontSize: 18,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 10,
    color: Constants.colors.textColor,
  },
  smBtn: {
    flexDirection: 'row',
    // backgroundColor: '#000',
    paddingHorizontal: 15,
    paddingVertical: 5,
    alignItems: 'center',
    alignSelf: 'flex-start',
    borderRadius: 8,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: Constants.colors.textColor,
  },
  smBtnText: {
    color: Constants.colors.textColor,
    fontWeight: '500',
  },
  btn: {
    backgroundColor: Constants.colors.accent,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    minWidth: 200,
  },
  btnText: {
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.backgroundSecondary,
  },
  errors: {
    marginBottom: 0,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  formGroup: {
    marginBottom: 15,
    marginRight: 15,
  },
  label: {
    fontSize: 16,
    color: Constants.colors.textColor,
  },
  desc: {
    fontSize: 13,
    fontStyle: 'italic',
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  imagesWrap: {
    marginVertical: 10,
  },
  image: {
    width: '100%',
    aspectRatio: 2/1,
    borderRadius: 8,
    marginRight: 15,
    marginBottom: 15,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    backgroundColor: '#000',
  },
  imageIconCloseWrap: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  imageIconClose: {
    backgroundColor: '#b11a1a',
    borderWidth: 1,
    borderColor: '#fff',
    width: 25,
    height: 25,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  msgInput: {
    minHeight: 250,
    padding: 15,
    paddingTop: 15,
    paddingBottom: 15,
  },
});

const stylesSM = StyleSheet.create({
  addMessageWrap: {
    paddingHorizontal: 15,
    paddingVertical: 15,
  },
  container: {
    ...(Platform.OS === 'web' ? {
      paddingHorizontal: 8,
    } : {})
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
