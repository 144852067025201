import axios from 'axios';

export default class AppAPI {
  constructor({
    endpoint, authProvider
  }) {
    this.endpoint = endpoint;
    this.authProvider = authProvider;
  }

  async createToken({ email, password, device_name }) {
    const r = await this.apiCall({
      method: 'post',
      path: '/token',
      data: {
        email,
        password,
        device_name,
      },
    });

    return r.data.token;
  }

  async forgotPass({ email }) {
    const r = await this.apiCall({
      method: 'post',
      path: '/forgot-password',
      data: {
        email,
      },
    });

    return r.data;
  }

  async whoami() {
    try {
      const r = await this.apiCall({
        method: 'get',
        path: '/user',
      });

      return r.data;
    } catch (e) {
      return false;
    }
  }

  async getPaymentOptions() {
    const r = await this.apiCall({
      method: 'get',
      path: '/payment-options',
    });

    return r.data;
  }

  async register({ payment_option_id, payment_method, email, password, password_confirmation, device_name, affiliate_id }) {
    const r = await this.apiCall({
      method: 'post',
      path: '/register',
      data: {
        email,
        password,
        password_confirmation,
        affiliate_id,
      },
    });

    const token = await this.createToken({ email, password, device_name });
    await this.authProvider.setAuthToken(token);

    return this.createMembership({
      payment_option_id,
      payment_method,
      user_id: r.data.id,
    });

    return r.data;
  }

  async createMembership({ payment_option_id, payment_method, user_id }) {
    const r = await this.apiCall({
      method: 'post',
      path: '/memberships',
      data: { payment_option_id, payment_method, user_id },
    });

    return r.data;
  }

  async updateMembership(membership_id, data) {
    const r = await this.apiCall({
      method: 'put',
      path: `/memberships/${membership_id}`,
      data,
    });

    return r.data;
  }

  async getPayment(id) {
    const r = await this.apiCall({
      method: 'get',
      path: `/payments/${id}`,
    });

    return r.data;
  }

  async getPayments(params) {
    const r = await this.apiCall({
      method: 'get',
      path: `/payments`,
      params,
    });

    return r.data;
  }

  async createPayment(data) {
    const r = await this.apiCall({
      method: 'post',
      path: `/payments`,
      data,
    });

    return r.data;
  }

  async updatePayment(id, data) {
    const r = await this.apiCall({
      method: 'post',
      path: `/payments/${id}`,
      data,
    });

    return r.data;
  }

  async deletePayment(id) {
    const r = await this.apiCall({
      method: 'delete',
      path: `/payments/${id}`,
    });

    return r.data;
  }

  async getUsers(params) {
    console.log({ params });
    const r = await this.apiCall({
      method: 'get',
      path: `/users`,
      params,
    });

    return r.data;
  }

  async getUser(id) {
    const r = await this.apiCall({
      method: 'get',
      path: `/users/${id}`,
    });

    return r.data;
  }

  async createUser(data) {
    const r = await this.apiCall({
      method: 'post',
      path: `/users`,
      data,
    });

    return r.data;
  }

  async updateUser(user_id, data) {
    const r = await this.apiCall({
      method: 'put',
      path: `/users/${user_id}`,
      data,
    });

    return r.data;
  }

  async deleteUser(user_id) {
    const r = await this.apiCall({
      method: 'delete',
      path: `/users/${user_id}`,
    });

    return r.data;
  }

  async uploadAttachment(uri) {
    const file = isBase64DataUrl(uri) ? dataURLtoFile(uri, 'file') : localURItoFile(uri);

    let formData = new FormData();
    formData.append('file', file);
    console.log({ file });

    const r = await this.apiCall({
      method: 'post',
      path: '/attachments',
      data: formData,
      multipart: true,
    });

    return r.data;
  }

  async createIdea(data) {
    const r = await this.apiCall({
      method: 'post',
      path: '/ideas',
      data: data,
    });

    return r.data;
  }

  async getIdeas(params) {
    const r = await this.apiCall({
      method: 'get',
      path: `/ideas`,
      params,
    });

    return r.data;
  }

  async getIdea(id) {
    const r = await this.apiCall({
      method: 'get',
      path: `/ideas/${id}`,
    });

    return r.data;
  }

  async updateIdea(id, data) {
    const r = await this.apiCall({
      method: 'put',
      path: `/ideas/${id}`,
      data,
    });

    return r.data;
  }

  async deleteIdea(id) {
    const r = await this.apiCall({
      method: 'delete',
      path: `/ideas/${id}`,
    });

    return r.data;
  }

  async getBroadcastMessages(params) {
    if (!params || !params.group) return [];

    const r = await this.apiCall({
      method: 'get',
      path: `/broadcast-chat`,
      params,
    });

    return r.data;
  }

  async getBroadcastMessage(id) {
    const r = await this.apiCall({
      method: 'get',
      path: `/broadcast-chat/${id}`,
    });

    return r.data;
  }

  async createBroadcastMessage(data) {
    const r = await this.apiCall({
      method: 'post',
      path: `/broadcast-chat`,
      data,
    });

    return r.data;
  }

  async updateBroadcastMessage(id, data) {
    const r = await this.apiCall({
      method: 'put',
      path: `/broadcast-chat/${id}`,
      data,
    });

    return r.data;
  }

  async deleteBroadcastMessage(id) {
    console.log('deleteBroadcastMessage', { id });
    const r = await this.apiCall({
      method: 'delete',
      path: `/broadcast-chat/${id}`,
    });

    return r.data;
  }

  async getLearnCategories(params) {
    console.log({ params });
    const r = await this.apiCall({
      method: 'get',
      path: `/learn-categories`,
      params,
    });

    return r.data;
  }

  async createLearnCategory(data) {
    const r = await this.apiCall({
      method: 'post',
      path: `/learn-categories`,
      data,
    });

    return r.data;
  }

  async updateLearnCategory(id, data) {
    const r = await this.apiCall({
      method: 'put',
      path: `/learn-categories/${id}`,
      data,
    });

    return r.data;
  }

  async deleteLearnCategory(id) {
    const r = await this.apiCall({
      method: 'delete',
      path: `/learn-categories/${id}`,
    });

    return r.data;
  }

  async getLearnVideos(params) {
    console.log({ params });
    const r = await this.apiCall({
      method: 'get',
      path: `/learn-videos`,
      params,
    });

    return r.data;
  }

  async createLearnVideo(data) {
    const r = await this.apiCall({
      method: 'post',
      path: `/learn-videos`,
      data,
    });

    return r.data;
  }

  async updateLearnVideo(id, data) {
    const r = await this.apiCall({
      method: 'put',
      path: `/learn-videos/${id}`,
      data,
    });

    return r.data;
  }

  async deleteLearnVideo(id) {
    const r = await this.apiCall({
      method: 'delete',
      path: `/learn-videos/${id}`,
    });

    return r.data;
  }

  async saveDevice(token) {
    const r = await this.apiCall({
      method: 'post',
      path: `/devices`,
      data: { token },
    });

    return r.data;
  }

  async affiliateRegister(token) {
    const r = await this.apiCall({
      method: 'post',
      path: `/affiliates/register`,
      data: { token },
    });

    return r.data;
  }

  async getInvite(token) {
    const r = await this.apiCall({
      method: 'get',
      path: `/affiliates/invite/${token}`,
    });

    return r.data;
  }

  async updateWallet({ payout_address }) {
    const r = await this.apiCall({
      method: 'put',
      path: `/wallet`,
      data: {
        payout_address,
      },
    });

    return r.data;
  }

  async getAffiliatePendingInvitations(params) {
    const r = await this.apiCall({
      method: 'get',
      path: `/affiliates/invitations`,
      params,
    });

    return r.data;
  }

  async getAffiliates(params) {
    const r = await this.apiCall({
      method: 'get',
      path: `/affiliates`,
      params,
    });

    return r.data;
  }

  async getAffiliate(id) {
    const r = await this.apiCall({
      method: 'get',
      path: `/affiliates/${id}`,
    });

    return r.data;
  }

  async sendInvite(data) {
    const r = await this.apiCall({
      method: 'post',
      path: `/affiliates/invite`,
      data,
    });

    return r.data;
  }

  async getPayouts(params) {
    const r = await this.apiCall({
      method: 'get',
      path: `/payouts`,
      params,
    });

    return r.data;
  }

  async getPayoutSum(params) {
    const r = await this.apiCall({
      method: 'get',
      path: `/payouts/sum-report`,
      params,
    });

    return r.data;
  }

  async getWithdrawals(params) {
    const r = await this.apiCall({
      method: 'get',
      path: `/wallet/withdrawals`,
      params,
    });

    return r.data;
  }

  async getWallet() {
    const r = await this.apiCall({
      method: 'get',
      path: `/wallet`,
    });

    return r.data;
  }

  async getOptions(params) {
    const r = await this.apiCall({
      method: 'get',
      path: `/site-options`,
      params,
    });

    const dataObject = r.data.reduce((acc, item) => {
      acc[item.option_key] = item.option_value;
      return acc;
    }, {});

    return { ...r.data, dataObject };
  }

  async updateOptions(data) {
    const r = await this.apiCall({
      method: 'put',
      path: `/site-options/update-keys`,
      data,
    });

    return r.data;
  }

  async getPartners() {
    const r = await this.apiCall({
      method: 'get',
      path: `/site-partners`,
    });

    return r.data;
  }

  async updatePartners(data) {
    const r = await this.apiCall({
      method: 'post',
      path: `/site-partners/bulk`,
      data,
    });

    return r.data;
  }

  async contactSupport(data) {
    const r = await this.apiCall({
      method: 'post',
      path: `/contact-forms/support`,
      data,
    });

    return r.data;
  }

  async contactAffiliate(data) {
    const r = await this.apiCall({
      method: 'post',
      path: `/contact-forms/affiliate`,
      data,
    });

    return r.data;
  }

  async getCandles(exchange, params) {
    const r = await this.apiCall({
      method: 'get',
      path: `/market-data/${exchange}/candles`,
      params,
    });

    return r.data;
  }

  async getChartData({ type, symbol }) {
    const r = await this.apiCall({
      method: 'get',
      path: `/chart-data/${type}/${symbol}`,
    });

    return r.data;
  }

  async saveChartData({ type, symbol, data }) {
    const r = await this.apiCall({
      method: 'post',
      path: `/chart-data/${type}/${symbol}`,
      data: { data },
    });

    return r.data;
  }

  async getLiveSchedule() {
    const r = await this.apiCall({
      method: 'get',
      path: `/live-schedule`,
    });

    return r.data;
  }

  async getLiveChannels(params) {
    const r = await this.apiCall({
      method: 'get',
      path: `/live-channels`,
      params,
    });

    return r.data;
  }

  async createLiveChannel(data) {
    const r = await this.apiCall({
      method: 'post',
      path: `/live-channels`,
      data,
    });

    return r.data;
  }

  async updateLiveChannel(id, data) {
    const r = await this.apiCall({
      method: 'put',
      path: `/live-channels/${id}`,
      data,
    });

    return r.data;
  }

  async deleteLiveChannel(id) {
    const r = await this.apiCall({
      method: 'delete',
      path: `/live-channels/${id}`,
    });

    return r.data;
  }

  async payWithCard(data) {
    const r = await this.apiCall({
      method: 'post',
      path: `/card-payments/pay`,
      data,
    });

    return r.data;
  }

  async saveCard(data) {
    const r = await this.apiCall({
      method: 'post',
      path: `/card-payments/cards`,
      data,
    });

    return r.data;
  }

  async getCards(params) {
    const r = await this.apiCall({
      method: 'get',
      path: `/card-payments/cards`,
      params,
    });

    return r.data;
  }

  async setDefaultCard(id) {
    const r = await this.apiCall({
      method: 'post',
      path: `/card-payments/cards/${id}`,
    });

    return r.data;
  }

  async deleteCard(id) {
    const r = await this.apiCall({
      method: 'delete',
      path: `/card-payments/cards/${id}`,
    });

    return r.data;
  }

  async getAppWalletReport(params) {
    const r = await this.apiCall({
      method: 'get',
      path: `/app-wallet-report`,
      params
    });

    return r.data;
  }


  async apiCall({ method, path, data, params, multipart }) {
    console.log(this.endpoint, { method, path, data, params });
    console.log({
      method,
      url: `${this.endpoint}${path}`,
      data,
      params,
      headers: {
        Authorization: 'Bearer ' + this.authProvider.token,
        'Content-Type': !multipart ? 'application/json' : 'multipart/form-data',
        'Accept': 'application/json',
      }
    });
    const r = await axios({
      method,
      url: `${this.endpoint}${path}`,
      data,
      params,
      validateStatus: () => true,
      headers: {
        Authorization: 'Bearer ' + this.authProvider.token,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    });

    if (r.status >= 200 && r.status < 300) return r;

    let message = r.data.message;
    if (!message) {
      if (r.status === 400 || r.status === 422) {
        message = 'Invalid Request';
      } else if (r.status === 401) {
        message = 'Unauthorized';
      } else if (r.status === 403) {
        message = 'Invalid Permissions';
      } else if (r.status === 404) {
        message = 'Not Found';
      } else if (r.status === 429) {
        message = 'Too Many Requests';
      }
    }

    const e = new Error(message);
    e.errors = r.data.errors;
    throw e;
  }
}

const isBase64DataUrl = (dataurl) => {
  const p = dataurl.split(',');
  return p[0].substring(0, 5) === 'data:' && p[0].substring(p[0].length - 6) === 'base64';
};

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1)
    n -= 1 // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime })
}

const localURItoFile = (uri) => {
  let localUri = uri;
  let filename = localUri.split('/').pop();

  let match = /\.(\w+)$/.exec(filename);
  let type = match ? `image/${match[1]}` : `image`;

  return { uri: localUri, name: filename, type };
}
