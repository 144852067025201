import React, { useEffect, useState } from "react";
import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';
import { StyleSheet, useWindowDimensions, View } from "react-native";
import Constants from "../../constants";
import Responsive from "../utilities/responsive";
import appState from "../../app.state";

export default function MenuDrawerContent(props) {
  const window = useWindowDimensions();
  const [u, setUser] = useState(false);
  const ww = window.width;
  console.log({ props });
  const routeName = props.state.routeNames[props.state.index]

  useEffect(() => {
    (async () => {
      try {
        setUser(await appState.authService.whoami());
      } catch (e) {
        console.error(e);
      }
    })();
  });

  const menuItems = [
    {
      label: "Ideas",
      route: "Ideas",
      onPress: () => {
        props.navigation.navigate('Ideas')
      },
    },
    {
      label: "Strategies",
      route: "Charts",
      onPress: () => {
        props.navigation.navigate('Charts')
      },
    },
    {
      label: "Learn",
      route: "Learn",
      onPress: () => {
        props.navigation.navigate('Learn')
      },
    },
    {
      label: "Live",
      route: "Live",
      onPress: () => {
        props.navigation.navigate('Live')
      },
    },
    {
      label: "Account",
      route: "Account",
      onPress: () => {
        props.navigation.navigate('Account')
      },
    },
    {
      label: "Support",
      route: "Support",
      onPress: () => {
        props.navigation.navigate('Support')
      },
    },
    ...(u && u.user_access.includes('manage users') ? [
      {
        label: "Users",
        route: "Users",
        onPress: () => {
          props.navigation.navigate('Users', { page: 1 })
        },
      },
      {
        label: "Payments",
        route: "Payments",
        onPress: () => {
          props.navigation.navigate('Payments', { page: 1 })
        },
      },
      {
        label: "Affiliates",
        route: "Affiliates",
        onPress: () => {
          props.navigation.navigate('Affiliates', { page: 1 })
        },
      },
    ] : []),
    ...(u && u.affiliate ? [
      {
        label: "Your Affiliate Dashboard",
        route: "AffiliateDashboard",
        onPress: () => {
          props.navigation.navigate('AffiliateDashboard')
        },
      },
    ] : []),
    ...(u && u.hasWallet ? [
      {
        label: "Your Wallet",
        route: "WalletDashboard",
        onPress: () => {
          props.navigation.navigate('WalletDashboard')
        },
      },
    ] : []),
    ...(u && u.user_access.includes('manage options') ? [
      {
        label: "Partners",
        route: "PartnerSettings",
        onPress: () => {
          props.navigation.navigate('PartnerSettings')
        },
      },
      {
        label: "App Wallet",
        route: "AppWallet",
        onPress: () => {
          props.navigation.navigate('AppWallet')
        },
      },
    ] : []),
  ];

  return (
    <DrawerContentScrollView {...props}>
      <View style={r.get('menuContainer', ww)}>
        {menuItems.map((item) => (
          <DrawerItem
            key={item.route}
            style={[
              r.get('menuItem', ww),
              routeName === item.route && r.get('menuItemActive', ww)
            ]}
            label={item.label}
            labelStyle={[
              r.get('menuItemText', ww),
              routeName === item.route && r.get('menuItemTextActive', ww)
            ]}
            onPress={item.onPress}
          />
        ))}
      </View>
    </DrawerContentScrollView>
  );
}

const baseStyles = StyleSheet.create({
  menuItem: {
    backgroundColor: '#fff',
  },
  menuItemActive: {
    backgroundColor: '#eee6d9',
  },
  menuItemText: {
    color: '#000',
  },
  menuItemTextActive: {
    color: '#000',
  },
});

const stylesSM = StyleSheet.create({});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
