import * as React from 'react';
import { LogBox, Platform, StatusBar, Text, useWindowDimensions, View } from 'react-native';
import {
  Link,
  NavigationContainer,
  useLinkTo,
  useNavigation,
  useRoute,
  DefaultTheme,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import Ionicons from '@expo/vector-icons/Ionicons';
import * as Linking from 'expo-linking';
import 'react-native-gesture-handler';
import IdeasScreen from "./src/screens/Ideas";
import ChartsScreen from "./src/screens/Charts";
import LearnScreen from "./src/screens/Learn";
import LiveScreen from "./src/screens/Live";
import AccountScreen from "./src/screens/Account";
import appState from "./app.state";
import SplashScreen from "./src/screens/Splash";
import LoginScreen from "./src/screens/Login";
import { observer } from "mobx-react-lite";
import ForgotPassScreen from "./src/screens/ForgotPass";
import Register from "./src/screens/Register";
import RegisterAffiliate from "./src/screens/RegisterAffiliate";
import PayScreen from "./src/screens/Pay";
import AddEditIdeaScreen from "./src/screens/AddEditIdea";
import IdeaScreen from "./src/screens/Idea";
import AddEditMessage from "./src/screens/AddEditMessage";
import DeleteMessageScreen from "./src/screens/DeleteMessage";
import DeleteIdeaScreen from "./src/screens/DeleteIdea";
import UsersScreen from "./src/screens/Users";
import PaymentsScreen from "./src/screens/Payments";
import UserEditScreen from "./src/screens/UserEdit";
import UserCreateScreen from "./src/screens/UserCreate";
import EditLearnCategoriesScreen from "./src/screens/EditLearnCategories";
import EditLearnCategoryVideosScreen from "./src/screens/EditLearnCategoryVideos";
import ChatScreen from "./src/screens/Chat";
import { useEffect, useState } from "react";
import MoreScreen from "./src/screens/More";
import * as Notifications from "expo-notifications";
import HoverTouchableOpacity from "./src/components/HoverTouchableOpacity";
import { reveal as Menu } from "react-burger-menu";
import MenuDrawerContent from "./src/components/MenuDrawerContent";
import NotFoundScreen from "./src/screens/NotFound";
import { runInAction } from "mobx";
import 'intl';
import 'intl/locale-data/jsonp/en';
import Constants from "./constants";
import AffiliateDashboard from "./src/screens/AffiliateDashboard";
import WalletDashboard from "./src/screens/WalletDashboard";
import AffiliatesScreen from "./src/screens/Affiliates";
import PartnerSettings from "./src/screens/PartnerSettings";
import Support from "./src/screens/Support";
import EditLiveScreen from "./src/screens/EditLive";
import LiveChannelScreen from "./src/screens/LiveChannel";
import CardPayScreen from "./src/screens/CardPay";
import AppWalletScreen from "./src/screens/AppWallet";
import ManageCardsScreen from "./src/screens/ManageCards";

const authLinkConfig = {
  screens: {
    Ideas: {
      path: 'ideas',
      initialRouteName: 'Ideas',
      screens: {
        Ideas: '',
        EditIdea: {
          path: ':id',
        },
        Idea: {
          path: 'view/:id',
        },
        DeleteIdea: {
          path: 'delete/:id',
        },
        DeleteMessage: {
          path: 'chat/:id/delete',
        },
        AddEditMessage: {
          path: 'chat/:group/:id',
        },
        Chat: {
          path: 'chat/:group',
        },
      },
    },
    Charts: {
      path: 'charts',
      initialRouteName: 'Charts',
      screens: {
        Charts: ':type/:symbol',
        DeleteMessage: {
          path: 'chat/:id/delete',
        },
        AddEditMessage: {
          path: 'chat/:group/:id',
        },
        Chat: {
          path: 'chat/:group',
        },
      },
    },
    // Learn: 'learn/:categorySlug?',
    Learn: {
      path: 'learn',
      initialRouteName: 'Learn',
      screens: {
        DeleteMessage: {
          path: 'chat/:id/delete',
        },
        AddEditMessage: {
          path: 'chat/:group/:id',
        },
        Chat: {
          path: 'chat/:group',
        },
        EditLearnCategories: 'categories/edit',
        EditLearnCategoryVideos: ':categorySlug/edit',
        Learn: ':categorySlug?',
      },
    },
    Live: {
      path: 'live',
      initialRouteName: 'Live',
      screens: {
        EditLive: 'edit',
        LiveChannel: 'channel/:id',
        Live: '',
      },
    },
    Account: {
      path: 'account',
      initialRouteName: 'Account',
      screens: {
        Account: '',
        ManageCards: 'cards',
      },
    },
    Pay: 'account/pay/:id',
    CardPay: 'account/card-pay/:membership_id/:payment_option_id',
    EditUser: 'users/edit/:id',
    EditPayment: 'payments/edit/:id',
    Users: 'users/:page',
    Affiliates: 'affiliates/:page',
    Payments: 'payments/:page',
    // EditLearnCategories: 'learn/categories/edit',
    // EditLearnCategoryVideos: 'learn/:categorySlug/edit',
    RegisterAffiliate: 'affiliate-register/:token',
    AffiliateDashboard: 'affiliate-dashboard/:id?',
    WalletDashboard: 'wallet',
    PartnerSettings: 'partner-settings',
    AppWallet: 'app-wallet',
    Support: 'support',
    // NotFound: '*',
  },
};

const guestLinkConfig = {
  screens: {
    Login: 'login',
    ForgotPass: 'forgot-password',
    Register: 'register',
    RegisterAffiliate: 'affiliate-register/:token',
    NotFound: '*',
  },
};

const prefixes = [Linking.createURL('/'), 'https://ui.886crypto.tangentmedia.com', 'https://886crypto.com'];
console.log({ prefixes });

const linking = {
  prefixes,
  async getInitialURL() {
    // First, you may want to do the default deep link handling
    // Check if app was opened from a deep link
    let url = await Linking.getInitialURL();
    console.log('initial url', { url });
    if (url != null) {
      return url;
    }

    // Handle URL from expo push notifications
    const response = await Notifications.getLastNotificationResponseAsync();
    url = response?.notification.request.content.data.url;
    console.log('last url', { url });
    return url;
  },
  subscribe(listener) {
    console.log('react navigation subscribe');
    const onReceiveURL = ({ url }) => {
      console.log('onReceiveURL', { url });
      return listener(url);
    };

    // Listen to incoming links from deep linking
    const linkSub = Linking.addEventListener('url', onReceiveURL);

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener(response => {
      const url = response.notification.request.content.data.url;

      if (!url) return;
      console.log('expo listener', { url });
      listener(url);
    });

    return () => {
      // Clean up the event listeners
      linkSub.remove();
      subscription.remove();
      console.log('react navigation cleaned up');
    };
  },
};

const IdeasWebNav = function () {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator
      initialRouteName="Ideas"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="Ideas" component={IdeasScreen} />
      <Stack.Screen name="Chat" component={ChatScreen} options={{ title: 'Ideas Chat' }} />
      <Stack.Group screenOptions={{
        presentation: 'transparentModal',
        animationEnabled: true,
        ...(Platform.OS === 'web' ? TransitionPresets.FadeFromBottomAndroid : TransitionPresets.ModalPresentationIOS),
      }}>
        <Stack.Screen name="EditIdea" component={AddEditIdeaScreen} />
        <Stack.Screen name="Idea" component={IdeaScreen} />
        <Stack.Screen name="AddEditMessage" component={AddEditMessage} />
        <Stack.Screen name="DeleteMessage" component={DeleteMessageScreen} />
        <Stack.Screen name="DeleteIdea" component={DeleteIdeaScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

const ChartsWebNav = function () {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="Charts"
    >
      <Stack.Screen options={{ title: 'Strategies' }} name="Charts" component={ChartsScreen}
                    initialParams={{ symbol: 'BTC-USD', type: 'swing' }} />
      <Stack.Screen name="Chat" component={ChatScreen} options={{ title: 'Chat' }} />
      <Stack.Group screenOptions={{
        presentation: 'transparentModal',
        animationEnabled: true,
        ...(Platform.OS === 'web' ? TransitionPresets.FadeFromBottomAndroid : TransitionPresets.ModalPresentationIOS),
      }}>
        <Stack.Screen name="AddEditMessage" component={AddEditMessage} />
        <Stack.Screen name="DeleteMessage" component={DeleteMessageScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

const LearnWebNav = function () {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="Learn"
    >
      <Stack.Screen name="Learn" component={LearnScreen} />
      <Stack.Screen name="EditLearnCategories" component={EditLearnCategoriesScreen} />
      <Stack.Screen name="EditLearnCategoryVideos" component={EditLearnCategoryVideosScreen} />

      <Stack.Screen name="Chat" component={ChatScreen} options={{ title: 'Chat' }} />
      <Stack.Group screenOptions={{
        presentation: 'transparentModal',
        animationEnabled: true,
        ...(Platform.OS === 'web' ? TransitionPresets.FadeFromBottomAndroid : TransitionPresets.ModalPresentationIOS),
      }}>
        <Stack.Screen name="AddEditMessage" component={AddEditMessage} />
        <Stack.Screen name="DeleteMessage" component={DeleteMessageScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

const LiveWebNav = function () {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="Live"
    >
      <Stack.Screen name="Live" component={LiveScreen} />
      <Stack.Screen name="EditLive" component={EditLiveScreen} />
      <Stack.Screen name="LiveChannel" component={LiveChannelScreen} />
    </Stack.Navigator>
  );
};

const AccountWebNav = function () {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="Account"
    >
      <Stack.Screen name="Account" component={AccountScreen} />

      <Stack.Group screenOptions={{
        presentation: 'transparentModal',
        animationEnabled: true,
        ...(Platform.OS === 'web' ? TransitionPresets.FadeFromBottomAndroid : TransitionPresets.ModalPresentationIOS),
      }}>
        <Stack.Screen name="ManageCards" component={ManageCardsScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

const WebNav = observer(function () {
  const linkTo = useLinkTo();
  const redirect = appState.state.redirect;
  useEffect(() => {
    if (redirect) {
      const r = redirect;
      runInAction(() => {
        appState.state.redirect = null;
      });
      linkTo(r);
    }
  }, [linkTo, redirect]);

  const Drawer = createDrawerNavigator();
  return (
    <Drawer.Navigator
      screenOptions={{ headerShown: false, drawerPosition: 'right' }}
      useLegacyImplementation={true}
      drawerContent={(props) => <MenuDrawerContent {...props} />}
    >
      {appState.authService.loggedIn ? (
        <>
          <Drawer.Screen name="Ideas" component={IdeasWebNav} />
          <Drawer.Screen name="Charts" component={ChartsWebNav} options={{ title: 'Strategies' }} />
          <Drawer.Screen name="Learn" component={LearnWebNav} />
          <Drawer.Screen name="Live" component={LiveWebNav} />
          <Drawer.Screen name="Account" component={AccountWebNav} />
          <Drawer.Screen name="Pay" component={PayScreen} />
          <Drawer.Screen name="CardPay" component={CardPayScreen} />
          <Drawer.Screen name="Users" component={UsersScreen} initialParams={{ page: 1 }} />
          <Drawer.Screen name="Affiliates" component={AffiliatesScreen}
                         initialParams={{ page: 1 }} />
          <Drawer.Screen name="Payments" component={PaymentsScreen} initialParams={{ page: 1 }} />
          <Drawer.Screen name="EditUser" component={UserEditScreen} />
          <Drawer.Screen name="CreateUser" component={UserCreateScreen} />
          <Drawer.Screen name="EditLearnCategories" component={EditLearnCategoriesScreen} />
          <Drawer.Screen name="EditLearnCategoryVideos" component={EditLearnCategoryVideosScreen} />
          <Drawer.Screen name="RegisterAffiliate" component={RegisterAffiliate} />
          <Drawer.Screen name="AffiliateDashboard" component={AffiliateDashboard} />
          <Drawer.Screen name="WalletDashboard" component={WalletDashboard} />
          <Drawer.Screen name="PartnerSettings" component={PartnerSettings} />
          <Drawer.Screen name="AppWallet" component={AppWalletScreen} />
          <Drawer.Screen name="Support" component={Support} />
          <Drawer.Screen name="NotFound" component={NotFoundScreen} />
        </>
      ) : (
        <>
          <Drawer.Screen name="Login" component={LoginScreen} />
          <Drawer.Screen name="ForgotPass" component={ForgotPassScreen} />
          <Drawer.Screen name="Register" component={Register} />
          <Drawer.Screen name="RegisterAffiliate" component={RegisterAffiliate} />
          <Drawer.Screen name="NotFound" component={NotFoundScreen} />
        </>
      )}
    </Drawer.Navigator>
  );
});

const IdeasNativeNav = function () {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerStyle: {
          backgroundColor: Constants.colors.backgroundPrimary,
          shadowColor: Constants.colors.borderColor,
        },
        headerTintColor: Constants.colors.textColor,
        headerBackTitle: ' ',
      }}
      initialRouteName="Ideas"
    >
      <Stack.Screen name="Ideas" component={IdeasScreen} />
      <Stack.Screen name="EditIdea" component={AddEditIdeaScreen} options={{ title: ' ' }} />
      <Stack.Screen name="Idea" component={IdeaScreen} />
      <Stack.Screen name="AddEditMessage" component={AddEditMessage} options={{ title: ' ' }} />
      <Stack.Screen name="DeleteMessage" component={DeleteMessageScreen} options={{ title: ' ' }} />
      <Stack.Screen name="DeleteIdea" component={DeleteIdeaScreen} />
      <Stack.Screen name="Chat" component={ChatScreen} options={{ title: 'Ideas Chat' }} />
    </Stack.Navigator>
  );
};

const ChartsNativeNav = function () {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerStyle: {
          backgroundColor: Constants.colors.backgroundPrimary,
          shadowColor: Constants.colors.borderColor,
        },
        headerTintColor: Constants.colors.textColor,
        headerBackTitle: ' ',
      }}
      initialRouteName="Charts"
    >
      <Stack.Screen options={{ title: 'Strategies' }} name="Charts" component={ChartsScreen}
                    initialParams={{ symbol: 'BTC-USD', type: 'swing' }} />
      <Stack.Screen name="Chat" component={ChatScreen} options={{ title: '' }} />
      <Stack.Screen name="AddEditMessage" component={AddEditMessage} options={{ title: ' ' }} />
      <Stack.Screen name="DeleteMessage" component={DeleteMessageScreen} options={{ title: ' ' }} />
    </Stack.Navigator>
  );
};

const LearnNativeNav = function () {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerStyle: {
          backgroundColor: Constants.colors.backgroundPrimary,
          shadowColor: Constants.colors.borderColor,
        },
        headerTintColor: Constants.colors.textColor,
        headerBackTitle: ' ',
      }}
      initialRouteName="Learn"
    >
      <Stack.Screen name="Learn" component={LearnScreen} options={{ title: ' ' }} />
      <Stack.Screen name="EditLearnCategories" component={EditLearnCategoriesScreen}
                    options={{ title: 'Edit Learn Categories' }} />
      <Stack.Screen name="EditLearnCategoryVideos" component={EditLearnCategoryVideosScreen}
                    options={{ title: ' ' }} />
    </Stack.Navigator>
  );
};

const LiveNativeNav = function () {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerStyle: {
          backgroundColor: Constants.colors.backgroundPrimary,
          shadowColor: Constants.colors.borderColor,
        },
        headerTintColor: Constants.colors.textColor,
        headerBackTitle: ' ',
      }}
      initialRouteName="Live"
    >
      <Stack.Screen name="Live" component={LiveScreen} options={{ title: 'Live' }} />
      <Stack.Screen name="EditLive" component={EditLiveScreen} options={{ title: 'Edit Live' }} />
      <Stack.Screen name="LiveChannel" component={LiveChannelScreen} options={{ title: 'Live Channel' }} />
    </Stack.Navigator>
  );
};

const MoreNativeNav = function () {
  const Stack = createStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerBackTitle: ' ',
        headerStyle: {
          backgroundColor: Constants.colors.backgroundPrimary,
          shadowColor: Constants.colors.borderColor,
        },
        headerTintColor: Constants.colors.textColor,
      }}
      initialRouteName="More"
    >
      <Stack.Screen name="More" component={MoreScreen} options={{ title: 'More' }} />
      <Stack.Screen name="Account" component={AccountScreen} />
      <Stack.Screen name="Pay" component={PayScreen} />
      <Stack.Screen name="CardPay" component={CardPayScreen} />
      <Stack.Screen name="Users" component={UsersScreen} initialParams={{ page: 1 }} />
      <Stack.Screen name="Affiliates" component={AffiliatesScreen} initialParams={{ page: 1 }} />
      <Stack.Screen name="Payments" component={PaymentsScreen} initialParams={{ page: 1 }} />
      <Stack.Screen name="EditUser" component={UserEditScreen} />
      <Stack.Screen name="CreateUser" component={UserCreateScreen} />
      <Stack.Screen name="AffiliateDashboard" component={AffiliateDashboard} />
      <Stack.Screen name="WalletDashboard" component={WalletDashboard} />
      <Stack.Screen name="PartnerSettings" component={PartnerSettings} />
      <Stack.Screen name="AppWallet" component={AppWalletScreen} />
      <Stack.Screen name="Support" component={Support} />
    </Stack.Navigator>
  );
};

const NativeNav = observer(function () {
  const linkTo = useLinkTo();
  const redirect = appState.state.redirect;
  useEffect(() => {
    if (redirect) {
      const r = redirect;
      runInAction(() => {
        appState.state.redirect = null;
      });
      linkTo(r);
    }
  }, [linkTo, redirect]);

  const Tab = createBottomTabNavigator();
  const Stack = createNativeStackNavigator();

  return appState.authService.loggedIn ? (
    <Tab.Navigator
      screenOptions={{
        tabBarActiveTintColor: Constants.colors.textColor,
        tabBarInactiveTintColor: '#888',
        tabBarStyle: {
          backgroundColor: Constants.colors.backgroundPrimary,
          borderTopColor: Constants.colors.borderColor,
        },
      }}
    >
      <Tab.Screen
        name="Ideas"
        component={IdeasNativeNav}
        options={{
          headerShown: false,
          title: 'Ideas',
          tabBarLabel: 'Ideas',
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="bulb-outline" color={color} size={size} />
          ),
        }}
      />

      {/*<Tab.Screen*/}
      {/*  name="Charts"*/}
      {/*  component={ChartsNativeNav}*/}
      {/*  options={{*/}
      {/*    headerShown: false,*/}
      {/*    title: 'Strategies',*/}
      {/*    tabBarLabel: 'Strategies',*/}
      {/*    tabBarIcon: ({ color, size }) => (*/}
      {/*      <Ionicons name="analytics-outline" color={color} size={size} />*/}
      {/*    ),*/}
      {/*  }}*/}
      {/*/>*/}

      <Tab.Screen
        name="Learn"
        component={LearnNativeNav}
        options={{
          headerShown: false,
          title: 'Learn',
          tabBarLabel: 'Learn',
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="library-outline" color={color} size={size} />
          ),
        }}
      />

      <Tab.Screen
        name="Live"
        component={LiveNativeNav}
        options={{
          headerShown: false,
          title: 'Live',
          tabBarLabel: 'Live',
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="videocam-outline" color={color} size={size} />
          ),
        }}
      />

      <Tab.Screen
        name="More"
        component={MoreNativeNav}
        options={{
          headerShown: false,
          title: 'More',
          tabBarLabel: 'More',
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="menu-outline" color={color} size={size} />
          ),
        }}
      />
    </Tab.Navigator>
  ) : (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Login" component={LoginScreen} />
      <Stack.Screen name="ForgotPass" component={ForgotPassScreen} />
      <Stack.Screen name="Register" component={Register} />
    </Stack.Navigator>
  );
});

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: Constants.colors.backgroundPrimary,
  },
};

export default observer(function App() {
  // LogBox.ignoreAllLogs();
  if (appState.authService.loading) {
    return <View style={{
      flex: 1,
      backgroundColor: Constants.colors.backgroundPrimary
    }}><SplashScreen /></View>;
  }


  const l = {
    ...linking,
    config: appState.authService.loggedIn ? authLinkConfig : guestLinkConfig
  };

  return (Platform.OS === 'web') ? (
    <NavigationContainer linking={l} theme={MyTheme}>
      <style>{`body { overflow-x: hidden }`}</style>
      <WebNav />
    </NavigationContainer>
  ) : (
    <NavigationContainer linking={l} theme={MyTheme}>
      <StatusBar backgroundColor='black' barStyle='light-content' />
      <NativeNav />
    </NavigationContainer>
  );
});
