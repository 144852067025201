import React, { Component } from "react";
import { Image } from "react-native";

export default class ScaledImage extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentWillMount() {
    Image.getSize(this.props.source.uri, (width, height) => {
      const aspectRatio = width / height;
      this.setState({
        aspectRatio,
      });
    });
  }

  render() {
    const { style, ...otherProps } = this.props;
    return (
      <Image
        style={[style, { aspectRatio: this.state && this.state.aspectRatio }]}
        {...otherProps}
      />
    );
  }
}
