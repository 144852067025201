import * as React from 'react';
import { useHover } from 'react-native-web-hooks';
import HoverTouchableOpacity from "./HoverTouchableOpacity";
import { useEffect, useState } from "react";
import { View, Text, StyleSheet, useWindowDimensions } from "react-native";
import Constants from "../../constants";
import Responsive from "../utilities/responsive";
import { Link } from "@react-navigation/native";

export default function DropdownMenu({ style, topOffset, align, renderButton, menuItems, onOpen }) {
  const ref = React.useRef(null);
  const [open, setOpen] = useState(false);
  const hovered = useHover(ref);
  const window = useWindowDimensions();
  const ww = window.width;

  useEffect(() => {
    setOpen(hovered);
  }, [hovered]);

  useEffect(() => {
    if (open && typeof onOpen === 'function') {
      onOpen();
    }
  }, [open]);

  return (
    <View ref={ref} style={style}>
      <HoverTouchableOpacity onPress={() => setOpen(true)}>
        {renderButton()}
      </HoverTouchableOpacity>
      {open && (
        <View style={[r.get('dropdownMenu', ww), { paddingTop: topOffset, ...(align === 'right' ? { right: 0 } : { left: 0 }) }]}>
          <View style={r.get('dropdownMenuInner', ww)}>
            {menuItems.map((m) => (
              <Link to={m.to}>
                <HoverTouchableOpacity containerStyle={{ flex: 1, width: '100%' }} style={r.get('menuItem', ww)}>
                  <Text>{m.name}</Text>
                </HoverTouchableOpacity>
              </Link>
            ))}
          </View>
        </View>
      )}
    </View>
  );
}

const baseStyles = StyleSheet.create({
  dropdownMenu: {
    position: 'absolute',
    paddingTop: 40,
    left: 0,
    zIndex: 100,
  },
  dropdownMenuInner: {
    backgroundColor: '#ddd',
    borderRadius: 8,
    overflow: 'hidden',
    minWidth: 200,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 7,

    elevation: 3,
  },
  menuItem: {
    padding: 20,
    borderBottomWidth: 1,
    borderColor: '#ddd',
    backgroundColor: '#fff',
    flex: 1,
    width: '100%',
    fontWeight: '500',
  },
});

const stylesSM = StyleSheet.create({

});

const stylesMD = StyleSheet.create({

});

const stylesLG = StyleSheet.create({

});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
