import React, { useEffect } from "react";
import { View, ActivityIndicator, Text } from "react-native";
import DashboardLayout from "../layouts/DashboardLayout";
import { useLinkTo } from "@react-navigation/native";
import Constants from "../../constants";

export default function NotFoundScreen({ navigation, route }) {

  useEffect(() => {
    if (route && route.path) {
      navigation.navigate('Login', { redirect: route.path });
    }
  }, [route]);

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Text>{route && route.path}</Text>
      <ActivityIndicator color={Constants.colors.textColor} />
    </View>
  );
}
