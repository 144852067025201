import { Platform } from "react-native";

export default {
  breakpoints: [600, 1000, 9999999],
  breakpointAbbr: ['SM', 'MD', 'LG'],
  fontFamily: Platform.select({
    web: 'Avenir, Montseratt, Trebuchet MS, sans-serif',
    ios: 'Helvetica Neue',
  }),
  colors: {
    accent: '#9f925b',
    dangerColor: '#d22424',

    backgroundPrimary: '#000000',
    backgroundSecondary: '#1e2025',
    backgroundThird: '#282b31',
    borderColor: '#32353d',
    textColor: '#ffffff',

    // backgroundPrimary: '#ffffff',
    // backgroundSecondary: '#ffffff',
    // backgroundThird: '#eeeeee',
    // borderColor: '#dddddd',
    // textColor: '#000000',
  },
}
