import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ScrollView,
  FlatList,
  Image, ActivityIndicator, Animated, TextInput, Platform, RefreshControl,
} from 'react-native';
import { Link, useFocusEffect } from '@react-navigation/native';
import Swiper from 'react-native-swiper/src'
import Lightbox from 'react-native-lightbox';
import AwesomeAlert from 'react-native-awesome-alerts';
import appState from '../../app.state';

import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import Ionicons from '@expo/vector-icons/Ionicons';
import ideaImg from '../../assets/1661195595897-image.jpg';
import authorImg from '../../assets/1623578250256-Picasso-150-150.jpg';
import SplashScreen from "./Splash";
import { fmtDT } from "../utilities/fmtDate";
import fmtAttachment from "../utilities/fmtAttachment";
import { observer } from "mobx-react-lite";
import { observable, runInAction } from "mobx";
import BottomSheet, { BottomSheetScrollView } from "@gorhom/bottom-sheet";
import ChatImgSlider from "../components/ChatImgSlider";
import Chat from "../components/Chat";

export default function IdeasScreen({ navigation, route }) {
  const window = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [ideaRefreshing, setIdeaRefreshing] = useState(false);
  const [e, setError] = useState(false);
  const [ideas, setIdeas] = useState(false);
  const [ideasPagination, setIdeasPagination] = useState(false);
  const [u, setUser] = useState(false);
  const bottomSheetRef = useRef(null);
  const snapPoints = useMemo(() => ["80%"], []);

  const loadIdeas = async () => {
    const res = await appState.appApi.getIdeas({ limit: 12 });
    setIdeasPagination(res);
    setIdeas(res.data);
    console.log({ ideas: res.data });
  };

  const refreshIdeas = () => {
    (async () => {
      try {
        setError(false);
        setIdeaRefreshing(true);
        await loadIdeas();
      } catch (e) {
        setError(e);
      } finally {
        setIdeaRefreshing(false);
      }
    })();
  };

  useFocusEffect(useCallback(() => {
    console.log('focused');
    refreshIdeas();
  }, [navigation]));

  useEffect(() => {
    console.log('focused');
    (async () => {
      try {
        setLoading(true);
        setUser(await appState.authService.whoami());
        const res = await appState.appApi.getIdeas({ limit: 12 });
        setIdeasPagination(res);
        setIdeas(res.data);
        console.log({ ideas: res.data });

        if (Platform.OS !== 'web') {
          if (!(await appState.pushNotificationService.hasAsked())) {
            console.log('push notifications asking');

            setTimeout(() => {
              console.log('open bottom sheet', bottomSheetRef.current);
              bottomSheetRef.current.snapToIndex(0);
            }, 1000);
            await appState.pushNotificationService.setAsked(true);
          }
        }
      } catch (e) {
        setError(e);
        // console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  let numColumns = 4;
  if (window.width < 600) {
    numColumns = 1;
  } else if (window.width < 1350) {
    numColumns = 2;
  } else if (window.width < 1650) {
    numColumns = 3;
  }

  const itemStatusColor = (status) => {
    if (status === 'win') {
      return '#1cca67';
    } else if (status === 'loss') {
      return '#e02828';
    } else if (status === 'breakeven') {
      return '#999999';
    } else if (status === 'active') {
      return '#1cace5';
    }

    return '#999999';
  };

  const addNewIdea = () => {
    navigation.navigate('EditIdea', { id: 'new' });
  };

  const editIdea = (id) => {
    navigation.navigate('EditIdea', { id });
  };

  const deleteIdea = (id) => {
    navigation.navigate('DeleteIdea', { id });
  };

  if (loading) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  if (!u.membership_active) return <DashboardLayout><Text
    style={{ padding: 20, textAlign: 'center' }}>Membership inactive go to account and renew.</Text></DashboardLayout>

  navigation.setOptions({
    headerRight: () => u.user_access.includes('manage ideas') && (
      <HoverTouchableOpacity
        style={[r.get('circleBtn', window.width), r.get('nativeHeaderCircleBtn', window.width)]}
        onPress={() => navigation.navigate('EditIdea', { id: 'new' })}>
        <Ionicons name="ios-add-outline" size={28} color={Constants.colors.textColor} />
      </HoverTouchableOpacity>
    ),
    headerLeft: () => (
      <HoverTouchableOpacity
        style={[r.get('headerLeftBtn', window.width)]}
        onPress={() => navigation.navigate('Chat', { group: 'idea' })}
      >
        <Ionicons name="chatbubble-outline" size={28} color={Constants.colors.textColor} />
      </HoverTouchableOpacity>
    ),
  });

  return (
    <DashboardLayout>
      <View style={r.get('container', window.width)}>
        <View style={r.get('ideasWrap', window.width)}>
          <FlatList
            style={Platform.OS === 'web' && { height: 100 }}
            key={numColumns}
            contentContainerStyle={r.get('ideasListContent', window.width)}
            refreshControl={<RefreshControl refreshing={ideaRefreshing} onRefresh={refreshIdeas}
                                            tintColor={Constants.colors.textColor}
                                            titleColor={Constants.colors.textColor} />}
            ListHeaderComponent={Platform.OS === 'web' && (
              <View style={r.get('ideasHeader', window.width)}>
                <Text style={r.get('heading', window.width)}>Ideas</Text>

                {u.user_access.includes('manage ideas') && (
                  <HoverTouchableOpacity onPress={() => addNewIdea()}
                                         style={[r.get('btn', window.width)]}>
                    <Text style={r.get('btnText', window.width)}>Add New</Text>
                  </HoverTouchableOpacity>
                )}

                {window.width < 1000 && (
                  <View style={[r.get('headerChatBtnWrap', window.width)]}>
                    <HoverTouchableOpacity
                      style={[r.get('headerChatBtn', window.width)]}
                      onPress={() => navigation.navigate('Chat', { group: 'idea' })}
                    >
                      <Ionicons name="chatbubble-outline" size={28}
                                color={Constants.colors.textColor} />
                    </HoverTouchableOpacity>
                  </View>
                )}
              </View>
            )}
            numColumns={numColumns}
            data={ideas}
            renderItem={({ item, index, separators }) => (
              <View
                style={[r.get('ideaItemWrap', window.width), numColumns > 1 && { flex: 1 / numColumns }]}>
                <HoverTouchableOpacity activeOpacity={0.7} style={r.get('ideaItem', window.width)}
                                       onPress={() => navigation.navigate('Idea', { id: item.id })}>
                  <View style={r.get('ideaHeader', window.width)}>
                    <View style={{ flex: 1 }}>
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={r.get('ideaSymbol', window.width)}>{item.pair}</Text>
                        <Text
                          style={[r.get('ideaStatus', window.width), { color: itemStatusColor(item.status) }]}>{item.status}</Text>
                      </View>
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text
                          style={r.get('ideaHeadSubText', window.width)}>{item.order_type}</Text>
                        <Text style={r.get('textSep', window.width)}>•</Text>
                        <Text style={r.get('ideaHeadSubText', window.width)}>{item.timeframe}</Text>
                        <Text style={r.get('textSep', window.width)}>•</Text>
                        <Text style={r.get('ideaHeadSubText', window.width)}>{item.idea_type}</Text>
                      </View>
                    </View>
                    <View>
                      <Ionicons name="ios-chevron-forward-outline" size={24} color="#000" />
                    </View>
                  </View>
                  {item.images[0] && (<Image style={r.get('ideaImage', window.width)}
                                             source={{ uri: item.images[0] && fmtAttachment(item.images[0]).sizes.lg }}
                                             resizeMode={'contain'} />)}
                  <View style={r.get('ideaBody', window.width)}>
                    <View style={r.get('ideaBodyRow', window.width)}>
                      <View style={r.get('ideaBodyCol', window.width)}>
                        <Text style={r.get('ideaBodyLabel', window.width)}>Entry</Text>
                        <Text
                          style={r.get('ideaBodyValue', window.width)}>{item.entries.length > 1 ? `${item.entries[0]} - ${item.entries[1]}` : item.entries[0]}</Text>
                      </View>

                      <View style={r.get('ideaBodyCol', window.width)}>
                        <Text style={r.get('ideaBodyLabel', window.width)}>Invalidation</Text>
                        <Text
                          style={r.get('ideaBodyValue', window.width)}>{item.invalidation}</Text>
                      </View>
                    </View>

                    <View>
                      <Text style={r.get('ideaBodyLabel', window.width)}>Exits</Text>

                      <View style={r.get('ideaBodyExits', window.width)}>
                        {item.exits.length > 0 && item.exits.map((e, i) => (
                          <View style={r.get('ideaBodyExit', window.width)}>
                            <Text style={r.get('ideaBodyListNumber', window.width)}>{i + 1}</Text>
                            <Text style={r.get('ideaBodyValue', window.width)}>{e}</Text>
                          </View>
                        ))}
                      </View>
                    </View>

                  </View>
                  {(() => {
                    if (!item.author) return;
                    const a = item.author.profile_img && fmtAttachment(item.author.profile_img);
                    const authorImg = { uri: a && a.sizes && a.sizes.sm_crop };
                    return (
                      <View style={r.get('ideaFooter', window.width)}>
                        <Image style={r.get('ideaAuthorImg', window.width)} source={authorImg}
                               resizeMode={'cover'} />
                        <View style={r.get('ideaFooterRight', window.width)}>
                          <Text style={r.get('ideaAuthor', window.width)}>{item.author.name}</Text>
                          <Text
                            style={r.get('ideaDate', window.width)}>Posted: {fmtDT(item.created_at)}</Text>
                        </View>
                      </View>
                    );
                  })()}
                </HoverTouchableOpacity>
                {u.user_access.includes('manage ideas') && (
                  <>
                    <HoverTouchableOpacity onPress={() => editIdea(item.id)}
                                           containerStyle={r.get('editIdeaBtnWrap', window.width)}
                                           style={[r.get('editIdeaBtn', window.width)]}>
                      <Ionicons name="ios-create-outline" size={16} color="#fff" />
                    </HoverTouchableOpacity>

                    <HoverTouchableOpacity onPress={() => deleteIdea(item.id)}
                                           containerStyle={r.get('deleteIdeaBtnWrap', window.width)}
                                           style={[r.get('deleteIdeaBtn', window.width)]}>
                      <Ionicons name="ios-trash-outline" size={12} color="red" />
                      <Text style={[r.get('deleteIdeaBtnText', window.width)]}>Delete</Text>
                    </HoverTouchableOpacity>
                  </>
                )}
              </View>
            )}
          />
        </View>
        {Platform.OS === 'web' && (
          <View testID='chatwrap' style={r.get('chatWrap', window.width)}>
            <Chat
              group="idea"
              user={u}
              navigation={navigation}
              route={route}
              loadMessages={async () => appState.appApi.getBroadcastMessages({ group: 'idea', limit: 10 })}
            />
          </View>
        )}
      </View>

      {Platform.OS !== 'web' && (
        <BottomSheet
          ref={bottomSheetRef}
          snapPoints={snapPoints}
          detach={true}
          enablePanDownToClose={true}
          index={-1}
          style={[r.get('sheetContainer', window.width)]}
        >
          <BottomSheetScrollView style={{ minHeight: '100%' }}
                                 contentContainerStyle={{ minHeight: '100%' }}>
            <View style={[r.get('sheetBody', window.width)]}>
              <Text style={[r.get('sheetHeading', window.width)]}>Want to get alerts?</Text>
              <Text style={[r.get('sheetDescription', window.width)]}>Be the first to know what is
                happening in the markets.</Text>
              <HoverTouchableOpacity
                style={r.get('btnSm', window.width)}
                onPress={() => {
                  bottomSheetRef.current.close();
                  appState.pushNotificationService.registerForPushNotifications();
                }}
              >
                <Text style={r.get('btnSmText', window.width)}>Enable Notifications</Text>
              </HoverTouchableOpacity>
            </View>
          </BottomSheetScrollView>
        </BottomSheet>
      )}
    </DashboardLayout>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundSecondary,
    flexDirection: 'row',
  },
  ideasWrap: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundPrimary,
  },
  chatWrap: {
    width: 400,
    borderLeftWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  scrollViewContainer: {
    padding: 30,
  },
  ideasHeader: {
    paddingLeft: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    color: Constants.colors.textColor,
  },
  heading2: {
    fontSize: 18,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    color: Constants.colors.textColor,
  },
  btn: {
    backgroundColor: Constants.colors.backgroundPrimary,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: Constants.colors.accent,
  },
  btnText: {
    color: Constants.colors.accent,
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  ideasItems: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  ideaItemWrap: {
    flex: 1,
  },
  ideaItem: {
    margin: 10,
    flex: 1,
    backgroundColor: Constants.colors.backgroundSecondary,
    borderRadius: 7,
    // aspectRatio: 6/8,
    overflow: 'hidden',

    // shadowColor: '#000',
    // shadowOffset: { width: 0, height: 1 },
    // shadowOpacity: 0.15,
    // shadowRadius: 7,
    //
    // elevation: 3,
    cursor: 'pointer',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  ideasListContent: {
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  editIdeaBtnWrap: {
    position: 'absolute',
    zIndex: 10,
    right: 50,
    top: 28,
  },
  editIdeaBtn: {
    backgroundColor: Constants.colors.backgroundPrimary,
    height: 32,
    width: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 25,
    cursor: 'pointer',
  },
  ideaHeader: {
    height: 70,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    paddingLeft: 20,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  ideaImage: {
    backgroundColor: '#000',
    overflow: 'hidden',
    aspectRatio: 2 / 1,
  },
  ideaBody: {
    flex: 1,
    padding: 20,
    paddingVertical: 15,

  },
  ideaFooter: {
    height: 48,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  ideaSymbol: {
    fontWeight: '600',
    fontSize: 17,
    marginRight: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  ideaStatus: {
    fontSize: 12,
    fontWeight: '600',
    textTransform: 'uppercase',
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  ideaHeadSubText: {
    fontSize: 14,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    textTransform: 'capitalize',
    color: Constants.colors.textColor,
  },
  textSep: {
    paddingHorizontal: 5,
    fontSize: 14,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  ideaFooterRight: {
    paddingLeft: 10,
  },
  ideaAuthorImg: {
    width: 30,
    height: 30,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  ideaAuthor: {
    fontSize: 14,
    fontWeight: '600',
    fontFamily: Constants.fontFamily,
    lineHeight: 15,
    color: Constants.colors.textColor,
  },
  ideaDate: {
    fontSize: 10,
    fontFamily: Constants.fontFamily,
    lineHeight: 12,
    fontWeight: '500',
    opacity: 0.7,
    color: Constants.colors.textColor,
  },
  ideaBodyRow: {
    flexDirection: 'row',
  },
  ideaBodyCol: {
    flex: 1,
    marginBottom: 7,
  },
  ideaBodyLabel: {
    textTransform: 'uppercase',
    opacity: 0.8,
    fontSize: 11,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  ideaBodyValue: {
    fontSize: 14,
    fontFamily: Constants.fontFamily,
    fontWeight: '500',
    color: Constants.colors.textColor,
  },
  ideaBodyListNumber: {
    backgroundColor: '#eee',
    borderWidth: 1,
    borderColor: '#ddd',
    color: '#000',
    fontSize: 12,
    fontWeight: '600',
    fontFamily: Constants.fontFamily,
    marginRight: 8,
    height: 17,
    width: 17,
    borderRadius: 8,
    textAlign: 'center',
    overflow: 'hidden',
    ...(Platform.select({
      web: {
        lineHeight: 17,
      },
      ios: {
        lineHeight: 14,
      },
      android: {
        lineHeight: 14,
      },
    }))
  },
  ideaBodyExits: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  ideaBodyExit: {
    flexDirection: 'row',
    marginRight: 20,
    marginTop: 5,
    alignItems: 'center',
  },


  chatMessage: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginBottom: 25,
    borderRadius: 7,
    backgroundColor: Constants.colors.backgroundThird,

    overflow: 'hidden',

    // shadowColor: '#000',
    // shadowOffset: { width: 0, height: 1 },
    // shadowOpacity: 0.1,
    // shadowRadius: 2,
    //
    // elevation: 3,
  },
  chatMessageHead: {
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  chatListContent: {
    paddingHorizontal: 30,
    paddingVertical: 30,
  },
  chatMessageAuthor: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  chatAuthorImg: {
    width: 30,
    height: 30,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginRight: 10,
  },
  chatAuthorName: {
    fontWeight: '600',
    fontFamily: Constants.fontFamily,
    fontSize: 14,
    color: Constants.colors.textColor,
  },
  chatMessageDate: {
    fontFamily: Constants.fontFamily,
    fontWeight: '400',
    fontSize: 11,
    opacity: 0.7,
    color: Constants.colors.textColor,
  },
  chatMessageBody: {
    padding: 15,
  },
  chatMessageBodyText: {
    fontFamily: Constants.fontFamily,
    fontWeight: '400',
    fontSize: 13,
    opacity: 0.7,
    color: Constants.colors.textColor,
  },

  chatMessageImg: {
    // flex: 1,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  chatMessageSlide: {
    flex: 1,

  },
  chatMessageSlideImg: {
    backgroundColor: '#000',
  },
  chatMessageSlider: {},
  chatSliderArrow: {
    textShadowColor: '#00000078',
    textShadowRadius: 3,
    textShadowOffset: {
      width: 0,            // These can't both be 0
      height: 0,           // i.e. the shadow has to be offset in some way
    },
  },
  chatHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  circleBtn: {
    backgroundColor: '#000',
    cursor: 'pointer',
    borderRadius: 50,
    height: 32,
    width: 32,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    marginLeft: -10,
  },
  addMessageWrap: {
    padding: 20,
    backgroundColor: Constants.colors.textColor,
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderRadius: 8,
    marginBottom: 20,
  },
  linkWrap: {
    cursor: 'pointer',
    paddingVertical: 4,
    paddingHorizontal: 10,
  },
  linkText: {
    color: Constants.colors.textColor,
    textDecorationLine: 'underline',
  },
  deleteIdeaBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
  },
  deleteIdeaBtnText: {
    color: 'red',
    fontSize: 12,
  },
  nativeHeaderCircleBtn: {
    marginRight: 10,
    backgroundColor: 'transparent',
  },
  headerLeftBtn: {
    marginLeft: 15,
  },
  headerChatBtnWrap: {
    flex: 1,
    alignItems: 'flex-end',
  },
});

const stylesSM = StyleSheet.create({
  chatWrap: {
    height: 0,
    width: 0,
  },
  ideasListContent: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  nativeHeaderCircleBtn: {
    marginTop: 5,
  },
  ideaBodyExit: {
    width: '43%'
  },
  ideaAuthorImg: {
    width: 38,
    height: 38,
  },
  ideaFooter: {
    height: 52,
    // backgroundColor: Constants.colors.textColor,
  },
  ideaItem: {
    // borderWidth: 1,
    // borderColor: '#ddd',
    margin: 0,
    marginBottom: 10,
    borderRadius: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  ideasHeader: {
    marginVertical: 20,
    marginHorizontal: 10,
  },
  sheetBody: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  btnSm: {
    backgroundColor: Constants.colors.backgroundPrimary,
    padding: 20,
    paddingHorizontal: 25,
    borderRadius: 8,
    marginVertical: 10,
  },
  btnSmText: {
    fontWeight: '500',
    color: Constants.colors.textColor,
    fontSize: 18,
  },
  sheetDescription: {
    maxWidth: 225,
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 17,
  },
  sheetHeading: {
    fontSize: 25,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginBottom: 10,
  },
});

const stylesMD = StyleSheet.create({
  chatWrap: {
    height: 0,
    width: 0,
  },
  ideasListContent: {
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
