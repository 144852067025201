import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator, ScrollView, TextInput, TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import appState from "../../app.state";
import { useCallback, useEffect, useState } from "react";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import PaginatedList from "../components/PaginatedList";
import { fmtD, fmtDT } from "../utilities/fmtDate";
import { DateTime } from "luxon";
import { Link, useFocusEffect } from "@react-navigation/native";
import DropDownPicker from "react-native-dropdown-picker";
import DatePicker from "../components/DatePicker";
import UserSelect from "../components/UserSelect";
import SplashScreen from "./Splash";

export default function SupportScreen({ navigation }) {
  const window = useWindowDimensions();
  const ww = window.width;

  const [sendingMsg, setSendingMsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgErr, setMsgErr] = useState(false);
  const [sendMsgSuccess, setSendMsgSuccess] = useState(false);

  const sendMsg = async () => {
    try {
      setMsgErr(null);
      setSendingMsg(true);
      await appState.appApi.contactSupport({ message: msg });
      setSendMsgSuccess(true);
      setMsg('');
      setTimeout(() => setSendMsgSuccess(false), 2000);
    } catch (e) {
      console.error(e);
      setMsgErr(e);
    } finally {
      setSendingMsg(false);
    }
  };

  return (
    <DashboardLayout>
      <ScrollView>
        <View>
          <View style={r.get('container', ww)}>
            <View style={r.get('body', ww)}>
              {Platform.OS === 'web' && (
                <Text style={r.get('heading', ww)}>Contact Support</Text>
              )}

              <View>
                {sendMsgSuccess && (
                  <Text style={[r.get('successMsg', ww)]}>Message Sent!</Text>
                )}

                <TextInput
                  style={[r.get('input', ww), { height: 100 }]}
                  onChangeText={setMsg}
                  value={msg}
                  placeholder={'Enter message...'}
                  multiline={true}
                  numberOfLines={4}
                  placeholderTextColor={'#aaa'}
                />

                {msgErr && (
                  <Text style={[r.get('error', ww)]}>{msgErr.message}</Text>
                )}

                <View style={{ alignItems: 'flex-start' }}>
                  <HoverTouchableOpacity onPress={() => sendMsg()} style={[r.get('btn', ww), r.get('btnLeft', ww)]}>
                    {sendingMsg && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
                    <Text style={r.get('btnTextSm', ww)}>Send Message</Text>
                  </HoverTouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </DashboardLayout>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  body: {
    padding: 40,
    backgroundColor: Constants.colors.backgroundSecondary,
    maxWidth: 900,
    width: '100%',
    margin: 20,
    borderRadius: 8,

    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 7,

    elevation: 3,

    overflow: 'hidden',

    minHeight: 500,
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  label: {
    fontSize: 15,
    marginBottom: 5,
    color: Constants.colors.textColor,
    fontWeight: '600',
  },
  formGroup: {
    marginBottom: 30,
  },
  successMsg: {
    color: '#000',
    backgroundColor: '#d0ffe3',
    borderWidth: 1,
    borderColor: '#33a963',
    padding: 15,
    borderRadius: 5,
    marginBottom: 20,
  },
  btn: {
    backgroundColor: Constants.colors.accent,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    // minWidth: 400,
    // maxWidth: '100%',
  },
  btnText: {
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.backgroundSecondary,
  },
  btnSm: {
    backgroundColor: Constants.colors.accent,
    borderWidth: 1,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnTextSm: {
    color: Constants.colors.backgroundPrimary,
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  btnLeft: {
    alignSelf: 'flex-start',
  },
  partners: {
    marginBottom: 20,
  },
  partner: {
    padding: 12,
    paddingTop: 0,
    borderColor: Constants.colors.borderColor,
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 20,
    paddingRight: 40,
  },
  iconCloseWrap: {
    alignSelf: 'flex-start',
  },
  iconCloseWrapRight: {
    position: 'absolute',
    top: '50%',
    marginTop: -15,
    // transform: [{ translateY: '-50%' }],
    right: 15,
  },
  errors: {
    marginBottom: 0,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
});

const stylesSM = StyleSheet.create({
  body: {
    padding: 20,
    margin: 0,
    borderRadius: 0,
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
