import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  TextInput,
  ActivityIndicator,
  ScrollView,
  Image,
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import DropDownPicker from 'react-native-dropdown-picker';
DropDownPicker.setTheme("DARK");
import DraggableFlatList, {
  ScaleDecorator,
  RenderItemParams,
} from "react-native-draggable-flatlist";
import * as ImagePicker from 'expo-image-picker';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import { useCallback, useEffect, useState } from "react";
import SplashScreen from "./Splash";
import appState from '../../app.state';
import { StackActions, useFocusEffect } from '@react-navigation/native';
import fmtAttachment from "../utilities/fmtAttachment";

export default function AddEditIdeaScreen({ navigation, route }) {
  const window = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [e, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [pair, setPair] = useState('');
  const [description, setDescription] = useState('');
  const [entry1, setEntry1] = useState('');
  const [entry2, setEntry2] = useState('');
  const [invalidation, setInvalidation] = useState('');
  const [exits, setExits] = useState(['', '', '']);
  const [images, setImages] = useState([]);
  const [entryIsRange, setEntryIsRange] = useState(false);
  const [timeframeOpen, setTimeframeOpen] = useState(false);
  const [timeframe, setTimeframe] = useState('1h');
  const [timeframeOptions, setTimeframeOptions] = useState([
    { label: '1m', value: '1m' },
    { label: '5m', value: '5m' },
    { label: '15m', value: '15m' },
    { label: '30m', value: '30m' },
    { label: '1h', value: '1h' },
    { label: '4h', value: '4h' },
    { label: '6h', value: '6h' },
    { label: '8h', value: '8h' },
    { label: '12h', value: '12h' },
    { label: '1D', value: '1D' },
    { label: '3D', value: '3D' },
    { label: '1W', value: '1W' },
    { label: '1M', value: '1M' },
  ]);
  const [orderTypeOpen, setOrderTypeOpen] = useState(false);
  const [orderType, setOrderType] = useState('buy');
  const [orderTypeOptions, setOrderTypeOptions] = useState([
    { label: 'Buy', value: 'buy' },
    { label: 'Sell', value: 'sell' },
  ]);
  const [ideaTypeOpen, setIdeaTypeOpen] = useState(false);
  const [ideaType, setIdeaType] = useState('swing');
  const [ideaTypeOptions, setIdeaTypeOptions] = useState([
    { label: 'Swing', value: 'swing' },
    { label: 'Scalp', value: 'scalp' },
  ]);
  const [statusOpen, setStatusOpen] = useState(false);
  const [status, setStatus] = useState('active');
  const [statusOptions, setStatusOptions] = useState([
    { label: 'Active', value: 'active' },
    { label: 'Pending', value: 'pending' },
    { label: 'Win', value: 'win' },
    { label: 'Loss', value: 'loss' },
    { label: 'Breakeven', value: 'breakeven' },
  ]);

  const dropdownsSetOpen = [setTimeframeOpen, setOrderTypeOpen, setIdeaTypeOpen, setStatusOpen];

  let { id } = route.params;
  const isNew = id === 'new';
  if (isNew) id = null;

  useFocusEffect(useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        if (isNew) return;
        const idea = await appState.appApi.getIdea(id);
        setPair(idea.pair);
        setStatus(idea.status);
        setDescription(idea.description);
        setEntry1(idea.entries[0]);
        setEntry2(idea.entries[1]);
        setEntryIsRange(idea.entries.length >= 2);
        setInvalidation(idea.invalidation);
        setExits(idea.exits);
        setTimeframe(idea.timeframe);
        setOrderType(idea.order_type);
        setIdeaType(idea.idea_type);
        setImages(idea.images.map((i) => {
          const a = fmtAttachment(i);
          return {
            id: i.id,
            key: i.id,
            ...a,
            isNew: false,
            source: { uri: a && a.sizes && a.sizes.lg },
          };
        }));
      } catch (e) {
        setError(e);
        console.log('error');
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation, id]));

  const fieldError = (f) => (e && e.errors && e.errors[f]) || [];

  const closeOtherDropdowns = (f) => {
    dropdownsSetOpen.map((f2) => {
      if (f !== f2) f2(false);
    });
  };

  const setExit = (value, index) => {
    const exitsDup = [...exits];
    exitsDup[index] = txtNumeric(value);
    setExits(exitsDup);
  };

  const removeExit = (index) => {
    const exitsDup = [...exits];
    exitsDup.splice(index, 1);
    setExits(exitsDup);
  };

  const addExit = (index) => {
    const exitsDup = [...exits];
    exitsDup.splice(index + 1, 0, '');
    setExits(exitsDup);
  };

  const addImages = () => {
    (async () => {
      try {
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          aspect: [16, 9],
          quality: 1,
          allowsMultipleSelection: true,
        });

        console.log(result);

        if (result.cancelled) return;

        const newImages = result.selected.map((r) => ({
          result: r,
          isNew: true,
          key: (Math.random() + 1).toString(36).substring(7),
          source: { uri: r.uri },
        }));

        setImages([...images, ...newImages]);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const save = () => {
    if (submitting) return;
    console.log('save');
    (async () => {
      try {
        setError(false);
        setSubmitting(true);
        // upload attachments
        const imageIds = await Promise.all(images.map(async (i) => {
          const dup = { ...i };
          if (dup.isNew) {
            dup.id = await appState.appApi.uploadAttachment(i.result.uri);
          }

          return dup.id;
        }));

        const data = {
          pair,
          timeframe,
          order_type: orderType,
          idea_type: ideaType,
          description,
          invalidation,
          entries: [entry1, entry2].filter(e => e),
          exits: exits.filter(e => e),
          images: imageIds,
          status,
        };

        let ideaId = id;

        if (isNew) {
          ideaId = await appState.appApi.createIdea(data);

          // navigation.dispatch(
          //   StackActions.replace('EditIdea', {
          //     id: ideaId,
          //   })
          // );
        } else {
          await appState.appApi.updateIdea(ideaId, data);
        }

        navigation.goBack();
      } catch (e) {
        console.error(e);
        setError(e);
      } finally {
        setSubmitting(false);
      }
    })();
  };

  const deleteIdea = () => {
    if (deleting) return;
    console.log('delete');
    (async () => {
      try {
        setDeleting(true);
        await appState.appApi.deleteIdea(id);
        navigation.goBack();
      } catch (e) {
        console.error(e);
      } finally {
        setDeleting(false);
      }
    })();
  };

  console.log({ id, images });

  if (loading) return <SplashScreen />;

  navigation.setOptions({ title: isNew ? 'Add Idea' : `Edit Idea (ID: ${id})` });

  return (
    <TouchableWithoutFeedback onPress={() => Platform.OS === 'web' && navigation.goBack()}>
      <ScrollView style={{ height: window.height, backgroundColor: Constants.colors.backgroundSecondary }}>
        <View style={r.get('container', window.width)}>
          <TouchableWithoutFeedback>
            <View style={r.get('containerInner', window.width)}
                  onStartShouldSetResponder={() => true}>
              {Platform.OS === 'web' && (
                <View style={r.get('head', window.width)}>
                  <Text style={[r.get('title', window.width)]}>
                    {isNew ? 'Add Idea' : `Edit Idea (ID: ${id})`}
                  </Text>

                  <HoverTouchableOpacity onPress={() => navigation.goBack()}
                                         containerStyle={[r.get('closeWrap', window.width)]}
                                         style={[r.get('close', window.width)]}>
                    <Ionicons name="ios-close" size={22} style={r.get('closeIcon', window.width)}
                              accessibilityLabel={"Close"} />
                  </HoverTouchableOpacity>
                </View>
              )}

              <View style={r.get('body', window.width)}>

                <View style={[r.get('row', window.width), { zIndex: 2 }]}>
                  <View style={[r.get('formGroup', window.width), { zIndex: 4 }]}>
                    <Text style={[r.get('label', window.width)]}>Pair</Text>
                    <Errors errors={fieldError('pair')} />
                    <TextInput
                      style={r.get('input', window.width)}
                      onChangeText={setPair}
                      value={pair}
                      placeholder={'BTC/USD'}
                      placeholderTextColor={'#aaa'}
                    />
                  </View>

                  <View style={[r.get('formGroup', window.width), { zIndex: 3 }]}>
                    <Text style={[r.get('label', window.width)]}>Timeframe</Text>
                    <Errors errors={fieldError('timeframe')} />
                    <DropDownPicker
                      style={r.get('dropdown', window.width)}
                      placeholder="Choose"
                      placeholderStyle={{ color: Constants.colors.textColor, opacity: 0.4 }}
                      labelStyle={{ color: Constants.colors.textColor }}
                      open={timeframeOpen}
                      value={timeframe}
                      items={timeframeOptions}
                      setOpen={setTimeframeOpen}
                      setValue={setTimeframe}
                      setItems={setTimeframeOptions}
                      onOpen={() => closeOtherDropdowns(setTimeframeOpen)}
                    />
                  </View>

                  <View style={[r.get('formGroup', window.width), { zIndex: 2 }]}>
                    <Text style={[r.get('label', window.width)]}>Order Type</Text>
                    <Errors errors={fieldError('order_type')} />
                    <DropDownPicker
                      style={r.get('dropdown', window.width)}
                      placeholder="Choose"
                      placeholderStyle={{ color: Constants.colors.textColor, opacity: 0.4 }}
                      labelStyle={{ color: Constants.colors.textColor }}
                      open={orderTypeOpen}
                      value={orderType}
                      items={orderTypeOptions}
                      setOpen={setOrderTypeOpen}
                      setValue={setOrderType}
                      setItems={setOrderTypeOptions}
                      onOpen={() => closeOtherDropdowns(setOrderTypeOpen)}
                    />
                  </View>

                  <View style={[r.get('formGroup', window.width), { zIndex: 1 }]}>
                    <Text style={[r.get('label', window.width)]}>Idea Type</Text>
                    <Errors errors={fieldError('idea_type')} />
                    <DropDownPicker
                      style={r.get('dropdown', window.width)}
                      dropDownContainerStyle={{ zIndex: 10000 }}
                      placeholder="Choose"
                      placeholderStyle={{ color: Constants.colors.textColor, opacity: 0.4 }}
                      labelStyle={{ color: Constants.colors.textColor }}
                      open={ideaTypeOpen}
                      value={ideaType}
                      items={ideaTypeOptions}
                      setOpen={setIdeaTypeOpen}
                      setValue={setIdeaType}
                      setItems={setIdeaTypeOptions}
                      onOpen={() => closeOtherDropdowns(setIdeaTypeOpen)}
                    />
                  </View>

                  <View style={[r.get('formGroup', window.width), { zIndex: 1 }]}>
                    <Text style={[r.get('label', window.width)]}>Status</Text>
                    <Errors errors={fieldError('status')} />
                    <DropDownPicker
                      style={r.get('dropdown', window.width)}
                      dropDownContainerStyle={{ zIndex: 10000 }}
                      placeholder="Choose"
                      placeholderStyle={{ color: Constants.colors.textColor, opacity: 0.4 }}
                      labelStyle={{ color: Constants.colors.textColor }}
                      open={statusOpen}
                      value={status}
                      items={statusOptions}
                      setOpen={setStatusOpen}
                      setValue={setStatus}
                      setItems={setStatusOptions}
                      onOpen={() => closeOtherDropdowns(setStatusOpen)}
                    />
                  </View>
                </View>

                <View style={[r.get('row', window.width)]}>
                  <View style={r.get('formGroup', window.width)}>
                    <Text style={[r.get('label', window.width)]}>Entry</Text>
                    <Errors errors={fieldError('entries')} />
                    <Errors errors={fieldError('entries.0')} />
                    <Errors errors={fieldError('entries.1')} />

                    <View style={r.get('rangeGroup', window.width)}>
                      <View>
                        <TextInput
                          style={r.get('input', window.width)}
                          onChangeText={setFnNumOnly(setEntry1)}
                          value={entry1}
                          placeholder={'0.00'}
                          placeholderTextColor={'#aaa'}
                        />
                        {!entryIsRange && (
                          <HoverTouchableOpacity
                            containerStyle={r.get('inputIconWrap', window.width)}
                            style={r.get('inputIcon', window.width)}
                            onPress={() => setEntryIsRange(true)}
                          >
                            <Ionicons name="ios-add-outline" size={22} color={Constants.colors.textColor} />
                          </HoverTouchableOpacity>
                        )}
                      </View>

                      {entryIsRange && (
                        <>
                          <Text style={r.get('rangeSep', window.width)}>—</Text>

                          <View>
                            <TextInput
                              style={r.get('input', window.width)}
                              onChangeText={setFnNumOnly(setEntry2)}
                              value={entry2}
                              placeholder={'0.00'}
                              placeholderTextColor={'#aaa'}
                            />

                            <HoverTouchableOpacity
                              containerStyle={r.get('inputIconWrap', window.width)}
                              style={r.get('inputIcon', window.width)}
                              onPress={() => {
                                setEntry2('');
                                setEntryIsRange(false);
                              }}
                            >
                              <Ionicons name="ios-remove-outline" size={22} color={Constants.colors.textColor} />
                            </HoverTouchableOpacity>
                          </View>
                        </>
                      )}
                    </View>
                  </View>

                  <View style={r.get('formGroup', window.width)}>
                    <Text style={[r.get('label', window.width)]}>Invalidation</Text>
                    <Errors errors={fieldError('invalidation')} />
                    <TextInput
                      style={r.get('input', window.width)}
                      onChangeText={setFnNumOnly(setInvalidation)}
                      value={invalidation}
                      placeholder={'0.00'}
                      placeholderTextColor={'#aaa'}
                    />
                  </View>
                </View>

                <View>
                  <View style={r.get('formGroup', window.width)}>
                    <Text style={[r.get('label', window.width)]}>Exits</Text>
                    <Errors errors={fieldError('exits')} />

                    {exits.map((e, i) => (
                      <View key={i}>
                        <Errors errors={fieldError(`exits.${i}`)} />

                        <View>
                          <TextInput
                            style={[r.get('input', window.width), { paddingHorizontal: 25 }]}
                            onChangeText={(v) => setExit(v, i)}
                            value={e}
                            placeholder={'0.00'}
                            placeholderTextColor={'#aaa'}
                          />

                          {exits.length > 1 && (
                            <HoverTouchableOpacity
                              containerStyle={r.get('inputIconLeftWrap', window.width)}
                              style={r.get('inputIconLeft', window.width)}
                              onPress={() => removeExit(i)}
                            >
                              <Ionicons name="ios-remove-outline" size={22} color={Constants.colors.textColor}
                                        accessibilityLabel="Remove Exit" />
                            </HoverTouchableOpacity>
                          )}

                          {i + 1 !== exits.length && (
                            <HoverTouchableOpacity
                              containerStyle={r.get('inputIconWrap', window.width)}
                              style={r.get('inputIcon', window.width)}
                              onPress={() => addExit(i)}
                            >
                              <Ionicons name="ios-add-outline" size={22} color={Constants.colors.textColor}
                                        accessibilityLabel="Add Exit" />
                            </HoverTouchableOpacity>
                          )}
                        </View>
                      </View>
                    ))}


                    <HoverTouchableOpacity style={r.get('smBtn', window.width)}
                                           onPress={() => setExits([...exits, ''])}>
                      <Ionicons style={r.get('smBtnIcon', window.width)} name="ios-add-outline"
                                size={22} color={'#fff'} />
                      <Text style={r.get('smBtnText', window.width)}>Add Exit</Text>
                    </HoverTouchableOpacity>
                  </View>
                </View>

                <View>
                  <View style={[r.get('formGroup', window.width)]}>
                    <Text style={[r.get('label', window.width)]}>Description</Text>
                    <Errors errors={fieldError('description')} />
                    <TextInput
                      style={[r.get('input', window.width), { minHeight: 100 }]}
                      onChangeText={setDescription}
                      value={description}
                      placeholder={''}
                      placeholderTextColor={'#aaa'}
                      multiline={true}
                      numberOfLines={4}
                    />
                  </View>
                </View>

                <View>
                  <View style={[r.get('formGroup', window.width)]}>
                    <Text style={[r.get('label', window.width)]}>Images</Text>
                    <Text style={[r.get('desc', window.width)]}>
                      Suggest image size is 1920 x 1080 (landscape)
                    </Text>
                    <Errors errors={fieldError('images')} />

                    <View style={[r.get('imagesWrap', window.width)]}>
                      <ImagesList images={images} setImages={setImages} />
                    </View>

                    <HoverTouchableOpacity style={r.get('smBtn', window.width)}
                                           onPress={() => addImages()}>
                      <Ionicons style={r.get('smBtnIcon', window.width)} name="ios-add-outline"
                                size={22} color={'#fff'} />
                      <Text style={r.get('smBtnText', window.width)}>Add Images</Text>
                    </HoverTouchableOpacity>
                  </View>
                </View>

                {e && !!e.message && (
                  <View style={[r.get('errors', window.width), { marginBottom: 20 }]}>
                    <Text key={e} style={r.get('error', window.width)}>{e.message}</Text>
                  </View>
                )}

                <HoverTouchableOpacity style={r.get('btn', window.width)} onPress={() => save()}>
                  {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
                  <Text style={r.get('btnText', window.width)}>Save</Text>
                </HoverTouchableOpacity>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </ScrollView>
    </TouchableWithoutFeedback>
  );
}

function ImagesList({ images, setImages }) {
  const removeImage = (i) => {
    const d = [...images];
    d.splice(i, 1);
    setImages(d);
  };

  const renderItem = ({ item, drag, isActive, getIndex }) => {
    return (
      <ScaleDecorator>
        <TouchableOpacity
          activeOpacity={0.9}
          onPressIn={drag}
          disabled={isActive}
          style={[r.get('imageWrap', window.width)]}
        >
          <Image source={item.source} style={[r.get('image', window.width)]}
                 resizeMode={'contain'} />

          <HoverTouchableOpacity
            containerStyle={r.get('imageIconCloseWrap', window.width)}
            style={r.get('imageIconClose', window.width)}
            onPress={() => removeImage(getIndex())}
          >
            <Ionicons name="ios-close-outline" size={22} color={'#fff'}
                      accessibilityLabel="Delete Image" />
          </HoverTouchableOpacity>
        </TouchableOpacity>
      </ScaleDecorator>
    );
  };

  return (
    <DraggableFlatList
      data={images}
      // horizontal={true}
      onDragEnd={({ data }) => setImages(data)}
      keyExtractor={(item) => item.key}
      renderItem={renderItem}
    />
  );
}

function Errors({ errors }) {
  if (!errors || errors.length === 0) return;

  return (
    <View style={r.get('errors', window.width)}>
      {errors.map((e) => (
        <Text key={e} style={r.get('error', window.width)}>{e}</Text>
      ))}
    </View>
  );
}

function setFnNumOnly(f) {
  return (str) => f(txtNumeric(str));
}

function txtNumeric(str) {
  return (str.match(/[0-9\.]+/g) || []).join('');
}

const baseStyles = StyleSheet.create({
  container: Platform.OS === 'web' ? {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
    padding: 20,
  } : {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  containerInner: {
    padding: 40,
    minHeight: '50%',
    maxWidth: 700,
    width: '100%',
    backgroundColor: Constants.colors.backgroundSecondary,
    ...(Platform.OS === 'web' ? {
      borderRadius: 12,
    } : {})
  },
  closeIcon: {
    color: Constants.colors.textColor,
  },
  head: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  title: {
    flex: 1,
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    color: Constants.colors.textColor,
  },
  errors: {
    marginBottom: 0,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  formGroup: {
    marginBottom: 15,
    marginRight: 15,
  },
  label: {
    fontSize: 16,
    color: Constants.colors.textColor,
  },
  desc: {
    fontSize: 13,
    fontStyle: 'italic',
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  dropdown: {
    width: 130,
    marginTop: 5,
    borderRadius: 5,
    borderColor: Constants.colors.borderColor,
    paddingHorizontal: 15,
    paddingVertical: 10,
    position: 'relative',
    backgroundColor: Constants.colors.backgroundSecondary,
  },
  rangeGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 15,
  },
  rangeSep: {
    marginHorizontal: 10,
    fontWeight: '600',
    marginTop: -4,
  },
  inputIconWrap: {
    position: 'absolute',
    top: '50%',
    right: -15,
    marginTop: -15,
    // transform: [{ translateY: '-50%' }],
  },
  inputIcon: {
    backgroundColor: Constants.colors.backgroundSecondary,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    width: 30,
    height: 30,
    paddingLeft: 1,
    marginTop: -2,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  inputIconLeftWrap: {
    position: 'absolute',
    top: '50%',
    left: -15,
    marginTop: -15,
    // transform: [{ translateY: '-50%' }],
  },
  inputIconLeft: {
    backgroundColor: Constants.colors.backgroundSecondary,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    width: 30,
    height: 30,
    paddingLeft: 1,
    marginTop: -2,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  smBtn: {
    flexDirection: 'row',
    // backgroundColor: '#000',
    paddingHorizontal: 15,
    paddingVertical: 5,
    alignItems: 'center',
    alignSelf: 'flex-start',
    borderRadius: 8,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: Constants.colors.textColor,
  },
  smBtnText: {
    color: Constants.colors.textColor,
    fontWeight: '500',
  },
  btn: {
    backgroundColor: Constants.colors.accent,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnText: {
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.backgroundSecondary,
  },
  imagesWrap: {
    marginVertical: 10,
  },
  image: {
    width: '100%',
    aspectRatio: 2 / 1,
    borderRadius: 8,
    marginRight: 15,
    marginBottom: 15,
    borderWidth: 1,
    borderColor: '#ddd',
    backgroundColor: '#000',
  },
  imageIconCloseWrap: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  imageIconClose: {
    backgroundColor: '#b11a1a',
    borderWidth: 1,
    borderColor: '#fff',
    width: 25,
    height: 25,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  linkWrap: {
    cursor: 'pointer',
    paddingVertical: 4,
    paddingHorizontal: 10,
    marginTop: 20,
  },
  linkText: {
    color: '#000',
    textDecorationLine: 'underline',
  },
});

const stylesSM = StyleSheet.create({
  containerInner: {
    padding: 20,
  },
  input: {
    minWidth: 150,
  },
  container: {
    ...(Platform.OS === 'web' ? {
      paddingHorizontal: 8,
    } : {})
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
