import * as React from 'react';
import { useState } from "react";
import { unstable_createElement as createElement } from "react-native-web";
import { Platform, View, Text, Button, StyleSheet, useWindowDimensions } from "react-native";
import { DateTime } from "luxon";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import HoverTouchableOpacity from "./HoverTouchableOpacity";
import Ionicons from "@expo/vector-icons/Ionicons";
import Responsive from "../utilities/responsive";
import Constants from "../../constants";

export default function DatePicker(props) {
  if (Platform.OS === 'web') {
    return <MyWebDatePicker {...props} />;
  }

  return <MyNativeDatePicker {...props} />;
};

const MyWebDatePicker = ({ date, setDate }) => {
  return createElement('input', {
    type: 'date',
    value: date && !isNaN(date) && date.toISOString().split("T")[0],
    onChange: (event) => {
      console.log('web date picker change', event.target.value);
      setDate(DateTime.fromFormat(event.target.value, 'y-MM-dd').toJSDate())
    },
    style: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: Constants.colors.borderColor,
      padding: 10,
      borderRadius: 5,
      height: 28,
      backgroundColor: Constants.colors.backgroundSecondary,
      color: Constants.colors.textColor,
      outlineStyle: 'none',
      colorScheme: 'dark',
    },
  });
};

const MyNativeDatePicker = ({ date, setDate }) => {
  const window = useWindowDimensions();
  const ww = window.width;

  const [show, setShow] = useState(false);

  const handleConfirm = (date) => {
    console.log("A date has been picked: ", date.toISOString().split("T")[0]);
    setDate(DateTime.fromFormat(date.toISOString().split("T")[0], 'y-MM-dd').toJSDate());
    setShow(false);
  };

  const dateStr = date && !isNaN(date) && date.toISOString().split("T")[0];

  return (
    <View style={r.get('inputWrap', ww)}>
      <View style={r.get('input', ww)}>
        <HoverTouchableOpacity containerStyle={r.get('inputLink', ww)} onPress={() => setShow(true)}>
          {dateStr ? (
            <Text style={r.get('inputText', ww)}>{dateStr}</Text>
          ) : (
            <Text style={[r.get('inputText', ww), r.get('inputPlaceholderText', ww)]}>Select date...</Text>
          )}

        </HoverTouchableOpacity>

        <HoverTouchableOpacity style={r.get('inputClearBtn', ww)} onPress={() => setDate(null)}>
          <Ionicons
            name="ios-close"
            size={22}
            style={r.get('inputClearIcon', ww)}
            accessibilityLabel={"Clear Date"}
          />
        </HoverTouchableOpacity>
      </View>
      <DateTimePickerModal
        date={date || undefined}
        isVisible={show}
        mode="date"
        onConfirm={handleConfirm}
        onCancel={() => setShow(false)}
      />
    </View>
  );
};

const baseStyles = StyleSheet.create({
  inputWrap: {
    marginBottom: 10,
  },
  input: {
    backgroundColor: Constants.colors.backgroundSecondary,
    width: '100%',
    padding: 0,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    outlineStyle: 'none',

  },
  inputLink: {
    flex: 1,
    padding: 15,
  },
  inputPlaceholderText: {
    color: '#999',
  },
  inputClearIcon: {
    color: '#c10e0e',
  },
  inputClearBtn: {
    paddingHorizontal: 10,
    paddingLeft: 40,
    alignItems: 'flex-end',
  },
});

const stylesSM = StyleSheet.create({

});

const stylesMD = StyleSheet.create({

});

const stylesLG = StyleSheet.create({

});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
