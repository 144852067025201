import * as React from 'react';
import { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  TextInput,
  Platform,
  ActivityIndicator, Alert
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import PreAuthLayout from "../layouts/PreAuthLayout";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import appState from '../../app.state';
import { Link } from "@react-navigation/native";
import * as Device from 'expo-device';
import SplashScreen from "./Splash";

export default function RegisterAffiliateScreen({ navigation, route }) {
  const window = useWindowDimensions();

  const { token } = route.params;

  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [u, setUser] = useState(false);
  const [e, setError] = useState(null);
  const [invite, setInvite] = useState(null);
  const [payoutAddress, setPayoutAddress] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const invite = await appState.appApi.getInvite(token);
        setInvite(invite);
        if (!invite || !invite.id) setNotFound(true);
      } catch (e) {
        setNotFound(true);
        console.error(e);
      }

      try {
        const POs = await appState.appApi.getPaymentOptions();
        setPaymentOptions(POs);
        setSelectedPaymentOption(POs[0]);

        setUser(await appState.authService.whoami());
      } catch (e) {
        console.error(e);
        Alert.alert('Failed to fetch payment options. Please, try again later.');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const fieldError = (f) => (e && e.errors && e.errors[f]) || [];

  const deviceName = Platform.OS === 'web' ? `Web App Login: ${Device.modelName} ${Device.osName} ${Device.osVersion}` : `Native Login: ${Device.deviceName}`;

  const formSubmit = async function () {
    if (submitting) return;
    try {
      setSubmitting(true);

      if (!appState.authService.loggedIn) {
        await appState.appApi.register({
          payment_option_id: po.id,
          email,
          password,
          password_confirmation: passwordConfirmation,
          device_name: deviceName,
        });

        await appState.authService.login({ email, password, device_name: deviceName });
        setUser(await appState.authService.whoami());
      }


      await appState.appApi.affiliateRegister(token);

      await appState.appApi.updateWallet({ payout_address: payoutAddress });

      // navigation to affiliate dash
      setTimeout(() => navigation.navigate('Account'), 10);
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      setSubmitting(false);
    }
  };

  const po = selectedPaymentOption;

  if (loading) return <SplashScreen />;

  if (notFound) return (
    <PreAuthLayout>
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Text style={{ color: Constants.colors.textColor, fontSize: 24, fontWeight: 600 }}>
          Token Not Found
        </Text>
      </View>
    </PreAuthLayout>
  );

  return (
    <PreAuthLayout>
      <View style={responsive.get('container', window.width)}>
        <View style={responsive.get('form', window.width)}>
          <Text style={responsive.get('heading', window.width)}>Affiliate Sign Up</Text>

          <View>
            <Text style={responsive.get('poHeading1', window.width)}>
              Starting at $15 per user for sign ups and renewals. Start Earning Now!
            </Text>
            <Text style={responsive.get('poHeading2', window.width)}>Paid in Ethereum</Text>

            <Text
              style={responsive.get('poHeading2', window.width)}>
              {appState.authService.loggedIn && `You are logged in as ${u.email}.`}
              {invite && invite.affiliate && `You are signing up under ${invite.affiliate.name}.`}</Text>
          </View>

          {!appState.authService.loggedIn && (
            <>
              <Errors errors={fieldError('email')} />

              <TextInput
                style={responsive.get('input', window.width)}
                onChangeText={setEmail}
                value={email}
                keyboardType={'email-address'}
                placeholder={'Email'}
                placeholderTextColor={'#aaa'}
              />

              <Errors errors={fieldError('password')} />

              <TextInput
                style={responsive.get('input', window.width)}
                onChangeText={setPassword}
                value={password}
                secureTextEntry={true}
                placeholder={'Set Account Password'}
                placeholderTextColor={'#aaa'}
              />

              <Errors errors={fieldError('password_confirmation')} />

              <TextInput
                style={responsive.get('input', window.width)}
                onChangeText={setPasswordConfirmation}
                value={passwordConfirmation}
                secureTextEntry={true}
                placeholder={'Password Confirmation'}
                placeholderTextColor={'#aaa'}
              />
            </>
          )}

          <TextInput
            style={responsive.get('input', window.width)}
            onChangeText={setPayoutAddress}
            value={payoutAddress}
            placeholder={'Ethereum Address for Payouts'}
            placeholderTextColor={'#aaa'}
          />

          {e && !!e.message && (
            <View style={[r.get('errors', window.width), { marginBottom: 20 }]}>
              <Text key={e} style={r.get('error', window.width)}>{e.message}</Text>
            </View>
          )}

          <HoverTouchableOpacity onPress={formSubmit} style={responsive.get('btn', window.width)}>
            {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
            <Text style={responsive.get('btnText', window.width)}>Sign Up</Text>
          </HoverTouchableOpacity>
        </View>
        {!appState.authService.loggedIn && (
          <Link to={{ screen: 'Login', params: { redirect: route.path } }}>
            <HoverTouchableOpacity style={responsive.get('link', window.width)}>
              <Text style={responsive.get('linkText', window.width)}>Already have an account?
                Login</Text>
            </HoverTouchableOpacity>
          </Link>
        )}
      </View>
    </PreAuthLayout>
  );
}

function Errors({ errors }) {
  if (!errors || errors.length === 0) return;

  return (
    <View style={responsive.get('errors', window.width)}>
      {errors.map((e) => (
        <Text key={e} style={responsive.get('error', window.width)}>{e}</Text>
      ))}
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundPrimary,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  form: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: Constants.colors.backgroundSecondary,
    padding: 30,
    borderRadius: 5,
    marginBottom: 20,
  },
  heading: {
    fontSize: 21,
    marginBottom: 20,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  poHeading1: {
    fontSize: 19,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  poHeading2: {
    fontSize: 14,
    marginBottom: 20,
    fontFamily: Constants.fontFamily,
    fontStyle: 'italic',
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  btn: {
    backgroundColor: Constants.colors.accent,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnText: {
    color: Constants.colors.backgroundSecondary,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },

  linkText: {
    color: Constants.colors.textColor,
    fontSize: 14,
    fontFamily: Constants.fontFamily,
  },
  errors: {
    marginBottom: 10,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
});

const stylesSM = StyleSheet.create({});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const responsive = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
