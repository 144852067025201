import * as React from 'react';
import { useEffect, useState } from "react";
import { unstable_createElement as createElement } from "react-native-web";
import {
  Platform,
  View,
  Text,
  Button,
  StyleSheet,
  useWindowDimensions,
  TextInput, ActivityIndicator
} from "react-native";
import { DateTime } from "luxon";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import HoverTouchableOpacity from "./HoverTouchableOpacity";
import Ionicons from "@expo/vector-icons/Ionicons";
import Modal from "react-native-modal";
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import appState from "../../app.state";
import { fmtD, fmtDT } from "../utilities/fmtDate";
import { Link } from "@react-navigation/native";

export default function UserSelect({ user, userSelected }) {
  const window = useWindowDimensions();
  const ww = window.width;

  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);

  const toggleModal = () => {
    setLoading(false);
    setSearch('');
    setItems([]);
    setVisible(!visible);
  };

  const select = (user) => {
    console.log('select', user);
    userSelected(user);
    toggleModal();
  };

  const getData = () => {
    (async () => {
      try {
        if (search.length < 2) {
          setItems([]);
        } else {
          const users = await appState.appApi.getUsers({
            page: 1,
            limit: 3,
            search,
          });
          setItems(users.data);
        }
      } catch (e) {
        console.error(e);
        setItems([]);
      } finally {
        setLoading(false);
      }
    })();
  };

  useEffect(() => {
    setLoading(true);
    const watchId = setTimeout(() => {
      getData();
    }, 300);

    return () => clearTimeout(watchId);
  }, [search]);

  return (
    <View>
      <View style={{ alignItems: 'flex-start' }}>
        {!user ? (
          <HoverTouchableOpacity onPress={toggleModal} style={r.get('btn', ww)}>
            <Text style={r.get('btnText', ww)}>Select User</Text>
          </HoverTouchableOpacity>
        ) : (
          <HoverTouchableOpacity onPress={toggleModal} style={[r.get('input', ww), r.get('inputUser', ww)]}>
            <Text style={r.get('inputText', ww)}>{user.email} (ID: {user.id})</Text>
            <View style={r.get('userIcon', ww)}>
              <Ionicons name="ios-create-outline" color={Constants.colors.accent} size={18} />
            </View>
          </HoverTouchableOpacity>
        )}
      </View>

      <Modal isVisible={visible} onBackdropPress={toggleModal} style={r.get('modal', ww)}>
        <View style={r.get('modalInner', ww)}>
          <View style={r.get('modalBody', ww)}>
            <Text style={r.get('heading', ww)}>Search for User</Text>

            <View style={r.get('filters', ww)}>
              <View style={r.get('filter', ww)}>
                <Text style={r.get('label', ww)}>Search</Text>

                <TextInput
                  style={r.get('input', ww)}
                  onChangeText={setSearch}
                  value={search}
                  placeholder={'Search by user\'s email'}
                  placeholderTextColor={'#aaa'}
                />
              </View>

            </View>
          </View>

          {loading && (
            <View style={r.get('loadingWrap', ww)}>
              <ActivityIndicator color={Constants.colors.textColor} />
            </View>
          )}

          <View style={r.get('modalBody', ww)}>
            {items && items.length > 0 && items.map((item, index) => (
              <HoverTouchableOpacity
                containerStyle={[r.get('rowWrap', ww)]}
                style={[r.get('row', ww)]}
                key={index}
                onPress={() => select(item)}
              >
                <View style={[r.get('rowInner', ww)]}>
                  <View style={r.get('rowCol', ww)}>
                    <Text style={r.get('rowTitle', ww)}>{item.email}</Text>
                    <Text
                      style={r.get('rowValue', ww)}>{item.membership_active ? 'Active' : 'Inactive'} {item.membership && ` - Exp: ${fmtD(item.membership.expiration)}`}</Text>
                  </View>
                  <View style={[r.get('rowCol', ww), r.get('rowColLast', ww)]}>
                    <Text
                      style={r.get('rowValue', ww)}>{item.user_roles && item.user_roles.map((r) => makeTitle(r)).join(', ')}</Text>
                    <Text style={r.get('rowValue', ww)}>{fmtDT(item.created_at)}</Text>
                  </View>
                </View>
              </HoverTouchableOpacity>
            ))}

          </View>
        </View>
      </Modal>
    </View>
  );
};

function makeTitle(slug) {
  var words = slug.split('-');

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(' ');
}

const baseStyles = StyleSheet.create({
  btn: {
    borderColor: Constants.colors.accent,
    borderWidth: 1,
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderRadius: 5,
    marginTop: 0,
  },
  btnText: {
    color: Constants.colors.accent,
  },
  heading: {
    fontSize: 22,
    color: Constants.colors.textColor,
  },
  bodyText: {
    color: Constants.colors.textColor,
  },
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
  },
  modalBody: {
    paddingHorizontal: 40,
  },
  modalInner: {
    width: 600,
    minHeight: 400,
    maxWidth: '100%',
    backgroundColor: Constants.colors.backgroundSecondary,
    borderRadius: 8,
    paddingVertical: 40,
  },
  filters: {
    flexDirection: 'row',
    zIndex: 1,
    marginTop: 20,
  },
  filter: {
    marginRight: 10,
    flex: 1,
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    color: Constants.colors.textColor,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  loadingWrap: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    paddingHorizontal: 25,
    paddingVertical: 15,
    backgroundColor: Constants.colors.backgroundThird,
    borderRadius: 8,

    borderColor: Constants.colors.borderColor,
    borderWidth: 1,

    overflow: 'hidden',
    marginVertical: 5,

    cursor: 'pointer',
  },
  rowTitle: {
    fontSize: 15,
    fontWeight: '600',
    color: Constants.colors.textColor,
  },
  rowValue: {
    color: Constants.colors.textColor,
  },
  inputUser: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 4,
    marginTop: 0,
    marginBottom: 0,
  },
  inputText: {
    color: Constants.colors.textColor,
    marginRight: 15,
  },
  userIcon: {
    backgroundColor: Constants.colors.backgroundPrimary,
    height: 28,
    width: 28,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
  },
});

const stylesSM = StyleSheet.create({

});

const stylesMD = StyleSheet.create({

});

const stylesLG = StyleSheet.create({
  rowInner: {
    width: '100%',
    flexDirection: 'row',
  },
  rowCol: {
    flex: 1,
    justifyContent: 'center',
  },
  rowColLast: {
    alignItems: 'flex-end',
  },
  rowWrap: {
    flex: 1,
    width: '100%',
  },
});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
