import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import AsyncStorage from '@react-native-async-storage/async-storage';
import appState from '../../app.state';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export default class PushNotificationService {
  constructor() {
    Notifications.addNotificationResponseReceivedListener(this.handleNotificationResponse);
  }

  async registerForPushNotifications() {
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      const token = (await Notifications.getExpoPushTokenAsync()).data;
      console.log(token);

      await appState.appApi.saveDevice(token);
    } else {
      alert('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
  };

  async hasAsked() {
    return JSON.parse(await AsyncStorage.getItem("ask_to_enable_push_notifications"));
  }

  async setAsked(val) {
    return AsyncStorage.setItem("ask_to_enable_push_notifications", JSON.stringify(val))
  }

  handleNotificationResponse(response) {
    console.log(response);
  };
}
