import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  Platform,
  TouchableWithoutFeedback, FlatList, RefreshControl, Animated, Image, ActivityIndicator
} from 'react-native';
import axios from 'axios';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import { useCallback, useEffect, useRef, useState } from "react";
import appState from "../../app.state";
import DropDownPicker from "react-native-dropdown-picker";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import Ionicons from "@expo/vector-icons/Ionicons";
import fmtAttachment from "../utilities/fmtAttachment";
import { fmtDT } from "../utilities/fmtDate";
import { useFocusEffect } from "@react-navigation/native";
import SplashScreen from "./Splash";
import { observer } from "mobx-react-lite";
import { observable, runInAction } from "mobx";
import Lightbox from "react-native-lightbox";
import ChatImgSlider from "../components/ChatImgSlider";
import Chat from "../components/Chat";

export default function ChartsScreen({ navigation, route }) {
  const window = useWindowDimensions();
  const ww = window.width;
  console.log({ route });

  const [typeOpen, setTypeOpen] = useState(false);
  const [typeOptions, setTypeOptions] = useState([
    { label: 'Swing', value: 'swing' },
    { label: 'Scalp', value: 'scalp' },
  ]);

  const [symbolOpen, setSymbolOpen] = useState(false);
  const [symbolOptions, setSymbolOptions] = useState([
    { label: 'BTC-USD', value: 'BTC-USD' },
    { label: 'ETH-USD', value: 'ETH-USD' },
  ]);

  const dropdownsSetOpen = [setTypeOpen, setSymbolOpen];

  const [loading, setLoading] = useState(true);
  const [e, setError] = useState(false);
  const [u, setUser] = useState(false);

  const closeOtherDropdowns = (f) => {
    dropdownsSetOpen.map((f2) => {
      if (f !== f2) f2(false);
    });
  };

  useEffect(() => {
    console.log('focused');
    (async () => {
      try {
        if (!route.params || !route.params.type || !route.params.symbol) {
          navigation.navigate('Charts', {
            symbol: (route.params && route.params.symbol) || 'BTC-USD',
            type: (route.params && route.params.type) || 'swing',
          })
        }

        setLoading(true);
        setUser(await appState.authService.whoami());
      } catch (e) {
        setError(e);
        // console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation, route]);

  if (loading) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  return (
    <DashboardLayout>
      <TouchableWithoutFeedback onPressIn={() => closeOtherDropdowns()}>
        <View style={{ flex: 1 }}>
          <View style={[r.get('navbar', window.width), { height: 81 }]}>
            <View style={r.get('navbarInner', window.width)}>
              <DropDownPicker
                style={r.get('dropdown', ww)}
                containerStyle={r.get('dropdownWrap', ww)}
                placeholder="Choose"
                placeholderStyle={{ color: Constants.colors.textColor, opacity: 0.4 }}
                labelStyle={{ color: Constants.colors.textColor }}
                open={typeOpen}
                value={route.params.type}
                items={typeOptions}
                setOpen={setTypeOpen}
                setValue={(v) => navigation.navigate('Charts', {
                  symbol: route.params.symbol,
                  type: v(),
                })}
                setItems={setTypeOptions}
                onOpen={() => closeOtherDropdowns(setTypeOpen)}
              />

              <DropDownPicker
                style={r.get('dropdown', ww)}
                containerStyle={r.get('dropdownWrap', ww)}
                placeholder="Choose"
                placeholderStyle={{ color: Constants.colors.textColor, opacity: 0.4 }}
                labelStyle={{ color: Constants.colors.textColor }}
                open={symbolOpen}
                value={route.params.symbol}
                items={symbolOptions}
                setOpen={setSymbolOpen}
                setValue={(v) => navigation.navigate('Charts', {
                  type: route.params.type,
                  symbol: v(),
                })}
                setItems={setSymbolOptions}
                onOpen={() => closeOtherDropdowns(setSymbolOpen)}
              />
            </View>

            {window.width < 1000 && (
              <View style={[r.get('headerChatBtnWrap', window.width)]}>
                <HoverTouchableOpacity
                  style={[r.get('headerChatBtn', window.width)]}
                  onPress={() => navigation.navigate('Chat', { group: `chart-${route.params.type}-${route.params.symbol}` })}
                >
                  <Ionicons name="chatbubble-outline" size={28}
                            color={Constants.colors.textColor} />
                </HoverTouchableOpacity>
              </View>
            )}
          </View>
          <View style={r.get('container', window.width)}>
            <TVChart navigation={navigation} route={route} />
            {Platform.OS === 'web' && (
              <View testID='chatwrap' style={r.get('sidebar', window.width)}>
                <Chat
                  group={`chart-${route.params.type}-${route.params.symbol}`}
                  user={u}
                  navigation={navigation}
                  route={route}
                  loadMessages={async () => appState.appApi.getBroadcastMessages({
                    group: `chart-${route.params.type}-${route.params.symbol}`,
                    limit: 10
                  })}
                />
              </View>
            )}
          </View>
        </View>
      </TouchableWithoutFeedback>
    </DashboardLayout>
  );
}

function TVChart({ navigation, route }) {
  if (Platform.OS !== 'web') return (
    <View style={{ flex: 1, padding: 20, }}>
      <Text style={{ color: Constants.colors.textColor }}>Charts only supported on web.</Text>
    </View>
  );

  const ref = useRef(null);
  const [loading, setLoading] = useState(true);
  const [tvWidget, setTVWidget] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await loadTVCharts();
        await loadTVDatafeeds();
        const u = await appState.authService.whoami();
        setLoading(false);

        const widgetOptions = {
          interval: route.params.type === 'swing' ? '60' : '15',
          symbol: `Bitfinex:${route.params.symbol.replace('-', '/')}`,
          datafeed: datafeed,
          // symbol: 'AAPL',
          // datafeed: new window.Datafeeds.UDFCompatibleDatafeed('https://demo_feed.tradingview.com'),

          library_path: appState.config.APP_CHARTS_LIBRARY_URL,
          custom_css_url: 'css/style.css',
          container: 'tv_chart_container',
          locale: 'en',
          disabled_features: [
            'use_localstorage_for_settings',
            'study_templates',
            'header_compare',
            (!u || !u.user_access.includes('manage options')) && 'header_indicators',
            (!u || !u.user_access.includes('manage options')) && 'header_settings',
            'header_symbol_search',
            'symbol_search_hot_key',
            "create_volume_indicator_by_default",
            "legend_context_menu",
          ],
          // enabled_features: ['study_templates'],
          // charts_storage_url: appState.config.chartsStorageUrl,
          // charts_storage_api_version: appState.config.chartsStorageApiVersion,
          client_id: '886crypto.com',
          user_id: appState.authService.user && appState.authService.user.id,
          fullscreen: false,
          autosize: true,
          theme: 'Dark',
          custom_indicators_getter: (PineJS) => {
            return Promise.resolve([tradeIndicator(PineJS), ema(PineJS)])
          },
          compare_symbols: [],
          studies_overrides: {},
        };

        const w = new window.TradingView.widget(widgetOptions);

        w.applyOverrides({
          'paneProperties.background': Constants.colors.backgroundPrimary,
          'paneProperties.backgroundType': 'solid',
        });

        w.onChartReady(async function () {
          w.chart().createStudy("886 Crypto Algorithm", false, false);
          w.chart().createStudy("886 Crypto EMA", false, false);

          try {
            console.log('get chart', {
              type: route.params.type,
              symbol: route.params.symbol,
            });
            const cd = await appState.appApi.getChartData({
              type: route.params.type,
              symbol: route.params.symbol,
            });

            if (cd && Object.keys(cd).length !== 0) {
              console.log('load chart');
              w.load(cd);
            }
          } catch (e) {
            console.error(e);
          }
        });


        if (u && u.user_access.includes('manage options')) {
          w.headerReady().then(function () {
            const el = w.createButton();
            el.appendChild(document.createTextNode('Save'));
            el.addEventListener('click', function () {
              if (el.getAttribute("disabled")) return;
              w.save(async function (data) {
                try {
                  console.log({ widgetData: data });
                  el.setAttribute("disabled", "disabled");
                  console.log('save chart', {
                    type: route.params.type,
                    symbol: route.params.symbol,
                    data,
                  });
                  await appState.appApi.saveChartData({
                    type: route.params.type,
                    symbol: route.params.symbol,
                    data,
                  });
                  alert('chart saved!');
                  el.removeAttribute("disabled");
                } catch (e) {
                  console.error(e);
                }
              });
            });

            const clearEl = w.createButton();
            clearEl.appendChild(document.createTextNode('Clear'));
            clearEl.addEventListener('click', function () {
              w.chart().removeAllShapes();
            });
          });
        }

        setTVWidget(w);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      if (tvWidget !== null) {
        tvWidget.remove();
        setTVWidget(null);
      }
    };
  }, [navigation, route]);

  return (
    <div
      id={"tv_chart_container"}
      style={{ flex: 1 }}
    />
  );
}

const loadTVCharts = () => {
  return new Promise((resolve) => {
    const existingScript = document.getElementById('tvChartsLibrary');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = appState.config.APP_CHARTS_LIBRARY_URL + 'charting_library.standalone.js';
      script.id = 'tvChartsLibrary';
      document.body.appendChild(script);
      script.onload = () => {
        resolve();
      };
    } else {
      resolve();
    }
  });
};

const loadTVDatafeeds = () => {
  return new Promise((resolve) => {
    const existingScript = document.getElementById('tvDatafeedLibrary');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = appState.config.APP_TV_DATAFEED_LIB_URL;
      script.id = 'tvDatafeedLibrary';
      document.body.appendChild(script);
      script.onload = () => {
        resolve();
      };
    } else {
      resolve();
    }
  });
};

const tradeIndicator = function (PineJS) {
  return {
    name: "886 Crypto Algorithm",
    metainfo: {
      _metainfoVersion: 40,
      id: "886cryptoalgorithm@tv-basicstudies-1",
      scriptIdPart: "",
      name: "886 Crypto Algorithm",
      description: "886 Crypto Algorithm",
      shortDescription: "886 Crypto Algorithm",
      is_hidden_study: !0,
      is_price_study: !0,
      isCustomIndicator: !0,
      bands: [],
      plots: [{
        id: "plot_sell",
        type: "chars"
      }, {
        id: "plot_buy",
        type: "chars"
      }],
      defaults: {
        styles: {
          plot_sell: {
            color: "#FF002C",
            transparency: 0,
            visible: !0
          },
          plot_buy: {
            color: "#0066CC",
            transparency: 0,
            visible: !0
          }
        },
        precision: 2,
        inputs: {}
      },
      styles: {
        plot_sell: {
          isHidden: !0,
          location: "AboveBar",
          char: "↓",
          size: "small",
          title: "Sell Shape"
        },
        plot_buy: {
          isHidden: !0,
          location: "BelowBar",
          char: "↑",
          size: "small",
          title: "Buy Shape"
        }
      },
      inputs: []
    },
    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        this.last_type_trade = "";
      };

      this.send = function (e, t, i) {
        // console.log('algorithm send');
        //window.add_to_menu(e, t, i)
      };

      this.main = function (context, inputCallback) {
        // console.log('algorithm');
        this._context = context;
        this._input = inputCallback;
        var n = NaN;
        var s = NaN;
        if (![15].includes(PineJS.Std.interval(this._context)))
          return [NaN, NaN];
        try {
          var close = context.new_var(PineJS.Std.close(this._context));
          var high = PineJS.Std.high(this._context);
          var low = PineJS.Std.low(this._context);
          var ma = context.new_var(PineJS.Std.sma(close, 4, this._context));
          var ema = context.new_var(PineJS.Std.ema(close, 50, this._context));
          var d = new Date(PineJS.Std.time(this._context));
          var diff1 = (ma.get(1) - ema.get(1)) * (ma.get(2) - ema.get(2));
          // var d = (ma.get(1) - ema.get(1)) * (ma.get(2) - ema.get(2));
          var diff2 = ma.get(2) - ema.get(2);
          // console.log({
          //     close, high, low, ma, ema, diff1, diff2,
          // });
          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const dStr = `${d.getUTCDate()} ${months[d.getUTCMonth()]} ${d.getUTCFullYear()} ${String(d.getUTCHours()).padStart(2, '0')}:${String(d.getUTCMinutes()).padStart(2, '0')}`;


          if (diff1 > 0)
            return [NaN, NaN];

          if (diff2 > 0 && close.get(1) < ema.get(1) && "sell" !== this.last_type_trade) {
            this.last_type_trade = "sell";
            n = high;
            console.log('sell', { close: close.get(1), diff1, diff2, dStr });
          }

          if (diff2 < 0 && close.get(1) > ema.get(1) && "buy" !== this.last_type_trade) {
            this.last_type_trade = "buy";
            s = low;
            console.log('buy', { close: close.get(1), diff1, diff2, dStr });
          }
        } catch (e) {
          console.log(e)
        }
        return [n, s]
      };
    }
  }
};

const ema = function (e) {
  return {
    name: "886 Crypto EMA",
    metainfo: {
      _metainfoVersion: 40,
      id: "886cryptoema@tv-basicstudies-1",
      scriptIdPart: "",
      name: "886 Crypto EMA",
      description: "886 Crypto EMA",
      shortDescription: "886 Crypto EMA",
      is_hidden_study: !0,
      is_price_study: !0,
      isCustomIndicator: !0,
      bands: [],
      inputs: [{
        id: "in_0",
        name: "length",
        defval: 50,
        type: "integer",
        min: 1,
        isHidden: !0
      }, {
        id: "in_1",
        type: "source",
        name: "Source",
        defval: "close",
        options: "open high low close hl2 hlc3 ohlc4".split(" "),
        isHidden: !0
      }],
      plots: [{
        id: "plot_0",
        type: "line"
      }],
      styles: {
        plot_0: {
          title: "Line",
          histogramBase: 0,
          joinPoints: !1,
          isHidden: !0
        }
      },
      defaults: {
        styles: {
          plot_0: {
            linestyle: 0,
            linewidth: 3,
            plottype: 0,
            trackPrice: !1,
            transparency: 65,
            visible: !0,
            color: "#FE940B"
          }
        },
        precision: 4,
        inputs: {
          in_0: 50,
          in_1: "close"
        }
      }
    },
    constructor: function () {
      this.init = function (e, t) {
        this._context = e;
        this._input = t;
      };

      this.main = function (t, i) {
        this._context = t;
        this._input = i;
        try {
          let t = this._input(0);
          let i = this._context.new_var(e.Std[this._input(1)](this._context));
          return [e.Std.ema(i, t, this._context)]
        } catch (e) {
          console.log(e)
        }
      };
    }
  }
};


const baseStyles = StyleSheet.create({
  navbar: {
    borderBottomWidth: 1,
    borderBottomColor: Constants.colors.borderColor,
    flexDirection: 'row',
    zIndex: 100,
    alignItems: 'center',
  },
  navbarInner: {
    padding: 15,
    flexDirection: 'row',
    zIndex: 100,
    flexWrap: 'wrap',
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundPrimary,
    // alignItems: 'center',
    // justifyContent: 'center',
    flexDirection: 'row',
  },
  sidebar: {
    width: 400,
    backgroundColor: Constants.colors.backgroundSecondary,
    borderLeftWidth: 1,
    borderLeftColor: Constants.colors.borderColor,
  },
  dropdownWrap: {
    width: 230,
    marginRight: 10,
  },
  dropdown: {
    width: 230,
    borderRadius: 5,
    borderColor: Constants.colors.borderColor,
    backgroundColor: Constants.colors.backgroundSecondary,
    paddingHorizontal: 15,
    paddingVertical: 10,
    position: 'relative',
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    color: Constants.colors.textColor,
  },
  chatMessage: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginBottom: 25,
    borderRadius: 7,
    backgroundColor: Constants.colors.backgroundThird,

    overflow: 'hidden',

    // shadowColor: '#000',
    // shadowOffset: { width: 0, height: 1 },
    // shadowOpacity: 0.1,
    // shadowRadius: 2,
    //
    // elevation: 3,
  },
  chatMessageHead: {
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  chatListContent: {
    paddingHorizontal: 30,
    paddingVertical: 30,
  },
  chatMessageAuthor: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  chatAuthorImg: {
    width: 30,
    height: 30,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginRight: 10,
  },
  chatAuthorName: {
    fontWeight: '600',
    fontFamily: Constants.fontFamily,
    fontSize: 14,
    color: Constants.colors.textColor,
  },
  chatMessageDate: {
    fontFamily: Constants.fontFamily,
    fontWeight: '400',
    fontSize: 11,
    opacity: 0.7,
    color: Constants.colors.textColor,
  },
  chatMessageBody: {
    padding: 15,
  },
  chatMessageBodyText: {
    fontFamily: Constants.fontFamily,
    fontWeight: '400',
    fontSize: 13,
    opacity: 0.7,
    color: Constants.colors.textColor,
  },

  chatMessageImg: {
    // flex: 1,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  chatMessageSlide: {
    flex: 1,

  },
  chatMessageSlideImg: {
    backgroundColor: '#000',
  },
  chatMessageSlider: {},
  chatSliderArrow: {
    textShadowColor: '#00000078',
    textShadowRadius: 3,
    textShadowOffset: {
      width: 0,            // These can't both be 0
      height: 0,           // i.e. the shadow has to be offset in some way
    },
  },
  chatHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  circleBtn: {
    backgroundColor: '#000',
    cursor: 'pointer',
    borderRadius: 50,
    height: 32,
    width: 32,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    marginLeft: -10,
  },
  addMessageWrap: {
    padding: 20,
    backgroundColor: Constants.colors.textColor,
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderRadius: 8,
    marginBottom: 20,
  },
  linkWrap: {
    cursor: 'pointer',
    paddingVertical: 4,
    paddingHorizontal: 10,
  },
  linkText: {
    color: Constants.colors.textColor,
    // textDecorationLine: 'underline',
  },
  headerChatBtnWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: 15,
    backgroundColor: Constants.colors.backgroundSecondary,
    borderColor: Constants.colors.borderColor,
    borderLeftWidth: 1,
  },
});

const stylesSM = StyleSheet.create({
  sidebar: {
    height: 0,
    width: 0,
  },
  dropdownWrap: {
    width: 130,
    marginRight: 10,
  },
  dropdown: {
    width: 130,
  }
});

const stylesMD = StyleSheet.create({
  sidebar: {
    height: 0,
    width: 0,
  },
});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);

/**
 * Datafeed
 * */
const history = {};
const historyProvider = {
  history: history,

  getBars: async function (symbolInfo, resolution, { from, to, countBack: limit, firstDataRequest: first }) {
    const symbolSplit = symbolInfo.name.split(/[:/]/);
    const exchange = symbolInfo.exchange.toLowerCase();
    if (exchange !== 'bitfinex') {
      return [];
    }

    // console.log({ symbolInfo, resolution, from, to, limit });

    const candles = await appState.appApi.getCandles(exchange, {
      baseCurrency: symbolSplit[0].toUpperCase(),
      quoteCurrency: symbolSplit[1].toUpperCase(),
      resolution,
      limit: limit || 1000,
      start: from,
      end: !first ? to : undefined,
    });

    if (candles.length === 0) return [];

    const bars = candles.map((row) => ({
      time: row[0], //TradingView requires bar time in ms
      open: row[1],
      close: row[2],
      high: row[3],
      low: row[4],
      volume: row[5],
    }));

    if (first) {
      const lastBar = bars[bars.length - 1]
      history[symbolInfo.name] = { lastBar: lastBar };
    }

    return bars;
  }
}

const supportedResolutions = ["15", "60", "240", "360", "1D"];
const config = { supported_resolutions: supportedResolutions };
const subs = {};
// const subscribedChannels = {};
// const bitfinexWS = new WebSocket('wss://api-pub.bitfinex.com/ws/2');
// bitfinexWS.onmessage = (e) => console.log(JSON.parse(e.data))
// bitfinexWS.onclose = () => {
//   if (event.wasClean) {
//     console.log(`[ws close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
//   } else {
//     // e.g. server process killed or network down
//     // event.code is usually 1006 in this case
//     console.log('[ws close] Connection died');
//   }
// }
// bitfinexWS.onerror = function(error) {
//   console.log(`[error]`);
// };

const datafeed = {
  onReady: cb => {
    console.log('=====onReady running')
    setTimeout(() => cb(config), 0)

  },
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    console.log('====Search Symbols running')
  },
  resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
    try {
      console.log('======resolveSymbol running', symbolName);

      const symbolSplit = symbolName.split(/[:/]/);
      if (symbolSplit.length < 3) return;

      const symbol = {
        name: `${symbolSplit[1]}/${symbolSplit[2]}`,
        description: '',
        type: 'crypto',
        session: '24x7',
        timezone: 'Etc/UTC',
        ticker: symbolName,
        exchange: symbolSplit[0],
        minmov: 1,
        pricescale: 100000000,
        has_intraday: true,
        intraday_multipliers: ['15', '60', '360', '1D'],
        supported_resolution: supportedResolutions,
        volume_precision: 8,
        data_status: 'streaming',
      }

      if (symbolSplit[2].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
        symbol.pricescale = 100
      }
      setTimeout(function () {
        onSymbolResolvedCallback(symbol)
        console.log('Resolving that symbol....', symbol)
      }, 0)
    } catch (e) {
      console.error(e);
      onResolveErrorCallback(e.message);
    }

  },
  getBars: async function (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    console.log('=====getBars running');
    try {
      const bars = await historyProvider.getBars(symbolInfo, resolution, periodParams);

      console.log({ bars });
      if (bars.length) {
        onHistoryCallback(bars, { noData: false })
      } else {
        onHistoryCallback(bars, { noData: true })
      }
    } catch (e) {
      console.log({ e })
      onErrorCallback(e);
    }
  },
  subscribeBars: async (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
    console.log('=====subscribeBars runnning');

    subs[subscribeUID] = {
      id: subscribeUID,
      symbolInfo,
      resolution,
      listener: onRealtimeCallback,
      lastBar: historyProvider.history[symbolInfo.name].lastBar,
    };

    subs[subscribeUID].watchID = setInterval(async () => {
      console.log('update bars', symbolInfo.name, resolution);
      const bars = await historyProvider.getBars(symbolInfo, resolution, {
        from: (subs[subscribeUID].lastBar.time || Date.now())/1000,
        countBack: 99, // limit
        firstDataRequest: false,
      });
      console.log('new bars', bars);

      subs[subscribeUID].lastBar = bars[bars.length - 1];
      bars.map((b) => onRealtimeCallback(b));
    }, 60000);




    // const symbolSplit = symbolInfo.name.split(/[:/]/);
    // const exchange = symbolInfo.exchange.toLowerCase();
    // if (exchange !== 'bitfinex') {
    //   return [];
    // }
    //
    // let res = "";
    // if (resolution === "D" || resolution === "1D") {
    //   res = "1D";
    // } else if (resolution < 60) {
    //   res = `${resolution}m`;
    // } else if (resolution >= 60) {
    //   res = `${parseInt(resolution / 60)}h`;
    // }
    //
    // //Trading Candles
    // let msg = JSON.stringify({
    //   event: 'subscribe',
    //   channel: 'candles',
    //   key: `trade:${res}:t${symbolSplit[0].toUpperCase()}${symbolSplit[1].toUpperCase()}` //'trade:TIMEFRAME:SYMBOL'
    // })

    // bitfinexWS.send(msg);
    // console.log('open', msg);

  },
  unsubscribeBars: subscriberUID => {
    console.log('=====unsubscribeBars running')
    if (subs[subscriberUID]) {
      window.clearInterval(subs[subscriberUID].watchID);
    }
  },
  calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
    //optional
    console.log('=====calculateHistoryDepth running')
    // while optional, this makes sure we request 24 hours of minute data at a time
    // CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
    return resolution < 60 ? { resolutionBack: 'D', intervalBack: '1' } : undefined
  },
  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    //optional
    console.log('=====getMarks running')
  },
  getTimeScaleMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    //optional
    console.log('=====getTimeScaleMarks running')
  },
  getServerTime: cb => {
    console.log('=====getServerTime running')
  }
};

