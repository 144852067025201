import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator,
  Image, TextInput,
  ScrollView,
  Platform,
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import appState from '../../app.state';
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import Ionicons from '@expo/vector-icons/Ionicons';
import { useCallback, useEffect, useState } from "react";
import SplashScreen from "./Splash";
import { fmtD, fmtDT } from "../utilities/fmtDate";
import fmtAttachment from "../utilities/fmtAttachment";
import { DateTime } from "luxon";
import * as ImagePicker from 'expo-image-picker';
import { Link, useFocusEffect } from "@react-navigation/native";
import PaginatedList from "../components/PaginatedList";

export default function AffiliateDashboardScreen({ navigation, route }) {
  const window = useWindowDimensions();
  const ww = window.width;
  const [loading, setLoading] = useState(true);
  const [inviteSending, setInviteSending] = useState(false);
  const [inviteError, setInviteError] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [e, setError] = useState(null);
  const [user, setUser] = useState('');
  const [focusIndex, setFocusIndex] = useState(0);
  const [affiliateId, setAffiliateId] = useState(0);

  const [sendingMsg, setSendingMsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgErr, setMsgErr] = useState(false);
  const [sendMsgSuccess, setSendMsgSuccess] = useState(false);

  const fieldError = (f) => (e && e.errors && e.errors[f]) || [];

  useFocusEffect(useCallback(() => {
    const max = 9999999999999999;
    setFocusIndex(Math.floor(Math.random() * max));

    (async () => {
      try {
        setLoading(true);
        if (route && route.params && route.params.id) {
          const aId = route && route.params && route.params.id;
          setAffiliateId(aId);

          const a = await appState.appApi.getAffiliate(aId);
          setUser(await appState.appApi.getUser(a.user_id))
        } else {
          const u = appState.authService.user;
          setAffiliateId(u.affiliate && u.affiliate.id);
          console.log({ u: JSON.parse(JSON.stringify(u)) });
          setUser(u);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation, route]));

  const getInvitations = async (p) => {
    return appState.appApi.getAffiliatePendingInvitations({
      page: p,
      limit: 5,
      affiliate_id: affiliateId,
    });
  };

  const getAffiliates = async (p) => {
    return appState.appApi.getAffiliates({
      page: p,
      limit: 10,
      affiliate_id: affiliateId,
    });
  };

  const sendInvite = async () => {
    try {
      setInviteSending(true);
      await appState.appApi.sendInvite({
        email: inviteEmail,
      })

      //reload
      const max = 9999999999999999;
      setFocusIndex(Math.floor(Math.random() * max));
    } catch (e) {
      console.error(e);
      setInviteError(true);
      setTimeout(() => setInviteError(false), 1000);
    } finally {
      setInviteSending(false);
    }
  };

  const sendMsg = async () => {
    try {
      setMsgErr(null);
      setSendingMsg(true);
      await appState.appApi.contactAffiliate({ message: msg });
      setSendMsgSuccess(true);
      setMsg('');
      setTimeout(() => setSendMsgSuccess(false), 2000);
    } catch (e) {
      console.error(e);
      setMsgErr(e);
    } finally {
      setSendingMsg(false);
    }
  };

  if (loading) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  return (
    <DashboardLayout>
      <ScrollView>
        <View style={r.get('container', ww)}>
          {Platform.OS === 'web' && (
            <Text style={r.get('title', ww)}>Affiliate Dashboard</Text>
          )}

          <View style={r.get('row', ww)}>
            <View
              style={[r.get('column', ww), r.get('leftColumn', ww)]}>
              {user.affiliate && user.affiliate.level < 3 ? (
                <>
                  <Text style={[r.get('heading', ww)]}>Invite New Affiliate</Text>

                  <View style={r.get('inviteWrap', ww)}>
                    <View style={r.get('inviteInputCol', ww)}>
                      <TextInput
                        style={r.get('input', ww)}
                        onChangeText={setInviteEmail}
                        value={inviteEmail}
                        placeholder={'Email...'}
                        placeholderTextColor={'#aaa'}
                        onSubmitEditing={() => sendInvite()}
                      />
                    </View>

                    <HoverTouchableOpacity onPress={() => sendInvite()}
                                           style={[r.get('btnSm', ww), r.get('inviteSendBtn', ww)]}>
                      {inviteSending ? (
                        <ActivityIndicator color={Constants.colors.accent} />
                      ) : inviteError ? (
                        <Ionicons name="ios-warning-outline" size={18}
                                  color={Constants.colors.accent} />
                      ) : (
                        <Ionicons name="ios-paper-plane-outline" size={18}
                                  color={Constants.colors.accent} />
                      )}
                    </HoverTouchableOpacity>
                  </View>

                  <View style={r.get('div', ww)} />

                  <Text style={[r.get('heading', ww)]}>Pending Invitations</Text>

                  <PaginatedList
                    extraData={JSON.stringify([focusIndex])}
                    getData={getInvitations}
                    renderItem={({ item, index }) => (
                      <View style={[r.get('listRow', ww), {
                        cursor: 'default',
                        flex: 1,
                        width: '100%'
                      }]} key={index}>
                        <View style={[r.get('rowInner', ww)]}>
                          <View style={r.get('rowCol', ww)}>
                            <Text style={r.get('rowTitle', ww)}>{item.email}</Text>
                          </View>
                          <View style={[r.get('rowCol', ww), r.get('rowColLast', ww)]}>
                            <Text style={r.get('rowValue', ww)}>{fmtDT(item.created_at)}</Text>
                          </View>
                        </View>
                      </View>
                    )}
                  />

                  <View style={r.get('div', ww)} />

                  <Text style={[r.get('heading', ww)]}>Your Team</Text>

                  <PaginatedList
                    extraData={JSON.stringify([focusIndex])}
                    getData={getAffiliates}
                    renderItem={({ item, index }) => (
                      <View style={[r.get('listRow', ww), {
                        cursor: 'default',
                        flex: 1,
                        width: '100%'
                      }]} key={index}>
                        <View style={[r.get('rowInner', ww)]}>
                          <View style={r.get('rowCol', ww)}>
                            <Text style={r.get('rowTitle', ww)}>{item.user_info.name}</Text>
                            <Text style={r.get('rowValue', ww)}>Active
                              Subscribers: {item.active_subs}</Text>
                          </View>
                          <View style={[r.get('rowCol', ww), r.get('rowColLast', ww)]}>
                            <Text style={r.get('rowValue', ww)}>{fmtDT(item.created_at)}</Text>
                          </View>
                        </View>
                      </View>
                    )}
                  />
                </>
              ) : (
                <Text style={[r.get('heading', ww), { marginBottom: 0 }]}>
                  You have must a level 1 or 2 to use team builder.
                </Text>
              )}

              <View style={r.get('div', ww)} />

              <View>
                <Text style={[r.get('heading', ww)]}>
                  Contact Your Upline
                </Text>

                {sendMsgSuccess && (
                  <Text style={[r.get('successMsg', ww)]}>Message Sent!</Text>
                )}

                <TextInput
                  style={[r.get('input', ww), { height: 100 }]}
                  onChangeText={setMsg}
                  value={msg}
                  placeholder={'Enter message...'}
                  multiline={true}
                  numberOfLines={4}
                  placeholderTextColor={'#aaa'}
                />

                {msgErr && (
                  <Text style={[r.get('error', ww)]}>{msgErr.message}</Text>
                )}

                <View style={{ alignItems: 'flex-start' }}>
                  <HoverTouchableOpacity onPress={() => sendMsg()} style={[r.get('btn', ww), r.get('btnLeft', ww)]}>
                    {sendingMsg && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
                    <Text style={r.get('btnTextSm', ww)}>Send Message</Text>
                  </HoverTouchableOpacity>
                </View>
              </View>
            </View>

            <View
              style={[r.get('column', ww), r.get('rightColumn', ww)]}>
              <Text
                style={[r.get('heading', ww), { marginBottom: 0 }]}>Reports</Text>

              <Text style={r.get('statTitle', ww)}>Level</Text>
              <Text style={r.get('statValLg', ww)}>{user.affiliate.level}</Text>

              <Text style={r.get('statTitle', ww)}>Active Subscribers</Text>
              <Text style={r.get('statValLg', ww)}>{user.affiliate.active_subs}</Text>

              <Text style={r.get('statTitle', ww)}>Affiliate Link</Text>
              <TextInput
                readonly
                style={[r.get('readOnlyVal', ww), r.get('statValSm', ww)]}
                value={`https://886crypto.com?a=${affiliateId}`}
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </DashboardLayout>
  );
}

function Errors({ errors }) {
  const window = useWindowDimensions();
  const ww = window.width;
  if (!errors || errors.length === 0) return;

  return (
    <View style={r.get('errors', ww)}>
      {errors.map((e) => (
        <Text key={e} style={r.get('error', ww)}>{e}</Text>
      ))}
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    marginBottom: 40,
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 1000,
  },
  title: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginVertical: 30,
    color: Constants.colors.textColor,
  },
  profileHead: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  heading: {
    fontSize: 18,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  row: {
    flexDirection: 'row',
    flex: 1,
    maxWidth: 1000,
    width: '100%',
  },
  column: {
    backgroundColor: Constants.colors.backgroundSecondary,
    padding: 30,
    borderRadius: 8,
    marginBottom: 30,

    overflow: 'hidden',

    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,

    elevation: 3,
  },
  leftColumn: {
    flex: 1,
    marginRight: 10,
  },
  rightColumn: {
    width: 300,
  },
  btn: {
    borderColor: Constants.colors.accent,
    borderWidth: 1,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnSm: {
    borderColor: Constants.colors.accent,
    borderWidth: 1,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnText: {
    color: Constants.colors.backgroundSecondary,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  btnTextSm: {
    color: Constants.colors.accent,
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  div: {
    marginVertical: 30,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  errors: {
    marginBottom: 10,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  btnLeft: {
    alignSelf: 'flex-start',
  },
  successMsg: {
    color: '#000',
    backgroundColor: '#d0ffe3',
    borderWidth: 1,
    borderColor: '#33a963',
    padding: 15,
    borderRadius: 5,
    marginBottom: 20,
  },
  colTitle: {
    color: Constants.colors.textColor,
    fontSize: 24,
  },
  statTitle: {
    color: Constants.colors.accent,
    fontSize: 12,
    marginTop: 25,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '600',
    marginBottom: 10,
    // opacity: 0.8,
  },
  statValLg: {
    color: Constants.colors.textColor,
    fontSize: 32,
  },
  statValMd: {
    color: Constants.colors.textColor,
    fontSize: 21,
  },
  statValSm: {
    color: Constants.colors.textColor,
    fontSize: 15,
  },
  readOnlyVal: {
    padding: 10,
    backgroundColor: Constants.colors.backgroundSecondary,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    outlineStyle: 'none',
  },
  statDescMd: {
    color: Constants.colors.textColor,
    fontSize: 16,
    fontStyle: 'italic',
  },
  inviteWrap: {
    flexDirection: 'row',
  },
  inviteInputCol: {
    flex: 1,
  },
  inviteSendBtn: {
    height: 50,
    marginLeft: -7,
    backgroundColor: Constants.colors.backgroundSecondary,
  },
  rowValue: {
    color: Constants.colors.textColor,
  },
  listRow: {
    paddingHorizontal: 25,
    paddingVertical: 15,
    backgroundColor: Constants.colors.backgroundThird,
    borderRadius: 8,

    borderColor: Constants.colors.borderColor,
    borderWidth: 1,

    overflow: 'hidden',
    marginVertical: 5,

    cursor: 'pointer',
  },
  rowTitle: {
    fontSize: 15,
    fontWeight: '600',
    color: Constants.colors.textColor,
  },
});

const stylesSM = StyleSheet.create({
  row: {
    flexDirection: 'column',
  },
  column: {
    borderRadius: 0,
  },
  leftColumn: {
    marginRight: 0,
  },
  rightColumn: {
    width: '100%',
  },
  footer: {
    alignItems: 'center',
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({
  rowInner: {
    width: '100%',
    flexDirection: 'row',
  },
  rowCol: {
    flex: 1,
    justifyContent: 'center',
  },
  rowColLast: {
    alignItems: 'flex-end',
  },
  rowWrap: {
    flex: 1,
    width: '100%',
  },

});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
