import {
  APP_ATTACHMENT_URL,
} from '@env';

export default function fmtAttachment(row) {
  if (!row || !row.path) return;
  const url = `${APP_ATTACHMENT_URL}/${row.path}`;
  const sizes = row.formats && row.formats.reduce((acc, r) => {
    acc[r.key] = `${APP_ATTACHMENT_URL}/${r.path}`;
    return acc;
  }, {});
  return {
    url,
    sizes,
    type: row.mime_type,
  };
}
