import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { observable, runInAction } from "mobx";
import { ActivityIndicator, Image, Platform, StyleSheet, View } from "react-native";
import Ionicons from "@expo/vector-icons/Ionicons";
import Constants from "../../constants";
import Lightbox from "react-native-lightbox";
import Responsive from "../utilities/responsive";
import Swiper from 'react-native-swiper/src'
import AwesomeAlert from 'react-native-awesome-alerts';

export default observer(function ({ images }) {
  console.log({ images });
  const [index, setIndex] = useState(0);
  // const { height, width } = images[index];
  // const aspectRatio = width/height;
  const storeObservable = useRef(observable({
    aspectRatios: {},
    aspectRatioLoading: {},
    slideLayouts: {},
  }));
  const store = storeObservable.current;

  async function getImageSize(uri) {
    return new Promise(function (resolve, reject) {
      Image.getSize(uri, ((width, height) => resolve({ width, height })), reject);
    });
  }

  const indexChanged = (i) => {
    setIndex(i);
  };

  const setSlideLayout = (index, event) => {
    const { width, height } = event.nativeEvent.layout;
    runInAction(() => {
      store.slideLayouts[index] = { width, height };
    });
  };

  useEffect(() => {
    if ((store.aspectRatioLoading[index]) || (store.aspectRatios[index])) return;
    (async () => {
      try {
        runInAction(() => {
          store.aspectRatioLoading[index] = true;
        });
        const { width, height } = await getImageSize(images[index].sizes.md);
        runInAction(() => {
          store.aspectRatios[index] = width / height;
        });
      } catch (e) {
        console.error(e)
      } finally {
        runInAction(() => {
          store.aspectRatioLoading[index] = false;
        });
        // set loading false
      }
    })();
  }, [index]);

  const aspectRatioLoading = store.aspectRatioLoading[index];
  const aspectRatio = aspectRatioLoading ? 1 : store.aspectRatios[index];

  console.log({
    aspectRatio,
    aspectRatioLoading,
    slideLayouts: JSON.parse(JSON.stringify(store.slideLayouts)),
  });

  return (
    <View
      style={[r.get('chatMessageImg', window.width), { aspectRatio: aspectRatio > 1 ? aspectRatio : 1 }]}>
      <Swiper
        showsButtons={true}
        onIndexChanged={indexChanged}
        paginationStyle={{
          bottom: -20,
        }}
        loop={false}
        prevButton={<Ionicons style={[r.get('chatSliderArrow', window.width), {
          paddingVertical: 40,
          paddingRight: 40
        }]} name={'ios-caret-back-outline'} color={Constants.colors.textColor} />}
        nextButton={<Ionicons
          style={[r.get('chatSliderArrow', window.width), { paddingVertical: 40, paddingLeft: 40 }]}
          name={'ios-caret-forward-outline'} color={Constants.colors.textColor} />}
        dotStyle={{
          backgroundColor: '#777',
          opacity: 0.7,
          height: 5,
          width: 5,
          // borderWidth: 1,
          // borderColor: '#000',
        }}
        activeDotStyle={{
          backgroundColor: Constants.colors.textColor,
          height: 7,
          width: 7,
        }}
        containerStyle={r.get('chatMessageSlider', window.width)}
      >
        {images.map((i, index) => {
          const width = store.slideLayouts[index] && store.slideLayouts[index].width;
          const height = store.slideLayouts[index] && store.slideLayouts[index].height;
          // const aspectRatio = width/height;
          return (
            <View style={r.get('chatMessageSlide', window.width)} key={index}
                  onLayout={(event) => setSlideLayout(index, event)}>
              <Lightbox
                renderContent={() => (
                  <Image
                    style={[{ flex: 1 }]}
                    source={{ uri: i.sizes.xl }}
                    resizeMode={'contain'}
                  />
                )}
                // activeProps={{ style: { flex: 1}, resizeMode: "contain" }}
              >
                <Image
                  style={[
                    r.get('chatMessageSlideImg', window.width),
                    { height, width },
                  ]}
                  source={{ uri: i.sizes.md }}
                  resizeMode={'contain'}
                />
              </Lightbox>
              {store.aspectRatioLoading[index] && <ActivityIndicator
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  // transform: [{ translateX: '-50%' }, { translateY: '-50%' }],
                  backgroundColor: Constants.colors.textColor,
                  borderRadius: 25,
                  padding: 5,
                }}
                color="#000"
              />}
            </View>
          );
        })}
      </Swiper>
    </View>
  );
});

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundSecondary,
    flexDirection: 'row',
  },
  ideasWrap: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundPrimary,
  },
  chatWrap: {
    width: 400,
    borderLeftWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  scrollViewContainer: {
    padding: 30,
  },
  ideasHeader: {
    paddingLeft: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    color: Constants.colors.textColor,
  },
  heading2: {
    fontSize: 18,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    color: Constants.colors.textColor,
  },
  btn: {
    backgroundColor: Constants.colors.backgroundPrimary,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: Constants.colors.accent,
  },
  btnText: {
    color: Constants.colors.accent,
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  ideasItems: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  ideaItemWrap: {
    flex: 1,
  },
  ideaItem: {
    margin: 10,
    flex: 1,
    backgroundColor: Constants.colors.backgroundSecondary,
    borderRadius: 7,
    // aspectRatio: 6/8,
    overflow: 'hidden',

    // shadowColor: '#000',
    // shadowOffset: { width: 0, height: 1 },
    // shadowOpacity: 0.15,
    // shadowRadius: 7,
    //
    // elevation: 3,
    cursor: 'pointer',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  ideasListContent: {
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  editIdeaBtnWrap: {
    position: 'absolute',
    zIndex: 10,
    right: 50,
    top: 28,
  },
  editIdeaBtn: {
    backgroundColor: Constants.colors.backgroundPrimary,
    height: 32,
    width: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 25,
    cursor: 'pointer',
  },
  ideaHeader: {
    height: 70,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    paddingLeft: 20,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  ideaImage: {
    backgroundColor: '#000',
    overflow: 'hidden',
    aspectRatio: 2 / 1,
  },
  ideaBody: {
    flex: 1,
    padding: 20,
    paddingVertical: 15,

  },
  ideaFooter: {
    height: 48,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  ideaSymbol: {
    fontWeight: '600',
    fontSize: 17,
    marginRight: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  ideaStatus: {
    fontSize: 12,
    fontWeight: '600',
    textTransform: 'uppercase',
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  ideaHeadSubText: {
    fontSize: 14,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    textTransform: 'capitalize',
    color: Constants.colors.textColor,
  },
  textSep: {
    paddingHorizontal: 5,
    fontSize: 14,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  ideaFooterRight: {
    paddingLeft: 10,
  },
  ideaAuthorImg: {
    width: 30,
    height: 30,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  ideaAuthor: {
    fontSize: 14,
    fontWeight: '600',
    fontFamily: Constants.fontFamily,
    lineHeight: 15,
    color: Constants.colors.textColor,
  },
  ideaDate: {
    fontSize: 10,
    fontFamily: Constants.fontFamily,
    lineHeight: 12,
    fontWeight: '500',
    opacity: 0.7,
    color: Constants.colors.textColor,
  },
  ideaBodyRow: {
    flexDirection: 'row',
  },
  ideaBodyCol: {
    flex: 1,
    marginBottom: 7,
  },
  ideaBodyLabel: {
    textTransform: 'uppercase',
    opacity: 0.8,
    fontSize: 11,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  ideaBodyValue: {
    fontSize: 14,
    fontFamily: Constants.fontFamily,
    fontWeight: '500',
    color: Constants.colors.textColor,
  },
  ideaBodyListNumber: {
    backgroundColor: '#eee',
    borderWidth: 1,
    borderColor: '#ddd',
    color: '#000',
    fontSize: 12,
    fontWeight: '600',
    fontFamily: Constants.fontFamily,
    marginRight: 8,
    height: 17,
    width: 17,
    borderRadius: 8,
    textAlign: 'center',
    overflow: 'hidden',
    ...(Platform.select({
      web: {
        lineHeight: 17,
      },
      ios: {
        lineHeight: 14,
      },
      android: {
        lineHeight: 14,
      },
    }))
  },
  ideaBodyExits: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  ideaBodyExit: {
    flexDirection: 'row',
    marginRight: 20,
    marginTop: 5,
    alignItems: 'center',
  },


  chatMessage: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginBottom: 25,
    borderRadius: 7,
    backgroundColor: Constants.colors.backgroundThird,

    overflow: 'hidden',

    // shadowColor: '#000',
    // shadowOffset: { width: 0, height: 1 },
    // shadowOpacity: 0.1,
    // shadowRadius: 2,
    //
    // elevation: 3,
  },
  chatMessageHead: {
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  chatListContent: {
    paddingHorizontal: 30,
    paddingVertical: 30,
  },
  chatMessageAuthor: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  chatAuthorImg: {
    width: 30,
    height: 30,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginRight: 10,
  },
  chatAuthorName: {
    fontWeight: '600',
    fontFamily: Constants.fontFamily,
    fontSize: 14,
    color: Constants.colors.textColor,
  },
  chatMessageDate: {
    fontFamily: Constants.fontFamily,
    fontWeight: '400',
    fontSize: 11,
    opacity: 0.7,
    color: Constants.colors.textColor,
  },
  chatMessageBody: {
    padding: 15,
  },
  chatMessageBodyText: {
    fontFamily: Constants.fontFamily,
    fontWeight: '400',
    fontSize: 13,
    opacity: 0.7,
    color: Constants.colors.textColor,
  },

  chatMessageImg: {
    // flex: 1,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  chatMessageSlide: {
    flex: 1,

  },
  chatMessageSlideImg: {
    backgroundColor: '#000',
  },
  chatMessageSlider: {},
  chatSliderArrow: {
    textShadowColor: '#00000078',
    textShadowRadius: 3,
    textShadowOffset: {
      width: 0,            // These can't both be 0
      height: 0,           // i.e. the shadow has to be offset in some way
    },
  },
  chatHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  circleBtn: {
    backgroundColor: '#000',
    cursor: 'pointer',
    borderRadius: 50,
    height: 32,
    width: 32,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    marginLeft: -10,
  },
  addMessageWrap: {
    padding: 20,
    backgroundColor: Constants.colors.textColor,
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderRadius: 8,
    marginBottom: 20,
  },
  linkWrap: {
    cursor: 'pointer',
    paddingVertical: 4,
    paddingHorizontal: 10,
  },
  linkText: {
    color: Constants.colors.textColor,
    textDecorationLine: 'underline',
  },
  deleteIdeaBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
  },
  deleteIdeaBtnText: {
    color: 'red',
    fontSize: 12,
  },
  nativeHeaderCircleBtn: {
    marginRight: 10,
    backgroundColor: 'transparent',
  },
  headerLeftBtn: {
    marginLeft: 15,
  },
});

const stylesSM = StyleSheet.create({
  chatWrap: {
    height: 0,
    width: 0,
  },
  ideasListContent: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  nativeHeaderCircleBtn: {
    marginTop: 5,
  },
  ideaBodyExit: {
    width: '43%'
  },
  ideaAuthorImg: {
    width: 38,
    height: 38,
  },
  ideaFooter: {
    height: 52,
    // backgroundColor: Constants.colors.textColor,
  },
  ideaItem: {
    // borderWidth: 1,
    // borderColor: '#ddd',
    margin: 0,
    marginBottom: 10,
    borderRadius: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  ideasHeader: {
    marginVertical: 20,
    marginHorizontal: 10,
  },
  sheetBody: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  btnSm: {
    backgroundColor: Constants.colors.backgroundPrimary,
    padding: 20,
    paddingHorizontal: 25,
    borderRadius: 8,
    marginVertical: 10,
  },
  btnSmText: {
    fontWeight: '500',
    color: Constants.colors.textColor,
    fontSize: 18,
  },
  sheetDescription: {
    maxWidth: 225,
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 17,
  },
  sheetHeading: {
    fontSize: 25,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginBottom: 10,
  },
});

const stylesMD = StyleSheet.create({
  chatWrap: {
    height: 0,
    width: 0,
  },
  ideasListContent: {
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
