import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ScrollView, Image, Platform
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import SplashScreen from "./Splash";
import { useCallback, useState } from "react";
import { Link, useFocusEffect } from "@react-navigation/native";
import appState from "../../app.state";
import fmtAttachment from "../utilities/fmtAttachment";
import ScaledImage from "../components/ScaledImage";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";

export default function LiveScreen({ navigation, route }) {
  const window = useWindowDimensions();

  const [loading, setLoading] = useState(true);
  const [u, setUser] = useState(null);
  const [channels, setChannels] = useState([]);
  const [schedule, setSchedule] = useState('');

  useFocusEffect(useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        setUser(await appState.authService.whoami());
        const chRes = await appState.appApi.getLiveChannels({ limit: 99 });
        setChannels(chRes.data);
        setSchedule(await appState.appApi.getLiveSchedule());
        setLoading(false);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation, route]));

  if (loading) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  return (
    <DashboardLayout>
      <View style={r.get('container', window.width)}>
        <View style={r.get('channelsWrap', window.width)}>
          <ScrollView style={{ height: 100 }} contentContainerStyle={{ padding: 30 }}>
            {Platform.OS === 'web' && (
              <View style={r.get('headingWrap', window.width)}>
                <Text style={[r.get('heading', window.width), { marginBottom: 0 }]}>
                  Channels
                </Text>
                {u.user_access.includes('manage options') && (
                  <Link to={{ screen: 'EditLive' }} style={r.get('leftLink', window.width)}>
                    <HoverTouchableOpacity style={r.get('btnSm', window.width)}>
                      <Text style={r.get('btnSmText', window.width)}>Edit Live Settings</Text>
                    </HoverTouchableOpacity>
                  </Link>
                )}
              </View>
            )}

            <View style={r.get('channels', window.width)}>
              {channels.map((c) => {
                const img = fmtAttachment(c.thumbnail);
                return (
                  <View style={r.get('channelOuter', window.width)}>
                    <Link style={{ width: '100%' }} to={{ screen: 'LiveChannel', params: { id: c.id } }}>
                      <HoverTouchableOpacity containerStyle={{ width: '100%' }} style={r.get('channel', window.width)}>
                        <View style={r.get('channelImgWrap', window.width)}>
                          <ScaledImage source={{ uri: img && img.sizes.md }}
                                       style={r.get('channelImg', window.width)}
                                       resizeMode={'contain'} />
                        </View>
                        <View style={r.get('channelInfo', window.width)}>
                          <Text style={r.get('channelTitle', window.width)}>{c.title}</Text>
                          <Text style={r.get('channelDesc', window.width)}>{c.description}</Text>
                        </View>
                      </HoverTouchableOpacity>
                    </Link>
                  </View>
                );
              })}
            </View>
          </ScrollView>
        </View>
        <View style={r.get('scheduleWrap', window.width)}>
          <ScrollView style={{ height: 100 }} contentContainerStyle={{ padding: 30 }}>
            <Text style={r.get('heading', window.width)}>Schedule</Text>
            <Text style={r.get('scheduleText', window.width)}>{schedule}</Text>
          </ScrollView>
        </View>
      </View>
    </DashboardLayout>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundSecondary,
    flexDirection: 'row',
  },
  channelsWrap: {
    flex: 1,
    backgroundColor: Constants.colors.backgroundPrimary,
  },
  scheduleWrap: {
    width: 400,
    borderLeftWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  scheduleText: {
    fontSize: 16,
    lineHeight: 24,
    color: Constants.colors.textColor,
  },
  channels: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -15,
  },
  channelOuter: {
    padding: 15,
    width: '33.3%',
  },
  channel: {
    borderWidth: 1,
    borderRadius: 8,
    overflow: 'hidden',
    borderColor: Constants.colors.borderColor,
    backgroundColor: Constants.colors.backgroundSecondary,
    width: '100%',
  },
  channelImgWrap: {
    width: '100%',
    marginBottom: 0,
  },
  channelImg: {
    flex: 1,
  },
  channelInfo: {
    padding: 20,
  },
  channelTitle: {
    fontSize: 20,
    lineHeight: 24,
    color: Constants.colors.textColor,
    marginBottom: 5,
  },
  channelDesc: {
    fontSize: 15,
    lineHeight: 20,
    color: Constants.colors.textColor,
  },
  headingWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  leftLink: {
    alignSelf: 'flex-start',
  },
  btnLightSm: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 10,
    paddingHorizontal: 15,
    borderRadius: 8,
  },
  btnLightSmText: {
    fontWeight: '500',
  },
  btnSm: {
    // backgroundColor: Constants.colors.back,
    padding: 10,
    paddingHorizontal: 15,
    borderRadius: 8,
    marginVertical: 10,
    borderColor: Constants.colors.textColor,
    borderWidth: 1,
  },
  btnSmText: {
    fontWeight: '500',
    color: Constants.colors.textColor,
  },
});

const stylesSM = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  channelsWrap: {
    width: '100%',
  },
  scheduleWrap: {
    width: '100%',
  },
});

const stylesMD = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  channelsWrap: {
    width: '100%',
  },
  scheduleWrap: {
    width: '100%',
  },
});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
