import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  TextInput,
  ActivityIndicator,
  // ScrollView,
  Image,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Ionicons from '@expo/vector-icons/Ionicons';
import Swiper from 'react-native-swiper/src';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import { useCallback, useEffect, useRef, useState } from "react";
import SplashScreen from "./Splash";
import appState from '../../app.state';
import fmtAttachment from "../utilities/fmtAttachment";
import { fmtDT } from "../utilities/fmtDate";
import Lightbox from "react-native-lightbox";
import { observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import { useFocusEffect } from "@react-navigation/native";

export default function IdeaScreen({ navigation, route }) {
  const window = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [e, setError] = useState(true);
  const [item, setIdea] = useState(false);

  let { id } = route.params;

  useFocusEffect(useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const idea = await appState.appApi.getIdea(id);
        setIdea(idea);
      } catch (e) {
        setError(e);
        console.log('error');
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation, id]));

  const itemStatusColor = (status) => {
    if (status === 'win') {
      return '#1cca67';
    } else if (status === 'loss') {
      return '#e02828';
    } else if (status === 'breakeven') {
      return '#999999';
    } else if (status === 'active') {
      return '#1cace5';
    }

    return '#999999';
  };

  return (
    <IdeaContainer navigation={navigation}>

      {loading ? (
        <SplashScreen />
      ) : (
        <>
          <View style={r.get('head', window.width)}>
            <View style={r.get('ideaHeader', window.width)}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={r.get('ideaSymbol', window.width)}>{item.pair}</Text>
                <Text
                  style={[r.get('ideaStatus', window.width), { color: itemStatusColor(item.status) }]}>{item.status}</Text>
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={r.get('ideaHeadSubText', window.width)}>{item.order_type}</Text>
                <Text style={r.get('textSep', window.width)}>•</Text>
                <Text style={r.get('ideaHeadSubText', window.width)}>{item.timeframe}</Text>
                <Text style={r.get('textSep', window.width)}>•</Text>
                <Text style={r.get('ideaHeadSubText', window.width)}>{item.idea_type}</Text>
              </View>
            </View>

            {Platform.OS === 'web' && (
              <HoverTouchableOpacity onPress={() => navigation.goBack()}
                                     style={[r.get('close', window.width)]}>
                <Ionicons name="ios-close" size={22} style={r.get('closeIcon', window.width)}
                          accessibilityLabel={"Close"} />
              </HoverTouchableOpacity>
            )}
          </View>

          {item.images.length > 0 && (
            <ImgSlider images={item.images.map((i) => {
              const a = fmtAttachment(i);

              let source;
              if (window.width > 1200) {
                source = { uri: a && a.sizes && a.sizes.xl };
              } else if (window.width > 900) {
                source = { uri: a && a.sizes && a.sizes.lg };
              } else {
                source = { uri: a && a.sizes && a.sizes.md };
              }

              return {
                ...a,
                source,
              };
            })} />
          )}

          <View style={r.get('body', window.width)}>
            <View
              style={[r.get('ideaBody', window.width), item.images.length === 0 && r.get('ideaBodyNoImgs', window.width)]}>
              <View style={r.get('ideaData', window.width)}>
                <View style={r.get('ideaBodyRow', window.width)}>
                  <View style={r.get('ideaBodyCol', window.width)}>
                    <Text style={r.get('ideaBodyLabel', window.width)}>Entry</Text>
                    <Text
                      style={r.get('ideaBodyValue', window.width)}>{item.entries.length > 1 ? `${item.entries[0]} - ${item.entries[1]}` : item.entries[0]}</Text>
                  </View>

                  <View style={r.get('ideaBodyCol', window.width)}>
                    <Text style={r.get('ideaBodyLabel', window.width)}>Invalidation</Text>
                    <Text style={r.get('ideaBodyValue', window.width)}>{item.invalidation}</Text>
                  </View>
                </View>

                <View>
                  <Text style={r.get('ideaBodyLabel', window.width)}>Exits</Text>

                  <View style={r.get('ideaBodyExits', window.width)}>
                    {item.exits.length > 0 && item.exits.map((e, i) => (
                      <View style={r.get('ideaBodyExit', window.width)} key={i}>
                        <Text style={r.get('ideaBodyListNumber', window.width)}>{i + 1}</Text>
                        <Text style={r.get('ideaBodyValue', window.width)}>{e}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              </View>
              <View style={r.get('ideaDesc', window.width)}>
                <Text style={r.get('ideaBodyLabel', window.width)}>Description</Text>
                <Text style={r.get('ideaBodyValue', window.width)}>{item.description}</Text>
              </View>
            </View>

            {(() => {
              if (!item.author) return;
              const a = item.author.profile_img && fmtAttachment(item.author.profile_img);
              const authorImg = { uri: a && a.sizes && a.sizes.sm_crop };
              return (
                <View style={r.get('ideaFooter', window.width)}>
                  <Image style={r.get('ideaAuthorImg', window.width)} source={authorImg}
                         resizeMode={'cover'} />
                  <View style={r.get('ideaFooterRight', window.width)}>
                    <Text style={r.get('ideaAuthor', window.width)}>{item.author.name}</Text>
                    <Text
                      style={r.get('ideaDate', window.width)}>Posted: {fmtDT(item.created_at)}</Text>
                  </View>
                </View>
              );
            })()}
          </View>
        </>
      )}
    </IdeaContainer>
  );
}

function IdeaContainer({ navigation, children }) {
  const window = useWindowDimensions();

  return Platform.OS === 'web' ? (
    <TouchableWithoutFeedback onPress={() => Platform.OS === 'web' && navigation.goBack()}>
      <ScrollView style={{ height: window.height }}>
        <View style={[r.get('container', window.width)]}>
          <TouchableWithoutFeedback>
            <View style={[r.get('containerInner', window.width), { minHeight: window.height - 20 }]}
                  onStartShouldSetResponder={() => true}>
              {children}
            </View>
          </TouchableWithoutFeedback>
        </View>
      </ScrollView>
    </TouchableWithoutFeedback>
  ) : (
    <ScrollView
      style={{ height: window.height, backgroundColor: Constants.colors.backgroundSecondary }}>
      <View style={[r.get('container', window.width)]}>
        <View style={[r.get('containerInner', window.width), { minHeight: window.height - 20 }]}>
          {children}
        </View>
      </View>
    </ScrollView>
  );
}


const ImgSlider = observer(function ({ images }) {
  const aspectRatio = 16 / 9;
  const storeObservable = useRef(observable({
    slideLayouts: {},
  }));
  const store = storeObservable.current;

  const setSlideLayout = (index, event) => {
    const { width, height } = event.nativeEvent.layout;
    runInAction(() => {
      store.slideLayouts[index] = { width, height };
    });
  };

  console.log({ slideLayouts: JSON.parse(JSON.stringify(store.slideLayouts)) });

  return (
    <View
      style={[r.get('sliderImgWrap', window.width), { aspectRatio: aspectRatio > 1 ? aspectRatio : 1 }]}>
      <Swiper
        showsButtons={true}
        paginationStyle={{
          bottom: -20,
        }}
        loop={false}
        prevButton={<Ionicons
          style={[r.get('sliderArrow', window.width), { paddingVertical: 40, paddingRight: 40 }]}
          name={'ios-caret-back-outline'} color={'#fff'} />}
        nextButton={<Ionicons
          style={[r.get('sliderArrow', window.width), { paddingVertical: 40, paddingLeft: 40 }]}
          name={'ios-caret-forward-outline'} color={'#fff'} />}
        dotStyle={{
          backgroundColor: Constants.colors.textColor,
          opacity: 0.4,
          height: 5,
          width: 5,
          // borderWidth: 1,
          // borderColor: '#000',
        }}
        activeDotStyle={{
          backgroundColor: Constants.colors.textColor,
          height: 7,
          width: 7,
        }}
        containerStyle={r.get('slider', window.width)}
      >
        {images.map((i, index) => {
          const width = store.slideLayouts[index] && store.slideLayouts[index].width;
          const height = store.slideLayouts[index] && store.slideLayouts[index].height;
          // const aspectRatio = width/height;
          console.log({ index, width, height });

          return (
            <View style={r.get('sliderSlide', window.width)} key={index}
                  onLayout={(event) => setSlideLayout(index, event)}>
              <Lightbox
                swipeToDismiss={false}
                renderContent={() => (
                  <ScrollView
                    minimumZoomScale={1}
                    maximumZoomScale={4}
                    centerContent={true}
                    style={{ flex: 1 }}
                    contentContainerStyle={{ flex: 1 }}
                  >
                    <Image
                      style={[{ flex: 1, backgroundColor: '#000' }]}
                      source={{ uri: i.sizes.xl }}
                      resizeMode={'contain'}
                    />
                  </ScrollView>
                )}
              >
                <Image
                  style={[
                    r.get('sliderImg', window.width),
                    {
                      height,
                      width,
                    },
                  ]}
                  source={i.source}
                  resizeMode={'contain'}
                />
              </Lightbox>
            </View>
          );
        })}
      </Swiper>
    </View>
  );
});

const baseStyles = StyleSheet.create({
  container: Platform.OS === 'web' ? {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
    paddingTop: 20,
  } : {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingTop: 0,
  },
  containerInner: {
    minHeight: '50%',
    maxWidth: 800,
    width: '100%',
    backgroundColor: Constants.colors.backgroundSecondary,

    ...Platform.select({
      web: {
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        overflow: 'hidden',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: -15 },
        shadowOpacity: 0.15,
        shadowRadius: 7,
        elevation: 3,
      },
      // ios: {
      //   borderWidth: 1,
      //   borderColor: '#ddd',
      // },
    }),
  },
  closeIcon: {
    color: Constants.colors.textColor,
  },
  head: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  title: {
    flex: 1,
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    color: Constants.colors.textColor,
  },
  errors: {
    marginBottom: 0,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  sliderImgWrap: {
    // flex: 1,
    paddingBottom: 10,
  },
  sliderSlide: {
    flex: 1,
  },
  sliderImg: {
    backgroundColor: '#000',
  },
  slider: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  sliderArrow: {
    textShadowColor: '#00000078',
    textShadowRadius: 3,
    textShadowOffset: {
      width: 0,            // These can't both be 0
      height: 0,           // i.e. the shadow has to be offset in some way
    },
  },
  ideaHeader: {
    flex: 1,
  },
  ideaSymbol: {
    fontWeight: '600',
    fontSize: 20,
    marginRight: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  ideaStatus: {
    fontSize: 14,
    fontWeight: '600',
    textTransform: 'uppercase',
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  ideaHeadSubText: {
    fontSize: 16,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    textTransform: 'capitalize',
    color: Constants.colors.textColor,
  },
  textSep: {
    paddingHorizontal: 5,
    fontSize: 14,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  ideaFooter: {
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 40,
  },
  ideaFooterRight: {
    paddingLeft: 10,
  },
  ideaAuthorImg: {
    width: 30,
    height: 30,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  ideaAuthor: {
    fontSize: 15,
    fontWeight: '700',
    fontFamily: Constants.fontFamily,
    lineHeight: 15,
    color: Constants.colors.textColor,
  },
  ideaDate: {
    fontSize: 12,
    fontFamily: Constants.fontFamily,
    lineHeight: 12,
    fontWeight: '500',
    opacity: 0.7,
    color: Constants.colors.textColor,
  },
  ideaBodyRow: {
    flexDirection: 'row',
  },
  ideaBodyCol: {
    flex: 1,
    marginBottom: 7,
  },
  ideaBodyLabel: {
    textTransform: 'uppercase',
    opacity: 0.8,
    fontSize: 13,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
  },
  ideaBodyValue: {
    fontSize: 16,
    fontFamily: Constants.fontFamily,
    fontWeight: '500',
    color: Constants.colors.textColor,
  },
  ideaBodyListNumber: {
    backgroundColor: '#eee',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    color: '#000',
    fontSize: 12,
    fontWeight: '700',
    fontFamily: Constants.fontFamily,
    marginRight: 8,
    height: 17,
    width: 17,
    borderRadius: 8,
    overflow: 'hidden',
    textAlign: 'center',
    ...(Platform.select({
      web: {
        lineHeight: 17,
      },
      ios: {
        lineHeight: 14,
      },
      android: {
        lineHeight: 14,
      },
    }))
  },
  ideaBodyExits: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  ideaBodyExit: {
    flexDirection: 'row',
    marginRight: 20,
    marginTop: 5,
    alignItems: 'center',
  },
  body: {
    flex: 1,
  },
  ideaBody: {
    flexDirection: 'row',
    flex: 1,
    padding: 40,
  },
  ideaBodyNoImgs: {
    paddingTop: 20,
    marginTop: -20,
    borderTopWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  ideaData: {},
  ideaDesc: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    backgroundColor: Constants.colors.backgroundThird,
    padding: 30,
    alignSelf: 'flex-start',
    borderRadius: 8,
  },
});

const stylesSM = StyleSheet.create({
  ideaBody: {
    flexDirection: 'column',
    padding: 15,
    paddingTop: 40,
  },
  ideaData: {
    marginBottom: 20,
  },
  ideaDesc: {
    width: '100%',
  },
  head: {
    paddingHorizontal: 15,
    paddingVertical: 15,
  },
  ideaBodyExit: {
    width: '44%',
  },
});

const stylesMD = StyleSheet.create({
  ideaBody: {
    flexDirection: 'column',
  },
  ideaData: {
    marginBottom: 20,
  },
});

const stylesLG = StyleSheet.create({
  ideaDesc: {
    flex: 1,
  },
  ideaData: {
    flex: 1,
  },
});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
