import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator, ScrollView, TextInput
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import appState from "../../app.state";
import { useState } from "react";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";

export default function UserCreateScreen({ navigation }) {
  const window = useWindowDimensions();
  const ww = window.width;

  const [submitting, setSubmitting] = useState(false);
  const [e, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const fieldError = (f) => (e && e.errors && e.errors[f]) || [];

  const formSubmit = () => {
    if (submitting) return;

    (async () => {
      try {
        setSubmitting(true);
        setError(false);

        const { id } = await appState.appApi.createUser({
          email,
          password,
          password_confirmation: passwordConfirmation,
        });

        navigation.navigate('EditUser', { id });
      } catch (e) {
        console.error(e);
        setError(e);
      } finally {
        setSubmitting(false);
      }
    })();
  };

  return (
    <DashboardLayout>
      <ScrollView>
        <View style={r.get('container', ww)}>
          <View style={r.get('body', ww)}>
            <Text style={r.get('heading', ww)}>Create User</Text>

            <Errors errors={fieldError('email')} />

            <TextInput
              style={r.get('input', window.width)}
              onChangeText={setEmail}
              value={email}
              keyboardType={'email-address'}
              placeholder={'Email'}
              placeholderTextColor={'#aaa'}
            />

            <Errors errors={fieldError('password')} />

            <TextInput
              style={r.get('input', window.width)}
              onChangeText={setPassword}
              value={password}
              secureTextEntry={true}
              placeholder={'Set Account Password'}
              placeholderTextColor={'#aaa'}
            />

            <Errors errors={fieldError('password_confirmation')} />

            <TextInput
              style={r.get('input', window.width)}
              onChangeText={setPasswordConfirmation}
              value={passwordConfirmation}
              secureTextEntry={true}
              placeholder={'Password Confirmation'}
              placeholderTextColor={'#aaa'}
            />

            <HoverTouchableOpacity onPress={formSubmit} style={r.get('btn', window.width)}>
              {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
              <Text style={r.get('btnText', window.width)}>Create User</Text>
            </HoverTouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </DashboardLayout>
  );
}

function makeTitle(slug) {
  var words = slug.split('-');

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(' ');
}

function Errors({ errors }) {
  const window = useWindowDimensions();
  const ww = window.width;
  if (!errors || errors.length === 0) return;

  return (
    <View style={r.get('errors', ww)}>
      {errors.map((e) => (
        <Text key={e} style={r.get('error', ww)}>{e}</Text>
      ))}
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  body: {
    padding: 40,
    backgroundColor: Constants.colors.backgroundSecondary,
    maxWidth: 900,
    width: '100%',
    margin: 20,
    borderRadius: 8,

    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 7,

    elevation: 3,

    overflow: 'hidden',

    minHeight: 500,
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  row: {
    paddingHorizontal: 25,
    paddingVertical: 15,
    backgroundColor: Constants.colors.backgroundThird,
    borderRadius: 8,

    borderColor: Constants.colors.borderColor,
    borderWidth: 1,

    overflow: 'hidden',
    marginVertical: 5,

    cursor: 'pointer',

    flexDirection: 'row',
  },
  rowTitle: {
    fontSize: 15,
    fontWeight: '600',
    color: Constants.colors.textColor,
  },
  rowCol: {
    flex: 1,
    justifyContent: 'center',
  },
  rowColLast: {
    alignItems: 'flex-end',
  },
  form: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: '#ffffff',
    padding: 30,
    borderRadius: 5,
    marginBottom: 20,
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  btn: {
    backgroundColor: Constants.colors.accent,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnText: {
    color: Constants.colors.backgroundSecondary,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },

  linkText: {
    color: '#000',
    fontSize: 14,
    fontFamily: Constants.fontFamily,
  },
  errors: {
    marginBottom: 10,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
});

const stylesSM = StyleSheet.create({});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
