import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator,
  Image, TextInput,
  ScrollView,
  Platform,
  Switch,
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import appState from '../../app.state';
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import Ionicons from '@expo/vector-icons/Ionicons';
import { useCallback, useEffect, useState } from "react";
import SplashScreen from "./Splash";
import { fmtD, fmtDT } from "../utilities/fmtDate";
import fmtAttachment from "../utilities/fmtAttachment";
import { DateTime } from "luxon";
import * as ImagePicker from 'expo-image-picker';
import { useFocusEffect } from "@react-navigation/native";

export default function AccountScreen({ navigation }) {
  const window = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submittingMakePayment, setSubmittingMakePayment] = useState(false);
  const [e, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [user, setUser] = useState('');
  const [payments, setPayments] = useState('');
  const [passwordMsg, setPasswordMsg] = useState('');
  const [newImage, setNewImage] = useState(false);
  const [newImageSaving, setNewImageSaving] = useState(false);
  const [displayNameMsg, setDisplayNameMsg] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [autoRenew, setAutoRenew] = useState(false);
  const [submittingProfile, setSubmittingProfile] = useState(false);

  const fieldError = (f) => (e && e.errors && e.errors[f]) || [];

  useFocusEffect(useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const u = appState.authService.user;
        setUser(u)
        setDisplayName(u.display_name || '');
        setPayments(await appState.appApi.getPayments({ user_id: u.id }));
        setPaymentOptions(await appState.appApi.getPaymentOptions());
        setAutoRenew(u.membership && u.membership.auto_renew);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [navigation]));

  if (loading) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  console.log({ user });

  const changePass = () => {
    if (submitting) return;
    (async () => {
      try {
        setSubmitting(true);
        await appState.appApi.updateUser(user.id, {
          password,
          password_confirmation: passwordConfirmation,
        });
        setPasswordMsg('Password Changed');
      } catch (e) {
        console.error(e);
        setError(e);
      } finally {
        setSubmitting(false);
      }
    })();
  };

  const makePayment = () => {
    if (submittingMakePayment) return;
    (async () => {
      try {
        setSubmittingMakePayment(true);
        const POs = await appState.appApi.getPaymentOptions();

        if (!user.membership) {
          const { payment } = await appState.appApi.createMembership({
            payment_option_id: POs[0].id,
            user_id: user.id,
          });
          navigation.navigate('Pay', { id: payment.id });
        } else {
          const p = await appState.appApi.createPayment({
            membership_id: user.membership.id,
            payment_option_id: POs[0].id,
          });
          navigation.navigate('Pay', { id: p.id });
        }
      } catch (e) {
        console.error(e);
        alert('Failed to initiate payment.');
      } finally {
        setSubmittingMakePayment(false);
      }
    })();
  };

  const makeCardPayment = () => {
    if (submittingMakePayment) return;
    (async () => {
      try {
        setSubmittingMakePayment(true);
        const POs = await appState.appApi.getPaymentOptions();
        const po = POs[0];

        if (!user.membership) {
          const { membership } = await appState.appApi.createMembership({
            payment_option_id: po.id,
            payment_method: 'pf_card',
            user_id: user.id,
          });
          setTimeout(() => navigation.navigate('CardPay', { membership_id: membership.id, payment_option_id: po.id, }), 10);
        } else {
          setTimeout(() => navigation.navigate('CardPay', { membership_id: user.membership.id, payment_option_id: po.id, }), 10);
        }
      } catch (e) {
        console.error(e);
        alert('Failed to initiate payment.');
      } finally {
        setSubmittingMakePayment(false);
      }
    })();
  };

  const selectProfileImg = () => {
    (async () => {
      try {
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsEditing: true,
          aspect: [1, 1],
          quality: 1,
        });

        console.log(result);

        if (result.cancelled) return;

        setNewImage(result.uri);
        //setNewImageSaving(true);

        // const aId = await appState.appApi.uploadAttachment(result.uri);
        // await appState.appApi.updateUser(user.id, { profile_img_id: aId });
        //
        // setUser(await appState.appApi.whoami());

      } catch (e) {
        console.error(e);
        //alert('Failed to upload profile image');
      } finally {
        //setNewImageSaving(false);
        // setNewImage(false);
      }
    })();
  };

  const saveProfile = () => {
    if (submittingProfile) return;
    (async () => {
      try {
        setSubmittingProfile(true);

        const updateData = { display_name: displayName };
        if (newImage) {
          updateData.profile_img_id = await appState.appApi.uploadAttachment(newImage);
        }
        await appState.appApi.updateUser(user.id, updateData);
        if (user.membership) {
          await appState.appApi.updateMembership(user.membership.id, { auto_renew: autoRenew });
        }

        const u = await appState.appApi.whoami();
        setUser(u);
        setDisplayName(u.display_name || '');

        setDisplayNameMsg('Profile saved.');
      } catch (e) {
        setError(e);
        console.error(e);
      } finally {
        setSubmittingProfile(false);
      }
    })();
  };



  const po = paymentOptions[0];
  const uProfImg = fmtAttachment(user.profile_img);
  console.log({ uProfImg, newImage });

  return (
    <DashboardLayout>
      <ScrollView>
        <View style={responsive.get('container', window.width)}>
          {Platform.OS === 'web' && (
            <Text style={responsive.get('title', window.width)}>Account</Text>
          )}

          <View style={responsive.get('row', window.width)}>
            <View style={[responsive.get('column', window.width), responsive.get('profileColumn', window.width)]}>
              <Text style={responsive.get('heading', window.width)}>Profile</Text>

              {!!displayNameMsg && (
                <Text style={responsive.get('successMsg', window.width)}>{displayNameMsg}</Text>
              )}

              <Errors errors={fieldError('profile_img_id')} />

              <View style={responsive.get('profileHead', window.width)}>
                <HoverTouchableOpacity onPress={() => selectProfileImg()}  style={responsive.get('profileImageWrap', window.width)}>
                  <Image source={{ uri: newImage ? newImage : uProfImg && uProfImg.sizes.sm_crop }} style={responsive.get('profileImage', window.width)} />

                  <View style={responsive.get('profileEditBtn', window.width)}>
                    {newImageSaving ? (
                      <ActivityIndicator color={'#fff'} />
                    ) : (
                      <Ionicons name="ios-create-outline" size={18} color={'#fff'} />
                    )}
                  </View>
                </HoverTouchableOpacity>

                <View>
                  <Text style={responsive.get('email', window.width)}>{user.email}</Text>
                  <Text style={responsive.get('exp', window.width)}>
                    {user.membership_active ? 'Active' : 'Inactive'} - Exp: {user.membership && fmtD(user.membership.expiration)}
                  </Text>
                  {user.membership && (
                    <>
                      <View style={responsive.get('autoRenewWrap', window.width)}>
                        <Switch
                          trackColor={{ false: "#767577", true: "#767577" }}
                          thumbColor={"#f4f3f4"}
                          activeTrackColor={"#767577"}
                          activeThumbColor={"#f4f3f4"}
                          onValueChange={(val) => setAutoRenew(val)}
                          value={autoRenew}
                        />
                        <Text style={responsive.get('autoRenewText', window.width)}>Auto Renew</Text>
                      </View>
                      {user.membership.auto_pay_failures_count > 0 && (
                        <View style={responsive.get('autoRenewFails', window.width)}>
                          <Text style={responsive.get('error', window.width)}>Auto pay is failing.</Text>
                        </View>
                      )}
                      <View style={responsive.get('manageCardsWrap', window.width)}>
                        <View>
                          <HoverTouchableOpacity onPress={() => navigation.navigate('ManageCards')} style={[responsive.get('btnSm', window.width), responsive.get('btnLeft', window.width), responsive.get('paymentBtn', window.width)]}>
                            <Text style={responsive.get('btnTextSm', window.width)}>Manage Cards</Text>
                          </HoverTouchableOpacity>
                        </View>
                      </View>
                    </>
                  )}
                </View>
              </View>

              <Errors errors={fieldError('display_name')} />

              <TextInput
                style={responsive.get('input', window.width)}
                onChangeText={setDisplayName}
                value={displayName}
                placeholder={'Display Name'}
                placeholderTextColor={'#aaa'}
              />

              <HoverTouchableOpacity onPress={() => saveProfile()} style={[responsive.get('btn', window.width), responsive.get('btnLeft', window.width)]}>
                {submittingProfile && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
                <Text style={responsive.get('btnTextSm', window.width)}>Save Profile</Text>
              </HoverTouchableOpacity>

              <View style={responsive.get('div', window.width)} />

              <Text style={responsive.get('heading', window.width)}>Change Password</Text>

              {!!passwordMsg && (
                <Text style={responsive.get('successMsg', window.width)}>{passwordMsg}</Text>
              )}

              <Errors errors={fieldError('password')} />

              <TextInput
                style={responsive.get('input', window.width)}
                onChangeText={setPassword}
                value={password}
                secureTextEntry={true}
                placeholder={'New Password'}
                placeholderTextColor={'#aaa'}
              />

              <Errors errors={fieldError('password_confirmation')} />

              <TextInput
                style={responsive.get('input', window.width)}
                onChangeText={setPasswordConfirmation}
                value={passwordConfirmation}
                secureTextEntry={true}
                placeholder={'Password Confirmation'}
                placeholderTextColor={'#aaa'}
              />

              <HoverTouchableOpacity onPress={() => changePass()} style={[responsive.get('btn', window.width), responsive.get('btnLeft', window.width)]}>
                {submitting && <ActivityIndicator style={{ marginRight: 10 }} color={'#fff'} />}
                <Text style={responsive.get('btnTextSm', window.width)}>Change Password</Text>
              </HoverTouchableOpacity>
            </View>

            <View style={[responsive.get('column', window.width), responsive.get('paymentsColumn', window.width)]}>
              <Text style={[responsive.get('heading', window.width), { marginBottom: 5 }]}>Payments</Text>

              <Text style={responsive.get('makePayDesc', window.width)}>
                {`$${po.usd} for ${po.interval} ${po.interval_type}${po.interval > 1 ? 's' : ''} of membership.`}
              </Text>

              <HoverTouchableOpacity onPress={() => makePayment()} style={[responsive.get('btnSm', window.width), responsive.get('btnLeft', window.width), responsive.get('paymentBtn', window.width)]}>
                <Text style={responsive.get('btnTextSm', window.width)}>Pay with ETH</Text>
              </HoverTouchableOpacity>

              <HoverTouchableOpacity onPress={() => makeCardPayment()} style={[responsive.get('btnSm', window.width), responsive.get('btnLeft', window.width), responsive.get('paymentBtn', window.width)]}>
                <Text style={responsive.get('btnTextSm', window.width)}>Pay with Card</Text>
              </HoverTouchableOpacity>

              <View style={responsive.get('payments', window.width)}>
                {payments && payments.data && payments.data.map((p) => {
                  let status = p.status === 'paid' ? 'Paid' : 'Pending';
                  if (p.status !== 'paid' && DateTime.fromISO(p.created_at).plus(1000 * 60 * 60 * 4).toMillis() < DateTime.now()) {
                    status = 'Expired';
                  }

                  return (
                    <HoverTouchableOpacity onPress={() => navigation.navigate('Pay', { id: p.id })} key={p.id} style={responsive.get('payment', window.width)}>
                      <View style={responsive.get('paymentInner', window.width)}>
                        <View style={responsive.get('paymentHead', window.width)}>
                          <Text style={responsive.get('paymentAmt', window.width)}>{p.currency === 'USD' ? parseFloat(p.amount).toFixed(2) : p.amount} {p.currency}</Text>
                          <Text style={responsive.get('paymentStatus', window.width)}>{status}</Text>
                        </View>

                        <Text style={responsive.get('paymentDesc', window.width)}>{`For ${p.interval} ${p.interval_type}${p.interval > 1 ? 's' : ''} of membership`}</Text>
                        <Text style={responsive.get('paymentDate', window.width)}>Created: {fmtDT(p.created_at)}</Text>
                      </View>

                      <Ionicons style={responsive.get('paymentArrow', window.width)} name="ios-chevron-forward-outline" size={24} color="#000" />
                    </HoverTouchableOpacity>
                  );
                })}

              </View>
            </View>
          </View>

          <View style={responsive.get('footer', window.width)}>
            <HoverTouchableOpacity onPress={() => appState.authService.logout()} style={[responsive.get('btnSm', window.width), responsive.get('btnLeft', window.width)]}>
              <Text style={responsive.get('btnTextSm', window.width)}>Logout</Text>
            </HoverTouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </DashboardLayout>
  );
}

function Errors({ errors }) {
  if (!errors || errors.length === 0) return;

  return (
    <View style={responsive.get('errors', window.width)}>
      {errors.map((e) => (
        <Text key={e} style={responsive.get('error', window.width)}>{e}</Text>
      ))}
    </View>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    marginBottom: 40,
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 1000,
  },
  title: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginVertical: 30,
    color: Constants.colors.textColor,
  },
  profileHead: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  heading: {
    fontSize: 18,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  row: {
    flexDirection: 'row',
    flex: 1,
    maxWidth: 1000,
    width: '100%',
  },
  column: {
    backgroundColor: Constants.colors.backgroundSecondary,
    padding: 30,
    borderRadius: 8,
    marginBottom: 30,

    overflow: 'hidden',

    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,

    elevation: 3,
  },
  profileColumn: {
    flex: 1,
    marginRight: 10,
  },
  paymentsColumn: {
    width: 300,
  },
  btn: {
    borderColor: Constants.colors.accent,
    borderWidth: 1,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnSm: {
    borderColor: Constants.colors.accent,
    borderWidth: 1,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnText: {
    color: Constants.colors.backgroundSecondary,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  btnTextSm: {
    color: Constants.colors.accent,
    // textTransform: 'uppercase',
    // letterSpacing: 1,
    fontWeight: '600',
    fontSize: 16,
    fontFamily: Constants.fontFamily,
  },
  profileEditBtn: {
    position: 'absolute',
    top: -10,
    right: -10,
    backgroundColor: '#000',
    height: 32,
    width: 32,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  profileImageWrap: {
    height: 80,
    width: 80,
    marginRight: 20,
  },
  profileImage: {
    height: 80,
    width: 80,
    backgroundColor: Constants.colors.backgroundSecondary,
    borderRadius: 50,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  email: {
    fontSize: 20,
    color: Constants.colors.textColor,
  },
  exp: {
    color: Constants.colors.textColor,
  },
  div: {
    marginVertical: 30,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
  },
  errors: {
    marginBottom: 10,
  },
  error: {
    color: 'red',
    fontStyle: 'italic',
  },
  input: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  btnLeft: {
    alignSelf: 'flex-start',
  },
  payments: {
    marginVertical: 30,
    marginHorizontal: -30,
  },
  payment: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 30,
    paddingVertical: 15,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginBottom: -1,
  },
  paymentInner: {
    flex: 1,
  },
  paymentHead: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  paymentAmt: {
    fontWeight: '500',
    fontSize: 16,
    marginRight: 10,
    color: Constants.colors.textColor,
  },
  paymentStatus: {
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    borderRadius: 5,
    paddingVertical: 2,
    paddingHorizontal: 8,
    color: Constants.colors.textColor,
  },
  paymentDesc: {
    fontStyle: 'italic',
    color: Constants.colors.textColor,
  },
  paymentDate: {
    fontSize: 12,
    color: Constants.colors.textColor,
  },
  successMsg: {
    color: '#000',
    backgroundColor: '#d0ffe3',
    borderWidth: 1,
    borderColor: '#33a963',
    padding: 15,
    borderRadius: 5,
    marginBottom: 20,
  },
  makePayDesc: {
    fontSize: 11,
    fontStyle: 'italic',
    marginBottom: 10,
    color: Constants.colors.textColor,
  },
  paymentBtn: {
    marginBottom: 10,
  },
  autoRenewWrap: {
    flexDirection: 'row',
    marginTop: 10,
  },
  autoRenewText: {
    color: Constants.colors.textColor,
    marginLeft: 10,
  },
  manageCardsWrap: {
    marginTop: 10,
  },
});

const stylesSM = StyleSheet.create({
  row: {
    flexDirection: 'column',
  },
  column: {
    borderRadius: 0,
  },
  profileColumn: {
    marginRight: 0,
  },
  paymentsColumn: {
    width: '100%',
  },
  payments: {
    marginBottom: -30,
  },
  footer: {
    alignItems: 'center',
  },
});

const stylesMD = StyleSheet.create({

});

const stylesLG = StyleSheet.create({

});

const responsive = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
