import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  useWindowDimensions,
  ActivityIndicator, TouchableOpacity, Image, TextInput, ScrollView,
  Platform,
} from 'react-native';
import Responsive from "../utilities/responsive";
import Constants from "../../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import DraggableFlatList, { ScaleDecorator } from "react-native-draggable-flatlist";
import HoverTouchableOpacity from "../components/HoverTouchableOpacity";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useCallback, useEffect, useRef, useState } from "react";
import appState from "../../app.state";
import SplashScreen from "./Splash";
import { useFocusEffect } from "@react-navigation/native";

export default function EditLearnCategoriesScreen({ navigation }) {
  const window = useWindowDimensions();
  const ww = window.width;

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const deletedCategories = useRef([]);
  const [submitting, setSubmitting] = useState(false);

  const updateCategoryName = (index, text) => {
    const cats = JSON.parse(JSON.stringify(categories));
    cats[index].name = text;
    setCategories(cats);
  };

  const setSubCategories = (index, data) => {
    const cats = JSON.parse(JSON.stringify(categories));
    cats[index].sub_categories = data;
    setCategories(cats);
  };

  const addSubCategory = (index) => {
    const cats = JSON.parse(JSON.stringify(categories));
    cats[index].sub_categories.push({
      parent_id: cats[index].id,
      new: true,
      name: '',
      slug: makeid(5)
    });
    setCategories(cats);
  };

  const updateSubCategoryName = (parent_id, index, value) => {
    const cats = JSON.parse(JSON.stringify(categories));
    const pIdx = cats.findIndex((c) => c.id === parent_id);
    cats[pIdx].sub_categories[index].name = value;
    setCategories(cats);
  };

  const deleteSubCategory = (parent_id, index) => {
    const cats = JSON.parse(JSON.stringify(categories));
    const pIdx = cats.findIndex((c) => c.id === parent_id);

    const category = cats[pIdx].sub_categories[index];
    deletedCategories.current.push(category);

    cats[pIdx].sub_categories.splice(index, 1);
    setCategories(cats);
  };

  const toggleSubCategory = (index) => {
    const cats = JSON.parse(JSON.stringify(categories));
    const isOpen = !!(cats[index] && cats[index].sub_categories_open);
    cats[index].sub_categories_open = !isOpen;
    setCategories(cats);
  };

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-');
  }

  const save = () => {
    (async () => {
      try {
        if (submitting) return;
        setSubmitting(true);

        await Promise.all(categories.reduce((acc, c) => ([...acc, c, ...(c.sub_categories ? c.sub_categories : [])]), []).map(async (c, i) => {
          const data = { ...c, slug: convertToSlug(c.name), order: i };
          if (!c.new) {
            await appState.appApi.updateLearnCategory(c.id, data);
          } else {
            await appState.appApi.createLearnCategory(data);
          }
        }));

        await Promise.all(deletedCategories.current.map(async (c) => {
          await appState.appApi.deleteLearnCategory(c.id);
        }));
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    })();
  };

  const deleteCategory = (i) => {
    const category = categories[i];
    deletedCategories.current.push(category);

    const cats = JSON.parse(JSON.stringify(categories));
    cats.splice(i, 1);
    setCategories(cats);
  };

  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  const addCategory = () => {
    const cats = JSON.parse(JSON.stringify(categories));
    setCategories([...cats, { new: true, name: '', slug: makeid(5) }]);
  };

  useFocusEffect(useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const catsRes = await appState.appApi.getLearnCategories();
        setCategories(catsRes);
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false);
      }
    })();
  }, [navigation]));

  if (loading) return <DashboardLayout><SplashScreen /></DashboardLayout>;

  const renderItem = ({ item, drag, isActive, getIndex }) => {
    console.log({ item: item, parent_id: parseInt(item.parent_id) });
    return (
      <ScaleDecorator>
        <View style={[r.get('category', window.width)]}>
          <TouchableOpacity
            activeOpacity={0.9}
            onPressIn={drag}
            disabled={isActive}
            style={[r.get('moveWrap', window.width)]}
          >
            <Ionicons name="ios-move-outline" size={22} color={Constants.colors.textColor} />
          </TouchableOpacity>
          <View style={[r.get('categoryContent', window.width)]}>
            <View style={r.get('formGroup', window.width)}>
              <Text style={[r.get('label', window.width)]}>Name</Text>

              <TextInput
                style={r.get('input', window.width)}
                onChangeText={(text) => !item.parent_id ? updateCategoryName(getIndex(), text) : updateSubCategoryName(item.parent_id, getIndex(), text)}
                value={item.name}
              />
            </View>
          </View>

          <HoverTouchableOpacity
            containerStyle={[r.get('iconCloseWrap', window.width), r.get('iconCloseWrapRight', window.width)]}
            style={[r.get('iconClose', window.width), r.get('iconCloseDelete', window.width)]}
            onPress={() => !item.parent_id ? deleteCategory(getIndex()) : deleteSubCategory(item.parent_id, getIndex())}
          >
            <Ionicons name="ios-close-outline" size={22} color={Constants.colors.textColor}
                      accessibilityLabel="Delete Category" />
          </HoverTouchableOpacity>
        </View>

        {!item.parent_id && (
          <View style={[r.get('toggleSubCatBtnWrap', window.width)]}>
            <HoverTouchableOpacity
              style={[{ flexDirection: 'row', alignItems: 'center' }]}
              onPress={() => toggleSubCategory(getIndex())}
            >
              <View style={[r.get('toggleSubCatBtn', window.width)]}>
                <Ionicons
                  name={item.sub_categories_open ? 'caret-up-circle-outline' : 'caret-down-circle-outline'}
                  size={22} color={Constants.colors.textColor}
                  accessibilityLabel="Toggle Sub Category" />
              </View>
              <Text style={[{ color: Constants.colors.textColor }]}>Sub Categories</Text>
            </HoverTouchableOpacity>
          </View>
        )}

        {!item.parent_id && item.sub_categories_open && (
          <View style={[r.get('subCategories', window.width)]}>
            <DraggableFlatList
              data={item.sub_categories || []}
              contentContainerStyle={r.get('subContentContainer', ww)}
              // horizontal={true}
              onDragEnd={({ data }) => setSubCategories(getIndex(), data)}
              keyExtractor={(item) => item.slug}
              renderItem={renderItem}
              ListFooterComponent={() => (
                <View>
                  <HoverTouchableOpacity
                    containerStyle={[r.get('iconCloseWrap', window.width)]}
                    style={r.get('iconClose', window.width)}
                    onPress={() => addSubCategory(getIndex())}
                  >
                    <Ionicons name="ios-add-outline" size={22} color={Constants.colors.textColor}
                              accessibilityLabel="Add Category" />
                  </HoverTouchableOpacity>
                </View>
              )}
            />
          </View>
        )}
      </ScaleDecorator>
    );
  };

  return (
    <DashboardLayout>
      <ScrollView>
        <View style={r.get('container', ww)}>
          <View style={r.get('body', ww)}>
            <DraggableFlatList
              data={categories}
              contentContainerStyle={r.get('contentContainer', ww)}
              // horizontal={true}
              onDragEnd={({ data }) => setCategories(data)}
              keyExtractor={(item) => item.slug}
              ListHeaderComponent={() => (
                <>
                  {Platform.OS === 'web' && (
                    <View style={{ alignItems: 'center', }}>
                      <View style={{
                        maxWidth: 900,
                        width: '100%',
                        marginTop: 20,
                        marginBottom: 10,
                        alignItems: 'flex-start'
                      }}>
                        <HoverTouchableOpacity onPress={() => navigation.navigate('Learn')}
                                               style={r.get('goBackBtn', ww)}>
                          <Ionicons name="ios-chevron-back-outline" size={22}
                                    color={Constants.colors.textColor} />
                          <Text style={r.get('goBackText', ww)}>Go Back</Text>
                        </HoverTouchableOpacity>
                      </View>
                    </View>
                  )}

                  {Platform.OS === 'web' && (
                    <Text style={r.get('heading', ww)}>Edit Learn Categories</Text>
                  )}
                </>
              )}
              renderItem={renderItem}
              ListFooterComponent={() => (
                <View>
                  <HoverTouchableOpacity
                    containerStyle={[r.get('iconCloseWrap', window.width)]}
                    style={r.get('iconClose', window.width)}
                    onPress={() => addCategory()}
                  >
                    <Ionicons name="ios-add-outline" size={22} color={Constants.colors.textColor}
                              accessibilityLabel="Add Category" />
                  </HoverTouchableOpacity>

                  <HoverTouchableOpacity
                    onPress={() => save()}
                    containerStyle={[r.get('btnLeft', ww), { marginTop: 20 }]}
                    style={[r.get('btn', ww)]}
                  >
                    {submitting && <ActivityIndicator style={{ marginRight: 10 }}
                                                      color={Constants.colors.textColor} />}
                    <Text style={r.get('btnText', ww)}>Save</Text>
                  </HoverTouchableOpacity>
                </View>
              )}
            />
          </View>
        </View>
      </ScrollView>
    </DashboardLayout>
  );
}

const baseStyles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    backgroundColor: Constants.colors.backgroundPrimary,
  },
  body: {
    maxWidth: 900,
    width: '100%',
    borderRadius: 8,

    // overflow: 'hidden',

    // minHeight: 500,
  },
  contentContainer: {
    paddingVertical: 40,
    paddingHorizontal: 40,
  },
  subContentContainer: {
    paddingLeft: 25,
    paddingBottom: 10,
  },
  heading: {
    fontSize: 22,
    fontWeight: '500',
    fontFamily: Constants.fontFamily,
    marginRight: 20,
    marginBottom: 20,
    color: Constants.colors.textColor,
  },
  btnLeft: {
    alignSelf: 'flex-start',
  },
  btn: {
    // backgroundColor: '#000',
    borderColor: Constants.colors.accent,
    borderWidth: 1,
    padding: 15,
    paddingHorizontal: 30,
    borderRadius: 8,
    marginVertical: 10,
    letterSpacing: 1,
    textTransform: 'uppercase',
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnText: {
    fontWeight: '500',
    color: Constants.colors.accent,
    fontSize: 16,
  },
  category: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    marginBottom: 10,
    borderRadius: 8,
    backgroundColor: Constants.colors.backgroundSecondary,
  },
  label: {
    fontSize: 16,
    color: Constants.colors.textColor,
  },
  formGroup: {
    width: '100%',
  },
  input: {
    width: '100%',
    borderWidth: 1,
    borderColor: Constants.colors.borderColor,
    padding: 15,
    borderRadius: 5,
    marginTop: 5,
    fontFamily: Constants.fontFamily,
    color: Constants.colors.textColor,
    outlineStyle: 'none',
  },
  moveWrap: {
    // height: '100%',
    justifyContent: 'center',
    padding: 10,
    marginRight: 5,
  },
  categoryContent: {
    padding: 15,
    borderLeftWidth: 1,
    borderColor: Constants.colors.borderColor,
    flex: 1,
    paddingRight: 60,
  },
  iconCloseWrap: {
    alignSelf: 'flex-start',
  },
  iconCloseWrapRight: {
    position: 'absolute',
    top: '50%',
    marginTop: -15,
    // transform: [{ translateY: '-50%' }],
    right: 15,
  },
  iconClose: {
    backgroundColor: Constants.colors.backgroundSecondary,
    borderWidth: 1,
    borderColor: Constants.colors.textColor,
    width: 32,
    height: 32,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  iconCloseDelete: {
    backgroundColor: '#b11a1a',
  },
  goBackBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    // padding: 15,
    // paddingVertical: 5,
    borderRadius: 8,
    cursor: 'pointer',
  },
  goBackText: {
    color: Constants.colors.textColor,
  },
  toggleSubCatBtnWrap: {
    paddingHorizontal: 25,
    paddingBottom: 15,
  },
});

const stylesSM = StyleSheet.create({
  contentContainer: {
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
});

const stylesMD = StyleSheet.create({});

const stylesLG = StyleSheet.create({});

const r = new Responsive(
  Constants.breakpoints,
  [stylesSM, stylesMD, stylesLG],
  baseStyles
);
